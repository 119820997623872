

<!-- メイン -->
<div class="main">
  <div class="main-content">
    <div class="p-fluid p-formgrid p-grid search-screen">
      <div class="p-col-12">
        <div class="p-fluid p-formgrid p-grid search-screen-col grid">
          <div class="p-col-3 p-lg-3 p-md-3 p-xl-3 p-flex-sm-nowrap side-left">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12">
                <form
                  [formGroup]="c241SearchForm"
                  (ngSubmit)="getResultTaskDataList(true)"
                >
                  <p-accordion [multiple]="true">
                    <p-accordionTab
                      header="地域"
                      [disabled]="isDisabledTab('agenda_need_area')"
                      [(selected)]="accordion_agenda_need_area"
                    >
                      <div class="accrodion-form-list">
                        <div class="btn-check">
                          <span
                            ><a
                              href="javascript:void(0);"
                              (click)="
                                checkLargeCategory(
                                  'checkAll',
                                  getDicList(102),
                                  'agenda_need_area'
                                )
                              "
                              >すべて選択</a
                            > </span
                          >　|　
                          <span class="p-ml-2"
                            ><a
                              href="javascript:void(0);"
                              (click)="
                                checkLargeCategory(
                                  'removeAll',
                                  getDicList(102),
                                  'agenda_need_area'
                                )
                              "
                              >解除</a
                            ></span
                          >
                        </div>
                        <div *ngFor="let dicData of getDicList(102)">
                          <p-checkbox
                            [label]="dicData.sp_name"
                            [value]="dicData.sp_code"
                            [formControl]="
                              c241SearchForm.controls.agenda_need_area
                            "
                          ></p-checkbox>
                        </div>
                      </div>
                    </p-accordionTab>
                    <p-accordionTab header="活動の場名称">
                      <input
                        id="agenda_need_title"
                        type="text"
                        pInputText
                        formControlName="agenda_need_title"
                        autocomplete="off"
                      />
                    </p-accordionTab>
                    <p-accordionTab header="活動内容">
                      <input
                        id="agenda_need_expected_content"
                        type="text"
                        pInputText
                        formControlName="agenda_need_expected_content"
                        autocomplete="off"
                      />
                    </p-accordionTab>
                    <p-accordionTab
                      header="募集種別"
                      [disabled]="
                        isDisabledTab('agenda_need_recruitment_kind')
                      "
                    >
                      <div class="accrodion-form-list">
                        <div class="btn-check">
                          <span
                            ><a
                              href="javascript:void(0);"
                              (click)="
                                checkLargeCategory(
                                  'checkAll',
                                  getDicList(106),
                                  'agenda_need_recruitment_kind'
                                )
                              "
                              >すべて選択</a
                            ></span
                          >　|　
                          <span class="p-ml-2"
                            ><a
                              href="javascript:void(0);"
                              (click)="
                                checkLargeCategory(
                                  'removeAll',
                                  getDicList(106),
                                  'agenda_need_recruitment_kind'
                                )
                              "
                              >解除</a
                            ></span
                          >
                        </div>
                        <div *ngFor="let dicData of getDicList(106)">
                          <p-checkbox
                            [label]="dicData.sp_name"
                            [value]="dicData.sp_code"
                            [formControl]="
                              c241SearchForm.controls
                                .agenda_need_recruitment_kind
                            "
                          ></p-checkbox>
                        </div>
                      </div>
                    </p-accordionTab>
                    <p-accordionTab header="開催日">
                      <p-calendar
                        [showIcon]="true"
                        [showButtonBar]="true"
                        [yearNavigator]="true"
                        [monthNavigator]="true"
                        [readonlyInput]="true"
                        placeholder=""
                        dateFormat="yy/mm/dd"
                        dataType="string"
                        appendTo="body"
                        yearRange="2000:2999"
                        formControlName="agenda_need_event_date_FROM"
                        translate
                      ></p-calendar>
                      <br />
                      <div class="verticalTilde">〜</div>
                      <p-calendar
                        [showIcon]="true"
                        [showButtonBar]="true"
                        [yearNavigator]="true"
                        [monthNavigator]="true"
                        [readonlyInput]="true"
                        placeholder=""
                        dateFormat="yy/mm/dd"
                        dataType="string"
                        appendTo="body"
                        yearRange="2000:2999"
                        formControlName="agenda_need_event_date_TO"
                        translate
                      ></p-calendar>
                    </p-accordionTab>
                  </p-accordion>
                </form>
              </div>
              <div class="p-col-12 btn-block-search">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-12">
                    <button
                      pButton
                      type="button"
                      label="検索する"
                      class="p-button-danger btn-search-item"
                      (click)="getResultTaskDataList(true); changePage($event)"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-8 item-search-detail">
            <div class="p-fluid p-formgrid p-grid detail-item">
              <div class="p-col-12">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="card">
                    <p-dataView
                      #dv
                      [value]="resultTaskDataList"
                      [paginator]="true"
                      [rows]="30"
                      filterBy="name"
                      layout="grid"
                      [sortField]="sortField"
                      [sortOrder]="sortOrder"
                      (onPage)="changePage($event)"
                      emptyMessage="検索結果はありません"
                    >
                      <ng-template pTemplate="header">
                        <div
                          class="flex flex-column md:flex-row md:justify-content-between"
                        >
                          <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-6">
                              <span
                                class="header-title-list-item primary-title"
                              >
                                活動の場リスト
                              </span>
                            </div>
                            <div class="p-col-6 icon-view-data">
                              <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                            </div>
                          </div>
                        </div>
                        <!-- 一覧画面ヘッダー表示-->
                        <ng-container *ngIf="dv.layout == 'list'">
                          <div class="dv-header-table-header">
                            <!-- dataViewヘッダー -->
                            <p-table styleClass="p-datatable-sm generate-input">
                              <!-- 一覧画面ヘッダー表示-->
                              <ng-template pTemplate="header">
                                <tr class="p-col-2">
                                  <!-- APIヘッダーの表示 -->
                                  <ng-container
                                    *ngFor="let column of columnOrder"
                                  >
                                    <th
                                      [pSortableColumn]="column.field"
                                      (click)="onSortChange(column.field)"
                                    >
                                      {{ column.header }}
                                      <p-sortIcon
                                        [field]="column.field"
                                      ></p-sortIcon>
                                    </th>
                                  </ng-container>
                                </tr>
                              </ng-template>
                            </p-table>
                          </div>
                        </ng-container>
                      </ng-template>

                      <ng-template
                        let-searchResults
                        let-rowIndex="rowIndex"
                        pTemplate="listItem"
                      >
                        <p-table
                          #table
                          [value]="[searchResults]"
                          styleClass="p-table-striped order-table"
                          selectionMode="single"
                          dataKey="delivery_id"
                          expandableRows="true"
                        >
                          <!-- 一覧画面ボディ表示-->
                          <ng-template pTemplate="body">
                            <tr class="p-col-2">
                              <!-- チェックボックス -->

                              <ng-container
                                *ngFor="let column of columnOrder; index as i"
                                [ngSwitch]="true"
                              >
                                <!-- 一覧項目値表示(詳細画面遷移項目) -->
                                <td *ngIf="0 == i; else hideContent">
                                  <label
                                    class="pointer"
                                    (click)="
                                      moveC341(searchResults[column.field])
                                    "
                                  >
                                    <u>{{ searchResults[column.field] }}</u>
                                  </label>
                                </td>
                                <!-- 一覧項目値表示 -->
                                <ng-template
                                  #hideContent
                                  [ngSwitch]="column?.input_type"
                                >
                                  <td
                                    *ngSwitchCase="'NUMBER'"
                                    class="content-item"
                                    [pTooltip]="searchResults[column.field]"
                                    #tt
                                    [tooltipDisabled]="!isOverflow(tt)"
                                    showDelay="500"
                                    hideDelay="500"
                                    tooltipPosition="bottom"
                                  >
                                    {{ searchResults[column.field] | number }}
                                  </td>
                                  <td
                                    *ngSwitchDefault
                                    class="content-item"
                                    [pTooltip]="searchResults[column.field]"
                                    #tt
                                    [tooltipDisabled]="!isOverflow(tt)"
                                    showDelay="500"
                                    hideDelay="500"
                                    tooltipPosition="bottom"
                                  >
                                    {{ searchResults[column.field] }}
                                  </td>
                                </ng-template>
                              </ng-container>
                            </tr>
                          </ng-template>
                        </p-table>
                      </ng-template>
                      <ng-template let-resultTaskDataItem pTemplate="gridItem">
                        <div class="p-col-6 item-detail-row">
                          <div class="search-item-detail">
                            <div
                              class="search-img-item"
                              (click)="
                                moveC341(
                                  resultTaskDataItem.agenda_need_agenda_id
                                )
                              "
                            >
                              <div
                                [ngStyle]="{
                                  'background-image':
                                    'url(' + resultTaskDataItem.agenda_need_icon_image + ')'
                                }"
                                class="search-item-img-img">
                              </div>
                            </div>
                            <div
                              class="search-item-description"
                              (click)="
                                moveC341(
                                  resultTaskDataItem.agenda_need_agenda_id
                                )
                              "
                            >
                              <h6 class="item-description-large" 
                                [pTooltip]="resultTaskDataItem.agenda_need_title"
                                #tt
                                [tooltipDisabled]="!isOverflow(tt)"
                                showDelay="500"
                                hideDelay="500"
                                tooltipPosition="bottom"
                              >
                                {{ resultTaskDataItem.agenda_need_title }}
                              </h6>
                              <span class="item-sub-description">{{
                                resultTaskDataItem?.member_organization_name
                              }}</span>
                              <span class="item-sub-description">{{
                                resultTaskDataItem?.agenda_need_event_date
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-dataView>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
