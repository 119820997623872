

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">

    <div class="information-margin"></div>

    <!-- メッセージ -->
    <div class="message" *ngIf="this.matchType == '0'">
      <!-- メッセージ本文 -->
      <div class="message-text">
        <div class="p-d-flex p-jc-center">
          <i class="pi pi-check-circle black-color"></i><br />
        </div>
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center black-color">応募登録を受け付けました。</div>
        <div class="p-d-flex p-jc-center tier">
          主催者から返信をお待ちください。<br>
          返信内容は、メニューの「応募・依頼」で確認することができます。
        </div>
      </div>
    </div>
    <div class="message" *ngIf="this.matchType == '1'">
      <!-- メッセージ本文 -->
      <div class="message-text">
        <div class="p-d-flex p-jc-center">
          <i class="pi pi-check-circle black-color"></i><br />
        </div>
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center black-color">依頼登録を受け付けました。</div>
        <div class="p-d-flex p-jc-center tier">
          依頼を行ったサポーターとのコミュニケーションは<br>
          「応募・依頼」メニューからサポーター毎に個別に行ってください。
        </div>
      </div>
    </div>
    <!-- ボタンエリア -->
    <div class="p-d-flex p-jc-center button-area">
      <button
        pButton
        type="button"
        [label]="'応募・依頼一覧へ'"
        class="button-default-size"
        (click)="moveC502()"
      ></button>
    </div>
  </div>
</div>
