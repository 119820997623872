import { Component, OnInit } from '@angular/core';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private loginService: LoginService,
    private loadingState: LoadingState
  ) {}

  // メッセージ格納先
  message: string;

  ngOnInit(): void {
    // ログアウトメッセージ
    this.loginService.logoutMessage();

    // セッションに保存したメッセージコードからメッセージを取得
    this.message = this.commonService.msg(
      window.sessionStorage.getItem(SESSION_KEY.loginOutMessageCode)
    );

    // セッションに保存したメッセージコードを削除
    window.sessionStorage.removeItem(SESSION_KEY.loginOutMessageCode);

    // 画面ロードフラグをOFF(ロード強制終了)
    this.loadingState.loadForcedEnd();

    // sleep時間を設定(5分)
    const sleep = of('').pipe(delay(300000));
    // sleep時間後処理を実施
    sleep.subscribe(() => {
      // ログインボタン処理を実施
      this.login();
    });
  }

  /**
   * ログインボタン
   */
  public login(): void {
    // ログイン処理を実施(ログイン後デフォルトルートに遷移)
    // this.loginService.login();
  }
}
