import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** モジュール */
// ルーティング
import { PagesRoutingModule } from './pages-routing.module';

// 共通
import { SharedModule } from 'src/app//shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';

/** コンポーネント */
// Platform系統Component基幹
import { PlatformComponent } from './platform/platform.component';
// 会員一覧（A110）
import { A110Component } from './platform/a110/a110.component';
// 入会申請(A201)
// 入会申請確認(A202)
import { A201Component } from './platform/a201/a201.component';
// 入会申請メールアドレス確認
import { A203Component } from './platform/a203/a203.component';
// 入会申請完了
import { A204Component } from './platform/a204/a204.component';
// 入会審査完了後のメールアドレス入力（ＩＤ活性化）
import { A210Component } from './platform/a210/a210.component';
// パスワード設定メール送付完了
import { A211Component } from './platform/a211/a211.component';
// 新規入会時のパスワード設定
import { A212Component } from './platform/a212/a212.component';
// 新規入会時のパスワード設定完了
import { A213Component } from './platform/a213/a213.component';
// 入会申請詳細
import { A231Component } from './platform/a231/a231.component';
// 自治体会員登録・更新画面(入力)(B101)
// 自治体会員登録・更新画面(確認画面)(B102)
import { B101Component } from './platform/b101/b101.component';
// 自治体会員登録・更新画面（登録・更新完了）(B103)
import { B103Component } from './platform/b103/b103.component';
// ユーザー一覧(B220)
import { B220Component } from './platform/b220/b220.component';
// ユーザー登録(B221)
import { B221Component } from './platform/b221/b221.component';
// ユーザー登録完了(B223)
import { B223Component } from './platform/b223/b223.component';
// 会員プロフィール(自治体)
import { B211Component } from './platform/b211/b211.component';

// マイページ
import { B301Component } from './platform/b301/b301.component';
// 課題・ソリューション一覧
import { C231Component } from './platform/c231/c231.component';
import { C241Component } from './platform/c241/c241.component';
// 課題・ソリューション詳細
import { C331Component } from './platform/c331/c331.component';
import { C341Component } from './platform/c341/c341.component';
// 提案登録(C401)
// 提案登録確認(C402)
import { C431Component } from './platform/c431/c431.component';
// import { C441Component } from './platform/c441/c441.component';
// マッチング提案登録完了
import { C433Component } from './platform/c433/c433.component';
// import { C443Component } from './platform/c443/c443.component';
// C402
import { C404Component } from './platform/c404/c404.component';
// マッチング依頼一覧
import { C502Component } from './platform/c502/c502.component';
// 提案詳細
import { C601Component } from './platform/c601/c601.component';
// 会員入会審査
import { D211Component } from './platform/secretariat-list/d211/d211.component';

// 共通部品
import { ApprovalDialogComponent } from './platform/common/approval-dialog/approval-dialog.component';
import { RoleDialogComponent } from './platform/common/role-dialog/role-dialog.component';
import { EvaluationDialogComponent } from './platform/common/evaluation-dialog/evaluation-dialog.component';
import { DatetimeDialogComponent } from './platform/common/datetime-dialog/datetime-dialog.component';
import { PostalNoDialogComponent } from './platform/common/postal-no-dialog/postal-no-dialog.component';
// A200共通部品
import { A200StepsComponent } from './platform/a200-common/a200-steps/a200-steps.component';
import { A210StepsComponent } from './platform/a200-common/a210-steps/a210-steps.component';
import { A220StepsComponent } from './platform/a200-common/a220-steps/a220-steps.component';
import { A230StepsComponent } from './platform/a200-common/a230-steps/a230-steps.component';
// B100共通部品
import { B100StepsComponent } from './platform/b100-common/b100-steps/b100-steps.component';
// C400共通部品
import { C400StepsComponent } from './platform/c400-common/c400-steps/c400-steps.component';
// お知らせ一覧(E001)
// PF主催イベント一覧(E002)
// 会員主催イベント一覧(E101)
import { E001Component } from "./platform/e001/e001.component";
// ログアウト
import { LogoutComponent } from './logout/logout.component';
import { C701Component } from './platform/c701/c701.component';
import { C703Component } from './platform/c703/c703.component';
// Master component
import { M501Component } from './platform/master/m501/m501.component';
import { M301Component } from './platform/master/m301/m301.component';
import { M401Component } from './platform/master/m401/m401.component';
import { M201Component } from './platform/master/m201/m201.component';
import { M101Component } from './platform/master/m101/m101.component';
import { C331DialogComponent } from './platform/common/c331-dialog/c331-dialog.component';
import { C341DialogComponent } from './platform/common/c341-dialog/c341-dialog.component';
import { ConfirmRejectMatchDialogComponent } from './platform/common/confirm-reject-match-dialog/confirm-reject-match-dialog.component';
import { MemoDialogComponent } from './platform/common/memo-dialog/memo-dialog.component';
import { B220StepsComponent } from './platform/b200-common/b220-steps/b220-steps.component';
import { G102Component } from './platform/g102/g102.component';
import { LandingComponent } from './platform/landing/landing.component';
import { ContactComponent } from './platform/contact/contact.component';

@NgModule({
  declarations: [
    // Platform系統Component基幹
    PlatformComponent,
    // 会員一覧(A110)
    A110Component,
    // 入会申請(A201)
    // 入会申請確認(A202)
    A201Component,
    // 入会申請メールアドレス確認
    A203Component,
    // 入会申請完了
    A204Component,
    // 入会審査完了後のメールアドレス入力（ＩＤ活性化）
    A210Component,
    // パスワード設定メール送付完了
    A211Component,
    // 新規入会時のパスワード設定
    A212Component,
    // 新規入会時のパスワード設定完了
    A213Component,
    // 入会申請詳細
    A231Component,
    // 自治体会員登録・更新画面(入力)(B101)
    // 自治体会員登録・更新画面(確認画面)(B102)
    B101Component,
    // 自治体会員登録・更新画面（登録・更新完了）(B103)
    B103Component,
    // ユーザー一覧(B220)
    B220Component,
    // ユーザー登録(B221)
    B221Component,
    // ユーザー登録完了(B223)
    B223Component,
    // 会員プロフィール(自治体)
    B211Component,
    // マイページ
    B301Component,
    // 課題・ソリューション一覧
    C231Component,
    C241Component,
    // 課題・ソリューション詳細
    C331Component,
    C341Component,
    // 提案登録(C401)
    // 提案登録確認(C402)
    C431Component,
    // C441Component,
    // マッチング提案登録完了
    C433Component,
    // C443Component,
    C404Component,
    // マッチング依頼一覧
    C502Component,
    // 提案詳細
    C601Component,
    // 入会審査
    D211Component,
     // お知らせ一覧(E001)
    // PF主催イベント一覧(E002)
    // 会員主催イベント一覧(E101)
    E001Component,
    M201Component,
    M301Component,
    M101Component,
    M501Component,
    // 連携事例登録
    // H101Component,
    // // 連携事例詳細
    // H301Component,

    // 会員一覧・CSVユーザーマスタ管理



    // 共通部品
    RoleDialogComponent,
    EvaluationDialogComponent,
    DatetimeDialogComponent,
    PostalNoDialogComponent,
    C331DialogComponent,
    C341DialogComponent,
    ConfirmRejectMatchDialogComponent,
    // A200共通部品
    A200StepsComponent,
    A210StepsComponent,
    A220StepsComponent,
    A230StepsComponent,
    // B100共通部品
    B100StepsComponent,
    // B220共通部品
    B220StepsComponent,
    // C400共通部品
    C400StepsComponent,
   
    // // H100共通部品
    // H100StepsComponent,
    // ログアウト
    LogoutComponent,
    G102Component,
    C701Component,
    C703Component,
    M401Component,
    MemoDialogComponent,
    LandingComponent,
    ContactComponent
  ],
  imports: [CommonModule, PagesRoutingModule, SharedModule, LibraryModule],
})
export class PagesModule {}
