/**
 * 入力項目生成定数
 */
// 入力画面フラグ
export const GENERATE_INPUT_TYPE = {
  // 新規
  NEW: 'new',
  // 編集
  EDIT: 'edit',
  // 複製
  DUPLICATE: 'duplicate',
};

// 入力画面タイトル
export const GENERATE_INPUT_TITLE = {
  // 新規
  NEW: '新規',
  // 編集
  EDIT: '編集',
  // 複製
  DUPLICATE: '複製',

  M401_NEW: '活動の場登録',

  M401_EDIT: '活動の場編集',

  M401_DUPLICATE: '活動の場複製',

  M501_NEW: 'お知らせ登録',

  M501_EDIT: 'お知らせ編集',

  M501_DUPLICATE: 'お知らせ複製',
};
