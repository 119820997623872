import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

/** コンポーネント */
// 会員一覧（A110）
import { A110Component } from "./platform/a110/a110.component";
// 入会申請(A201)
// 入会申請確認(A202)
import { A201Component } from "./platform/a201/a201.component";
// 入会申請メールアドレス確認
import { A203Component } from "./platform/a203/a203.component";
// 入会申請完了
import { A204Component } from "./platform/a204/a204.component";
// 入会審査完了後のメールアドレス入力（ＩＤ活性化）
import { A210Component } from "./platform/a210/a210.component";
// パスワード設定メール送付完了
import { A211Component } from "./platform/a211/a211.component";
// 新規入会時のパスワード設定
import { A212Component } from "./platform/a212/a212.component";
// 新規入会時のパスワード設定完了
import { A213Component } from "./platform/a213/a213.component";
// 入会申請詳細
import { A231Component } from "./platform/a231/a231.component";
// 自治体会員登録・更新画面(入力)(B101)
// 自治体会員登録・更新画面(確認画面)(B102)
import { B101Component } from "./platform/b101/b101.component";
// 自治体会員登録・更新画面（登録・更新完了）(B103)
import { B103Component } from "./platform/b103/b103.component";
// 会員プロフィール(自治体)
import { B211Component } from "./platform/b211/b211.component";

// ユーザー一覧(B220)
import { B220Component } from "./platform/b220/b220.component";
// ユーザー一覧(B221)
import { B221Component } from "./platform/b221/b221.component";
// ユーザー登録完了(B223)
import { B223Component } from "./platform/b223/b223.component";
// マイページ
import { B301Component } from "./platform/b301/b301.component";
// 課題・ソリューション一覧
import { C231Component } from "./platform/c231/c231.component";
import { C241Component } from "./platform/c241/c241.component";
// 課題・ソリューション詳細
import { C331Component } from "./platform/c331/c331.component";
import { C341Component } from "./platform/c341/c341.component";
// 提案登録(C401)
// 提案登録確認(C402)
import { C431Component } from "./platform/c431/c431.component";
// import { C441Component } from "./platform/c441/c441.component";
// マッチング提案登録完了
import { C433Component } from "./platform/c433/c433.component";
// import { C443Component } from "./platform/c443/c443.component";
// マッチング依頼一覧
import { C502Component } from "./platform/c502/c502.component";
// 提案詳細
import { C601Component } from "./platform/c601/c601.component";
// 会員入会審査一覧
import { D211Component } from "./platform/secretariat-list/d211/d211.component";
// お知らせ一覧(E001)
// PF主催イベント一覧(E002)
// 会員主催イベント一覧(E101)
import { E001Component } from "./platform/e001/e001.component";
// お知らせ詳細
import { G102Component } from "./platform/g102/g102.component";
// ログアウト
import { LogoutComponent } from "./logout/logout.component";

import { M301Component } from "./platform/master/m301/m301.component";
import { C701Component } from "./platform/c701/c701.component";
import { C703Component } from "./platform/c703/c703.component";
// MASTER COMPONENT
import { M401Component } from "./platform/master/m401/m401.component";
import { M201Component } from "./platform/master/m201/m201.component";
import { M101Component } from "./platform/master/m101/m101.component";
import { M501Component } from "./platform/master/m501/m501.component";
import { LandingComponent } from "./platform/landing/landing.component";
import { ContactComponent } from "./platform/contact/contact.component";
import { C404Component } from "./platform/c404/c404.component";
import { AuthenticationGuard } from "../shared/service/authentication.guard";
import { LoginComponent } from "../shared/login/login.component";

const routes: Routes = [
  {
    // 初期画面
    path: '',
    // component: HomeComponent,
    redirectTo: "pages/platform/c502",
    pathMatch: "full",
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    // 会員検索（会員一覧）(A110)
    path: "pages/platform/a110",
    component: A110Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 入会申請(A201)
    // 入会申請確認(A202)
    // inputType(new：新規)
    // クエリパラメータ
    // screenId (a201⇒入会申請(A201),a202⇒入会申請確認(A202))
    path: "pages/platform/a201",
    component: A201Component,
    
  },
  {
    // 入会申請メールアドレス確認
    // クエリパラメータ
    // email (eメールアドレス)
    path: "pages/platform/a203",
    component: A203Component,
  },
  {
    // 入会申請完了
    path: "pages/platform/a204",
    component: A204Component,
  },
  {
    // 入会審査完了後のメールアドレス入力（ＩＤ活性化）
    path: "pages/platform/a210",
    component: A210Component,
  },
  {
    // パスワード設定メール送付完了
    path: "pages/platform/a211",
    component: A211Component,
  },
  {
    // 新規入会時のパスワード設定
    // クエリパラメータ
    // email (eメールアドレス)
    path: "pages/platform/a212",
    component: A212Component,
  },
  {
    // 新規入会時のパスワード設定完了
    path: "pages/platform/a213",
    component: A213Component,
  },
  {
    // 入会申請詳細
    // application_id(申請ID)
    path: "pages/platform/a231/:application_id",
    component: A231Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 自治体会員登録・更新画面(入力)(B101)
    // 自治体会員登録・更新画面(確認画面)(B102)
    // inputType(new：新規、edit：編集)
    // クエリパラメータ
    // screenId (b101⇒自治体会員登録・更新画面(入力)(B101),b102⇒自治体会員登録・更新画面(確認画面)(B102))
    // member_id (会員ID)
    // class_id (分類)
    path: "pages/platform/b101/:inputType",
    component: B101Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 自治体会員登録・更新画面（登録・更新完了）(B103)
    path: "pages/platform/b103",
    component: B103Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // ユーザー一覧(B220)
    path: "pages/platform/b220",
    component: B220Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // ユーザー登録(B221)
    // ユーザー登録確認(B222)
    // inputType(new：新規、edit：編集)
    // クエリパラメータ
    // screenId (b221⇒ユーザー登録(B221),b222⇒ユーザー登録確認(B222))
    // user (ユーザID)
    path: "pages/platform/b221/:inputType",
    component: B221Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // ユーザー登録完了(B223)
    path: "pages/platform/b223",
    component: B223Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 会員プロフィール(自治体)
    // member_id (会員ID)
    path: "pages/platform/b211/:member_id",
    component: B211Component,
  },
  {
    // マイページ
    path: "pages/platform/b301",
    component: B301Component,
    canActivate: [AuthenticationGuard],
  },

  {
    // 課題・ソリューション一覧
    // クエリパラメータ
    // agenda_kbn (区分)
    path: "pages/platform/c231",
    component: C231Component,
    children: [
      {
        path: "listview",
        component: M301Component,
      },
    ],
    canActivate: [AuthenticationGuard],
  },
  {
    // 課題・ソリューション一覧
    // クエリパラメータ
    // agenda_kbn (区分)
    path: "pages/platform/c241",
    component: C241Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 課題・ソリューション詳細
    // agenda_id (課題ID)
    path: "pages/platform/c331/:agenda_id",
    component: C331Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 課題・ソリューション詳細
    // agenda_id (課題ID)
    path: "pages/platform/c341/:agenda_id",
    component: C341Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 課題・ソリューション詳細
    // agenda_id (課題ID)
    path: "pages/platform/c341/:match_id/:agenda_id",
    component: C341Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 提案登録(C401)
    // 提案登録確認(C402)
    // inputType(new：新規、edit：編集)
    // クエリパラメータ
    // screenId (c401⇒提案登録(C401),c402⇒提案登録確認(C402))
    // agenda_id (課題ID)
    // suggest_id (提案ID)
    path: "pages/platform/c401/:inputType",
    component: C431Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // マッチング提案登録完了
    path: "pages/platform/c403",
    component: C433Component,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "pages/platform/c404/:agenda_id",
    component: C404Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // マッチング依頼一覧
    path: "pages/platform/c502",
    component: C502Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 提案詳細
    // suggest_id (提案ID)
    path: "pages/platform/c601",
    component: C601Component,
    canActivate: [AuthenticationGuard],
    // children: [{ path: ":id", component: C601Component }],
  },
  {
    // 提案詳細
    // suggest_id (提案ID)
    path: "pages/platform/c601/:id",
    component: C601Component,
    canActivate: [AuthenticationGuard],
    // children: [{ path: ":id", component: C601Component }],
  },
  {
    // Evaluation of matching proposal registration
    path: "pages/platform/c701/:inputType",
    component: C701Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // マッチング提案登録完了
    path: "pages/platform/c703",
    component: C703Component,
    canActivate: [AuthenticationGuard],
  },
  
  {
    // 会員入会審査一覧
    path: "pages/platform/secretariat-list/d211",
    component: D211Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // お知らせ一覧(E001)
    // PF主催イベント一覧(E002)
    // 会員主催イベント一覧(E101)
    // information(1：運営事務局からのお知らせ、0：会員からのお知らせ)
    path: "pages/platform/e001/:information",
    component: E001Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 会員主催イベント一覧・CSVダウンロード
    path: "pages/platform/master/m201",
    component: M201Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 会員主催イベント一覧・CSVダウンロード
    path: "pages/platform/master/m101",
    component: M101Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // 会員主催イベント一覧・CSVダウンロード
    path: "pages/platform/master/m301",
    component: M301Component,
    canActivate: [AuthenticationGuard],
  },

  {
    // 会員主催イベント一覧・CSVダウンロード
    path: "pages/platform/master/m401",
    component: M401Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // List of informations master
    path: "pages/platform/master/m501",
    component: M501Component,
    canActivate: [AuthenticationGuard],
  },

  {
    // お知らせ詳細
    path: "pages/platform/e002/:information_id",
    component: G102Component,
    canActivate: [AuthenticationGuard],
  },
  {
    // landing
    path: "pages/platform/landing",
    component: LandingComponent,
    // canActivate: [AuthenticationGuard],
  },
  {
    // landing
    path: "pages/platform/contact",
    component: ContactComponent,
    // canActivate: [AuthenticationGuard],
  },
  {
    // ログアウト画面
    path: "pages/logout",
    component: LogoutComponent,
  },
  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
