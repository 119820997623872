import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  API_URL_GET_AGENDA_SOLUTIONS,
  API_URL_GET_AGENDA_NEEDS,
  API_URL_SEARCH_MY_PAGE_CHAT,
  API_URL_UPDATE_MATCH_STATUS_MY_PAGE,
} from "manager/http-constants";
import { AuthorityDecision } from "manager/user-authority-constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { LoginService } from "src/app/shared/service/login.service";
import { ConfirmRejectMatchDialogComponent } from "../common/confirm-reject-match-dialog/confirm-reject-match-dialog.component";
import { MemoDialogComponent } from "../common/memo-dialog/memo-dialog.component";
import { PlatformComponent } from "../platform.component";
import { CHATSTATUSES, MATCH_TYPE, ROLES } from "src/app/shared/constant/constant";

@Component({
  selector: "app-b301",
  templateUrl: "./b301.component.html",
  styleUrls: ["./b301.component.scss", "../platform.component.scss"],
})

/**
 * マイページ
 */
export class B301Component extends PlatformComponent {
  /** 削除ダイアログ */
  @ViewChild(ConfirmRejectMatchDialogComponent)
  confirmRejectMatchDialogComponent: ConfirmRejectMatchDialogComponent;

  @ViewChild(MemoDialogComponent)
  memoDialogComponent: MemoDialogComponent;
  // 最近の更新（最近1ヶ月）格納先
  updateDataList: object[] = new Array();

  // 事務局からのお知らせ格納先
  noticeDataList: object[] = new Array();

  // 事務局からのお知らせ格納先
  needinputList: any;

  listAgendaSolutions: object[] = new Array();
  listAgendaNeeds: object[] = new Array();
  statusValue: any[] = [];
  roleAuth: any;
  isShowDialogC331: boolean = false;
  isShowDialogC341: boolean = false;
  isShowMemoDialog: boolean = false;
  isLoading: boolean = true;
  statusProgress: Object[] = [
    CHATSTATUSES.REQUEST_PROPOSAL,
    CHATSTATUSES.ADJUSTMENT,
    CHATSTATUSES.DECISION,
    CHATSTATUSES.IMPLEMENTED,
  ];
  status: Object[] = [
    CHATSTATUSES.ADJUSTMENT,
    CHATSTATUSES.END,
  ];
  checkAll: any[] = [];
  // list of chat information my page
  listChatMypage: any[] = [];
  matchId: string;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private loginService: LoginService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    if (!this.loginUser) {
      this.loadingState.loadStart();

      this.loginService.getLoginUser().subscribe((response) => {
        this.loadingState.loadSleepEnd(0.3);

        if (this.commonService.checkNoneResponse(response)) {
          this.loginService.logout(MESSAGE_CODE.E90000);
          return;
        } else {
          this.loginUser = response.body;
          this.roleAuth = response.body.class_id;
          this.getListAgendaByRole();
          this.searchChatList("");
        }
      });
    }else{
      this.roleAuth = this.loginUser.class_id;
      this.getListAgendaByRole();
      this.searchChatList("");
    }
  }

  public async getListAgendaByRole() {
    switch (this.roleAuth) {
      case ROLES.TEACHER:
        await this.getListAgendaSolutions();
        this.isLoading = false;
        break;

      case ROLES.PROVIDER:
        await this.getListAgendaNeeds();
        this.isLoading = false;
        break;

      case ROLES.ADMIN:
        await this.getListAgendaSolutions();
        await this.getListAgendaNeeds();
        this.isLoading = false;
    }
  }

  private getListAgendaSolutions() {
    return new Promise<any>((resolve) => {
      this.loadingState.loadStart(API_URL_GET_AGENDA_SOLUTIONS);

      this.dbOperationService
        .getNoTemplateData(API_URL_GET_AGENDA_SOLUTIONS)
        .subscribe((response) => {
          if (!this.commonService.checkNoneResponse(response)) {
            this.listAgendaSolutions = response.body;
          } else {
            this.listAgendaSolutions = new Array();
          }

          this.loadingState.loadSleepEnd(0.3, API_URL_GET_AGENDA_SOLUTIONS);
          resolve(null);
        });
    });
  }

  private getListAgendaNeeds() {
    return new Promise<any>((resolve) => {
      this.loadingState.loadStart(API_URL_GET_AGENDA_NEEDS);

      this.dbOperationService
        .getNoTemplateData(API_URL_GET_AGENDA_NEEDS)
        .subscribe((response) => {
          if (!this.commonService.checkNoneResponse(response)) {
            this.listAgendaNeeds = response.body;
          } else {
            this.listAgendaNeeds = new Array();
          }

          this.loadingState.loadSleepEnd(0.3, API_URL_GET_AGENDA_NEEDS);
          resolve(null);
        });
    });
  }
  /**
   * search my page chat
   */
  public searchChatList(chatstt) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_SEARCH_MY_PAGE_CHAT);
    if (!chatstt) {
      chatstt = "";
    }

    // 最近の更新（最近1ヶ月）を取得
    this.dbOperationService
      .getNoTemplateData(API_URL_SEARCH_MY_PAGE_CHAT, {
        status: chatstt,
      })
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          // 最近の更新（最近1ヶ月）を格納
          this.listChatMypage = response.body;
        } else {
          this.listChatMypage = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, API_URL_SEARCH_MY_PAGE_CHAT);
      });
  }

  checkUncheckAll(evt) {
    if (evt.checked) {
      this.statusValue = this.statusProgress.map((stt: any) => stt.code);
    } else {
      this.statusValue = [];
    }
    this.searchChatList("");
  }
  public searchChatListCondition() {
    if (this.statusValue.length == 4) {
      this.checkAll = [""];
      this.searchChatList("");
    } else {
      this.checkAll = [];
      this.searchChatList(this.statusValue);
    }
  }

  /**
   *  課題・ソリューション登録(C101)へ遷移する
   * @param agendaKbn 区分 1:課題　2:ソリューション
   */
  public moveC101(agendaKbn: number): void {
    // 課題・ソリューション登録(C101)へ遷移
    this.router.navigate(["pages/platform/c101/new"], {
      queryParams: {
        screenId: "c101",
        agenda_kbn: agendaKbn,
      },
    });
  }

  // /**
  //  * 連携事例(H101)へ遷移する
  //  */
  // public moveH101(): void {
  //   // 連携事例登録(H101)へ遷移
  //   this.router.navigate(['pages/platform/h101/new'], {
  //     queryParams: {
  //       screenId: 'h101',
  //     },
  //   });
  // }

  /**
   * ユーザ管理一覧(B220)へ遷移する
   */
  public moveB220(): void {
    // ユーザ管理一覧(B220)へ遷移
    this.router.navigate(["pages/platform/b220"]);
  }

  /**
   * マッチング依頼一覧(C502)へ遷移する
   */
  public moveC502(): void {
    // マッチング依頼一覧(C502)へ遷移
    this.router.navigate(["pages/platform/c502"]);
  }

  /**
   *事務局(D101)へ遷移する
   */
  public moveD101(): void {
    // 事務局(D101)へ遷移
    this.router.navigate(["pages/platform/d101"]);
  }

  /**
   * イベント申請一覧(E103)へ遷移する
   */
  public moveE103(): void {
    // イベント申請一覧(E103)へ遷移
    this.router.navigate(["pages/platform/e103"]);
  }

  /**
   * 分科会申請一覧(E203)へ遷移する
   */
  public moveE203(): void {
    // 分科会申請一覧(E203)へ遷移
    this.router.navigate(["pages/platform/e203"]);
  }

  public moveC331(agenda_id?: any): void {
    this.router.navigate([`pages/platform/c331/${agenda_id}`]);
  }


  public moveC231(): void {
    this.router.navigate(["pages/platform/c231/"]);
  }

  public moveC241(): void {
    this.router.navigate(["pages/platform/c241/"]);
  }

  public moveC341(agenda_id?: any): void {
    this.router.navigate([`pages/platform/c341/${agenda_id}`]);
  }

  public moveC601(matchId): void {
    this.router.navigate([`pages/platform/c601/${matchId}`]);
  }
  cancelConfirm(event) {
    this.listChatMypage.map((chat) => {
      event == chat.match_id ? (chat.match_status = CHATSTATUSES.ADJUSTMENT.code) : chat.match_status;
    });
  }
  public changeStatus(event, matchId) {
    if (event.value == CHATSTATUSES.END.code) {
      this.confirmRejectMatchDialogComponent.updateMatchStatus(
        API_URL_UPDATE_MATCH_STATUS_MY_PAGE,
        matchId,
        CHATSTATUSES.END.code
      );
    }
  }
  public changeStatusSuccess(event) {
    this.listChatMypage.map((chat, index) => {
      if (chat.match_id == event) {
        this.listChatMypage[index].match_id = CHATSTATUSES.END.code;
      }
    });
    this.searchChatListCondition();
  }

  public setClassStatus(matchStatus: any) {
    let classActiveColor = "";
    switch (matchStatus) {
      case CHATSTATUSES.REQUEST_PROPOSAL.code:
        classActiveColor = "yellow-color";
        break;
        case CHATSTATUSES.ADJUSTMENT.code:
        classActiveColor = "green-color";
        break;
        case CHATSTATUSES.DECISION.code:
        classActiveColor = "blue-color";
        break;
        case CHATSTATUSES.IMPLEMENTED.code:
        classActiveColor = "bg-red-color";
        break;

      default:
        classActiveColor = "text-default-color";
        break;
    }

    return classActiveColor;
  }

  public setClassStatusDropdown(matchStatus) {
    let classActiveColor = "";
    switch (matchStatus) {
      case CHATSTATUSES.ADJUSTMENT.code:
        classActiveColor = "green-dropdown-color";
        break;
        case CHATSTATUSES.END.code:
        classActiveColor = "default-dropdown-color";
        break;
      default:
        break;
    }
    return classActiveColor;
  }

  public moveScreenByMatchType(match_ns_id: string, match_type: string) {
    this.matchId = match_ns_id;
    if (match_type == MATCH_TYPE.AGENDA_SOLUTION) {
      this.isShowDialogC331 = true;
    } else if (match_type == MATCH_TYPE.AGENDA_NEED) {
      this.isShowDialogC341 = true;
    }
  }
  public closeDialogC331(openDialog: boolean): void {
    this.isShowDialogC331 = openDialog;
  }
  public closeDialogC341(openDialog: boolean): void {
    this.isShowDialogC341 = openDialog;
  }

  public openMemoDialog(chatId: any, memo: string): void {
    // 内閣府承認ダイアログ表示
    this.memoDialogComponent.openMemoDialog(chatId, memo);
  }

  closeMemoDialog(openDialog: boolean): void {
    this.searchChatListCondition();
  }

  /**
   * check login mêmber
   * @param memberId
   * @returns
   */
  isLoginMember(memberId: string) {
    return this.loginUser.member_id === memberId;
  }

  /**
   * check overflow of element
   * @param el html element of list 
   * @returns 
   */
  isOverflow(el: HTMLElement): boolean {
    var curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === "visible")
      el.style.overflow = "hidden";
    var isOverflowing = el.clientWidth < el.scrollWidth
      || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  
  number(value: string) {
    return Number(value);
  }
  
  reloadMatchingList(){
    this.searchChatList("");
  }
}
