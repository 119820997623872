<!-- 入力フォームページアンカー -->
<a id="generateInputFormTop"></a>
<!-- 入力フォーム -->
<div class="generateInputForm" *ngIf="!confirmationFlag">
  <form [formGroup]="generateInputForm" autocomplete="off">
    <!-- 入力情報出力領域 -->
    <p-table
      [value]="generateInputList"
      styleClass="p-datatable-sm generate-input"
    >
      <!-- 入力情報タイトル -->
      <ng-template pTemplate="header">
        <tr>
          <th colspan="2" class="table-title">下記フォームに記入して”入力チェック”ボタンを押してください</th>
        </tr>
      </ng-template>
      <!-- 入力情報出力箇所 -->
      <ng-template pTemplate="body" let-generateInputInformation>
      
        <!-- 画面表示不可以外の場合 -->
        <tr *ngIf="generateInputInformation.column_input_update != '4'">
          <!-- カラム名称 -->
          <td class="label-center">
            <label class="p-col-fixed">
              {{ generateInputInformation.column_name }}

              <!-- 項目定義マスタ.入力必須有無に'1'が存在する場合、必須を表示 -->
              <span
                *ngIf="generateInputInformation.column_input_require == '1'"
                class="p-tag p-tag-danger require"
              >
                必須
              </span>
              
            </label>
          </td>

          <!-- 入力項目 -->
          <td class="input-form">
            <!-- 入力項目タイプを判定 -->
            <!-- テキスト -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'text'"
            >
            <ng-container *ngIf="generateInputInformation.column_id == 'member_postal_no' || generateInputInformation.column_id == 'postal_no'; else notPostalInput">
              <input
                [name]="generateInputInformation.column_id"
                type="text"
                [maxlength]="generateInputInformation.column_hsize"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                (change)="getPostalsList($event, generateInputInformation.column_id)"
                pInputText
                autocomplete="off"
              />
            </ng-container>
            <ng-template #notPostalInput>
              <input
                [name]="generateInputInformation.column_id"
                type="text"
                [maxlength]="generateInputInformation.column_hsize"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputText
                autocomplete="off"
              />
            </ng-template>
            </div>

            <!-- ファイル -->
            <!-- file -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'file' || generateInputInformation.input_type == 'image'"
            >
              <input
                type="hidden"
                [name]="generateInputInformation.column_id"
                [formControlName]="generateInputInformation.column_id"
                pInputText
                autocomplete="off"
              />
              <p-fileUpload
                #fileUpload
                [customUpload]="true"
                (onSelect)="
                  uploadFile(
                    $event,
                    generateInputInformation.column_id,
                    fileUpload
                  )
                "
                [showUploadButton]="false"
                [showCancelButton]="false"
                [multiple]="false"
                class="btn-upload"
              >
                <ng-template pTemplate="content">
                  <div class="file-content" *ngIf="(generateInputInformation.column_id == 'agenda_solution_icon_image' || generateInputInformation.column_id == 'agenda_need_icon_image') && fileUploadedImage">
                    <div class="file_name" >{{ fileUploadedImage.name }}</div>
                    <div>
                      <i
                        class="pi pi-times"
                        (click)="removeFile(fileUploadedImage, fileUpload, generateInputInformation.column_id)"
                      ></i>
                    </div>
                  </div>
                  <div class="file-content" *ngIf="(generateInputInformation.column_id == 'agenda_solution_document' || generateInputInformation.column_id == 'agenda_need_document') && fileUploadDocument" >
                    <div class="file_name" >{{ fileUploadDocument.name }}</div>
                    
                    <div>
                      <i
                        class="pi pi-times"
                        (click)="removeFile(fileUploadDocument, fileUpload, generateInputInformation.column_id)"
                      ></i>
                    </div>
                  </div>
                </ng-template>
              </p-fileUpload>
            </div>

            <!-- 日付 -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'date'"
            >
              <p-calendar
                [name]="generateInputInformation.column_id"
                [showIcon]="true"
                [showButtonBar]="true"
                [yearNavigator]="true"
                [monthNavigator]="true"
                [readonlyInput]="true"
                dateFormat="yy/mm/dd"
                dataType="string"
                appendTo="body"
                [readonlyInput]="true"
                yearRange="2000:2999"
                [inputStyleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                "
                [formControlName]="generateInputInformation.column_id"
                translate
              >
              </p-calendar>
            </div>

            <!-- 数字 -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'number'"
            >
              <input
                [name]="generateInputInformation.column_id"
                type="number"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputText
                autocomplete="off"
              />
            </div>

            <!-- チェックボックス -->
            <div
              class="ui-fluid p-d-flex p-grid"
              *ngIf="generateInputInformation.input_type == 'checkbox'"
              [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              "
              
            >
              <div
                
                *ngFor="
                  let code_list of generateInputInformation.column_code_list_multi
                "
              >
                <p-checkbox
                  [name]="generateInputInformation.column_id"
                  [label]="code_list.name"
                  [value]="code_list.value"
                  [formControl]="
                    generateInputForm.controls[
                      generateInputInformation.column_id
                    ]
                  "
                  class="p-col"
                >
                </p-checkbox>
              </div>
            </div>

            <!-- ラジオボタン -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'radio'"
              [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              "
            >
              <div
                class="p-field-radiobutton"
                *ngFor="
                  let column of generateInputInformation.column_code_list_multi
                "
              >
                <p-radioButton
                  [name]="generateInputInformation.column_id"
                  [label]="column.name"
                  [value]="column.value"
                  [formControlName]="generateInputInformation.column_id"
                >
                </p-radioButton>
              </div>
            </div>

            <!-- シングルセレクト-->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'single_select'"
            >
            <ng-container *ngIf="generateInputInformation.column_id == 'member_prefectures' || generateInputInformation.column_id == 'prefectures'; else notPrefectures">
              <p-dropdown
                  [name]="generateInputInformation.column_id"
                  [options]="postalNoList"
                  placeholder="　"
                  optionLabel="name"
                  optionValue="value"
                  [showClear]="false"
                  [filter]="
                    generateInputInformation.column_code_list_multi.length >= 10
                  "
                  emptyFilterMessage="検索結果が存在しません"
                  [styleClass]="
                    checkErrorItem(generateInputInformation.column_id)
                      ? 'errorItem'
                      : ''
                  "
                  [formControl]="
                    generateInputForm.controls[
                      generateInputInformation.column_id
                    ]
                  "
                >
                </p-dropdown>
            </ng-container>
            <ng-template #notPrefectures>
              <ng-container
              *ngIf="generateInputInformation.column_id == 'agenda_category_class_cd1' || generateInputInformation.column_id == 'agenda_category_class_cd2'; else normalSingle"
              >
                 <ng-container *ngIf="generateInputInformation.column_id == 'agenda_category_class_cd1'">
                  <p-dropdown
                    
                  [options]="generateInputInformation.column_code_list_multi"
                  placeholder="　"
                  optionLabel="class_name1"
                  optionValue="value"
                  [showClear]="true"
                  [filter]="
                    generateInputInformation.column_code_list_multi.length >= 10
                  "
                  emptyFilterMessage="検索結果が存在しません"
                  [styleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                "
                [formControl]="
                  generateInputForm.controls[
                    generateInputInformation.column_id
                  ]
                "
                  appendTo="body"
                  (onChange)="onChangCategory($event)"
                ></p-dropdown>
                 </ng-container>
                 <ng-container *ngIf="generateInputInformation.column_id == 'agenda_category_class_cd2'">
                  <p-dropdown
                  [name]="generateInputInformation.column_id"
                  [options]="listSubCategory"
                  placeholder="　"
                  optionLabel="name"
                  optionValue="value"
                  [showClear]="true"
                 
                  emptyFilterMessage="検索結果が存在しません"
                  appendTo="body"
                  [styleClass]="
                    checkErrorItem(generateInputInformation.column_id)
                      ? 'errorItem'
                      : ''
                  "
                  [formControl]="
                    generateInputForm.controls[generateInputInformation.column_id]
                  "
                ></p-dropdown>
                
                 
                </ng-container>
              </ng-container>
                <ng-template #normalSingle>
                  <ng-container *ngIf="generateInputInformation.column_id == 'member_class_id'; else normalSingle2">
                    <p-dropdown
                    [name]="generateInputInformation.column_id"
                    [options]="generateInputInformation.column_code_list_multi"
                    placeholder="　"
                    optionLabel="name"
                    optionValue="value"
                    [showClear]="true"
                    [filter]="
                      generateInputInformation.column_code_list_multi.length >= 10
                    "
                    emptyFilterMessage="検索結果が存在しません"
                  (onChange)="onchangeClassId($event)"
                    appendTo="body"
                    [styleClass]="
                      checkErrorItem(generateInputInformation.column_id)
                        ? 'errorItem'
                        : ''
                    "
                    [formControl]="
                      generateInputForm.controls[
                        generateInputInformation.column_id
                      ]
                    "
                  >
                  </p-dropdown>
                  </ng-container>
                  <ng-template #normalSingle2>
                    <p-dropdown
                    [name]="generateInputInformation.column_id"
                    [options]="generateInputInformation.column_code_list_multi"
                    placeholder="　"
                    optionLabel="name"
                    optionValue="value"
                    [showClear]="true"
                    [filter]="
                      generateInputInformation.column_code_list_multi.length >= 10
                    "
                    emptyFilterMessage="検索結果が存在しません"
                    [styleClass]="
                      checkErrorItem(generateInputInformation.column_id)
                        ? 'errorItem'
                        : ''
                    "
                    [formControl]="
                      generateInputForm.controls[
                        generateInputInformation.column_id
                      ]
                    "
                  >
                  </p-dropdown>
                  </ng-template>
                  
                   
                </ng-template>
            </ng-template>
            </div>
           
            <!-- マルチセレクト -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'multiple_select'"
            >
              <p-multiSelect
                [name]="generateInputInformation.column_id"
                [options]="generateInputInformation.column_code_list_multi"
                defaultLabel="　"
                emptyFilterMessage="検索結果が存在しません"
                optionLabel="name"
                appendTo="body"
                [styleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                "
                [formControl]="
                  generateInputForm.controls[generateInputInformation.column_id]
                "
              >
              </p-multiSelect>
            </div>

            <!-- テキストエリア -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'textarea'"
            >
              <textarea
                [name]="generateInputInformation.column_id"
                [maxlength]="generateInputInformation.column_hsize"
                rows="10"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputTextarea
                autocomplete="off"
              ></textarea>
            </div>

            <!-- パスワード -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'password'"
            >
              <input
                [name]="generateInputInformation.column_id"
                type="password"
                [maxlength]="generateInputInformation.column_hsize"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputText
                autocomplete="off"
              />
            </div>

            <!-- エラーメッセージ出力箇所 -->
            <app-validator-message
              [control]="
                generateInputForm.get(generateInputInformation.column_id)
              "
            >
            </app-validator-message>
          </td>
        </tr>
       
        <!-- ピッカーリスト -->
        <!-- TODO 条件仮置き-->
        <tr *ngIf="generateInputInformation.input_type == 'test'">
          <td colspan="2">
            <br />
            <p-pickList
              [source]="pickListSource(generateInputInformation.column_id)"
              [target]="pickListTarget(generateInputInformation.column_id)"
              [sourceHeader]="generateInputInformation.column_name"
              targetHeader="選択リスト"
              [dragdrop]="true"
              [showSourceControls]="false"
              [showTargetControls]="false"
              filterBy="name"
              (onMoveToTarget)="
                moveToTarget(generateInputInformation.column_id, $event)
              "
              (onMoveToSource)="
                moveToSource(generateInputInformation.column_id, $event)
              "
              (onMoveAllToTarget)="
                moveToTarget(generateInputInformation.column_id, $event)
              "
              (onMoveAllToSource)="
                moveToSource(generateInputInformation.column_id, $event)
              "
            >
              <ng-template let-code pTemplate="item">
                {{ code.name }}
              </ng-template>
            </p-pickList>
            <br />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button
      class="button p-button-br-7"
      type="submit"
      label="入力チェック"
      (click)="inputVerification()"
      [disabled]="!generateInputForm.valid"
      pButton
    ></button>
  </div>
</div>

<!-- 入力確認 -->
<div class="generateInputConfirmation" *ngIf="confirmationFlag">
  <!-- 確認情報出力領域 -->
  <p-table
    [value]="generateInputFormConfirmationList"
    styleClass="p-datatable-sm"
  >
    <!-- 確認情報タイトル -->
    <ng-template pTemplate="header">
      <tr>
        <th colspan="2" class="table-title">入力内容を確認し“登録”ボタンを押してください</th>
      </tr>
    </ng-template>
    <!-- 確認情報出力箇所 -->
    <ng-template pTemplate="body" let-generateInputFormConfirmation>
      <tr>
        <!-- カラム名称 -->
        <td class="label-center">
          <label class="p-col-fixed">
            {{ generateInputFormConfirmation._columnName }}
          </label>
        </td>
        <!-- 入力項目値 -->
        <td class="ui-fluid confirmationData">
          {{ generateInputFormConfirmation._columnData }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button
      class="button button-back p-button-br-7"
      type="button"
      label="戻る"
      (click)="returnConfirmationButton()"
      [disabled]="insertFlag"
      pButton
    ></button>
    <button
      class="button p-button-br-7"
      type="button"
      label="登録"
      (click)="returnGenerateInputForm()"
      [disabled]="insertFlag"
      pButton
    ></button>
  </div>
</div>
