<!-- 内閣府承認ダイアログ -->
<p-dialog
  header=" "
  [modal]="true"
  [(visible)]="approvalDialogFlag"
  styleClass="approval-dialog"
>
  <!-- ダイアログコンテンツ -->
  <div
    [formGroup]="approvalInputForm"
    [class.platform-large-text]="getTextSize()"
  >
    <!-- 承認ボタンエリア -->
    <div class="p-d-flex p-jc-center approva-radioButton-area">
      <span *ngFor="let dicData of approvalSpecCodeList">
        <p-radioButton
          [label]="dicData.sp_name"
          [value]="dicData.sp_code"
          class="approva-radioButton"
          formControlName="status"
        ></p-radioButton>
      </span>
    </div>
    <!-- エラーメッセージ出力箇所 -->
    <app-validator-message [control]="approvalInputForm.get('status')">
    </app-validator-message>

    <!-- 承認拒否理由エリア -->
    <div class="approva-refusal-reason-area">
      <div>
        <label class="message1">承認を拒否する理由を記入してください。</label>
        <span class="required">【必須】</span>
      </div>
      <div class="message2">※システムから申請者へメールで通知します</div>
      <!-- 承認拒否入力 -->
      <textarea
        pInputTextarea
        formControlName="status_comment"
        *ngIf="!confirmationFlag"
        [ngClass]='checkInputMaxBoundary(approvalInputForm,"status_comment")?"invalid-length":""'
        autocomplete="off"
      ></textarea>
      <!-- 承認拒否確認 -->
      <div class="message3" *ngIf="confirmationFlag">
        {{ approvalInputForm.value.status_comment }}
      </div>
      <!-- エラーメッセージ出力箇所 -->
      <app-validator-message
        [control]="approvalInputForm.get('status_comment')"
      >
      </app-validator-message>
    </div>

    <!-- 入力ボタンエリア -->
    <div
      class="p-d-flex p-jc-center approval-button-area"
      *ngIf="!confirmationFlag"
    >
      <button
        pButton
        type="button"
        label="決定する"
        class="button-default-size button1"
        (click)="confirmationApproval()"
        [disabled]="!approvalInputForm.valid"
      ></button>
    </div>

    <!-- 確認ボタンエリア -->
    <div
      class="p-d-flex p-jc-center approval-button-area"
      *ngIf="confirmationFlag"
    >
      <button
        pButton
        type="button"
        label="確定する"
        class="button-default-size button1"
        (click)="insertApproval()"
      ></button>
      <button
        pButton
        type="button"
        label="修正する"
        class="button-default-size button2"
        (click)="returnInputApprova()"
      ></button>
    </div>
  </div>
</p-dialog>

<!-- 内閣府承認ダイアログ(小) -->
<p-dialog
  [(visible)]="smallApprovalDialogFlag"
  [draggable]="false"
  [closable]="false"
  styleClass="small-dpproval-dialog"
  position="bottom-right"
>
  <!-- ヘッダー -->
  <ng-template pTemplate="header">
    <div class="header-botton p-d-flex p-jc-end">
      <!-- 閉じるボタン -->
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        class="p-button-sm p-button-rounded p-button-outlined"
        (click)="smallApprovalDialogFlag = false"
      ></button>
    </div>
  </ng-template>

  <!-- コンテンツ -->
  <div class="content" [formGroup]="approvalInputForm">
    <p-accordion>
      <p-accordionTab [selected]="true">
        <ng-template pTemplate="header">
          <div class="header">承認ダイアログ</div>
        </ng-template>
        <ng-template pTemplate="content">
          <!-- 承認ボタンエリア -->
          <div class="p-d-flex p-jc-center approva-radioButton-area">
            <span *ngFor="let dicData of approvalSpecCodeList">
              <p-radioButton
                [label]="dicData.sp_name"
                [value]="dicData.sp_code"
                class="approva-radioButton"
                formControlName="status"
              ></p-radioButton>
            </span>
          </div>

          <!-- 承認拒否理由エリア -->
          <div class="approva-refusal-reason-area" *ngIf="!boardFlag">
            <div class="p-pb-2">
              <label class="message1">申し送り事項を記入してください。</label>
              <span 
                class="required"
                *ngIf="(approvalInputForm.value?.status === '2')"
              >
                *必須
              </span>
            </div>
            <!-- 承認拒否入力 -->
            <textarea
              pInputTextarea
              formControlName="status_comment"
              *ngIf="!confirmationFlag"
              autocomplete="off"
              class="m001-input"
              [ngClass]='checkInputMaxBoundary(approvalInputForm,"status_comment")?"invalid-length":""'
            ></textarea>
            <app-validator-message
              [control]="
                approvalInputForm.get('status_comment')
              "
            >
            </app-validator-message>
            <!-- 承認拒否確認 -->
            <p-scrollPanel styleClass="message3-scrol" *ngIf="confirmationFlag">
              <div class="message3">
                {{ approvalInputForm.value.status_comment }}
              </div>
            </p-scrollPanel>
          </div>

          <!-- 入力ボタンエリア -->
          <div
            class="p-d-flex p-jc-center approval-button-area"
            *ngIf="!confirmationFlag"
          >
            <button
              pButton
              type="button"
              label="決定する"
              class="button-default-size button1"
              (click)="confirmationApproval()"
              [disabled]="!approvalInputForm.valid || (!approvalInputForm.value?.status_comment.trim() && approvalInputForm.value?.status === '2')"
            ></button>
          </div>

          <!-- 確認ボタンエリア -->
          <div
            class="p-d-flex p-jc-center approval-button-area"
            *ngIf="confirmationFlag"
          >
            <button
              pButton
              type="button"
              label="確定する"
              class="button-default-size button1"
              (click)="insertApprovalReject()"
            ></button>
            <button
              pButton
              type="button"
              label="修正する"
              class="button-default-size button2 btn-back"
              (click)="returnInputApprova()"
            ></button>
          </div>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</p-dialog>

<!-- 内閣府承認ダイアログ(小) -->
<p-dialog
  [(visible)]="smallApprovalDialogMasterFlag"
  [draggable]="false"
  [modal]="true" 
  [resizable]="false"
  [closable]="true"
  styleClass="small-approval-master-dialog"
>
  <!-- ヘッダー -->
  <ng-template pTemplate="header">
    <div class="header">承認ダイアログ</div>
  </ng-template>

  <!-- コンテンツ -->
  <div class="content" [formGroup]="approvalInputForm">
   
          <!-- 承認ボタンエリア -->
          <div class="p-d-flex p-jc-center approval-radioButton-area">
            <span *ngFor="let dicData of approvalSpecCodeList">
              <p-radioButton
                [label]="dicData.sp_name"
                [value]="dicData.sp_code"
                class="approva-radioButton"
                formControlName="status"
              ></p-radioButton>
            </span>
          </div>

          <!-- 承認拒否理由エリア -->
          <div class="approva-refusal-reason-area" *ngIf="!boardFlag">
            <div class="p-pb-2">
              <label class="message1">申し送り事項を記入してください。</label>
              <span 
              class="required"
              *ngIf="(approvalInputForm.value?.status === '2')"
            >
              *必須
            </span>
            </div>
            <!-- 承認拒否入力 -->
            <textarea
              pInputTextarea
              formControlName="status_comment"
              *ngIf="!confirmationFlag"
              autocomplete="off"
              class="m001-input"
              [ngClass]='checkInputMaxBoundary(approvalInputForm,"status_comment")?"invalid-length":""'
            ></textarea>
            <app-validator-message
              [control]="
                approvalInputForm.get('status_comment')
              "
            >
            </app-validator-message>
            <!-- 承認拒否確認 -->
            <p-scrollPanel styleClass="message3-scrol" *ngIf="confirmationFlag">
              <div class="message3">
                {{ approvalInputForm.value.status_comment }}
              </div>
            </p-scrollPanel>
          </div>

          <!-- 入力ボタンエリア -->
          <div
            class="p-d-flex p-jc-center approval-button-master-area"
            *ngIf="!confirmationFlag"
          >
            <button
              pButton
              type="button"
              label="決定する"
              class="button-default-size button1"
              (click)="confirmationApproval()"
              [disabled]="!approvalInputForm.valid || (!approvalInputForm.value?.status_comment.trim() && approvalInputForm.value?.status === '2')"
            ></button>
          </div>

          <!-- 確認ボタンエリア -->
          <div
            class="p-d-flex p-jc-center approval-button-area"
            *ngIf="confirmationFlag"
          >
            <button
              pButton
              type="button"
              label="確定する"
              class="button-default-size button1"
              (click)="insertApproval()"
            ></button>
            <button
              pButton
              type="button"
              label="修正する"
              class="button-default-size button2 btn-back"
              (click)="returnInputApprova()"
            ></button>
          </div>
      
  </div>
</p-dialog>
