

<!-- メイン -->
<div class="main">
    <!-- メインコンテンツ -->
    <div class="main-content" [class.platform-large-text]="getTextSize()">
      <!-- B100系共通部品_Steps -->
      <app-b100-steps></app-b100-steps>

      <div class="information-margin"></div>
  
      <!-- メッセージ -->
      <div class="message">
        <!-- メッセージ本文 -->
        <div class="message-text black-color">
          <div class="p-d-flex p-jc-center">
            <i class="pi pi-check-circle"></i><br />
          </div>
          <div class="message-margin"></div>
          <div class="p-d-flex p-jc-center">団体プロフィールを登録・更新いたしました</div>
        </div>
      </div>
  
      <!-- ボタンエリア -->
      <div class="p-d-flex p-jc-center button-area">
        <button
          pButton
          type="button"
          label="団体プロフィールへ戻る"
          class="button-default-size button1"
          (click)="moveB211()"
        ></button>
      </div>
    </div>
  </div>

  

<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>
