import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { C400_STEPS_ITEMS } from './c400-steps-constants';

@Component({
  selector: 'app-c400-steps',
  templateUrl: './c400-steps.component.html',
})

/**
 * C400系共通部品_Steps
 */
export class C400StepsComponent implements OnInit {
  // Steps項目一覧
  stepsItems: object[] = C400_STEPS_ITEMS;

  // アクティブインデックス
  activeIndex: number = 0;

  constructor(private readonly _router: Router) {
    this._router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((event) => {
        // Steps項目活性化を設定
        this.cangeSteps(event);
      });
  }

  ngOnInit(): void {}

  /**
   * Steps項目活性化を設定
   * @param event コンポーネント情報
   */
  private cangeSteps(event) {
    // URLを取得
    const path: string = event.snapshot.routeConfig.path;

    // クエリパラメータを取得
    const queryParam: any = event.snapshot.queryParams;

    // 現在urlを判定
    if (path.includes('pages/platform/c401') && 'c401' == queryParam.screenId) {
      // 提案登録(C401)の場合

      //マッチングの提案登録を活性化
      this.activeIndex = 0;
    } else if (
      path.includes('pages/platform/c401') &&
      'c402' == queryParam.screenId
    ) {
      // 提案登録確認(C402)の場合

      // 提案内容確認を活性化
      this.activeIndex = 1;
    } else if (path.includes('pages/platform/c403')) {
      // マッチング提案登録完了(C403)の場合

      // 登録完了を活性化
      this.activeIndex = 2;
    }
  }
}
