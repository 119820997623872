import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { API_RESPONSE } from '../../constant/api-constant';
import { MESSAGE_CODE } from '../../constant/message-constant';
import { TOAST } from '../../constant/primeng-constants';
import { GENERATE_INPUT_TYPE } from '../../generate/generate-input/constant';
import { CommonService } from '../../service/common.service';
import { TOAST_MESSAGE_DEFAULT_CONSTANT } from './constant';

/**
 * メッセージクラス
 */
@Injectable()
export class MessageData {
  constructor(
    private commonService: CommonService,
    private messageService: MessageService
  ) {}

  /**
   * TOASTメッセージを設定
   * @param toastMessageData TOASTメッセージ情報
   * ※最前面配置されているTOASTメッセージを表示
   */
  public toastMessage(toastMessageData: ToastMessageData): void {
    // TOASTメッセージを生成
    this.messageService.add({
      key: toastMessageData.position + TOAST.FRONT_KEY,
      severity: toastMessageData.severity,
      summary: toastMessageData.summary,
      detail: toastMessageData.detail,
      life: toastMessageData.life,
    });
  }

  /**
   * TOAST固定メッセージを設定
   * @param toastMessageData TOASTメッセージ情報
   * ※最前面配置されていないTOASTメッセージを表示
   */
  public toastNormalMessage(toastMessageData: ToastMessageData): void {
    // TOASTメッセージを生成
    this.messageService.add({
      key: toastMessageData.position,
      severity: toastMessageData.severity,
      summary: toastMessageData.summary,
      detail: toastMessageData.detail,
      sticky: toastMessageData.sticky,
      closable: toastMessageData.closable,
      life: toastMessageData.life,
    });
  }

  /**
   * TOASTメッセージをクリア
   * @param position 削除TOASTメッセージの配置箇所
   */
  public clearToastMessage(position?: string): void {
    // 削除TOASTメッセージの配置箇所が存在するか否か
    if (!position) {
      // 削除TOASTメッセージが存在しない場合

      // デフォルト配置箇所を指定
      position = TOAST_MESSAGE_DEFAULT_CONSTANT.position;
    }

    // TOASTメッセージをクリア
    this.messageService.clear(position);
  }

  /**
   * アイテムアダプター入力、編集、複製用TOASTメッセージ
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param response レスポンス情報
   * @returns true(レスポンスが正常)、false(レスポンスが異常)
   */
  public generateInputResponseMessage(
    inputType: string,
    response: any
  ): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // エラーメッセージの表示
      this.messageService.add({
        key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00003),
        detail: response.message,
      });

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body.Message) {
      // レスポンス結果が正常以外の場合

      if (API_RESPONSE.FAIL == response.body.Message) {
        // レスポンス結果が不正の場合

        // 失敗メッセージの表示
        this.messageService.add({
          key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: this.commonService.msg(MESSAGE_CODE.E00004),
        });

        // 異常終了
        return false;
      } else {
        // レスポンス結果が不正(メッセージあり)の場合
        // TODO 都庁案件で時間が足りない為、暫定対応

        // 失敗メッセージの表示
        this.messageService.add({
          key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: response.body.Message,
        });

        // 異常終了
        return false;
      }
    }

    // レスポンス結果が正常の場合
    // 登録種別を判定
    if (GENERATE_INPUT_TYPE.NEW == inputType) {
      // 登録種別が"新規"の場合

      this.messageService.add({
        key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
        severity: TOAST.SUCCESS,
        summary: this.commonService.msg(MESSAGE_CODE.T00001),
        detail: this.commonService.msg(MESSAGE_CODE.I00001),
      });
    } else if (GENERATE_INPUT_TYPE.EDIT == inputType) {
      // 登録種別が"編集"の場合

      this.messageService.add({
        key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
        severity: TOAST.SUCCESS,
        summary: this.commonService.msg(MESSAGE_CODE.T00001),
        detail: this.commonService.msg(MESSAGE_CODE.U00001),
      });
    } else {
      // 登録種別が"複製"の場合

      this.messageService.add({
        key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
        severity: TOAST.SUCCESS,
        summary: this.commonService.msg(MESSAGE_CODE.T00001),
        detail: this.commonService.msg(MESSAGE_CODE.I00002),
      });
    }

    // 正常終了
    return true;
  }

  /**
   * レスポンス情報を判定しTOASTメッセージを設定(結果がMessage設定)
   * @param response レスポンス情報
   * @param successHeader 正常時のTOASTヘッダー(デフォルト:完了)
   * @param successBody 正常時のTOASTボディ(デフォルト:完了しました)
   * @returns true(レスポンスが正常)、false(レスポンスが異常)
   */
  public responseToastMessage(
    response: any,
    successHeader?: string,
    successBody?: string
  ): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // エラーメッセージの表示
      this.messageService.add({
        key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00003),
        detail: response.message,
      });

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body.Message) {
      // レスポンス結果が正常以外の場合

      if (API_RESPONSE.FAIL == response.body.Message) {
        // レスポンス結果が不正の場合

        // 失敗メッセージの表示
        this.messageService.add({
          key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: this.commonService.msg(MESSAGE_CODE.E00002),
        });

        // 異常終了
        return false;
      } else {
        // レスポンス結果が不正(メッセージあり)の場合
        // TODO 都庁案件で時間が足りない為、暫定対応

        // 失敗メッセージの表示
        this.messageService.add({
          key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: response.body.Message,
        });

        // 異常終了
        return false;
      }
    }

    // 正常時のTOASTヘッダーが存在しているか否か
    if (!successHeader) {
      // 正常時のTOASTヘッダーが存在していない場合

      // '完了'メッセージを取得
      successHeader = this.commonService.msg(MESSAGE_CODE.T00001);
    }

    // 正常時のTOASTボディが存在しているか否か
    if (!successBody) {
      // 正常時のTOASTボディが存在していない場合

      // '完了しました'メッセージを取得
      successBody = this.commonService.msg(MESSAGE_CODE.T00002);
    }

    // レスポンス結果が正常の場合
    this.messageService.add({
      key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
      severity: TOAST.SUCCESS,
      summary: successHeader,
      detail: successBody,
    });

    // 正常終了
    return true;
  }

  /**
   * レスポンス情報を判定しTOASTメッセージを設定(結果がresult設定)
   * @param response レスポンス情報
   * @param successHeader 正常時のTOASTヘッダー(デフォルト:完了)
   * @param successBody 正常時のTOASTボディ(デフォルト:完了しました)
   * @returns true(レスポンスが正常)、false(レスポンスが異常)
   */
  public resultResponseToastMessage(
    response: any,
    successHeader?: string,
    successBody?: string
  ): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // エラーメッセージの表示
      this.messageService.add({
        key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00003),
        detail: response.message,
      });

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body.result) {
      // レスポンス結果が正常以外の場合

      if (API_RESPONSE.FAIL == response.body.result) {
        // レスポンス結果が不正の場合

        // 失敗メッセージの表示
        this.messageService.add({
          key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: this.commonService.msg(MESSAGE_CODE.E00002),
        });

        // 異常終了
        return false;
      } else {
        // レスポンス結果が不正(メッセージあり)の場合

        // 失敗メッセージの表示
        this.messageService.add({
          key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: response.body.Message,
        });

        // 異常終了
        return false;
      }
    }

    // 正常時のTOASTヘッダーが存在しているか否か
    if (!successHeader) {
      // 正常時のTOASTヘッダーが存在していない場合

      // '完了'メッセージを取得
      successHeader = this.commonService.msg(MESSAGE_CODE.T00001);
    }

    // 正常時のTOASTボディが存在しているか否か
    if (!successBody) {
      // 正常時のTOASTボディが存在していない場合

      // '完了しました'メッセージを取得
      successBody = this.commonService.msg(MESSAGE_CODE.T00002);
    }

    // レスポンス結果が正常の場合
    this.messageService.add({
      key: TOAST_MESSAGE_DEFAULT_CONSTANT.position + TOAST.FRONT_KEY,
      severity: TOAST.SUCCESS,
      summary: successHeader,
      detail: successBody,
    });

    // 正常終了
    return true;
  }
}

/** TOASTメッセージオブジェクト */
export class ToastMessageData {
  // 重大度
  private _severity: string = TOAST_MESSAGE_DEFAULT_CONSTANT.severity;

  // 概要
  private _summary: string;

  // 詳細
  private _detail: string;

  // 表示時間
  private _life: number = TOAST_MESSAGE_DEFAULT_CONSTANT.life;

  // 保持表示
  private _sticky: boolean = false;

  // 閉鎖可能
  private _closable: boolean = true;

  // メッセージ出力場所
  private _position: string = TOAST_MESSAGE_DEFAULT_CONSTANT.position;

  constructor(init?: Partial<ToastMessageData>) {
    Object.assign(this, init);
  }

  set severity(severity: string) {
    this._severity = severity;
  }

  get severity(): string {
    return this._severity;
  }

  set summary(summary: string) {
    this._summary = summary;
  }

  get summary(): string {
    return this._summary;
  }

  set detail(detail: string) {
    this._detail = detail;
  }

  get detail(): string {
    return this._detail;
  }

  set life(life: number) {
    this._life = life;
  }

  get life(): number {
    return this._life;
  }

  set sticky(sticky: boolean) {
    this._sticky = sticky;
  }

  get sticky(): boolean {
    return this._sticky;
  }

  set closable(closable: boolean) {
    this._closable = closable;
  }

  get closable(): boolean {
    return this._closable;
  }

  set position(position: string) {
    this._position = position;
  }

  get position(): string {
    return this._position;
  }
}
