import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LIST_NEW_NOTIFICATION } from 'manager/http-constants';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CommonService } from '../../service/common.service';
import { DbOperationService } from '../../service/db-operation.service';
import { LoadingState } from '../loading/loading-state';


@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit, AfterContentChecked{

  @ViewChild('op',{static:false}) op: OverlayPanel;

  @Output() reCount = new EventEmitter<string>();

  e001: any[] = [];
  loading: boolean = false;
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {  
    this.cdr.detach();
  };

  ngAfterContentChecked(): void {
      this.cdr.detectChanges(); 
  };
  ngOnInit(): void {
  }

  openDialog(event:Event){
    this.op.toggle(event);
  }
      /**
   * check overflow of element
   * @param el html element of list 
   * @returns 
   */
      isOverflow(el: HTMLElement): boolean {
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === "visible")
          el.style.overflow = "hidden";
        var isOverflowing = el.clientWidth < el.scrollWidth
          || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
      }

  moveE002(infomationId){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['pages/platform/e002/' + infomationId]));
    this.op.hide();
  }

  moveE001(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['pages/platform/e001/1']));
    this.op.hide();
  }

  getNoticeDataList(event:Event = null) {
    this.reCount.emit();
    this.loading = true;
    this.dbOperationService.getNoTemplateData(
      LIST_NEW_NOTIFICATION,
      {},
      false
    ).subscribe((response) => {
      if (!this.commonService.checkNoneResponse(response)) {
        this.e001 = response.body;
      } else {
        this.e001 = new Array();
      }
      this.loading = false;
    });
  }
  onShow() {
    this.getNoticeDataList();
  }
  onHide() {
    this.e001 = [];
  }
}
