import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { API_URL_UPDATE_MEMO, APPLICATIONS } from "manager/http-constants";
import { API_RESPONSE } from "src/app/shared/constant/api-constant";
import { LOADING_KEY } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { PlatformComponent } from "../../platform.component";

@Component({
  selector: "app-memo-dialog",
  templateUrl: "./memo-dialog.component.html",
  styleUrls: ["./memo-dialog.component.scss"],
})
export class MemoDialogComponent extends PlatformComponent {
  @Input() id: string;
  @Output() returnMemoDialog = new EventEmitter<boolean>();

  MemoDialogFlag: boolean = false;
  initMemoContent: string = "";
  memoInput: string = "";
  chatId: string;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private messageData: MessageData
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  openMemoDialog(chatId: string, memo: string) {
    this.MemoDialogFlag = true;
    this.chatId = chatId;
    this.memoInput = memo;
    this.initMemoContent = memo;
  }

  editMemo() {
    this.loadingState.loadStart(LOADING_KEY.DATA_INSERT);
    if (this.memoInput.trim() == "" && this.initMemoContent.trim() == "") {
      this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);
      this.closeMemoDialog();
      return;
    }
    this.dbOperationService
      .updateData(API_URL_UPDATE_MEMO, this.chatId, { memo: this.memoInput.trim() })
      .subscribe((response) => {
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

        // レスポンス結果を判定
        if (API_RESPONSE.SUCCESS == response.body.Message) {
          this.returnMemoDialog.emit(true);

          // 正常メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.SUCCESS,
              summary: this.commonService.msg(MESSAGE_CODE.T00002),
            })
          );
          this.closeMemoDialog();
        }else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.returnMemoDialog.emit(true);
          this.closeMemoDialog();
        } else {
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00001),
              detail: this.commonService.msg(MESSAGE_CODE.E80002),
            })
          );
        }
      });
  }

  public closeMemoDialog(): void {
    this.MemoDialogFlag = false;
  }
  isDisabledMemo() {
    return this.memoInput.trim() == "";
    // return ((this.initMemoContent.trim() == "" && this.memoInput.trim() == "") ||  this.initMemoContent.trim() == this.memoInput.trim() || this.memoInput.trim() == "")
  }
}
