import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  API_URL_GET_DETAIL_MATCHING,
  API_URL_ADD_EVALUATION
} from "manager/http-constants";
import { forkJoin, Observable } from "rxjs";
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { CONSTANT, LOADING_KEY } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { Validator } from "src/app/shared/validator/validator";
import { GENERATE_INPUT_TYPE } from "src/app/shared/generate/generate-input/constant";
import { PlatformComponent } from "../platform.component";
import { SESSION_KEY } from "../../../shared/constant/session-constants";
import { LoginService } from "src/app/shared/service/login.service";

@Component({
  selector: "app-c701",
  templateUrl: "./c701.component.html",
  styleUrls: ["./c701.component.scss", "../platform.component.scss"],
})

/**
 * 提案登録(C701)
 * 提案登録確認(C702)
 */
export class C701Component extends PlatformComponent {
  // 画面.入力フォーム
  c701InputForm: FormGroup = this.formBuilder.group({
    // 提案タイトル
    implementation_date: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "industrialization_report",
          "implementation_date",
          INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE
        ),
      ],
    }),
    // 提案詳細内容
    detail: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "industrialization_report",
          "detail",
          INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
        ),
      ],
    }),
    // 導入事例・実績等など
    comment: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "industrialization_report",
          "comment",
          INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
        ),
      ],
    }),
    evaluation: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "industrialization_report",
          "evaluation",
          INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE
        ),
      ],
    }),
    reason: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "industrialization_report",
          "reason",
          INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
        ),
      ],
    }),
  });

  // 課題IDを格納
  matchId: string = this.route.snapshot.queryParams.match_id;

  // 提案IDを格納
  // suggestId: string = this.route.snapshot.queryParams.suggest_id;

  // matchType: number = this.route.snapshot.queryParams.match_type;

  // 課題・ソリューション詳細格納先
  detailTask: any;
  confirmationFlag: boolean = false;
  roleAuth: any;

  class_id: any = '';
  isLoading: boolean = true;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder,
    private validator: Validator,
    private messageData: MessageData,
    private location: Location,
    private loginService: LoginService,
  ) {
    super(route, commonService, dbOperationService, loadingState);

    this.router.navigate(["pages/platform/c701/" + this.inputType], {
      queryParams: {
        screenId: "c701",
        match_id: this.matchId,
      },
    });
  }

  public ngOnInit() {
    this.getUserLoginInfo();
    this.getDetailTask();
  }

  public getUserLoginInfo() {
    this.loginService.getLoginUser().subscribe((response) => {
      this.loadingState.loadSleepEnd(0.3);
      if (this.commonService.checkNoneResponse(response)) {
        this.loginService.logout(MESSAGE_CODE.E90000);
        return;
      } else {
        this.class_id = response.body.class_id;
      }
    });
  }

  private getDetailTask(): void {
    this.loadingState.loadStart(API_URL_GET_DETAIL_MATCHING);

    this.dbOperationService
      .getNoTemplateDisplayData(API_URL_GET_DETAIL_MATCHING, this.matchId)
      .subscribe((response) => {
        this.loadingState.loadSleepEnd(0.3, API_URL_GET_DETAIL_MATCHING);
        if (this.commonService.checkInvalidValue(response)) {
          // 異常終了の場合
          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.location.back();
        }else if (this.commonService.checkNoneResponse(response)) {
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.location.back();
        } else {
          this.detailTask = response.body;
          this.isLoading = false;
        }
      });
  }

  public moveC702(): void {
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      })
    );


    let task: Observable<any>[] = [
      /* 共通バリデーションチェック */
      this.dbOperationService.multiValidationResult(
        "suggest",
        this.c701InputForm.value
      ),
    ];

    // 非同期同時実行
    forkJoin(task).subscribe((dataList) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd();

      // 提案登録確認(C702)へ遷移
      this.router.navigate(["pages/platform/c701/" + this.inputType], {
        queryParams: { screenId: "c702", match_id: this.matchId },
      });

      // 提案登録確認(C702)を表示する
      this.confirmationFlag = true;
    });
  }

  /**
   * 提案登録完了(C703)を表示する
   */
  public moveC703(): void {
    // 画面.入力フォームから入力内容を取得
    // ※値渡し
    let c701InputForm: any = JSON.parse(
      JSON.stringify(this.c701InputForm.value)
    );

    // 課題・ソリューションID
    c701InputForm.match_id = this.matchId;

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.DATA_INSERT);

    // 登録タイプ判定
    if (GENERATE_INPUT_TYPE.NEW == this.inputType) {
      // 新規登録の場合

      // 提案登録を実施
      this.dbOperationService
        .insertData(API_URL_ADD_EVALUATION, c701InputForm)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

          // レスポンス結果を判定
          if (API_RESPONSE.SUCCESS == response.body.Message) {
            // 正常終了の場合

            // 提案登録完了(C433)へ遷移
            this.router.navigate(["pages/platform/c703"]);
          }else if (this.commonService.checkInvalidValue(response)) {
            // 異常終了の場合
            // 異常終了メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.router.navigate(["pages/platform/c502"]);
          } else if (API_RESPONSE.FAIL == response.body.Message) {
            // 異常終了の場合

            // 異常終了メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(MESSAGE_CODE.E00002),
                detail: this.commonService.msg(MESSAGE_CODE.E00004),
              })
            );
            this.router.navigate(["pages/platform/c502"]);
            // 提案登録(C431)を表示する
            this.confirmationFlag = false;
          } else {
            // 提案上限の場合

            // 提案上限メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(MESSAGE_CODE.E00001),
                detail: response.body.Message,
              })
            );

            // 提案登録(C431)を表示する
            this.confirmationFlag = false;
          }
        });
    } 
  }

  public formatDateJapan() {
    let date = new Date(this.c701InputForm.value?.implementation_date);
    let lang = 'en',
    year = date.toLocaleString(lang, {year:'numeric'}),
    month = date.toLocaleString(lang, {month:'numeric'}),
    day = date.toLocaleString(lang, {day:'numeric'});
    return `${year}年 ${month}月 ${day}日`;
  }
  moveC701(){
    this.router.navigate(["pages/platform/c701/" + this.inputType], {
      queryParams: { screenId: "c701", matchId: this.matchId },
    });

    this.confirmationFlag = false;
  }
}
