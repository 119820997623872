

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- タイトル -->
    <div class="p-d-flex p-jc-center title">
      <div>
        <label class="p-d-flex p-jc-center title-name">お知らせ</label>
      </div>
    </div>

    <div class="p-d-flex p-jc-center information-title p-mb-3">{{ detailInformation?.head }}</div>

    <!-- お知らせ詳細 -->
    <p-table [value]="[{}]" styleClass="information-Form">
      <ng-template pTemplate="body">
        <!-- お知らせ区分 -->
        <tr>
          <td class="item-name">お知らせ区分</td>
          <td class="item-information">
            {{ detailInformation?.information_kbn.name }}
          </td>
        </tr>
        <!-- 見出し -->
        <tr>
          <td class="item-name">見出し</td>
          <td class="item-information">
            {{ detailInformation?.head }}
          </td>
        </tr>
        <!-- タイトル -->
        <tr>
          <td class="item-name">タイトル</td>
          <td class="item-information">
            {{ detailInformation?.title }}
          </td>
        </tr>
        <!-- 掲載URL -->
        <tr>
          <td class="item-name">掲載URL</td>
          <td class="item-information">
            <a [href]="detailInformation?.url" target="_blank" class="linktext-blue underline">
              {{ detailInformation?.url }}
            </a>
          </td>
        </tr>
        <!-- 掲載開始日 -->
        <tr>
          <td class="item-name">掲載開始日</td>
          <td class="item-information">
            {{ detailInformation?.publishe_start }}
          </td>
        </tr>
        <!-- 掲載終了日 -->
        <tr>
          <td class="item-name">掲載終了日</td>
          <td class="item-information">
            {{ detailInformation?.publishe_end }}
          </td>
        </tr>
        <!-- お知らせ内容 -->
        <tr>
          <td class="item-name">お知らせ内容</td>
          <td class="item-information">
            {{ detailInformation?.information_detail }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>
