/** 入力フォーム確認用オブジェクト */
export class GenerateInputFormConfirmation {
  // カラム名称
  private _columnName: string;

  // カラムデータ
  private _columnData: string;

  set columnName(columnName: string) {
    this._columnName = columnName;
  }

  get columnName(): string {
    return this._columnName;
  }

  set columnData(columnData: string) {
    this._columnData = columnData;
  }

  get columnData(): string {
    return this._columnData;
  }
}
