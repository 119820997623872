import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';
import { LoginService } from "src/app/shared/service/login.service";

@Component({
  selector: 'app-a213',
  templateUrl: './a213.component.html',
  styleUrls: ['./a213.component.scss', '../platform.component.scss'],
})

/**
 * 新規入会時のパスワード設定完了
 */
export class A213Component extends PlatformComponent {
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private loginService: LoginService
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * マイページ(B301)へ遷移する
   */
  public moveB301(): void {
    // マイページ(B301)へ遷移
    // this.router.navigate(['pages/platform/b301']);
    this.loginService.logout();
  }
}
