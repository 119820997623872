import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { PlatformComponent } from '../platform.component';
import { ROLES } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-c433',
  templateUrl: './c433.component.html',
  styleUrls: ['./c433.component.scss', '../platform.component.scss'],
})

/**
 * マッチング提案登録完了
 */
export class C433Component extends PlatformComponent {
  roleAuth: any;
  title: string;
  matchType: string = '';
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private loginService: LoginService
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.getUserClassId();
    this.matchType = this.route.snapshot.queryParams.matchType
  }

  /**
   * move to c502
   */
  public moveC502(): void {
    this.router.navigate(['pages/platform/c502']);
  }

  public getUserClassId(): string | void {
    this.loginService.getLoginUser().subscribe((response) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3);

      // ログインユーザ情報がユーザマスタに存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        // ユーザマスタに存在しない場合

        // 不正なユーザの為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.E90000);

        // 処理を終了
        return;
      }
    });
  }
}
