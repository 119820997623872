<div class="main">
  <div class="main-content" *ngIf="!confirmationFlag">
    <!-- 戻る -->
    <div class="return">
      <button
        pButton
        type="button"
        label="一覧へ戻る"
        icon="pi pi-angle-left"
        class="p-button-text"
        (click)="moveB220()"
      ></button>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-jc-center content-detail">
        <div class="steps p-field p-col-12">
          <app-b220-steps></app-b220-steps>
        </div>

        <div class="p-grid form mt-6" >
          <div
            class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
          >
            <div class="form-content p-col-8 p-md-12 p-sm-12">
              <form [formGroup]="b221InputForm">
                <div class="form-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="zip-code" class="zip-code-label">氏名</label>
                    </div>
                    <div class="p-col-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9">
                      <input
                        id="zip-code"
                        class="input-zip-code-after"
                        type="text"
                        pInputText
                        maxlength="20"
                        formControlName="full_name"
                        [ngClass]='checkInputMaxBoundary(b221InputForm,"full_name")?"invalid-length":""'
                        autocomplete="off"
                      />
                    </div>

                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="b221InputForm.get('full_name')"
                      >
                      </app-validator-message>
                    </div>
                  </div>

                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="city" class="city-label"
                        >メールアドレス</label
                      >
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9">
                      <input
                        id="address"
                        class="input-address"
                        type="text"
                        pInputText
                        formControlName="email"
                        [ngClass]='checkInputMaxBoundary(b221InputForm,"email")?"invalid-length":""'
                        autocomplete="off"
                      />
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="b221InputForm.get('email')"
                      >
                      </app-validator-message>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form-content register p-col-8">
              <div class="p-grid p-grid-register">
                <div class="p-col-4 btn-block p-md-3">
                  <button
                    pButton
                    type="button"
                    label="確認画面へ進む"
                    class="btn-register button1"
                    [disabled]="!b221InputForm.valid"
                    (click)="moveB222()"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content" *ngIf="confirmationFlag">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-jc-center content-detail">
        <div class="steps p-field p-col-12">
          <app-b220-steps></app-b220-steps>
        </div>

        <div class="p-grid form mt-6">
          <div
            class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
          >
            <div class="form-content p-col-8 p-md-12 p-sm-12">
              <form [formGroup]="b221InputForm">
                <div class="form-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="zip-code" class="zip-code-label">氏名</label>
                    </div>
                    <div class="p-col-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9 text-center">
                      {{ b221InputForm.value?.full_name }}
                    </div>
                  </div>

                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="city" class="city-label"
                        >メールアドレス</label
                      >
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9 text-center" *ngIf="'new' == inputType">
                      {{ b221InputForm.value?.email }}
                    </div>

                    <div class="p-col-10 p-md-9 text-center" *ngIf="'edit' == inputType">
                      {{ editDisplayEmail }}
                    </div>
    
                  </div>
                </div>
              </form>
            </div>

            <div class="form-content register p-col-8">
              <div class="p-grid p-grid-register">
                <div class="p-col-4 btn-block p-md-3">
                  <button
                    pButton
                    type="button"
                    label="登録する"
                    class="button1 btn-register"
                    (click)="moveB223()"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
