/**
 * 画面用メッセージ定数
 */
/** 権限チェックエラー */
export const ROUTING_ERROR = {
  // 権限情報設定無し
  ROUTING_NONE: "権限情報が設定されておりません",
  // 権限パスが不正
  ROUTING_AUTHORITY_PATH_ERROR: "URLが不正です",
  // 権限が不正
  ROUTING_AUTHORITY_ERROR: "ログインユーザ情報の権限が不正です",
};

/** HTTPステータスエラー */
export const HTTP_ERROR = {
  // 401
  RESPONSE_NONE: "APIレスポンスが存在しません",
  // 400
  HTTP_400: "APIに不正なリクエストが行われました",
  // 401
  HTTP_401: "認証に失敗しました",
  // 403
  HTTP_403: "不正なリクエストが送信されました",
  // 404
  HTTP_404: "APIが存在しません",
  // 412
  HTTP_412: "システム稼働時間外となります",
  // 422
  HTTP_422: "API取得失敗",
  // 500
  HTTP_500: "API取得失敗",
  // ERROR
  ERROR: "APIに予期しないエラーが発生しました",
};

/** メッセージコード */
// 先頭文字
// T 下記のいずれにも一致しない
// S DB検索系のメッセージ
// I DB登録系メッセージ
// U DB更新系メッセージ
// D DB削除系メッセージ
// N 通常メッセージ
// E エラーメッセージ
export const MESSAGE_CODE = {
  // 完了
  T00001: "T00001",
  // 完了しました
  T00002: "T00002",
  // 反映まで5分かかります
  T00003: "T00003",
  // {0}:{1}
  T00004: "T00004",
  // ファイル情報を作成致します。<br>作成にお時間が掛かりますが、よろしいでしょうか？<br>
  T00005: "T00005",
  // 情報が選択されていません
  T00006: "T00006",
  // 出力する情報を選択して下さい
  T00007: "T00007",
  // 一括入力してもよろしいですか？<br><font color="#FF0000">注意：現在の入力値は破棄されます</font>
  T00008: "T00008",
  // <font color="#FF0000">現在の入力値は全て破棄されますが<br>よろしいですか？</font>
  T00009: "T00009",
  // チェックされた行があります。<br><font color="#FF0000">注：チェック行は｢選択ボタン｣を押下するまで反映されません。<br>&ensp;&ensp;&ensp;&ensp;画面遷移しても、よろしいでしょうか？</font>
  T00010: "T00010",
  // {0}:{1}<br>承認してもよろしいですか？
  T00011: "T00011",
  // 一括承認しても宜しいでしょうか?
  T00012: "T00012",
  // ファイル情報の作成を開始しました。
  T00013: "T00013",
  // 確認コード
  T00014: "T00014",
  // {0}が存在しませんでした
  S00001: "S00001",
  // 入力確認中
  S00002: "S00002",
  // 検索中
  S00003: "S00003",
  // 一覧取得中
  S00004: "S00004",
  // ファイル確認中
  S00005: "S00005",
  // 注文確認中
  S00006: "S00006",
  // リセット中
  S00007: "S00007",
  // リセットしてもよろしいですか？
  S00008: "S00008",
  // 数量確認中
  S00009: "S00009",
  // 在庫確認中
  S00010: "S00010",
  // 帳票出力中
  S00011: "S00011",
  // ファイル出力中
  S00012: "S00012",
  // 画像ダウンロード中
  S00013: "S00013",
  // 登録完了しました
  I00001: "I00001",
  // 複製完了しました
  I00002: "I00002",
  // 画像登録完了しました
  I00003: "I00003",
  // 注文内容は<br>お間違え御座いませんでしょうか？
  I00004: "I00004",
  // 注文が完了しました
  I00005: "I00005",
  // ファイル情報の登録は<br>お間違え御座いませんでしょうか?
  I00006: "I00006",
  // ファイル情報の登録が完了しました
  I00007: "I00007",
  // 登録中
  I00008: "I00008",
  // 複製中
  I00009: "I00009",
  // ファイル登録中
  I00010: "I00010",
  // 注文中
  I00011: "I00011",
  // 追加中
  I00012: "I00012",
  // ファイル登録完了しました
  I00013: "I00013",
  // 追加完了
  I00014: "I00014",
  // {0}を追加しました
  I00015: "I00015",
  // 計画数は<br>お間違え御座いませんでしょうか？
  I00016: "I00016",
  // 制作指示中
  I00017: "I00017",
  // 制作指示内容は<br>お間違え御座いませんでしょうか？
  I00018: "I00018",
  // 確認コードを再送信しました
  I00019: "I00019",
  // 編集完了しました
  U00001: "U00001",
  // 更新完了しました
  U00002: "U00002",
  // キャンセルしました
  U00003: "U00003",
  // {0}:{1}<br>キャンセルしてもよろしいですか？
  U00004: "U00004",
  // {0}の出力が完了しました
  U00005: "U00005",
  // 編集中
  U00006: "U00006",
  // 更新中
  U00007: "U00007",
  // 情報出力中
  U00008: "U00008",
  // キャンセル中
  U00009: "U00009",
  // 選択された{0}を{1}しました
  U00010: "U00010",
  // 回答完了しました
  U00011: "U00011",
  // {0}完了しました
  D00001: "D00001",
  // {0}:{1}<br>{2}処理します。よろしいですか？
  D00002: "D00002",
  // {0}<br>削除してもよろしいですか？
  D00003: "D00003",
  // 削除中
  D00004: "D00004",
  // {0}を削除しました
  D00005: "D00005",
  // 終了に更新します。よろしいですか？
  D00006: "D00006",
  //このユーザを削除したら{0}団体も削除されます。<br>よろしいでしょうか。
  D00007: "D00007",
  //完了
  D00008: "D00008",
  
  //Do you want to discard this draft?
  D00009: "D00009",
  //Do you want to delete this message?
  D00010: "D00010",
  // 検討中に更新します。よろしいですか?
  D00011: "D00011",
  // マッチング成立に更新します。よろしいですか?
  D00012: "D00012",
  // マッチング不成立に更新します。よろしいですか?
  D00013: "D00013",
  // ログイン
  N80000: "N80000",
  // ようこそ、{0}さん
  N80001: "N80001",
  // ログアウト
  N80002: "N80002",
  // ご利用ありがとうございました
  N80003: "N80003",
  // ログアウトしました
  N90000: "N90000",
  // ユーザのログイン有効期限が過ぎております<br>下記から再度ログインをお願い致します
  N90001: "N90001",
  // システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します
  N90002: "N90002",
  // ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します
  N90003: "N90003",
  // 失敗
  E00001: "E00001",
  // 失敗しました
  E00002: "E00002",
  // エラー
  E00003: "E00003",
  // 登録失敗しました
  E00004: "E00004",
  // 削除失敗しました
  E00005: "E00005",
  // キャンセル失敗しました
  E00006: "E00006",
  // status:{0} body:{1} url:{3}
  E00007: "E00007",
  // status:{0} message:{1}
  E00008: "E00008",
  // 登録失敗しました
  E00009: "E00009",
  // 画像の登録は、{0}のファイルとなります
  E00010: "E00010",
  // 画像サイズは{0}以下までとなります
  E00011: "E00011",
  // ファイル読み込みが失敗しました
  E00012: "E00012",
  // ファイル拡張子は{0}のみとなります
  E00013: "E00013",
  // 活動イメージ写真と活動内容（ファイル）の総容量は{0}以下までとなります
  E00014: "E00014",
  // カート追加に失敗しました
  E00015: "E00015",
  // {0}を1件以上選択してください。
  E00016: "E00016",
  // 注文に失敗しました
  E00017: "E00017",
  // 一括入力に失敗しました
  E00018: "E00018",
  // 一括入力値を入力して下さい
  E00019: "E00019",
  // 入力値を入力して下さい
  E00020: "E00020",
  // 承認に失敗しました
  E00021: "E00021",
  // 画像ダウンロード失敗しました
  E00022: "E00022",
  // 画像登録を実施してください
  E00023: "E00023",
  // ファイル作成に失敗しました
  E00024: "E00024",
  // ファイルサイズは{0}のみ、登録出来ます
  E00025: "E00025",
  // 該当情報は既に存在しません。
  E00026: "E00026",
  // 確認コードが一致しません
  E00027: "E00027",
  // 該当メールアドレスが存在しません
  E00028: "E00028",
  // 確認コードを再送信に失敗しました
  E00029: "E00029",
  // 終了日が開始日未満の日付です。
  E00030: "E00030",
  // 画像は{0}以下までとなります
  E00031: "E00031",
  // ファイルは{0}以下までとなります
  E00032: "E00032",
  // 資料ファイル登録失敗しました
  E00033: "E00033",
  // 必須項目です。必ず入力してください。
  E00034: "E00034",
  // 画像登録失敗しました
  E00035: "E00035",
  // {0}API URLが存在しません({1})
  E80000: "E80000",
  // 異常発生
  E80001: "E80001",
  // お手数ですが画面を再度読み込み直してください
  E80002: "E80002",
  // 警告
  E80003: "E80003",
  // 不正なURLになります
  E80004: "E80004",
  // 不正なユーザとなります<br>下記から再ログインをお願い致します
  E90000: "E90000",
  // ログインユーザに権限がございません<br>下記から再ログインをお願い致します
  E90001: "E90001",
  // 不正なリクエストが行われました<br>下記から再ログインをお願い致します
  E90002: "E90002",
  // 掲示板コメントの連続投稿は3回までに制限されています
  E90003: "E90003",
  // 1件以上選択してください
  E90004: "E90004",
  // ファイルの総容量は{0}以下までとなります
  E90005: "E90005",
  // ステータス更新失敗しました
  E90006: "E90006",
};

/** メッセージ */
// 先頭文字
// T 下記のいずれにも一致しない
// S DB検索系のメッセージ
// I DB登録系メッセージ
// U DB更新系メッセージ
// D DB削除系メッセージ
// N 通常メッセージ
// E エラーメッセージ
export const MESSAGE = {
  // 完了
  T00001: "完了",
  // 完了しました
  T00002: "完了しました",
  // 反映まで5分かかります
  T00003: "反映まで5分かかります",
  // {0}:{1}
  T00004: "{0}：{1}",
  // ファイル情報を作成致します。<br>作成にお時間が掛かりますが、よろしいでしょうか？<br>
  T00005:
    "ファイル情報を作成致します。<br>作成にお時間が掛かりますが、よろしいでしょうか？<br>",
  // 情報が選択されていません
  T00006: "情報が選択されていません",
  // 出力する情報を選択して下さい
  T00007: "出力する情報を選択して下さい",
  // 一括入力してもよろしいですか？<br><font color="#FF0000">注意：現在の入力値は破棄されます</font>
  T00008:
    '一括入力してもよろしいですか？<br><font color="#FF0000">注意：現在の入力値は破棄されます</font>',
  // <font color="#FF0000">現在の入力値は全て破棄されますが<br>よろしいですか？</font>
  T00009:
    '<font color="#FF0000">現在の入力値は全て破棄されますが<br>よろしいですか？</font>',
  // チェックされた行があります。<br><font color="#FF0000">注：チェック行は｢選択ボタン｣を押下するまで反映されません。<br>&ensp;&ensp;&ensp;&ensp;画面遷移しても、よろしいでしょうか？</font>
  T00010:
    'チェックされた行があります。<br><font color="#FF0000">注：チェック行は｢選択ボタン｣を押下するまで反映されません。<br>&ensp;&ensp;&ensp;&ensp;画面遷移しても、よろしいでしょうか？</font>',
  // {0}:{1}<br>承認してもよろしいですか？
  T00011: "{0}:{1}<br>承認してもよろしいですか？",
  // 一括承認してもよろしいですか？
  T00012: "一括承認してもよろしいですか？",
  // ファイル情報の作成を開始しました。
  T00013: "ファイル情報の作成を開始しました。",
  // 確認コード
  T00014: "確認コード",
  // {0}が存在しませんでした
  S00001: "{0}が存在しませんでした",
  // 入力確認中
  S00002: "入力確認中",
  // 検索中
  S00003: "検索中",
  // 一覧取得中
  S00004: "一覧取得中",
  // ファイル確認中
  S00005: "ファイル確認中",
  // 注文確認中
  S00006: "注文確認中",
  // リセット中
  S00007: "リセット中",
  // リセットしてもよろしいですか？
  S00008: "リセットしてもよろしいですか？",
  // 数量確認中
  S00009: "数量確認中",
  // 在庫確認中
  S00010: "在庫確認中",
  // 帳票出力中
  S00011: "帳票出力中",
  // ファイル出力中
  S00012: "ファイル出力中",
  // 画像ダウンロード中
  S00013: "画像ダウンロード中",
  // 登録完了しました
  I00001: "登録完了しました",
  // 複製完了しました
  I00002: "複製完了しました",
  // 画像登録完了しました
  I00003: "画像登録完了しました",
  // 注文内容は<br>お間違え御座いませんでしょうか？
  I00004: "注文内容は<br>お間違え御座いませんでしょうか？",
  // ファイル登録失敗しました
  I00005: "ファイル登録失敗しました",
  // ファイル情報の登録は<br>お間違え御座いませんでしょうか?
  I00006: "ファイル情報の登録は<br>お間違え御座いませんでしょうか?",
  // ファイル情報の登録が完了しました
  I00007: "ファイル情報の登録が完了しました",
  // 登録中
  I00008: "登録中",
  // 複製中
  I00009: "複製中",
  // ファイル登録中
  I00010: "ファイル登録中",
  // 注文中
  I00011: "注文中",
  // 追加中
  I00012: "追加中",
  // ファイル登録完了しました
  I00013: "ファイル登録完了しました",
  // 追加完了
  I00014: "追加完了",
  // {0}を追加しました
  I00015: "{0}を追加しました",
  // 計画数は<br>お間違え御座いませんでしょうか？
  I00016: "計画数は<br>お間違え御座いませんでしょうか？",
  // 制作指示中
  I00017: "制作指示中",
  // 制作指示内容は<br>お間違え御座いませんでしょうか？
  I00018: "制作指示内容は<br>お間違え御座いませんでしょうか？",
  // 確認コードを再送信しました
  I00019: "確認コードを再送信しました",
  // 編集完了しました
  U00001: "編集完了しました",
  // 更新完了しました
  U00002: "更新完了しました",
  // キャンセルしました
  U00003: "キャンセルしました",
  // {0}:{1}<br>キャンセルしてもよろしいですか？
  U00004: "{0}:{1}<br>キャンセルしてもよろしいですか？",
  // {0}の出力が完了しました
  U00005: "{0}の出力が完了しました",
  // 編集中
  U00006: "編集中",
  // 更新中
  U00007: "更新中",
  // 情報出力中
  U00008: "情報出力中",
  // キャンセル中
  U00009: "キャンセル中",
  // 選択された{0}を{1}しました
  U00010: "選択された{0}を{1}しました",
  // 回答完了しました
  U00011: "回答完了しました",
  // {0}完了しました
  D00001: "{0}削除完了しました",
  // {0}:{1}<br>ユーザを削除します。よろしいですか？
  D00002: "{0}：{1}<br>{2}を削除します。よろしいですか？",
  // {0}<br>削除してもよろしいですか？
  D00003: "{0}<br>削除してもよろしいですか？",
  // 削除中
  D00004: "削除中",
  // {0}を削除しました
  D00005: "{0}を削除しました",
  // 終了に更新します。よろしいですか？？
  D00006: "終了に更新します。よろしいですか？",
  //このユーザを削除したら団体 ID:{0}も削除されます<br>よろしいでしょうか。
  D00007:
    'このユーザを削除したら団体 ID:<span class="p-text-bold">{0}</span>も削除されます。<br>よろしいでしょうか。',
  //完了
  D00008: "完了",
  //Do you want to discard this draft?
  D00009: "このメッセージをキャンセルします。よろしいでしょうか。",
  //Do you want to delete this message?
  D00010: "このメッセージを削除します。よろしいでしょうか。",
  // 検討中に更新します。よろしいですか?
  D00011: "検討中に更新します。よろしいですか?",
  // マッチング成立に更新します。よろしいですか?
  D00012: "マッチング成立に更新します。よろしいですか?",
  // マッチング不成立に更新します。よろしいですか?
  D00013: "マッチング不成立に更新します。よろしいですか?",
  // ログイン
  N80000: "ログイン",
  // ようこそ、{0}さん
  N80001: "ようこそ、{0}さん",
  // ログアウト
  N80002: "ログアウト",
  // ご利用ありがとうございました
  N80003: "ご利用ありがとうございました",
  // ログアウトしました
  N90000: "ログアウトしました",
  // ユーザのログイン有効期限が過ぎております<br>下記から再度ログインをお願い致します
  N90001:
    "セッションの有効期限が切れました",
  // システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します
  N90002:
    "システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します",
  // ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します
  N90003:
    "ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します",
  // 失敗
  E00001: "失敗",
  // 失敗しました
  E00002: "失敗しました",
  // エラー
  E00003: "エラー",
  // 登録失敗しました
  E00004: "登録失敗しました",
  // 削除失敗しました
  E00005: "削除失敗しました",
  // キャンセル失敗しました
  E00006: "キャンセル失敗しました",
  // status:{0} body:{1} url:{3}
  E00007: "status:{0} body:{1} url:{2}",
  // status:{0} message:{1}
  E00008: "status:{0} message:{1}",
  // M402: Select a file with a format extension other than PDF or pdf
  E00009: "登録失敗しました",
  // 画像の登録は、{0}のファイルとなります
  E00010: "画像の登録は、{0}のファイルとなります",
  // 画像サイズは{0}以下までとなります
  E00011: "画像サイズは{0}以下までとなります",
  // ファイル読み込みが失敗しました
  E00012: "ファイル読み込みが失敗しました",
  // ファイル拡張子は{0}のみとなります
  E00013: "ファイル拡張子は{0}のみとなります",
  // 画像とファイルの総容量は{0}以下までとなります
  E00014: "画像とファイルの総容量は{0}以下までとなります",
  // カート追加に失敗しました
  E00015: "カート追加に失敗しました",
  // {0}を1件以上選択してください。
  E00016: "{0}を1件以上選択してください。",
  // 注文に失敗しました
  E00017: "注文に失敗しました",
  // 一括入力に失敗しました
  E00018: "一括入力に失敗しました",
  // 一括入力値に入力して下さい
  E00019: "一括入力値を入力して下さい",
  // 入力値を入力して下さい
  E00020: "入力値を入力して下さい",
  // 承認に失敗しました
  E00021: "承認に失敗しました",
  // 画像ダウンロード失敗しました
  E00022: "画像ダウンロード失敗しました",
  // 画像登録を実施してください
  E00023: "画像登録を実施してください",
  // ファイル作成に失敗しました
  E00024: "ファイル作成に失敗しました",
  // ファイルサイズは{0}のみ、登録出来ます
  E00025: "ファイルサイズは{0}のみ、登録出来ます",
  // 該当情報は既に存在しません。
  E00026: "該当情報は既に存在しません。",
  // 確認コードが一致しません
  E00027: "確認コードが一致しません",
  // 該当メールアドレスが存在しません
  E00028: "該当メールアドレスが存在しません",
  // 確認コードを再送信に失敗しました
  E00029: "確認コードを再送信に失敗しました",
  // 終了日が開始日未満の日付です。
  E00030: "終了日が開始日未満の日付です。",
  // M402 Select photo > {0} Ex: 4MB
  E00031: "画像は{0}以下までとなります",
  // M402: Select file > {0} Ex: 4MB
  E00032: "ファイルは{0}以下までとなります",
  // C401 Select a file with a format extension other than PDF or pdf
  E00033: "登録失敗しました",
  // 必須項目です。必ず入力してください。
  E00034: "必須項目です。必ず入力してください。",
  //M402: Select an image with a format extension other than PNG/png/JPEG/jpeg
  E00035: "登録失敗しました",
  // {0}API URLが存在しません({1})
  E80000: "{0}API URLが存在しません({1})",
  // 異常発生
  E80001: "異常発生",
  // お手数ですが画面を再度読み込み直してください
  E80002: "お手数ですが画面を再度読み込み直してください",
  // 警告
  E80003: "警告",
  // 不正なURLになります
  E80004: "不正なURLになります",
  // 不正なユーザとなります<br>下記から再ログインをお願い致します
  E90000: "不正なユーザとなります<br>下記から再度ログインをお願い致します",
  // ログインユーザに権限がございません<br>下記から再ログインをお願い致します
  E90001:
    "ログインユーザに権限がございません<br>下記から再度ログインをお願い致します",
  // 不正なリクエストが行われました<br>下記から再ログインをお願い致します
  E90002:
    "不正なリクエストが行われました<br>下記から再度ログインをお願い致します",
  // 掲示板コメントの連続投稿は3回までに制限されています
  E90003: "掲示板コメントの連続投稿は3回までに制限されています",
  // 1件以上選択してください
  E90004: "1件以上選択してください",
  // ファイルの総容量は{0}以下までとなります
  E90005: "ファイルの総容量は{0}以下までとなります",
  // ステータス更新失敗しました
  E90006: "  ステータス更新失敗しました",

};
