<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- A200系共通部品_Steps -->
    <app-a200-steps [class_id]="class_id" ></app-a200-steps>

    <!-- メッセージ -->
    <div class="message">
      <!-- メッセージ本文 -->
      <div class="message-text">
        <div class="p-d-flex p-jc-center">
          <i class="pi pi-envelope black-color"></i><br />
        </div>
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center black-color" >
          登録したメールアドレス宛に確認コードが送られます
        </div>
      </div>
      <!-- メッセージ注意 -->
      <div class="message-warning">
        <div class="p-d-flex p-jc-center">
          <div class="p-as-center">
            <i class="pi pi-exclamation-circle"> </i>
            入会申請がまだ終わっていません
          </div>
        </div>
      </div>
    </div>

    <!-- 入力項目 -->
    <div class="confirmation">
      <div class="p-d-flex p-jc-center">
        メール内に記載された4桁の確認コードを入力してください。
      </div>
      <div class="confirmation-margin"></div>
      <!-- 確認コード -->
      <p-table [value]="[{}]" styleClass="information-Form">
        <ng-template pTemplate="body">
          <!-- 確認コード -->
          <tr>
            <td class="item-name">
              確認コード
              <span class="required">【必須】</span>
            </td>
            <td class="item-information">
              <input
                type="text"
                pInputText
                placeholder="例）0000"
                [formControl]="code"
                [ngClass]='checkInputMaxBoundary(code)?"invalid-length":""'

                autocomplete="off"
              />
              <!-- エラーメッセージ出力箇所 -->
              <app-validator-message [control]="code"> </app-validator-message>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="confirmation-margin"></div>
      メールが届かない場合、迷惑メールフォルダを確認してください。<br/>
      <span class="confirmation-retran" (click)="resendCode()">
        確認コード再送信 </span
      >を行ってください。<br/>
      それでも届かない場合は、運営事務局までお問い合わせください。
    </div>

    <!-- ボタンエリア -->
    <div class="p-d-flex p-jc-center button-area p-mt-3">
      <button
        pButton
        type="button"
        label="確認する"
        class="button-default-size button1"
        (click)="moveA204()"
        [disabled]="!code.valid"
      ></button>
    </div>
  </div>
</div>

