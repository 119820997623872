import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';
import { LoginService } from "src/app/shared/service/login.service";

@Component({
  selector: 'app-a204',
  templateUrl: './a204.component.html',
  styleUrls: ['./a204.component.scss', '../platform.component.scss'],
})

/**
 * 入会申請完了
 */
export class A204Component extends PlatformComponent {
  class_id: string = this.route.snapshot.queryParams.class_id;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private loginService: LoginService
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * ホームページへ遷移する
   */
  public moveLogin(): void {
    // ホームページへ遷移
    this.loginService.logout();
    // window.location.href = '';
  }
}
