import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  API_URL_ADD_MATCHING,
  API_URL_GET_LIST_TITLE_APPLICATION,
  API_URL_GET_UPLOAD_URL
} from "manager/http-constants";
import { forkJoin, Observable } from "rxjs";
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { CONSTANT, DICID_NUMBER, FILE_EXTENSION, LOADING_KEY, RECRUITMENT_TYPE } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { Validator } from "src/app/shared/validator/validator";
import { GENERATE_INPUT_TYPE } from "src/app/shared/generate/generate-input/constant";
import { PlatformComponent } from "../platform.component";
import { SESSION_KEY } from "../../../shared/constant/session-constants";
import { LoginService } from "src/app/shared/service/login.service";
import { FileUpload } from "primeng/fileupload";
import { MessageService } from "primeng/api";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";

@Component({
  selector: "app-c431",
  templateUrl: "./c431.component.html",
  styleUrls: ["./c431.component.scss", "../platform.component.scss"],
})

/**
 * 提案登録(C431)
 * 提案登録確認(C432)
 */
export class C431Component extends PlatformComponent {
  // 画面.入力フォーム
  c431InputForm: FormGroup ;

  // 課題IDを格納
  agendaId: string = this.route.snapshot.queryParams.agenda_id;

  // 提案IDを格納
  suggestId: string = this.route.snapshot.queryParams.suggest_id;
  // match type 1 == seed, 0 == need
  matchType: number = this.route.snapshot.queryParams.match_type;

  // 課題・ソリューション詳細格納先
  detailTask: any;

  locale:any = this.getDicList(102);

  // 確認画面表示フラグ(true=提案登録確認(C432),false=提案登録(C431))
  confirmationFlag: boolean = false;
  isDisabled: boolean = false;

  recruitmentType:any[]= [
    {
      name:RECRUITMENT_TYPE.CONFERENCE,
      value:1
    },
    {
      name:RECRUITMENT_TYPE.OTHERS,
      value:2
    },

  ];

  roleAuth: any;
  titleMulti: string;
  isDataLoaded: boolean= false;
  fileUploadDocument: File;
  //reset file upload
  fileUploadFlag: boolean = true;
    hasSelectedFile:boolean = false;
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder,
    private validator: Validator,
    private messageData: MessageData,
    private location: Location,
    private loginService: LoginService,
    private messageService: MessageService,
    private http: HttpClient,
  ) {
    super(route, commonService, dbOperationService, loadingState);

    // urlを提案登録(C431)に変更する
    this.router.navigate(["pages/platform/c401/" + this.inputType], {
      queryParams: {
        screenId: "c401",
        agenda_id: this.agendaId,
        suggest_id: this.suggestId,
        match_type: this.matchType
      },
    });
  }

  public ngOnInit() {
    this.getSpecCodeList(DICID_NUMBER.PRACTICE_FORMAT, DICID_NUMBER.TARGET_GRADE);

    this.initForm();
    // ユーザマスタに存在する場合
    this.getUserClassId();
    // 課題一覧を取得
    this.getDetailTask();

  }

  public getUserClassId(): string | void {
    this.loginService.getLoginUser().subscribe((response) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3);

      // ログインユーザ情報がユーザマスタに存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        // ユーザマスタに存在しない場合

        // 不正なユーザの為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.E90000);

        // 処理を終了
        return;
      } else {
        // ユーザマスタに存在する場合
        this.roleAuth = response.body.class_id;
      }
    });
  }

  private initForm(){
    if(this.matchType == 1) {
      this.c431InputForm = this.formBuilder.group({
        title: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "title",
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
            ),
          ],
        }),

        recruitment_kind: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "recruitment_kind",
              INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
            ),
          ],
        }),

        expected_content: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "expected_content",
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
            ),
          ],
        }),

        expected_url: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "expected_url",
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
            ),
          ],
        }),

        document: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "document",
              INPUT_INFORMATIONS_API_CONSTANT.FILE_TYPE
            ),
          ],
        }),

        deadline: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "deadline",
              INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE
            ),
          ],
        }),

        event_date: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "event_date",
              INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE
            ),
          ],
        }),

        event_date_memo: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "event_date_memo",
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
            ),
          ],
        }),

        area: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "area",
              INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
            ),
          ],
        }),

        organizer: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "organizer",
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
            ),
          ],
        }),

        venue: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "venue",
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
            ),
          ],
        }),

        payment_terms: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "payment_terms",
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
            ),
          ],
        }),

        manager: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "manager",
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
            ),
          ],
        }),

        contact_information: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "contact_information",
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
            ),
          ],
        }),

      });
    }
    else {
      this.c431InputForm = this.formBuilder.group({

        // 提案詳細内容
        memo: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
          asyncValidators: [
            this.validator.CheckValidator(
              "match",
              "memo",
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
            ),
          ],
        }),
      });
      this.c431InputForm.get('memo').markAsDirty()
    }
  }


  public getUrlMatchType() {
    return this.matchType == 1 ? API_URL_GET_LIST_TITLE_APPLICATION : API_URL_GET_LIST_TITLE_APPLICATION;
  }

  /**
   * 課題・ソリューション詳細を取得
   */
  private getDetailTask(): void {

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();
    const url = this.commonService.url(
      API_URL_GET_LIST_TITLE_APPLICATION,
      '?agenda_id=',
      this.agendaId,
      '&match_type=',
      this.matchType
    );
    // if(this.inputType=='1'){
    // 課題・ソリューション詳細を取得
    this.dbOperationService
      .getNoTemplateDisplayData(url, '')
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, );

        if (this.commonService.checkInvalidValue(response)) {
          this.isDataLoaded = false;
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );

          // 前画面へ戻る
          this.location.back();
        } else {
          this.isDataLoaded = true;
          // 課題・ソリューション詳細を格納
          this.detailTask = response.body;
          this.titleMulti = `下記のサポーター${this.detailTask.length}名に依頼します`
        }
      });
    // }else{
    //   this.loadingState.loadSleepEnd(0.3, );

    //   this.isDataLoaded = true;
    //   this.detailTask = [{}];
    //   this.titleMulti = `全${this.detailTask.length}件の申し込み`
    // }

  }


  /**
   * 提案登録(C431)を表示する
   */
  public moveC431(): void {
    // 提案登録(C431)へ遷移
    this.router.navigate(["pages/platform/c401/" + this.inputType], {
      queryParams: { screenId: "c401", agenda_id: this.agendaId ,match_type : this.matchType },
    });

    // 提案登録(C431)を表示する
    this.confirmationFlag = false;
  }

  /**
   * 提案登録確認(C432)を表示する
   */
  public moveC432(): void {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: "",
        background_color: "",
        opacity: 0.3,
      })
    );

    /* 入力項目値バリデーションチェック */
    // 非同期同時実行リスト
    const task: Observable<any>[] = [
      /* 共通バリデーションチェック */
      this.dbOperationService.multiValidationResult(
        "match",
        this.c431InputForm.value
      ),
    ];

    // 非同期同時実行
    forkJoin(task).subscribe((dataList) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd();

      // 提案登録確認(C432)へ遷移
      this.router.navigate(["pages/platform/c401/" + this.inputType], {
        queryParams: { screenId: "c402", agenda_id: this.agendaId  ,match_type : this.matchType},
      });

      // 提案登録確認(C432)を表示する
      this.confirmationFlag = true;
    });
  }

  /**
   * 提案登録完了(C433)を表示する
   */
  public async moveC433(): Promise<void> {
    // 画面.入力フォームから入力内容を取得
    // ※値渡し
    this.isDisabled = true;
    const c431InputForm: any = JSON.parse(
      JSON.stringify(this.c431InputForm.value)
    );
    if(this.fileUploadDocument){
      await this.uploadFiletoS3(
        "document",
        this.fileUploadDocument,
        'match',
        this.agendaId
      );
    }
    // 課題・ソリューションID
    c431InputForm.agenda_id = this.agendaId;

    // 会員ID
    // c431InputForm.member_id = this.getLoginMemberId();

    c431InputForm.match_type = this.matchType;

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.DATA_INSERT);

    // 登録タイプ判定
    if (GENERATE_INPUT_TYPE.NEW == this.inputType) {
      // 新規登録の場合

      // 提案登録を実施
      this.dbOperationService
        .insertData(API_URL_ADD_MATCHING, c431InputForm)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

          // レスポンス結果を判定
          if (API_RESPONSE.SUCCESS == response.body.Message) {
            // 正常終了の場合

            // 提案登録完了(C433)へ遷移
            this.router.navigate(["pages/platform/c403"],
              {
                state:
                {
                  agenda: this.detailTask?.agenda,
                  role: this.roleAuth
                },
                queryParams: {
                  matchType: this.matchType
                }
              }
            );
          }
          if (this.commonService.checkInvalidValue(response)){
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );

            // 前画面へ戻る
            this.location.back();
            this.location.back();
            this.isDisabled = false;
          } else if (API_RESPONSE.FAIL == response.body.Message) {
            // 異常終了の場合

            // 異常終了メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(MESSAGE_CODE.E00001),
                detail: this.commonService.msg(MESSAGE_CODE.E80002),
              })
            );

            // 提案登録(C431)を表示する
            this.confirmationFlag = false;
            this.isDisabled = false;
          } else {
            // 提案上限の場合

            // 提案登録(C431)を表示する
            this.confirmationFlag = false;
            this.isDisabled = false;
          }
        });
    } else if (GENERATE_INPUT_TYPE.EDIT == this.inputType) {
      // 編集の場合

      // 提案更新を実施
      this.dbOperationService
        .updateData(API_URL_ADD_MATCHING, this.suggestId, c431InputForm)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

          // レスポンス結果を判定
          if (API_RESPONSE.SUCCESS == response.body.Message) {
            // 正常終了の場合

            // 提案登録完了(C433)へ遷移
            this.router.navigate(["pages/platform/c403"],{
              queryParams: {
                matchType: this.matchType,
              }
            });
          } else {
            // 異常終了メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(MESSAGE_CODE.E00001),
                detail: this.commonService.msg(MESSAGE_CODE.E80002),
              })
            );

            // 提案登録(C431)を表示する
            this.confirmationFlag = false;
            this.isDisabled = false;
          }
        });
    }
  }

  // remove fileUpload from list
  removeFile(file: File, uploader: FileUpload,) {
    const index = uploader.files.indexOf(file);
    uploader.remove(null, index);
    this.fileUploadDocument = null;
    this.c431InputForm.controls['document'].setValue(CONSTANT.EMPTY_STRING);
    this.c431InputForm.controls['document'].markAsDirty();
    this.hasSelectedFile = false;
  }

  uploadFile(event, fileUpload) {
    const fileObj: File = event.files[0];

    // Obtain an file extension
    const fileExtension = fileObj.name.substring(
      event.files[0].name.lastIndexOf(CONSTANT.PERIOD)
    );

    let valid = true;

    if (
      !(
        FILE_EXTENSION.PDF == fileExtension ||
        FILE_EXTENSION.PDF2 == fileExtension
      )) {
      // Invalid Pdf File

      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00033),
        detail: this.commonService.msg(MESSAGE_CODE.E00013, "PDF"),
      });

      valid = false;
    } else if (fileObj.size > CONSTANT.MAX_FILE_SIZE) {
      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00033),
        detail: this.commonService.msg(MESSAGE_CODE.E00032, CONSTANT.MAX_FILE_SIZE_text),
      });
      valid = false;

    } else if (!valid) {
      // If file is invalid
      // Clear the button operation status
      fileUpload.clear();

      this.fileUploadDocument = null;
      this.c431InputForm.controls['document'].setValue(null);
      // Processing is terminated
      return;
    } else {
      this.c431InputForm.controls['document'].setValue(
        event.currentFiles[0].name
      );
      this.fileUploadDocument = fileObj;
    }
    if (!this.fileUploadFlag) {
      fileUpload.clear();
    }
  }
  public async uploadFiletoS3(id: any, fileUploaded: File, tableId, agendaId) {
    const uploadInfo: any = await this._getUploadInfo(
      fileUploaded.name,
      tableId,
      agendaId
    );
    for (let index = 0; index < uploadInfo.length; index++) {
      const upload = uploadInfo[index];
      if (
        upload.url === undefined ||
        upload.url === null ||
        upload.url.length === 0 ||
        upload.url.indexOf("s3.amazonaws.com") === -1
      ) {
        this.messageService.add({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.I00005),
          detail: this.commonService.msg(MESSAGE_CODE.E00002),
        });
        return;
      }
      await this._uploadToS3(upload, fileUploaded);
    }
    setTimeout(() => {
      console.log('upload finish');
    }, 3000);
  }

  private _getUploadInfo(filename, tableId, agendaId) {
    const colName = 'document';
    const url = this.commonService.url(API_URL_GET_UPLOAD_URL);

    return this.http
      .get(url, {
        params: {
          tablename: tableId,
          filetype: "file",
          colname: colName,
          filename: encodeURIComponent(filename),
          code: "",
          agenda_id: agendaId,
        },
      })
      .toPromise();
  }

  private _uploadToS3(uploadInfo, fileUpload) {
    return new Promise<any>((resolve) => {
      const uploadUrl = uploadInfo.url;
      console.log(uploadUrl)
      const fields: any = uploadInfo.fields;

      const formData = new FormData();
      formData.append("key", fields.key);
      formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
      formData.append("policy", fields.policy);
      formData.append("signature", fields.signature);
      formData.append("x-amz-security-token", fields["x-amz-security-token"]);
      formData.append("file", fileUpload, fileUpload.name);

      this.http
        .post(uploadUrl, formData, { observe: "response" })
        .pipe(map((res: HttpResponse<any>) => res))
        .subscribe((res) => resolve(res));
    });
  }

  getAreaName(id){
    const areaList: any[] = this.getDicList(DICID_NUMBER.TARGET_GRADE);
    for (let index = 0; index < areaList.length; index++) {
      const element = areaList[index];
      if (element.sp_code == id) {
        return element.sp_name;
      }

    }
  }
  linkPDF() {
    return 'https://tokyo-ss-prod-file.s3.ap-northeast-1.amazonaws.com/common/%E6%B4%BB%E5%8B%95%E3%81%AE%E5%A0%B4%E4%BE%9D%E9%A0%BC%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E5%85%A5%E5%8A%9B%E4%BE%8B.pdf'
  }

  openURI() {
    window.open(this.linkPDF(), '_blank').focus();
  }
}
