

<div class="main" *ngIf="!isLoading">
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <div class="p-fluid p-formgrid p-grid" *ngIf="!confirmationFlag">
      <div class="p-field p-col-12 p-md-12 p-jc-center content-detail">
        <!-- A200系共通部品_Steps -->

        <!-- Form register -->
        <form [formGroup]="c701InputForm">
          <div class="p-grid form">
            <div
              class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
            >
              <div class="form-content p-col-9 p-md-12 p-sm-12 p-pb-2">
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-3">
                      <label for="school" class="school-label" *ngIf="detailTask?.match_type === '1'"
                        >教育支援内容タイトル</label
                      >
                      <label for="school" class="school-label" *ngIf="detailTask?.match_type === '0'"
                        >提案タイトル</label
                      >
                    </div>

                    <div class="p-col-9">
                      <label class="school-label-content"
                        >{{ detailTask?.title }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-content p-col-9 p-md-12 p-sm-12 p-pb-2">
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-3">
                      <label for="school" class="school-label">学年</label>
                    </div>
                    <div class="p-col-9">
                      <label class="school-label-content"
                        >{{ detailTask?.target_grade }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-content p-col-9 p-md-12 p-sm-12 p-pb-2">
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-3">
                      <label for="school" class="school-label">記録者</label>
                    </div>
                    <div class="p-col-9">
                      <label class="school-label-content">{{ detailTask?.organization_name }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-content p-col-9 p-md-12 p-sm-12">
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">実施日</label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9">
                      <p-calendar
                        class="input-school information-Form-inputtext"
                        [showIcon]="true"
                        [showButtonBar]="true"
                        [yearNavigator]="true"
                        [monthNavigator]="true"
                        [readonlyInput]="true"
                        placeholder=""
                        dateFormat="yy/mm/dd"
                        dataType="string"
                        appendTo="body"
                        yearRange="2000:2999"
                        formControlName="implementation_date"
                        translate
                      ></p-calendar>

                      <app-validator-message
                        [control]="c701InputForm.get('implementation_date')"
                      >
                      </app-validator-message>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="form-content form-content-after p-col-9 p-md-12 p-sm-12"
              >
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">
                        教育支援の内容
                      </label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9">
                      <textarea
                        pInputTextarea
                        class="input-school"
                        formControlName="detail"
                        autocomplete="off"
                        [ngClass]='checkInputMaxBoundary(c701InputForm,"detail")?"invalid-length":""'
                      ></textarea>
                      <app-validator-message
                        [control]="c701InputForm.get('detail')"
                      >
                      </app-validator-message>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-content form-content-after p-col-9 p-md-12 p-sm-12"
              >
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">
                        児童生徒の反応
                      </label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9">
                      <textarea
                        pInputTextarea
                        class="input-school"
                        formControlName="comment"
                        [ngClass]='checkInputMaxBoundary(c701InputForm,"comment")?"invalid-length":""'
                        autocomplete="off"
                      ></textarea>
                      <app-validator-message
                        [control]="c701InputForm.get('comment')"
                      >
                      </app-validator-message>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-content form-content-after p-col-9 p-md-12 p-sm-12"
              >
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">評価</label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div
                      class="p-col-10 p-md-9 p-d-flex p-ai-center school-label"
                    >
                      <div
                        class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center evaluation-row"
                      >
                        <span class="p-col-1">良い</span>
                        <div class="list-rate p-col-10">
                          <p-radioButton label="5" value="5" formControlName="evaluation"></p-radioButton>
                          <p-radioButton label="4" value="4" formControlName="evaluation"></p-radioButton>
                          <p-radioButton label="3" value="3" formControlName="evaluation"></p-radioButton>
                          <p-radioButton label="2" value="2" formControlName="evaluation"></p-radioButton>
                          <p-radioButton label="1" value="1" formControlName="evaluation"></p-radioButton>
                        </div>
                        <span class="p-col-1">悪い</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-content form-content-after p-col-9 p-md-12 p-sm-12"
              >
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">
                        評価の理由</label
                      >
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9">
                      <textarea
                        pInputTextarea
                        class="input-school"
                        formControlName="reason"
                        [ngClass]='checkInputMaxBoundary(c701InputForm,"reason")?"invalid-length":""'
                        autocomplete="off"
                      ></textarea>
                      <app-validator-message
                        [control]="c701InputForm.get('reason')"
                      >
                      </app-validator-message>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div
          class="form-content register p-col-12"
        >
          <div class="p-grid p-grid-register center">
            <div class="p-col-4 btn-block p-md-3">
              <button
                pButton
                type="button"
                label="確認画面へ"
                class="btn-register"
                [disabled]="!c701InputForm.valid"
                (click)="moveC702()"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid" *ngIf="confirmationFlag">
      <div class="p-field p-col-12 p-md-12 p-jc-center content-detail">
        <form [formGroup]="c701InputForm">
          <div class="p-grid form">
            <div
              class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
            >
              <div class="form-content p-col-9 p-md-12 p-sm-12 p-pb-2">
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-3">
                      <label for="school" class="school-label" *ngIf="detailTask?.match_type === '1'"
                        >教育支援内容タイトル</label
                      >
                      <label for="school" class="school-label" *ngIf="detailTask?.match_type === '0'"
                        >提案タイトル</label
                      >
                    </div>

                   

                    <div class="p-col-9">
                      <label class="school-label-confirm"
                        >{{ detailTask?.title }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-content p-col-9 p-md-12 p-sm-12 p-pb-2">
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-3">
                      <label for="school" class="school-label">学年</label>
                    </div>

                   

                    <div class="p-col-9">
                      <label class="school-label-confirm"
                        >{{ detailTask?.target_grade }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-content p-col-9 p-md-12 p-sm-12 p-pb-2">
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-3">
                      <label for="school" class="school-label">記録者</label>
                    </div>

                    

                    <div class="p-col-9">
                      <label class="school-label-confirm">{{ detailTask?.organization_name }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-content p-col-9 p-md-12 p-sm-12">
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">実施日</label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <!-- <span class="required">*必須</span> -->
                    </div>
                    <div class="p-col-10 p-md-9">
                      <span class="school-label-confirm">{{
                        formatDateJapan()
                      }}</span>

                    </div>
                  </div>
                </div>
              </div>

              <div
                class="form-content form-content-after p-col-9 p-md-12 p-sm-12"
              >
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">
                        教育支援の内容
                      </label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <!-- <span class="required">*必須</span> -->
                    </div>
                    <div class="p-col-10 p-md-9">
                      <span class="school-label-confirm">{{
                        c701InputForm.value?.detail
                      }}</span>

                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-content form-content-after p-col-9 p-md-12 p-sm-12"
              >
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">
                        児童生徒の反応
                      </label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <!-- <span class="required">*必須</span> -->
                    </div>
                    <div class="p-col-10 p-md-9">
                      <span class="school-label-confirm">{{
                        c701InputForm.value?.comment
                      }}</span>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-content form-content-after p-col-9 p-md-12 p-sm-12"
              >
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">評価</label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <!-- <span class="required">*必須</span> -->
                    </div>
                    <div
                      class="p-col-10 p-md-9 p-d-flex p-ai-center school-label  p-pl-0"
                    >
                      <div
                        class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center  evaluation-row"
                      >
                        <span class="p-col-1">良い</span>
                        <div class="list-rate p-col-10">
                          <div class="star-rate">
                            <span class="star-rate-number">5</span>
                            <span class="circle" *ngIf="c701InputForm.value?.evaluation == '5'"></span>
                          </div>
                          <div class="star-rate ">
                            <span class="star-rate-number">4</span>
                            <span class="circle" *ngIf="c701InputForm.value?.evaluation == '4'"></span>
                          </div>
                          <div class="star-rate">
                            <span class="star-rate-number">3</span>
                            <span class="circle" *ngIf="c701InputForm.value?.evaluation == '3'"></span>
                          </div>
                          <div class="star-rate">
                            <span class="star-rate-number">2</span>
                            <span class="circle" *ngIf="c701InputForm.value?.evaluation == '2'"></span>
                          </div>
                          <div class="star-rate">
                            <span class="star-rate-number">1</span>
                            <span class="circle" *ngIf="c701InputForm.value?.evaluation == '1'"></span>
                          </div>
                        </div>
                        <span class="p-col-1">悪い</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-content form-content-after p-col-9 p-md-12 p-sm-12"
              >
                <div class="form-content-wraper">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="school" class="school-label">
                        評価の理由</label
                      >
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <!-- <span class="required">*必須</span> -->
                    </div>
                    <div class="p-col-10 p-md-9">
                     
                      <span class="school-label-confirm">{{
                        c701InputForm.value?.reason
                      }}</span>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div
          class="form-content register p-col-12"
        >
          <div class="p-grid p-grid-register center">
              <button
                pButton
                type="button"
                label="登録する"
                class="btn-register"
                (click)="moveC703()"
              ></button>
              <button
              pButton
              type="button"
              label="入力画面へ戻る"
              class="button2 btn-register btn-delete"
              (click)="moveC701()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
