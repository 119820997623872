import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { NO_IMAGE } from "manager/file-contstants";
@Injectable({
  providedIn: "root",
})
export class LinkCheckService {
  constructor() {}

  validImage(url: string): Observable<string> {
    const result = new Subject<string>();

    const image = new Image();
    image.onload = () => {
      result.next(url);
      result.complete();
    };
    image.onerror = () => {
      result.next(NO_IMAGE);
      result.complete();
    };
    image.src = url;
    return result.asObservable();
  }
}