

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- B100系共通部品_Steps -->
    <app-b100-steps></app-b100-steps>

    <div class="information-margin"></div>

    <!-- 自治体団体登録・更新画面(入力)(B101) -->
    <div *ngIf="!confirmationFlag">

      <!-- 入力フォーム -->
      <form [formGroup]="b101InputForm" autocomplete="off">
        <!-- 入力情報 -->
        <div class="input-form-information">
          <!-- 入力備考 -->

          <!-- 入力内容 -->
          <div class="form-content p-col-8 p-md-12 p-sm-12 information-Form">
            <div class="form-wraper">
              <div
                class="p-grid"
                *ngIf="
                  b101InputForm.value.class_id != '9'
                "
              >
                <div class="p-col-1 p-md-2 label-wraper">
                  <label for="zip-code" class="item-input-name"
                    >団体組織区分</label
                  >
                </div>
                <div class="p-col-1 required-end">
                  <span class="required">*必須</span>
                </div>
                <div
                  class="p-col-10 p-md-9 p-d-flex p-ai-center"
                  
                >
                  {{ className }}
                </div>
                <div class="p-col-10 p-md-9 error-message">
                  
                </div>
              </div>

              <div class="p-grid">
                <div class="p-col-1 p-md-2 p-xxl-1 label-wraper">
                  <label for="school" class="item-input-name"
                    >団体組織名</label
                  >
                </div>
                <div class="p-col-1 p-md-1 p-xxl-1 required-end">
                  <span class="required">*必須</span>
                </div>
                <div class="p-col-10 p-md-9 p-xxl-10">
                  <input
                    id="school"
                    class="member-form-input"
                    type="text"
                    pInputText
                    disabled
                    formControlName="member_organization_name"
                    autocomplete="off"
                    [ngClass]='checkInputMaxBoundary(b101InputForm,"member_organization_name")?"invalid-length":""'
                  />
                </div>
                <div class="p-col-10 p-md-9 error-message">
                  <app-validator-message
                    [control]="
                      b101InputForm.value.member_organization_name
                    "
                  >
                  </app-validator-message>
                </div>
              </div>

           
              
              <div class="p-grid">
                <div class="p-col-1 p-md-2 label-wraper">
                  <label for="zip-code" class="item-input-name"
                    >郵便番号</label
                  >
                </div>
                <div class="p-col-1 required-end">
                  <span class="required">*必須</span>
                </div>
                <div class="p-col-3">
                  <input
                    id="zip-code"
                    class="member-form-input"
                    type="text"
                    pInputText
                    formControlName="member_postal_no"
                    (change)="getPostalsList($event)"
                    [ngClass]='checkInputMaxBoundary(b101InputForm,"member_postal_no")?"invalid-length":""'
                    autocomplete="off"
                  />
                </div>

                <div class="p-col-10 p-md-9 error-message">
                  <app-validator-message
                    [control]="b101InputForm.get('member_postal_no')"
                  >
                  </app-validator-message>
                </div>
              </div>

              <div class="p-grid">
                <div class="p-col-1 p-md-2 label-wraper">
                  <label for="address" class="item-input-name"
                    >都道府県</label
                  >
                </div>
                <div class="p-col-1 p-md-1 required-end">
                  <span class="required">*必須</span>
                </div>

                <div class="p-col-3">
                  <p-dropdown
                    inputId="city"
                    optionLabel="name"
                    optionValue="value"
                    styleClass="form-dropdown"
                    formControlName="member_prefectures"
                    [options]="postalNoList"
                    placeholder="都道府県"
                    [showClear]="false"
                  ></p-dropdown>
                </div>
                <div class="p-col-10 p-md-9 error-message">
                  <app-validator-message
                    [control]="b101InputForm.get('member_prefectures')"
                  >
                  </app-validator-message>
                </div>
              </div>

              <div class="p-grid">
                <div class="p-col-1 p-md-2 label-wraper">
                  <label for="city" class="item-input-name"> 所在地</label>
                </div>
                <div class="p-col-1 p-md-1 required-end">
                  <span class="required">*必須</span>
                </div>
                <div class="p-col-10 p-md-9">
                  <input
                    id="address"
                    class="member-form-input"
                    type="text"
                    pInputText
                    formControlName="member_address_building"
                    [ngClass]='checkInputMaxBoundary(b101InputForm,"member_address_building")?"invalid-length":""'
                    autocomplete="off"
                  />
                </div>
                <div class="p-col-10 p-md-9 error-message">
                  <app-validator-message
                    [control]="
                    b101InputForm.get('member_address_building')
                    "
                  >
                  </app-validator-message>
                </div>
              </div>

              <div class="p-grid">
                <div class="p-col-1 p-md-2 label-wraper">
                  <label for="phone-number" class="item-input-name"
                    >電話番号</label
                  >
                </div>
                <div class="p-col-1 required-end">
                  <span class="required">*必須</span>
                </div>

                <div class="p-col-4">
                  <input
                    id="phone-number"
                    class="member-form-input"
                    type="text"
                    pInputText
                    formControlName="user_tel"
                    [ngClass]='checkInputMaxBoundary(b101InputForm,"user_tel")?"invalid-length":""'
                    autocomplete="off"
                  />
                </div>
                <div class="p-col-10 p-md-9 error-message">
                  <app-validator-message
                    [control]="b101InputForm.get('user_tel')"
                  >
                  </app-validator-message>
                </div>
              </div>

              <div 
                class="p-grid"
                *ngIf="b101InputForm.value?.class_id === '2'"
              >
                <div class="p-col-1 p-md-2 label-wraper">
                  <label for="edu-support" class="item-input-name"
                    >過去の教育支援</label
                  >
                </div>
                <div class="p-col-1 p-md-1"></div>
                <div class="p-col-10 p-md-9">
                  <textarea
                    id="edu-support"
                    class="member-form-input"
                    pInputTextarea
                    formControlName="member_history_support_result"
                    [ngClass]='checkInputMaxBoundary(b101InputForm,"member_history_support_result")?"invalid-length":""'
                    rows="3"
                    autocomplete="off"
                  >
                  </textarea>
                </div>
                <div class="p-col-10 p-md-9 error-message">
                  <app-validator-message
                  [control]="
                    b101InputForm.get('member_history_support_result')
                  "
                  >
                  </app-validator-message>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </form>


      <!-- ボタンエリア -->
      <div class="p-d-flex p-jc-center button-area p-mt-3">
        <button
          pButton
          type="button"
          label="確認画面へ"
          class="button-default-size button1"
          (click)="moveB102()"
          [disabled]="!b101InputForm.valid"
        ></button>
      </div>
    </div>

    <!------------------------------------------------------------------------->

    <!-- 自治体団体登録・更新画面(確認画面)(B102) -->
    <div class="input-form-information" *ngIf="confirmationFlag">

      <!-- 入力内容 -->
      <div class="form-content p-col-8 p-md-12 p-sm-12">
        <div class="form-wraper">
          <div
           class="p-grid" 
           *ngIf="
            b101InputForm.value.class_id != '9'
           "
          >
            <div class="p-col-1 p-md-2 label-wraper">
              <label for="school" class="school-label"
                >団体組織区分</label
              >
            </div>
            <div class="p-col-1 p-md-1 required-end">
              <span class="required">*必須</span>
            </div>
            <div
              class="p-col-10 p-md-9 text-center label-wraper p-d-flex"
            >
              {{ className }}
            </div>
          </div>

          <div class="p-grid">
            <div class="p-col-1 p-md-2 label-wraper">
              <label for="school" class="school-label">団体組織名</label>
            </div>
            <div class="p-col-1 p-md-1 required-end">
              <span class="required">*必須</span>
            </div>
            <div class="p-col-10 p-md-9 text-center label-wraper">
              {{ b101InputForm.value?.member_organization_name }}
            </div>
          </div>

          <div class="p-grid">
            <div class="p-col-1 p-md-2 label-wraper">
              <label for="zip-code" class="zip-code-label"
                >郵便番号</label
              >
            </div>
            <div class="p-col-1 required-end">
              <span class="required">*必須</span>
            </div>
            <div class="p-col-3 text-center label-wraper">
              {{ b101InputForm.value?.member_postal_no }}
            </div>
          </div>

          <div class="p-grid">
            <div class="p-col-1 p-md-2 label-wraper">
              <label for="address" class="address-label">都道府県</label>
            </div>
            <div class="p-col-1 p-md-1 required-end">
              <span class="required">*必須</span>
            </div>

            <div class="p-col-3 text-center label-wraper">
              {{ this.postalNoList[0].name }}
            </div>
          </div>

          <div class="p-grid">
            <div class="p-col-1 p-md-2 label-wraper">
              <label for="city" class="city-label"> 所在地</label>
            </div>
            <div class="p-col-1 p-md-1 required-end">
              <span class="required">*必須</span>
            </div>
            <div class="p-col-10 p-md-9 text-center label-wraper">
              {{ b101InputForm.value?.member_address_building }}
            </div>
          </div>

          <div class="p-grid">
            <div class="p-col-1 p-md-2 label-wraper">
              <label for="phone-number" class="phone-number-label"
                >電話番号</label
              >
            </div>
            <div class="p-col-1 required-end">
              <span class="required">*必須</span>
            </div>

            <div class="p-col-4 text-center label-wraper">
              {{ b101InputForm.value?.user_tel }}
            </div>
          </div>

          <div
            class="p-grid"
            *ngIf="b101InputForm.value?.class_id === '2'"
          >
            <div class="p-col-1 p-md-2 label-wraper">
              <label for="edu-support" class="edu-support-label"
                >過去の教育支援</label
              >
            </div>
            <div class="p-col-1 p-md-1 label-wraper"></div>
            <div class="p-col-10 p-md-9 text-center label-wraper text-newline">
              {{ b101InputForm.value?.member_history_support_result }}
            </div>
          </div>
        </div>
      </div>
      

      <!-- ボタンエリア -->
      <div class="p-d-flex p-jc-center button-area  p-mt-3">
        <button
          pButton
          type="button"
          label="登録する"
          class="btn-register button1"
          (click)="moveB103()"
        ></button>
        <button
          pButton
          type="button"
          label="入力画面へ戻る"
          class="button2 btn-register"
          (click)="moveB101()"
        ></button>
      </div>
    </div>
  </div>
</div>

<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>
