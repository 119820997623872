

<!-- メイン -->
<div class="main" *ngIf="isDataLoaded">
  <!-- メインコンテンツ -->
  <div class="main-content">
    <!-- 戻る -->
    <div class="return">
      <button
        pButton
        type="button"
        label="前のページへ戻る"
        icon="pi pi-angle-left"
        class="p-button-text"
        (click)="moveC241()"
      ></button>
    </div>
    <div class="p-fluid p-formgrid p-grid screen-support">
      <div class="p-col-9">
        <div class="p-fluid p-formgrid p-grid support-header">
          <div class="p-col-12">
            <p-card
              class="support-header-card"
            >
              <div class="support-header-card-show">
                <div
                  class="p-fluid p-formgrid p-grid teacher-content"
                >
                  <div class="p-col-4 image">
                    <div
                      class="support-header-card-detail-img"
                      [ngStyle]="{
                        'background-image':
                          'url(' + detailTask?.icon_image + ')'
                      }"
                    ></div>
                  </div>
                  <div class="p-col-7">
                    <span
                      class="tittle-teacher text-white-color"
                      [ngClass]="setClassesKind(detailTask?.recruitment_kind)"
                      >{{ detailTask?.recruitment_kind + "の募集" }}
                    </span>
                    <div class="box-content">
                      <h6 class="title-header bold letter-spacing-1">
                        {{ detailTask?.title }}
                      </h6>
                      <h6 class="elementary-school letter-spacing-1">
                        {{ detailTask?.organization_name }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>

          <div class="p-col-12">
            <div class="support-separate"></div>
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-first">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">活動内容</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.expected_content }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-third">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">地域</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.area }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-second">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview no-wrap"
                      >募集種別
                    </span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.recruitment_kind }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fourth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">活動内容（URL）</span>
                  </div>
                  <div class="p-col-9 description-title-overview ">
                    <a [href]="detailTask?.expected_url" target="_blank" class="linktext-blue underline">
                      {{ detailTask?.expected_url }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fourth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">活動内容（ファイル）</span>
                  </div>
                  <div class="p-col-9 description-title-overview ">
                    <a [href]="detailTask?.document?.url" class="linktext-blue underline">
                      {{ detailTask?.document?.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>
          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-sixth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview no-wrap"
                      >開催日
                    </span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.event_date }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">開催日(テキスト）</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.event_date_memo }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">開催場所</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.venue }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">主催者</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.organizer }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">募集人数</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.expected_people_number }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <!-- <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">応募資格</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.qualification }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div> -->

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">募集締め切り</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.deadline }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12" *ngIf="userInfo != null">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12" *ngIf="userInfo != null">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">応募方法</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.implementation_format }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12" *ngIf="userInfo != null">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12" *ngIf="userInfo != null">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">謝礼支払条件</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.payment_terms }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12" *ngIf="userInfo != null">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12" *ngIf="userInfo != null">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">運営者（主催者と異なる場合）</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.manager }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12" *ngIf="userInfo != null">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12" *ngIf="userInfo != null">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">問合せ先</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.contact_information }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <div class="support-separate"></div>
          </div>

          <div class="p-col-12">
            <ng-container *ngIf="class_id == '1' && this.userInfo.prof_status == '1';">
              <div class="p-d-flex p-jc-center">
                <div class="p-d-flex flex-column p-jc-center btn-review">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="応募する"
                    class="btn-move"
                    (click)="moveC441()"
                    [disabled]="disabledBtnMove || detailTask?.flag_recruitment != '1'"
                  ></button>
                </div>
              </div>
              <div *ngIf="disabledBtnMove || detailTask?.flag_recruitment != '1'" class="p-d-flex p-jc-center text-notification">
                <p class="text">※募集を締め切ったため、応募できません</p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
