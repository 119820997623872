

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- タイトル -->
    <div class="title">
      <h1 class="text-title"><i class="pi pi-id-card membership-icon"></i> 会員入会審査</h1>
    </div>

    <!-- 検索ボタン -->
    <button
      pButton
      type="button"
      label="検索"
      class="button1 search-button"
      (click)="getResultTaskDataList()"
    ></button>

    <!-- 検索条件入力 -->
    <div class="search-form-area" [formGroup]="secretariatSearchForm">
      <div class="p-d-flex p-jc-start search-form-div p-col-12">
        <!-- 検索テキスト -->
        <!-- ステータス -->

        <div class="item p-col-4 p-d-flex p-ai-center">
          <label class="item-span p-mr-2">ユーザー分類</label>
          <p-dropdown
            [options]="membershipStatusType"
            optionLabel="sp_name"
            optionValue="sp_code"
            [showClear]="true"
            styleClass="information-Form-dropdown"
            formControlName="class_id"
            placeholder="&nbsp;"
            ></p-dropdown>
        </div>

        <div class="item p-col-4 p-d-flex p-ai-center">
          <label class="item-span p-mr-2">申請ステータス</label>
          <p-dropdown
            [options]="statusList"
            optionLabel="sp_name"
            optionValue="sp_code"
            [showClear]="true"
            styleClass="information-Form-dropdown"
            formControlName="status"
            placeholder="&nbsp;"
          ></p-dropdown>
        </div>
      
      </div>
      
    </div>

    <!-- 結果表示 -->
    <div class="result-area">
      <!-- ページアンカー -->
      <a id="jump-top"></a>
      <!-- 検索結果数 -->
      <div class="result-count-area">
        <label class="count"> {{ resultTaskDataList.length }} </label>
        件
      </div>
      <!-- リスト表示 -->
      <p-table
        #table
        [value]="resultTaskDataList"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
        [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
        emptyMessage="データが存在しません"
        selectionMode="single"
        styleClass="list-area"
        (onPage)="jumpTop()"
      >
        <ng-template pTemplate="header">
          <tr>
            <!-- Noは自治体の時のみ表示 -->
            <th class="organization_name" pSortableColumn="organization_name">ユーザー名<p-sortIcon field="organization_name"></p-sortIcon></th>
            <th class="status" pSortableColumn="class_id.value">ユーザー分類<p-sortIcon field="class_id.value"></p-sortIcon></th>
            <th class="status" pSortableColumn="status.id">申請ステータス<p-sortIcon field="status.id"></p-sortIcon></th>
            <th class="create_datetime" pSortableColumn="create_datetime">申請日時<p-sortIcon field="create_datetime"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-resultTaskDataList>
          <tr
            class="list"
          >
            <!-- Noは自治体の時のみ表示 -->
            <!-- 会員名 -->
            
            <td>
              <label 
                class="pointer tooltip-text" 
                (click)="moveA231(resultTaskDataList.application_id)"
                [pTooltip]="resultTaskDataList?.organization_name"
                #organization_name
                [tooltipDisabled]="!isOverflow(organization_name)"
                showDelay="500"
                hideDelay="500"
              >

                <u>{{ resultTaskDataList?.organization_name }}</u>
              </label>
            </td>
          
            <td>
              <span
                class="tooltip-text"
                [pTooltip]="resultTaskDataList?.class_id.name"
                #class_id
                [tooltipDisabled]="!isOverflow(class_id)"
                showDelay="500"
                hideDelay="500"
              >
                {{ resultTaskDataList?.class_id.name }}
              </span>
            </td>
          
            <!-- ステータス -->
            <td [class.denial]="1 == resultTaskDataList.status.id">
              {{ resultTaskDataList?.status.name }}
            </td>
            <!-- 申請日時 -->
            <td>
              <span
                class="tooltip-text"
                [pTooltip]="resultTaskDataList?.create_datetime "
                #create_datetime
                [tooltipDisabled]="!isOverflow(create_datetime)"
                showDelay="500"
                hideDelay="500"
                tooltipPosition="bottom"
              >
                {{ resultTaskDataList?.create_datetime }}
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>
