<!-- メイン -->
<div class="main" *ngIf="isDataLoaded">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- A400系共通部品_Steps -->

    <div class="p-fluid p-formgrid p-grid" *ngIf="!confirmationFlag">
      <div class="p-field p-col-12 p-md-12 p-jc-center message">
        <div class="p-grid form">
          <div
            class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center message-form-container"
          >
            <div
              class="form-content p-col-11 p-md-11 p-sm-11"
              *ngIf="matchType == 0"
            >
              <ng-container *ngIf="detailTask?.length === 1; else multiApp">
                <div class="block-message">
                  <h6 class="large-message">
                    {{ detailTask[0]?.title }}
                  </h6>
                  <span
                    class="location"
                    *ngIf="detailTask[0]?.organization_name"
                  >
                    {{ detailTask[0]?.organization_name }}
                  </span>
                </div>
              </ng-container>
              <ng-template #multiApp>
                <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                  <p-accordionTab [header]="titleMulti" [selected]="true">
                    <ng-container *ngFor="let task of detailTask">
                      <div class="first-info ruleTitle">
                        <div class="">
                          <div class="bold">
                            {{ task?.title }}
                          </div>
                          <div
                            class="multiApp-org-name"
                            *ngIf="task?.organization_name"
                          >
                            {{ task?.organization_name }}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </p-accordionTab>
                </p-accordion>
              </ng-template>
            </div>
            <div
              class="form-content p-col-11 p-md-11 p-sm-11"
              *ngIf="matchType == 1"
            >
              <ng-container *ngIf="detailTask?.length === 1; else multiApp">
                <div class="block-message">
                  <h6 class="large-message">
                    {{ detailTask[0]?.nickname }}
                  </h6>
                  <span class="location" *ngIf="detailTask[0]?.activity_area">
                    {{ detailTask[0]?.activity_area }}
                  </span>
                  <br />
                  <span class="location" *ngIf="detailTask[0]?.sumasapo_no">
                    {{ detailTask[0]?.sumasapo_no }}
                  </span>
                </div>
              </ng-container>
              <ng-template #multiApp>
                <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                  <p-accordionTab [header]="titleMulti" [selected]="true">
                    <ng-container *ngFor="let task of detailTask">
                      <div class="first-info ruleTitle">
                        <div class="">
                          <div class="bold">
                            {{ task?.nickname }}
                          </div>
                          <div
                            class="multiApp-org-name"
                            *ngIf="task?.activity_area"
                          >
                            {{ task?.activity_area }}
                          </div>
                          <br />
                          <div
                            class="multiApp-org-name"
                            *ngIf="task?.sumasapo_no"
                          >
                            {{ task?.sumasapo_no }}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </p-accordionTab>
                </p-accordion>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-md-12 p-jc-center content-detail">
        <!-- A200系共通部品_Steps -->

        <!-- Form register -->
        <!-- seed: c231 -->
        <ng-container *ngIf="matchType == 1; else typeNeed">
          <form [formGroup]="c431InputForm">
            <div class="p-grid form">
              <div
                class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
              >
                <div class="form-content p-col-8 p-md-12 p-sm-12">
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-12 p-md-12">
                        <label>※サポーターを指名して個別に依頼を行うことができます。依頼先のサポーター以外には入力した情報は公開されません。</label>
                      </div>
                      <div class="p-col-12 p-md-12 link-pdf">
                        <a class="linktext-blue underline" (click)="openURI()">？入力内容について</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-content form-content-after p-col-8 p-md-12 p-sm-12">
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          活動の場名称
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <input
                          id="school"
                          class="input-school information-Form-inputtext"
                          type="text"
                          pInputText
                          formControlName="title"
                          [ngClass]="
                            checkInputMaxBoundary(c431InputForm, 'title')
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="100"
                        />

                        <app-validator-message
                          [control]="c431InputForm.get('title')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          募集種別
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <p-dropdown
                          [options]="getDicList(106)"
                          placeholder="　"
                          optionLabel="sp_name"
                          optionValue="sp_code"
                          [showClear]="true"
                          class="input-school"
                          formControlName="recruitment_kind"
                        ></p-dropdown>

                        <app-validator-message
                          [control]="c431InputForm.get('recruitment_kind')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          依頼内容
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <textarea
                          pInputTextarea
                          class="input-school"
                          formControlName="expected_content"
                          [ngClass]="
                            checkInputMaxBoundary(
                              c431InputForm,
                              'expected_content'
                            )
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="900"
                        ></textarea>
                        <app-validator-message
                          [control]="c431InputForm.get('expected_content')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          活動内容（URL）
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end"></div>
                      <div class="p-col-10 input-form">
                        <input
                          id="school"
                          class="input-school information-Form-inputtext"
                          type="text"
                          pInputText
                          formControlName="expected_url"
                          [ngClass]="
                            checkInputMaxBoundary(c431InputForm, 'expected_url')
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="250"
                        />
                        <app-validator-message
                          [control]="c431InputForm.get('expected_url')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          活動内容（ファイル）
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">

                      </div>
                      <div class="p-col-10 input-form">
                        <p-fileUpload
                          #fileUpload
                          [customUpload]="true"
                          (onSelect)="uploadFile($event, fileUpload)"
                          [showUploadButton]="false"
                          [showCancelButton]="false"
                          [multiple]="false"
                          class="btn-upload"
                        >
                          <ng-template pTemplate="content">
                            <div class="file-content">
                              <div class="file_name">
                                {{ fileUploadDocument?.name }}
                              </div>
                              <div>
                                <i
                                  *ngIf="fileUploadDocument"
                                  class="pi pi-times"
                                  (click)="
                                    removeFile(fileUploadDocument, fileUpload)
                                  "
                                ></i>
                              </div>
                            </div>
                          </ng-template>
                        </p-fileUpload>
                        <app-validator-message
                          [control]="c431InputForm.get('document')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          回答締め切り
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <p-calendar
                          [showIcon]="true"
                          [showButtonBar]="true"
                          [yearNavigator]="true"
                          [monthNavigator]="true"
                          [readonlyInput]="true"
                          dateFormat="yy/mm/dd"
                          dataType="string"
                          appendTo="body"
                          [readonlyInput]="true"
                          yearRange="2000:2999"
                          formControlName="deadline"
                          translate
                        >
                        </p-calendar>
                        <app-validator-message
                          [control]="c431InputForm.get('deadline')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          開催日
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <p-calendar
                          [showIcon]="true"
                          [showButtonBar]="true"
                          [yearNavigator]="true"
                          [monthNavigator]="true"
                          [readonlyInput]="true"
                          dateFormat="yy/mm/dd"
                          dataType="string"
                          appendTo="body"
                          [readonlyInput]="true"
                          yearRange="2000:2999"
                          translate
                          formControlName="event_date"
                        >
                        </p-calendar>
                        <app-validator-message
                          [control]="c431InputForm.get('event_date')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          開催日（テキスト）
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <textarea
                          pInputTextarea
                          class="input-school"
                          formControlName="event_date_memo"
                          [ngClass]="
                            checkInputMaxBoundary(
                              c431InputForm,
                              'event_date_memo'
                            )
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="500">
                        </textarea>
                        <app-validator-message
                          [control]="c431InputForm.get('event_date_memo')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label"> 地域 </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <p-dropdown
                          [options]="getDicList(102)"
                          placeholder="　"
                          optionLabel="sp_name"
                          optionValue="sp_code"
                          [showClear]="true"
                          class="input-school"
                          formControlName="area"
                        ></p-dropdown>

                        <app-validator-message
                          [control]="c431InputForm.get('area')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          主催者
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <input
                          id="school"
                          class="input-school information-Form-inputtext"
                          type="text"
                          pInputText
                          formControlName="organizer"
                          [ngClass]="
                            checkInputMaxBoundary(c431InputForm, 'organizer')
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="500"
                        />
                        <app-validator-message
                          [control]="c431InputForm.get('organizer')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          開催場所
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <input
                          id="school"
                          class="input-school information-Form-inputtext"
                          type="text"
                          pInputText
                          formControlName="venue"
                          [ngClass]="
                            checkInputMaxBoundary(c431InputForm, 'venue')
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="100"
                        />
                        <app-validator-message
                          [control]="c431InputForm.get('venue')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          謝礼支払条件
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <textarea
                          pInputTextarea
                          class="input-school"
                          formControlName="payment_terms"
                          [ngClass]="
                            checkInputMaxBoundary(
                              c431InputForm,
                              'payment_terms'
                            )
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="900"
                        ></textarea>
                        <app-validator-message
                          [control]="c431InputForm.get('payment_terms')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          運営者（主催者と異なる場合）
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">

                      </div>
                      <div class="p-col-10 input-form">
                        <textarea
                          pInputTextarea
                          class="input-school"
                          formControlName="manager"
                          [ngClass]="
                            checkInputMaxBoundary(c431InputForm, 'manager')
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="900"
                        ></textarea>
                        <app-validator-message
                          [control]="c431InputForm.get('manager')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          問い合せ先
                        </label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <textarea
                          pInputTextarea
                          class="input-school"
                          formControlName="contact_information"
                          [ngClass]="
                            checkInputMaxBoundary(
                              c431InputForm,
                              'contact_information'
                            )
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="900"
                        ></textarea>
                        <app-validator-message
                          [control]="c431InputForm.get('contact_information')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-template #typeNeed>
          <form [formGroup]="c431InputForm">
            <div class="p-grid form">
              <div
                class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
              >
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          メッセージ、アピールポイント等ご記入ください
                        </label>
                      </div>
                      <div class="p-col-1 required-end validate-form"></div>
                      <div class="p-col-10 input-form">
                        <textarea
                          pInputTextarea
                          placeholder="例）iPhoneが得意です！&#13;例）親切丁寧に、熱意をもってサポートします"
                          class="input-school"
                          formControlName="memo"
                          [ngClass]="
                            checkInputMaxBoundary(c431InputForm, 'memo')
                              ? 'invalid-length'
                              : ''
                          "
                          autocomplete="off"
                          maxlength="900"
                        ></textarea>
                        <app-validator-message
                          [control]="c431InputForm.get('memo')"
                        >
                        </app-validator-message>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>

        <div class="form-content register register-seed p-col-12">
          <div class="p-grid p-grid-register center">
            <div class="p-col-4 btn-block p-md-3">
              <button
                pButton
                type="button"
                label="確認画面へ進む"
                class="btn-register"
                [disabled]="c431InputForm?.status!='VALID'"
                (click)="moveC432()"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid" *ngIf="confirmationFlag">
      <div class="p-field p-col-12 p-md-12 p-jc-center content-detail">
        <!-- A200系共通部品_Steps -->

        <div class="p-field p-col-12 p-md-12 p-jc-center message">
          <div class="p-grid form mg-t-custom">
            <div
              class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center message-form-container"
            >
              <div
                class="form-content p-col-11 p-md-11 p-sm-11"
                *ngIf="matchType == 0"
              >
                <ng-container *ngIf="detailTask?.length === 1; else multiApp">
                  <div class="block-message">
                    <h6 class="large-message">
                      {{ detailTask[0]?.title }}
                    </h6>
                    <span
                      class="location"
                      *ngIf="detailTask[0]?.organization_name"
                    >
                      {{ detailTask[0]?.organization_name }}
                    </span>
                  </div>
                </ng-container>
                <ng-template #multiApp>
                  <p-accordion
                    expandIcon="pi pi-plus"
                    collapseIcon="pi pi-minus"
                  >
                    <p-accordionTab [header]="titleMulti" [selected]="true">
                      <ng-container *ngFor="let task of detailTask">
                        <div class="first-info ruleTitle">
                          <div class="">
                            <div class="bold">
                              {{ task?.title }}
                            </div>
                            <div
                              class="multiApp-org-name"
                              *ngIf="task?.organization_name"
                            >
                              {{ task?.organization_name }}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </p-accordionTab>
                  </p-accordion>
                </ng-template>
              </div>
              <div
                class="form-content p-col-11 p-md-11 p-sm-11"
                *ngIf="matchType == 1"
              >
                <ng-container *ngIf="detailTask?.length === 1; else multiApp">
                  <div class="block-message">
                    <h6 class="large-message">
                      {{ detailTask[0]?.nickname }}
                    </h6>
                    <span class="location" *ngIf="detailTask[0]?.activity_area">
                      {{ detailTask[0]?.activity_area }}
                    </span>
                    <br />
                    <span class="location" *ngIf="detailTask[0]?.sumasapo_no">
                      {{ detailTask[0]?.sumasapo_no }}
                    </span>
                  </div>
                </ng-container>
                <ng-template #multiApp>
                  <p-accordion
                    expandIcon="pi pi-plus"
                    collapseIcon="pi pi-minus"
                  >
                    <p-accordionTab [header]="titleMulti" [selected]="true">
                      <ng-container *ngFor="let task of detailTask">
                        <div class="first-info ruleTitle">
                          <div class="">
                            <div class="bold">
                              {{ task?.nickname }}
                            </div>
                            <div
                              class="multiApp-org-name"
                              *ngIf="task?.activity_area"
                            >
                              {{ task?.activity_area }}
                            </div>
                            <br />
                            <div
                              class="multiApp-org-name"
                              *ngIf="task?.sumasapo_no"
                            >
                              {{ task?.sumasapo_no }}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </p-accordionTab>
                  </p-accordion>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <!-- Form register -->
        <form [formGroup]="c431InputForm">
          <div class="p-grid form">
            <div
              class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
            >
              <ng-container *ngIf="matchType == 1; else confirmNeed">
                <div class="form-content p-col-8 p-md-12 p-sm-12">
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label"> 活動の場名称</label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.title
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          募集種別</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <ng-container *ngFor="let item of recruitmentType">
                          <span
                            class="value-title"
                            *ngIf="
                              item.value ==
                              c431InputForm.value?.recruitment_kind
                            "
                            >{{ item.name }}</span
                          >
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          依頼内容</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.expected_content
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          活動内容（URL）</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">

                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.expected_url
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          活動内容（ファイル）</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">

                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.document
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          回答締め切り</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.deadline
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          開催日</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.event_date
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          開催日（テキスト）</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.event_date_memo
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label"> 地域</label>
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          getAreaName(c431InputForm.value?.area)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          主催者</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.organizer
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          開催場所</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.venue
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          謝礼支払条件</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.payment_terms
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          運営者（主催者と異なる場合）</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">

                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.manager
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-content form-content-after p-col-8 p-md-12 p-sm-12"
                >
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          問い合せ先</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end">
                        <span class="required">*必須</span>
                      </div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.contact_information
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #confirmNeed>
                <div class="form-content p-col-8 p-md-12 p-sm-12">
                  <div class="form-content-wraper">
                    <div class="p-grid">
                      <div class="p-col-1 label-form">
                        <label for="school" class="school-label">
                          メッセージ、アピールポイント等ご記入ください</label
                        >
                      </div>
                      <div class="p-col-1 validate-form required-end"></div>
                      <div class="p-col-10 input-form">
                        <span class="value-title">{{
                          c431InputForm.value?.memo
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </form>

        <div class="form-content register register-need p-col-12">
          <div class="p-grid p-grid-register center">
            <div class="p-col-4 btn-block p-md-3 center">
              <button
                pButton
                type="button"
                label="入力画面へ戻る"
                class="button2 btn-register"
                (click)="moveC431()"
              ></button>
              <button
                pButton
                type="button"
                label="登録する"
                class="btn-register btn-move-c433"
                (click)="moveC433()"
                [disabled]="isDisabled"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>
