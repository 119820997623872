<p-dialog
  header="教育支援内容詳細"
  [(visible)]="boardFlag"
  [modal]="true"
  [responsive]="true"
  [styleClass]="'main-dialog'"
  (onHide)="closeDialog()"
>
  <div class="main-height">
    <!-- メイン -->

    <!-- メインコンテンツ -->
    <div class="main-content">
      <div class="p-fluid p-formgrid p-grid screen-support">
        <div class="p-col-12">
          <div class="p-fluid p-formgrid p-grid support-header">
            <div class="p-col-12">
              <p-card
                class="support-header-card"
              >
                <div class="support-header-card-show">
                  <div
                    class="p-fluid p-formgrid p-grid support-header-card-detail"
                  >
                    <div class="p-col-4 image">
                      <div
                        class="support-header-card-detail-img"
                        [ngStyle]="{
                          'background-image':
                            'url(' + detailTask?.icon_image + ')'
                        }"
                      ></div>
                    </div>
                    <div
                      class="p-col-8 support-desc"
                    >
                      <div class="support-description-detail">
                        <span
                          class="support-tag-category"
                          [ngClass]="setClasses(detailTask?.category)"
                          >{{ detailTask?.category }}</span
                        >
                        <span class="support-large-title primary-title">{{
                          detailTask?.title
                        }}</span
                        >
                        <!-- Change location -->
                        <div class="support-sub-title">
                          <span
                            *ngIf="
                              class_id === '1' ||
                              class_id === '9' ||
                              (class_id === '2' && detailTask?.isOwner === 0)
                            "
                            >{{ detailTask?.member_id }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="support-btn"
                        *ngIf="detailTask?.isOwner === 1"
                      >
                        <ng-container>
                          <div class="p-col-12">
                            <div class="func-with-owner">
                              <div
                                class="p-fluid p-formgrid p-grid btn-control"
                              >
                                <div
                                  *ngIf="detailTask?.access_modifiers == 1"
                                  class="center not-public"
                                >
                                  <i
                                    class="pi pi-lock"
                                    
                                  ></i>
                                  <span>非公開</span>
                                </div>

                                <div
                                  class="center public"
                                  *ngIf="detailTask?.access_modifiers == 0"
                                >
                                  <span>公開中</span>
                                </div>

                                <div class="func-btn"></div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>

            <div class="p-col-12">
              <div class="support-separate"></div>
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-first">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview">概要</span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.overview }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-third">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview">対象学年</span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.target_grade }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-second">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview no-wrap"
                        >実施形式
                      </span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.implementation_format }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-fourth">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview no-wrap"
                        >実施不可能時期・日時</span
                      >
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.possible_period }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-sixth">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview">資料</span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <a [href]="detailTask?.document?.url" class="linktext-blue underline">
                        {{ detailTask?.document?.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <hr class="sperate-between-line" />
            </div>

            <div class="p-col-12">
              <div class="support-table-detail">
                <div class="support-line-fifth">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3 large-title">
                      <span class="title-overview">備考</span>
                    </div>
                    <div class="p-col-9 description-title-overview">
                      <span>
                        {{ detailTask?.remark }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <div class="support-separate"></div>
            </div>
          </div>
        </div>
      </div>
    </div></div
></p-dialog>
