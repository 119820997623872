

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content">
    <div class="logOut">
      <!-- メッセージエリア -->
      <div class="p-d-flex p-jc-center">
        <!-- メッセージ -->
        <label class="message" [innerHTML]="'ログアウト'"></label>
      </div>

      <!-- ボタンエリア -->
      <div class="p-d-flex p-jc-center">
        <button
          class="button"
          type="button"
          label="再ログイン"
          class="logOut-button"
          (click)="login()"
          pButton
        ></button>
      </div>
    </div>
  </div>
</div>
