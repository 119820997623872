import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  API_URL_DIC_LISTS,
  API_URL_UPDATE_IMPLEMENTATION_DATE,
} from "manager/http-constants";
import { MessageService } from "primeng/api";
import { forkJoin, Observable } from "rxjs";
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { CONSTANT, LOADING_KEY } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { Validator } from "src/app/shared/validator/validator";
import { PlatformComponent } from "../../platform.component";

@Component({
  selector: "app-datetime-dialog",
  templateUrl: "./datetime-dialog.component.html",
  styleUrls: ["./datetime-dialog.component.scss"],
})

/**
 * 共通部品_承認時ダイアログ
 */
export class DatetimeDialogComponent extends PlatformComponent {
  dateInputForm: FormGroup = this.formBuilder.group({
    start_implementation_date: CONSTANT.EMPTY_STRING,
    end_implementation_date: CONSTANT.EMPTY_STRING,
  });

  // @Output() returnRole = new EventEmitter<boolean>();
  @Output() updateCloseDialog = new EventEmitter<boolean>();
  @Output() returnDateSelect = new EventEmitter<any>();

  @Input() start_implementation_date: any;
  @Input() end_implementation_date: any;
  @Input() matchId: any;

  boardFlag: boolean = false;
  // roleValue: string;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private messageData: MessageData
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    if (this.start_implementation_date && this.end_implementation_date) {
      this.dateInputForm
        .get("start_implementation_date")
        .setValue(this.start_implementation_date);
      this.dateInputForm
        .get("end_implementation_date")
        .setValue(this.end_implementation_date);
    }

    this.openRoleDialog();
  }

  public openRoleDialog(): void {
    this.boardFlag = true;
  }

  public closeDialog(): void {
    this.boardFlag = false;
    this.updateCloseDialog.emit(this.boardFlag);
  }

  public updateDateTime(): void {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );
    const dateFrom = this.dateInputForm.get("start_implementation_date").value;
    const dateTo = this.dateInputForm.get("end_implementation_date").value;
    if (dateFrom > dateTo) {
      // if date from > date to
      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00030),
      });
      this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

      return;
    }

    // 内閣府承認
    this.dbOperationService
      .updateData(
        API_URL_UPDATE_IMPLEMENTATION_DATE,
        this.matchId,
        this.dateInputForm.value
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

        // レスポンス結果を判定
        if(this.commonService.checkInvalidValue(response)){
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.returnDateSelect.emit('error');
          return;
        }
         else if (API_RESPONSE.SUCCESS == response.body.Message) {
          // 正常終了の場合

          // 内閣府承認ダイアログ非表示
          this.boardFlag = false;

          // 正常メッセージ
          this.messageService.add({
            severity: TOAST.SUCCESS,
            summary: this.commonService.msg(MESSAGE_CODE.T00002),
          });
          // update date implementation success
          this.returnDateSelect.emit({
            start_implementation_date: dateFrom,
            end_implementation_date: dateTo,
          });
        } else {
          // 異常終了の場合

          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00001),
              detail: this.commonService.msg(MESSAGE_CODE.E80002),
            })
          );

          // 確認画面表示フラグ
          this.boardFlag = false;
        }
      });
  }
  public isvalidForm() {
    for (let key in this.dateInputForm.value) {
      if (
        this.dateInputForm.value[key] === null ||
        this.dateInputForm.value[key] === CONSTANT.EMPTY_STRING
      ) {
        return true;
      }
    }
  }
}
