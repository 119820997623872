
<div class="main">
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <div class="warning">
      本システムはメールアドレスをログインIDとしており、原則登録したメールアドレスを変更することはできません。<br/>
      1アカウントにつき複数のユーザーを登録することが可能です。 「アカウント追加」から、追加するご担当者様を登録してください。<br/>
      なお、メールアドレスを希望する場合は、「アカウント追加」から未登録のメールアドレスを登録し、不要となったメールアドレスを「削除」することで変更が可能です。
    </div>
    <div class="title">
      <h1 class="text-title">ユーザー管理</h1>
    </div>

    <div class="result-area">
      <a id="jump-top"></a>
      <button
        pButton
        type="button"
        label="アカウント追加"
        class="button1"
        (click)="moveInsertB221()"
      ></button>
      <p-table
        [value]="resultTaskDataList"
        [rows]="50"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
        [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
        emptyMessage="データが存在しません"
        selectionMode="single"
        styleClass="information-Form list-area"
        (onPage)="jumpTop()"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="user_id">アカウントNO</th>
            <th class="full_name">氏名</th>
            <th class="email">メールアドレス</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-resultTaskData>
          <tr class="list">
            <td>
              {{ resultTaskData.user_id }}
            </td>
            <td>
              {{ resultTaskData.full_name }}
            </td>
            <td>
              {{ resultTaskData.email }}
            </td>
            <td>
              <button
                pButton
                type="button"
                label="編集"
                class="button1"
                (click)="moveEditB221(resultTaskData.user_id)"
              ></button>

              <button
                pButton
                type="button"
                label="削除"
                class=" btn-delete"
                *ngIf="resultTaskData.isOwner != 1"
                (click)="deleteUser(resultTaskData.user_id)"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

