import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INFORMATION_LIST, MEMBER_EVENTS_LIST } from 'manager/http-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';
import { CONSTANT, ROLES } from 'src/app/shared/constant/constant';
import { NOTICE_TYPES } from './constant';
@Component({
  selector: 'app-e001',
  templateUrl: './e001.component.html',
  styleUrls: ['./e001.component.scss', '../platform.component.scss'],
})

/**
 * お知らせ一覧(E001)
 * PF主催イベント一覧(E002)
 * 会員主催イベント一覧(E101)
 */
export class E001Component extends PlatformComponent {
  // お知らせ情報リスト
  noticeDataList: object[] = new Array();

  // システムに関するお知らせ情報リスト
  platformEventDataList: object[] = new Array();

  // 会員主催イベント情報リスト
  memberEventDataList: object[] = new Array();

  // 分科会イベント情報リスト
  subcommitteeEventDataList: object[] = new Array();

  // お知らせタイプを格納 (1：運営事務局からのお知らせ、0：会員からのお知らせ)
  information: number = Number(this.activatedRoute.snapshot.params.information);

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    // お知らせ情報リスト取得取得
    this.getNoticeDataList(NOTICE_TYPES.MEMBER_NOTICE);

    this.getNoticeDataList(NOTICE_TYPES.SYSTEM_NOTICE);
    

    // 会員主催イベント情報リスト取得
    // this.getMemberEventDataList(1);

    // 分科会イベント情報リスト取得
    // this.getMemberEventDataList(2);
  }

  /**
   * お知らせ情報リスト取得
   * @param information_kbn お知らせ区分(1:お知らせ 2:PF主催イベント)
   */
  private getNoticeDataList(information_kbn: string) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(INFORMATION_LIST + information_kbn);

    // お知らせ一覧を取得
    this.dbOperationService
      .getNoTemplateData(
        INFORMATION_LIST,
        { information_kbn: information_kbn },
        true
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, INFORMATION_LIST + information_kbn);

        // お知らせ情報
        if ('1' == information_kbn) {
          if (!this.commonService.checkNoneResponse(response)) {
            // お知らせ一覧を格納
            this.noticeDataList = response.body;
          } else {
            this.noticeDataList = new Array();
          }
        }

        // システムに関するお知らせ情報
        if ('2' == information_kbn) {
          if (!this.commonService.checkNoneResponse(response)) {
            // システムに関するお知らせ一覧を格納
            this.platformEventDataList = response.body;
          } else {
            this.platformEventDataList = new Array();
          }
        }
      });
  }

  /**
   * 会員主催イベント情報リスト取得
   * @param event_kbn イベント区分(1:会員主催イベント 2:分科会イベント)
   */
  private getMemberEventDataList(event_kbn: number) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(MEMBER_EVENTS_LIST);

    // 会員主催イベント申請検索結果を取得
    this.dbOperationService
      .getNoTemplateData(
        MEMBER_EVENTS_LIST,
        { event_kbn: event_kbn, status: NOTICE_TYPES.EVENT_NOTICE },
        true
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, MEMBER_EVENTS_LIST);

        //  会員主催イベント情報
        if (1 == event_kbn) {
          if (!this.commonService.checkNoneResponse(response)) {
            //  会員主催イベント情報を格納
            this.memberEventDataList = response.body;
          } else {
            this.memberEventDataList = new Array();
          }
        }

        // 分科会イベント情報イベント情報
        if (2 == event_kbn) {
          if (!this.commonService.checkNoneResponse(response)) {
            // 分科会イベント情報イベント一覧を格納
            this.subcommitteeEventDataList = response.body;
          } else {
            this.subcommitteeEventDataList = new Array();
          }
        }
      });
  }

  /**
   * Information details (g102) transition
   * @param infomationId 会員主催イベントID
   */
  public detailInformation(infomationId: string): void {
    //    * Information details (g102) transition
    this.router.navigate(['pages/platform/e002/' + infomationId]);
  }
}
