

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">

    <div class="information-margin"></div>

    <!-- メッセージ -->
    <div class="message">
      <!-- メッセージ本文 -->
      <div class="message-text ">
        <div class="p-d-flex p-jc-center">
          <i class="pi pi-check-circle black-color"></i><br />
        </div>
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center black-color">評価登録を登録しました。</div>
      </div>
    </div>
    <!-- ボタンエリア -->
    <div class="p-d-flex p-jc-center button-area">
      <button
        pButton
        type="button"
        label="マイページへ戻る"
        class="button-default-size button1"
        (click)="moveB301()"
      ></button>
    </div>
  </div>
</div>
