import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { API_URL_DIC_LISTS } from 'manager/http-constants';
import { forkJoin, Observable } from 'rxjs';
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { Validator } from 'src/app/shared/validator/validator';
import { PlatformComponent } from '../../platform.component';

@Component({
  selector: 'app-role-dialog',
  templateUrl: './role-dialog.component.html',
  styleUrls: ['./role-dialog.component.scss'],
})

/**
 * 共通部品_承認時ダイアログ
 */
export class RoleDialogComponent extends PlatformComponent {
  roleInputForm: FormGroup = this.formBuilder.group({
    role: this.formBuilder.control(CONSTANT.EMPTY_STRING
    //   , {
    //   asyncValidators: [
    //     this.validator.CheckValidator(
    //       "application",
    //       "role",
    //       INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE
    //     ),
    //   ],
    // }
    )
  })

  @Output() returnRole = new EventEmitter<boolean>();
  boardFlag: boolean = false;

  roleValue: string;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private formBuilder: FormBuilder,
    private validator: Validator,
    private messageData: MessageData
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.openRoleDialog();
  }

  public openRoleDialog(): void {
    this.boardFlag = true;
  }

  public closeDialog(): void {
    this.boardFlag = false;
  }

  public selectRoleApplication(): void {
    this.returnRole.emit(this.roleInputForm.get('role').value);
    this.closeDialog();
  }
}
