import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MEMBER_INFO_UPDATE,
  MEMBERS_DETAIL,
  API_URL_GET_LIST_POSTALS_INITIAL,
  API_URL_GET_LIST_POSTALS,
} from "manager/http-constants";
import { forkJoin, Observable } from "rxjs";
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { CONSTANT, LOADING_KEY } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { Validator } from "src/app/shared/validator/validator";
import { GENERATE_INPUT_TYPE } from "src/app/shared/generate/generate-input/constant";
import { PlatformComponent } from "../platform.component";
import { DICVALUES_API_CONSTANT } from "src/app/shared/constant/api-constant";
import { LoginService } from "src/app/shared/service/login.service";
import { Location } from '@angular/common';


@Component({
  selector: "app-b101",
  templateUrl: "./b101.component.html",
  styleUrls: ["./b101.component.scss", "../platform.component.scss"],
})

/**
 * 自治体会員登録・更新画面(入力)(B101)
 * 自治体会員登録・更新画面(確認画面)(B102)
 */
export class B101Component extends PlatformComponent {
  className:string = "";
  // 画面.入力フォーム
  b101InputForm: FormGroup = this.formBuilder.group({
    // 分類
    class_id: this.formBuilder.control(
      this.route.snapshot.queryParams.class_id
    ),
    // 団体名称
    member_organization_name: this.formBuilder.control(CONSTANT.EMPTY_STRING),

    // 郵便番号
    member_postal_no: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "member",
          "postal_no",
          INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE
        ),
      ],
    }),
    // 都道府県
    member_prefectures: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "member",
          "prefectures",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),

    // 所在地（番地・建物）
    member_address_building: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "member",
          "address_building",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),

    user_tel: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "user",
          "tel",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),

    member_history_support_result: this.formBuilder.control(
      CONSTANT.EMPTY_STRING,
      {
        asyncValidators: [
          this.validator.CheckValidator(
            "member",
            "history_support_result",
            INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
          ),
        ],
      }
    ),
  });

  //　会員ID
  memberId: string = this.route.snapshot.queryParams.member_id;

  // 会員種別ID
  classId: number = this.route.snapshot.queryParams.class_id;

  // 確認画面表示フラグ(true=自治体会員登録・更新登録確認(B102),false=自治体会員登録・更新画面(入力）(B101)
  confirmationFlag: boolean = false;

  // 業種用辞書値格納先
  industryIdCodeList: any;

  // 業種用辞書値(目指すゴール)格納先
  dicList_3: any;

  //目指すゴールリスト格納先
  category_activities: any[] = new Array();
  postalNoList: any = new Array();

  user_role: string;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder,
    private validator: Validator,
    private messageData: MessageData,
    private loginService: LoginService,
    private location: Location,
  ) {
    super(route, commonService, dbOperationService, loadingState);

    // urlを自治体会員登録・更新画面(入力）(B101)に変更する
    this.router.navigate(["pages/platform/b101/" + this.inputType], {
      queryParams: {
        screenId: "b101",
        member_id: this.memberId,
        class_id: this.classId,
      },
    });
  }

  ngOnInit(): void {
    this.getUserLoginInfo();
  }

  /**
   * 編集初期表示
   */
  private setEditInitialData() {
    // 登録タイプ判定
    if (!(GENERATE_INPUT_TYPE.EDIT == this.inputType)) {
      // 編集以外の場合
      return;
    }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(MEMBERS_DETAIL);

    // 会員プロフィール詳細を取得
    this.dbOperationService
      .getNoTemplateDisplayData(MEMBERS_DETAIL, this.memberId, true)
      .subscribe((response) => {
        if (this.commonService.checkNoneResponse(response)) {
          return;
        }else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.location.back();
        } 
        if(!this.decisionPerspective(response.body.member_member_id)){
          this.loginService.logout();
        }
        this.className = response.body.member_class_id.name;
        // 初期値セット
        this.commonService.setFormGroupInitialData(
          this.b101InputForm,
          response.body
        );
        
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, MEMBERS_DETAIL);

        this.getPostalsListByPostCode(response.body.member_postal_no);
      });
  }

  /**
   * 自治体会員登録・更新画面(入力)(B101)を表示する
   */
  public moveB101(): void {
    // 会員登録・更新画面(入力)(B101)へ遷移
    this.router.navigate(["pages/platform/b101/edit"], {
      queryParams: {
        screenId: "b101",
        member_id: this.memberId,
        class_id: this.classId,
      },
    });

    // 自治体会員登録・更新画面(入力)(B101)を表示する
    this.confirmationFlag = false;
  }

  /**
   * 自治体会員登録・更新画面(確認画面)(B102)を表示する
   */
  public moveB102(): void {
    // 画面.入力フォームから入力内容を取得
    // ※値渡し

    let b101InputForm: any = JSON.parse(
      JSON.stringify(this.b101InputForm.value)
    );

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: "",
        background_color: "",
        opacity: 0.3,
      })
    );

    /* 入力項目値バリデーションチェック */
    // 非同期同時実行リスト
    let task: Observable<any>[] = [
      /* 共通バリデーションチェック */
      this.dbOperationService.multiValidationResult("member", b101InputForm),
    ];

    // 非同期同時実行
    forkJoin(task).subscribe((dataList) => {
      // レスポンスを結合
      // const data = this.generateService.JoinValidationResponseList(dataList);

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd();

      // 自治体会員登録・更新画面(確認画面)(B102)へ遷移
      this.router.navigate(
        ["pages/platform/b101/" + this.route.snapshot.params.inputType],
        {
          queryParams: {
            screenId: "b102",
            member_id: this.memberId,
            class_id: this.classId,
          },
        }
      );

      // 自治体会員登録・更新画面(確認画面)(B102)を表示する
      this.confirmationFlag = true;
    });
  }

  /**
   *自治体会員登録・更新画面(登録・更新完了)(B103)へ遷移する
   */
  public moveB103(): void {
    // 画面.入力フォームから入力内容を取得
    // ※値渡し

    let b101InputForm: any = JSON.parse(
      JSON.stringify(this.b101InputForm.value)
    );

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.DATA_INSERT);

    // 登録タイプ判定
    if (GENERATE_INPUT_TYPE.EDIT == this.inputType) {
      // 自治体会員プロファイル更新を実施
      this.dbOperationService
        .updateData(MEMBER_INFO_UPDATE, this.memberId, b101InputForm)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

          // レスポンス結果を判定
          if (API_RESPONSE.SUCCESS == response.body.Message) {
            // 正常終了の場合

            // 自治体会員登録・更新画面(登録・更新完了)(B103)へ遷移
            this.router.navigate(["pages/platform/b103"], {
              queryParams: {
                member_id: this.memberId,
                class_id: this.classId,
              },
            });
          }else if (this.commonService.checkInvalidValue(response)) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.router.navigate(["pages/platform/a110"]);
          }else {
            // 異常終了の場合

            // 異常終了メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(MESSAGE_CODE.E00001),
                detail: this.commonService.msg(MESSAGE_CODE.E80002),
              })
            );

            //  自治体会員登録・更新画面(入力)(B101)を表示する
            this.confirmationFlag = false;
          }
        });
    }
  }

  

  public getListPostalsInitial(): void {
    this.postalNoList = new Array();
    this.loadingState.loadStart(API_URL_GET_LIST_POSTALS_INITIAL);

    this.dbOperationService
      .getNoTemplateData(API_URL_GET_LIST_POSTALS_INITIAL, {}, true)
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.postalNoList = response.body;
        } else {
          this.postalNoList = new Array();
        }

        this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS_INITIAL);
      });
  }

  public getPostalsList(e: any): void {
    this.b101InputForm.get("member_prefectures").reset();
    if (!e.target.value.trim()) {
      this.getListPostalsInitial();
    } else {
      this.postalNoList = new Array();
      this.loadingState.loadStart(API_URL_GET_LIST_POSTALS);
      this.dbOperationService
        .getNoTemplateData(
          API_URL_GET_LIST_POSTALS,
          { member_postal_no: e.target.value },
          true
        )
        .subscribe((response) => {
          if (!this.commonService.checkNoneResponse(response)) {
            this.postalNoList = response.body;
            this.b101InputForm.patchValue({
              member_prefectures: this.postalNoList[0].value,
            });
            this.b101InputForm.get("member_prefectures").markAsDirty();
          } else {
            this.postalNoList = new Array();
            this.b101InputForm.get("member_prefectures").markAsDirty();
          }

          this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS);
        });
    }
  }

  public getPostalsListByPostCode(postCode: string): void {
    if (!postCode.trim()) {
      this.getListPostalsInitial();
      this.b101InputForm.get("member_prefectures").reset();
    } else {
      this.postalNoList = new Array();
      this.loadingState.loadStart(API_URL_GET_LIST_POSTALS);

      this.dbOperationService
        .getNoTemplateData(
          API_URL_GET_LIST_POSTALS,
          { member_postal_no: postCode },
          true
        )
        .subscribe((response) => {
          if (!this.commonService.checkNoneResponse(response)) {
            this.postalNoList = response.body;
            this.b101InputForm.patchValue({
              member_prefectures: this.postalNoList[0].value,
            });
          } else {
            this.postalNoList = new Array();
          }

          this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS);
        });
    }
  }

  public getUserLoginInfo() {
    // 編集初期表示
    this.setEditInitialData();
  }
}
