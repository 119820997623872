import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import {
  API_URL_GET_DETAIL_AGENDA_NEEDS, GENERATE_DISPLAY_NEED_SEED_MATCHING
} from 'manager/http-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { PlatformComponent } from '../../platform.component';
import { MATCH_TYPE } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-c341-dialog',
  templateUrl: './c341-dialog.component.html',
  styleUrls: ['../../platform.component.scss', './c341-dialog.component.scss']
})
export class C341DialogComponent extends PlatformComponent {
  @Input() matchId: string;
  @Output() returnStatusDialogC341 = new EventEmitter<boolean>();
  @Output() reloadData = new EventEmitter<null>();

   // 課題・ソリューション詳細格納先
   detailTask: any;
 
   // マッチング依頼一覧格納先
   matchingList: any;
 
   // 質問掲示板の件数格納先
   countQuestionBoard: number = 0;
   class_id: any;
   boardFlag: boolean;
   constructor(
     public route: ActivatedRoute,
     public commonService: CommonService,
     public dbOperationService: DbOperationService,
     public loadingState: LoadingState,
     private router: Router,
     private messageData: MessageData,
     private location: Location,
     private datePipe: DatePipe,
     private loginService: LoginService
   ) {
     super(route, commonService, dbOperationService, loadingState);
   }
 
   ngOnInit(): void {
 
     this.openRoleDialog();
     // // 課題・ソリューション詳細を取得
     this.getDetailTask();
 
     // // マッチング依頼一覧を取得
     // this.getMatchingList();
 
     // // 質問掲示板の件数を取得
     // this.getCountQuestionBoard();
   }
 
   public openRoleDialog(): void {
    this.boardFlag = true;
  }

  public closeDialog(): void {
    this.boardFlag = false;
    this.returnStatusDialogC341.emit(this.boardFlag);
  }
   public getUserLoginInfo() {
     return new Promise<any>((resolve) => {
       this.loginService.getLoginUser().subscribe((response) => {
         // 画面ロードフラグをOFF(ロード終了)
         this.loadingState.loadSleepEnd(0.3);
 
         // ログインユーザ情報がユーザマスタに存在するか否か
         if (this.commonService.checkNoneResponse(response)) {
           // ユーザマスタに存在しない場合
 
           // 不正なユーザの為、ログアウト処理
           this.loginService.logout(MESSAGE_CODE.E90000);
 
           // 処理を終了
           return;
         } else {
           // ユーザマスタに存在する場合
           this.class_id = response.body.class_id;
         }
         resolve(null);
       });
     });
   }
 
   /**
    * 課題・ソリューション詳細を取得
    */
   public getDetailTask(): void {
     // 画面ロードフラグをON(ロード中状態)
     this.loadingState.loadStart(GENERATE_DISPLAY_NEED_SEED_MATCHING);
 
     // 課題・ソリューション詳細を取得
     this.dbOperationService
       .getNoTemplateDisplayDataMultipleArgPost(GENERATE_DISPLAY_NEED_SEED_MATCHING, this.matchId)
       .subscribe((response) => {
         // 画面ロードフラグをOFF(ロード終了)
         this.loadingState.loadSleepEnd(0.3, GENERATE_DISPLAY_NEED_SEED_MATCHING);
 
         if (this.commonService.checkInvalidValue(response)) {
          this.closeDialog();
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.reloadData.emit();
        } else {
           // 課題・ソリューション詳細を格納
           this.detailTask = response.body;
           this.boardFlag = true;
 
           // 公開掲示板をデフォルトで表示
           // if (1 == this.detailTask?.board_open.id && this.decisionLoginState()) {
           //   this.moveChat()
           // }
         }
       });
   }
 
}
