import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  APPLICATIONS_APPROVE,
  APPLICATIONS_DETAIL,
} from 'manager/http-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ApprovalDialogComponent } from '../common/approval-dialog/approval-dialog.component';
import { PlatformComponent } from '../platform.component';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-a231',
  templateUrl: './a231.component.html',
  styleUrls: [ '../platform.component.scss', './a231.component.scss'],
})

/**
 * 入会申請詳細
 */
export class A231Component extends PlatformComponent {
  /** 共通部品_承認時ダイアログ */
  @ViewChild(ApprovalDialogComponent)
  approvalDialogComponent: ApprovalDialogComponent;

  // 申請ID
  applicationId: string = this.route.snapshot.params.application_id;

  // 入会申請詳細情報
  applicationInformation: any;
  class_id: any;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private messageData: MessageData,
    private router: Router,
    private location: Location,
    private loginService: LoginService,
    
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.getUserLoginInfo();
  }

  public getUserLoginInfo() {
   
        // ユーザマスタに存在する場合
        this.class_id = this.loginUser.class_id;
        // 入会申請詳細を取得を実施
        this.getDetailApplication();
      
  }

  /**
   * 入会申請詳細を取得
   */
  public getDetailApplication(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(APPLICATIONS_DETAIL);

    // 入会申請詳細を取得
    this.dbOperationService
      .getNoTemplateDisplayData(APPLICATIONS_DETAIL, this.applicationId, true)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, APPLICATIONS_DETAIL);

        if (this.commonService.checkNoneResponse(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );

          // 前画面へ戻る
          this.location.back();
        }else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.location.back();
        } else {
          // 入会申請詳細を格納
          this.applicationInformation = response.body;
        }
      });
  }
 
  /**
   * 内閣府承認ダイアログ表示
   */
  public openSmallApprovalDialog(): void {
    // 内閣府承認ダイアログ表示
    this.approvalDialogComponent.openSmallApprovalDialog(
      APPLICATIONS_APPROVE,
      this.applicationId,
      2001,
      0,
      1
    );
  }
  moveD211(){
    this.router.navigate(['pages/platform/secretariat-list/d211']);
  }
}
