<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- A200系共通部品_Steps -->
    <app-a200-steps [class_id]="class_id"></app-a200-steps>

    <!-- メッセージ -->
    <div class="message">
      <!-- メッセージ本文 -->
      <div class="message-text">
        <div class="p-d-flex p-jc-center">
          <i class="pi pi-check-circle black-color"></i><br />
        </div>
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center black-color">入会申請を受理しました</div>
      </div>
    </div>

    <!-- 本文 -->
    <div class="p-d-flex p-jc-center sub-message">
      入会審査後、本申請に記載されたメールアドレス宛てに審査結果の通知メールを送信いたします。<br />
      入会審査には1週間程度かかる場合があります。
    </div>

    <!-- ボタンエリア -->
    <div class="p-d-flex p-jc-center button-area">
      <button
        pButton
        type="button"
        label="ログイン画面へ "
        class="button-default-size"
        (click)="moveLogin()"
      ></button>
    </div>
  </div>
</div>

