<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- A210系共通部品_Steps -->
    <app-a210-steps></app-a210-steps>

    <!-- タイトル -->
    <div class="title">
      <h1>パスワード設定</h1>
    </div>

    <!-- 入力項目 -->
    <div class="input">
      <span class="ls-2">
        ログインするためのパスワード設定を行います。<br />
        半角大英文字、半角小英文字、半角数字のすべてを組み合わせて8文字以上で設定してください。
      </span>
      <div class="input-margin"></div>
      <!-- 入力内容 -->
      <p-table
        [value]="[{}]"
        styleClass="information-Form"
        [formGroup]="a212InputForm"
      >
        <ng-template pTemplate="body">
          <!-- パスワード -->
          <tr>
            <td class="item-name">
              パスワード
              <span class="required">【必須】</span>
            </td>
            <td class="item-information">
              半角大英文字、半角小英文字、半角数字のすべてを組み合わせて8文字以上で設定してください。
              <p-password
                promptLabel="入力してください"
                weakLabel="危険です"
                mediumLabel="注意です"
                strongLabel="問題ありません"
                mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}).[a-zA-Z0-9]+$"
                strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})[a-zA-Z0-9]+$"
                [toggleMask]="true"
                (input)="checkPassword(true)"
                styleClass="information-Form-password"
                inputStyleClass="information-Form-input-password"
                formControlName="password"
              ></p-password>
              <!-- エラーメッセージ出力箇所 -->
              <app-validator-message [control]="a212InputForm.get('password')">
              </app-validator-message>
            </td>
          </tr>
          <!-- 確認 -->
          <tr>
            <td class="item-name">
              確認
              <span class="required">【必須】</span>
            </td>
            <td class="item-information">
              確認のためにもう一度ご入力ください。<br />
              <p-password
                promptLabel="入力してください"
                weakLabel="危険です"
                mediumLabel="注意です"
                strongLabel="問題ありません"
                mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}).[a-zA-Z0-9]+$"
                strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})[a-zA-Z0-9]+$"
                [toggleMask]="true"
                (input)="checkConfirmationPassword(true)"
                styleClass="information-Form-password"
                inputStyleClass="information-Form-input-password"
                formControlName="confirmationPassword"
              ></p-password>
              <!-- エラーメッセージ出力箇所 -->
              <app-validator-message
                [control]="a212InputForm.get('confirmationPassword')"
              >
              </app-validator-message>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <!-- ボタンエリア -->
    <div class="p-d-flex p-jc-center button-area">
      <button
        pButton
        type="button"
        label="パスワードを設定する"
        class="button-default-size button1 btn-update"
        (click)="moveA213()"
        [disabled]="!a212InputForm.valid"
      ></button>
    </div>
  </div>
</div>

<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>
