import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { POSTALS_LIST } from 'manager/http-constants';
import { Table } from 'primeng/table';
import { CONSTANT } from 'src/app/shared/constant/constant';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../../platform.component';

@Component({
  selector: 'app-postal-no-dialog',
  templateUrl: './postal-no-dialog.component.html',
  styleUrls: ['./postal-no-dialog.component.scss'],
})

/**
 * 共通部品_郵便番号ダイアログ
 */
export class PostalNoDialogComponent extends PlatformComponent {
  // 画面.郵便番号フリーワード
  freeword = new FormControl(CONSTANT.EMPTY_STRING);

  // 選択郵便番号情報返却
  @Output() returnPostalNoData = new EventEmitter<Object>();

  /** テーブル状態 */
  @ViewChild('table') table: Table;

  // 郵便番号リスト格納先
  postalNoList: any = new Array();

  // 郵便番号ダイアログ表示フラグ(true:ダイアログ状態、false:ダイアログ非状態)
  postalNoDialogFlag: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * 郵便番号ダイアログ表示
   */
  public openPostalNoDialog() {
    // 画面.郵便番号フリーワードをリセット
    this.freeword.reset();

    // 郵便番号リストを初期化
    this.postalNoList = new Array();

    // 郵便番号ダイアログ表示
    this.postalNoDialogFlag = true;
  }

  /**
   * 郵便番号検索
   */
  public searchPostalNo(): void {
    this.postalNoList = new Array();
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(POSTALS_LIST);

    // 郵便番号マスタ一覧を取得
    this.dbOperationService
      .getNoTemplateData(POSTALS_LIST, { freeword: this.freeword.value }, true)
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          // 郵便番号マスタ一覧を格納
          this.postalNoList = response.body;
        } else {
          this.postalNoList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, POSTALS_LIST);
      });

    // テーブル状態が存在するか否か
    if (this.table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      this.table.reset();
    }
  }

  /**
   * 郵便番号セット
   * @param postalNoData 郵便番号情報
   */
  public setPostalNo(postalNoData: Object): void {
    // 郵便番号ダイアログ非表示
    this.postalNoDialogFlag = false;

    // 承認更新完了
    this.returnPostalNoData.emit(postalNoData);
  }
}
