import { Component, OnInit } from "@angular/core";
import { ActivationEnd, Router, ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { CONSTANT } from "src/app/shared/constant/constant";
import { B220_STEPS_ITEMS } from "./b220-steps-constants";

@Component({
  selector: "app-b220-steps",
  templateUrl: "./b220-steps.component.html",
  styleUrls: ["./b220-steps.component.scss", "../../platform.component.scss"],
})

/**
 * B220系共通部品_Steps
 */
export class B220StepsComponent implements OnInit {
  currentActive: number = 1;
  currentUrl: string;
  totalSteps = 4;
  progressWidth: string = "0%";

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.handleProgress();
  }

  public handleProgress(): void {
    if (
      this.currentUrl.startsWith("/pages/platform/b221/new?screenId=b221") ||
      this.currentUrl.startsWith("/pages/platform/b221/edit?screenId=b221")
    ) {
      this.currentActive = 1;
    } else if (
      this.currentUrl.startsWith("/pages/platform/b221/new?screenId=b222") ||
      this.currentUrl.startsWith("/pages/platform/b221/edit?screenId=b222")
    ) {
      this.currentActive = 2;
    } else if (this.currentUrl.startsWith("/pages/platform/b223")) {
      this.currentActive = 3;
    }

    this.progressWidth = this.getProgressWidth().toFixed(2) + "%";
  }

  getProgressWidth() {
    return ((this.currentActive - 1) / 2) * 100;
  }

  isActiveStep(step: number) {
    return this.currentActive >= step;
  }

  isActiveText(index: number) {
    if (index === this.currentActive) {
      return true;
    }
    return false;
  }

  ngAfterViewInit() {}
}
