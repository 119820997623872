import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AGENDA_CATEGORY_COUNT,
  AGENDA_SOLUTIONS_LIST,
  AGENDA_CATEGORY_LIST,
  AGENDA_NEEDS_LIST,
  AGENDA_NEEDS_LIST_NO_TOKEN,
} from 'manager/http-constants';
import { AGENDA_SOLUTION_M401_TEMPLATE } from 'manager/template-constant';
import { Table } from 'primeng/table';
import { DICVALUES_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { CONSTANT, DICID_NUMBER } from 'src/app/shared/constant/constant';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';
import { DataView } from "primeng/dataview";
import { LoginService } from 'src/app/shared/service/login.service';
import { MessageData, ToastMessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';

@Component({
  selector: 'app-c241',
  templateUrl: './c241.component.html',
  styleUrls: ['./c241.component.scss', '../platform.component.scss'],
})

/**
 * 課題・ソリューション一覧
 */
export class C241Component extends PlatformComponent {
  // 画面.検索フォーム
  c241SearchForm: FormGroup = this.formBuilder.group({
    agenda_need_area: CONSTANT.EMPTY_STRING,
    agenda_need_recruitment_kind: CONSTANT.EMPTY_STRING,
    agenda_need_event_date_FROM: CONSTANT.EMPTY_STRING,
    agenda_need_event_date_TO: CONSTANT.EMPTY_STRING,
    agenda_need_title: CONSTANT.EMPTY_STRING,
    agenda_need_expected_content: CONSTANT.EMPTY_STRING
  });

  /** テーブル状態 */
  @ViewChild('table') table: Table;

  // 課題・ソリューション種別のAccordion開閉フラグ
  accordion_agenda_kbn: boolean = false;

  // 会員種別のAccordion開閉フラグ
  accordion_class_id: boolean = false;

  // 都道府県のAccordion開閉フラグ
  accordion_prefectures: boolean = false;

  // 都道府県の中項目Accordion開閉フラグ
  accordion_prefectures_detail = new Map();

  // 更新日のAccordion開閉フラグ
  accordion_update_date: boolean = false;

  // 業種のAccordion開閉フラグ
  accordion_industry_id: boolean = false;

  // SDGsゴールのAccordion開閉フラグ
  accordion_sdgs_goal: boolean = false;

  // 課題・ソリューション結果リスト
  resultTaskDataList: object[] = new Array();

  // 課題・ソリューション件数格納先
  countTaskData: number = 0;

  // セッション保存名
  sessionTagName: string = 'c201SearchConditions';

  accordion_agenda_need_area: boolean = false;

  accordion_agenda_need_recruitment_kind: boolean = false;
  columnOrder: any[] = [];
  // dataView ソートカラム名
  sortField: string;
  // dataView ソート順 (1:昇順, -1:降順)
  sortOrder: number;

  @ViewChild("dv")
  dv: DataView;
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private loginService: LoginService,
    private messageData: MessageData
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('showMessSessions')) {
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00003),
          detail: this.commonService.msg(MESSAGE_CODE.N90001),
          position: TOAST.BOTTOM_RIGHT,
          life: 6000,
        })
      );
      sessionStorage.removeItem('showMessSessions')
    }
    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(AGENDA_SOLUTION_M401_TEMPLATE.SEARCH_C241_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });


    // 辞書値リストを取得
    this.getSpecCodeList(DICID_NUMBER.PRACTICE_FORMAT, DICID_NUMBER.TARGET_GRADE);

    // 課題カテゴリを取得
    // this.getAgendaCategoryList();

    // 初期処理:クエリパラメータの設定
    this.setQueryParams();

    // 課題・ソリューション結果を取得
    this.getResultTaskDataList();
  }

  /**
   * 初期処理:クエリパラメータの設定
   */
  private setQueryParams(): void {
    // クエリパラメータ.課題・ソリューション種別
    {
      // クエリパラメータ.課題・ソリューション種別を取得
      const agendaKbn = this.route.snapshot.queryParams.agenda_kbn;

      // 課題・ソリューション種別が存在するか否か
      if (agendaKbn) {
        // 課題・ソリューション種別が存在する場合

        // 課題・ソリューション種別を画面.検索フォームに格納
        this.c241SearchForm
          .get('agenda_kbn')
          .setValue(agendaKbn.split(CONSTANT.COMMA));

        // 課題・ソリューション種別のAccordionを開く
        this.accordion_agenda_kbn = true;
      }
    }

    // クエリパラメータ.会員種別
    {
      // クエリパラメータ.会員種別を取得
      const classId = this.route.snapshot.queryParams.class_id;

      // 会員種別が存在するか否か
      if (classId) {
        // 会員種別が存在する場合

        // 会員種別を画面.検索フォームに格納
        this.c241SearchForm
          .get('class_id')
          .setValue(classId.split(CONSTANT.COMMA));

        // 会員種別のAccordionを開く
        this.accordion_class_id = true;
      }
    }
  }

  /**
   * 課題・ソリューション結果を取得
   */
  public getResultTaskDataList(screenSearchFlg?: boolean): void {
    // 画面.検索フォームから検索条件を取得
    // ※値渡し
    let c241SearchForm: any = JSON.parse(
      JSON.stringify(this.c241SearchForm.value)
    );
    // 検索フォームが空の場合、セッションに保持している検索条件を設定
    if (!this.checkSetConditions(c241SearchForm) && !screenSearchFlg) {
      const sessionSearchForm = JSON.parse(sessionStorage.getItem(this.sessionTagName));
      // セッションに値がなければ設定しない
      if (this.checkSetConditions(sessionSearchForm)) {
        // 画面の検索条件に反映する
        this.c241SearchForm.setValue(sessionSearchForm);
        // 検索条件に設定する
        c241SearchForm = JSON.parse(
          JSON.stringify(this.c241SearchForm.value)
        );
        // 検索条件を元にアコーディオンの開閉フラグを設定
        this.setAccordionFlg(c241SearchForm);
      }
    }

    // 検索条件の地域が配列か否か
    if (c241SearchForm.prefectures) {
      // 検索条件の地域が配列の場合

      // 検索条件の地域から大項目(local_category)を削除する
      c241SearchForm.prefectures = c241SearchForm.prefectures.filter(
        (prefectures) => !prefectures.includes('local_category_')
      );
    }

    // 配列をカンマ区切り変換
    c241SearchForm.agenda_need_area = c241SearchForm.agenda_need_area
      ? c241SearchForm.agenda_need_area.toString()
      : CONSTANT.EMPTY_STRING;

    c241SearchForm.agenda_need_recruitment_kind = c241SearchForm.agenda_need_recruitment_kind
      ? c241SearchForm.agenda_need_recruitment_kind.toString()
      : CONSTANT.EMPTY_STRING;

    c241SearchForm.agenda_need_event_date_FROM = c241SearchForm.agenda_need_event_date_FROM
      ? c241SearchForm.agenda_need_event_date_FROM.toString()
      : CONSTANT.EMPTY_STRING;

    c241SearchForm.agenda_need_event_date_TO = c241SearchForm.agenda_need_event_date_TO
      ? c241SearchForm.agenda_need_event_date_TO.toString()
      : CONSTANT.EMPTY_STRING;

    
    c241SearchForm.agenda_need_title = c241SearchForm.agenda_need_title
      ? c241SearchForm.agenda_need_title.toString()
      : CONSTANT.EMPTY_STRING;
      
    c241SearchForm.agenda_need_expected_content = c241SearchForm.agenda_need_expected_content
      ? c241SearchForm.agenda_need_expected_content.toString()
      : CONSTANT.EMPTY_STRING;

    let urlAgendaNeedsList = AGENDA_NEEDS_LIST
    if (!this.loginService.getAuth0LoginState()) {
      urlAgendaNeedsList = AGENDA_NEEDS_LIST_NO_TOKEN
    }
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(urlAgendaNeedsList);
    const table = "view_agenda_need_my_page"
    // 課題・ソリューション一覧を取得
    this.dbOperationService
      .getForkJoinDataNoTemplate(table, urlAgendaNeedsList, c241SearchForm)
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          // 課題・ソリューション結果を格納
          this.resultTaskDataList = response.body;
        } else {
          this.resultTaskDataList = new Array();
        }
        // 検索条件を保持
        sessionStorage.setItem(this.sessionTagName, JSON.stringify(this.c241SearchForm.value));

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, urlAgendaNeedsList);
        this.dv.first = 0;
      });

    // 画面ロードフラグをON(ロード中状態)
    // this.loadingState.loadStart(AGENDA_CATEGORY_COUNT);

    // // 課題・ソリューション件数を取得
    // this.dbOperationService
    //   .getNoTemplateData(AGENDA_CATEGORY_COUNT, null, true)
    //   .subscribe((response) => {
    //     // 課題・ソリューション件数を格納
    //     this.countTaskData = response.body.count;

    //     // 画面ロードフラグをOFF(ロード終了)
    //     this.loadingState.loadSleepEnd(0.3, AGENDA_CATEGORY_COUNT);
    //   });

    // // テーブル状態が存在するか否か
    // if (this.table) {
    //   // テーブル状態が存在する場合

    //   // テーブル状態をリセット
    //   this.table.first = 0;
    // }
  }

  /**
   * カテゴリ大項目
   * @param event チェック状態
   * @param list チェック対象小項目リスト
   */
  public checkLargeCategory(type: string, list: any, controlName: string): void {
    // 画面.検索フォーム.カテゴリを取得
    const getList = this.c241SearchForm.get(controlName).value;
    // チェック対象小項目リストからコード値のみ取得
    let spCodeList
    if (controlName !== 'agenda_category_class_cd2')
      spCodeList = this.commonService.createArrayGetArrayObject(
        list,
        "sp_code"
      );
    else spCodeList = this.commonService.createArrayGetArrayObject(
      list,
      "value"
    );
    let result = [];
    // 該当のカテゴリコードを全て削除してresult変数に格納
    if (getList) {
      result = getList.filter((value) => !spCodeList.includes(value));
    }

    // チェック状態の判定

    // チェック状態の場合
    if (type == "checkAll") {
      // result変数に選択したカテゴリコードリストを追加
      result = result.concat(spCodeList);
    }
    else {
      result = result.filter((val) => !spCodeList.includes(val))
    }

    // 画面.検索フォーム.地域に設定
    this.c241SearchForm.get(controlName).setValue(result);
  }

  /**
   * 地域大項目
   * @param event チェック状態
   * @param list チェック対象小項目リスト
   */
  public checkLargePrefectures(e: any, list: any): void {

    // 親イベントを止める(ヘッダ開閉)
    event.stopPropagation();

    // 画面.検索フォーム.地域を取得
    const getList = this.c241SearchForm.get('prefectures').value;

    // チェック対象小項目リストからコード名称のみ取得
    const spCodeList = this.commonService.createArrayGetArrayObject(
      list,
      DICVALUES_API_CONSTANT.SP_NAME
    );

    // 該当の地域コードを全て削除してresult変数に格納
    let result = getList.filter((value) => !spCodeList.includes(value));

    // チェック状態の判定
    if (e.checked) {
      // チェック状態の場合

      // result変数に選択した地域コードリストを追加
      result = result.concat(spCodeList);
    }

    // 画面.検索フォーム.地域に設定
    this.c241SearchForm.get('prefectures').setValue(result);
  }

  /**
   * 地域中項目
   * @param event チェック状態
   * @param code 大項目コード
   * @param list チェック対象小項目リスト
   */
  public checkMediumPrefectures(event: any, code: string, list: any): void {
    // 画面.検索フォーム.地域を取得
    const getList = this.c241SearchForm.get('prefectures').value;

    // チェック状態の判定
    if (event.checked) {
      // チェック状態の場合

      // チェック対象小項目リストからコード名称のみ取得
      const spCodeList = this.commonService.createArrayGetArrayObject(
        list,
        DICVALUES_API_CONSTANT.SP_NAME
      );

      // チェック対象小項目リストが全てチェック状態の場合
      if (spCodeList.every((spCode) => getList.indexOf(spCode) != -1)) {
        // 画面.検索フォーム.地域に大項目コードを追加
        getList.push(code);

        // 画面.検索フォーム.地域に設定
        this.c241SearchForm.get('prefectures').setValue(getList);
      }
    } else {
      // 非チェック状態の場合

      // 画面.検索フォーム.地域から大項目コードを削除
      this.c241SearchForm
        .get('prefectures')
        .setValue(getList.filter((value) => value != code));
    }
  }

  /**
   * 課題・ソリューション詳細(C301)へ遷移する
   * @param agendaId 課題ID
   */
  public moveC301(agendaId: string): void {
    // 課題・ソリューション詳細(C301)へ遷移
    this.router.navigate(['pages/platform/c301/' + agendaId]);
  }

  /**
   * 検索条件が設定されているかチェックする
   * @param conditions
   * @returns boolean true:設定あり / false:未設定
   */
  public checkSetConditions(conditions: any): boolean {
    // 検索条件が存在するかチェック
    if (conditions === undefined || conditions === null) {
      return false;
    } else {
      // 検索条件が設定されているかチェック
      if (conditions["freeword"] || conditions["agenda_kbn"] || conditions["class_id"] ||
        conditions["category"] || conditions["prefectures"] || conditions["strdate"] ||
        conditions["enddate"] || conditions["industry_id"] || conditions["sdgs_goal"]) {
        return true;
      }
    }
    return false;
  }

  /**
   * アコーディオン表示フラグ設定
   */
  public setAccordionFlg(formVal: any): void {
    // 課題ソリューション種別
    this.accordion_agenda_kbn = (formVal.agenda_kbn != null && formVal.agenda_kbn?.length != 0) ? true : false;
    // 会員種別
    this.accordion_class_id = (formVal.class_id != null && formVal.class_id?.length != 0) ? true : false;
    // 地域
    this.accordion_prefectures = (formVal.prefectures != null && formVal.prefectures?.length != 0) ? true : false;
    // 地域 中項目
    if (this.accordion_prefectures) {
      for (let i = 0; i < this.prefecturesList.length; i++) {
        for (let j = 0; j < this.prefecturesList[i]["list"].length; j++) {
          if (formVal.prefectures.indexOf(this.prefecturesList[i]["list"][j]["sp_name"]) != -1) {
            this.accordion_prefectures_detail[this.prefecturesList[i]["code"]] = true;
            break;
          }
        }
      }
    }
    // 更新日
    this.accordion_update_date = (formVal.strdate != null && formVal.strdate?.length != 0) ? true :
      (formVal.enddate != null && formVal.enddate?.length != 0) ? true : false;
    // 業種
    this.accordion_industry_id = (formVal.industry_id != null && formVal.industry_id?.length != 0) ? true : false;
    // SDGsゴール
    this.accordion_sdgs_goal = (formVal.sdgs_goal != null && formVal.sdgs_goal?.length != 0) ? true : false;
  }

  // NamCV5 add
  public moveC341(agenda_id?: any): void {
    this.router.navigate([`pages/platform/c341/${agenda_id}`]);
  }

  public switchModeDisplayData(event: any): void {
    const element = event.target;
    if (
      element.classList.contains("pi-bars") ||
      (element.hasChildNodes() &&
        element.querySelector(".pi-bars").classList.contains("pi-bars"))
    ) {
      this.router.navigate(["pages/platform/master/m301"]);
    }
  }
  /**
    * dataView ソート処理
    */
  protected onSortChange(column: string) {
    let setOrder;
    if (this.sortField == column) {
      setOrder = this.sortOrder == 1 ? -1 : 1;
    } else {
      setOrder = 1
    }
    this.sortOrder = setOrder;
    this.sortField = column;
  }

  isDisabledTab(controlName) {
    const value = this.c241SearchForm.get(controlName).value
    if (value == null) {
      return false
    }
    else if (value.length > 0)
      return true;

    else return false
  }

  /**
 * check overflow of element
 * @param el html element of list 
 * @returns 
 */
  isOverflow(el: HTMLElement): boolean {
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === "visible")
      el.style.overflow = "hidden";
    const isOverflowing = el.clientWidth < el.scrollWidth
      || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
  }

  changePage(page: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
