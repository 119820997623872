import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CONSTANT } from 'src/app/shared/constant/constant';
import { B100_STEPS_ITEMS } from './b100-steps-constants';

@Component({
  selector: 'app-b100-steps',
  templateUrl: './b100-steps.component.html',
  styleUrls: ["./b100-steps.component.scss", "../../platform.component.scss"],
})

/**
 * B100系共通部品_Steps
 */
export class B100StepsComponent implements OnInit {

  @Input() class_id: string;
  stepsItems: any[] = B100_STEPS_ITEMS;

  // アクティブインデックス
  activeIndex: number = 0;
  
  progressWidth: string = "0%";

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((event) => {
        this.handleProgress(event);
      });
  }

  ngOnInit() {
  }

  public handleProgress(event): void {
    const path: string = event.snapshot.routeConfig.path;

    const queryParam: any = event.snapshot.queryParams;
    if (path.includes('pages/platform/b101') && 'b101' == queryParam.screenId) {
      this.activeIndex = 1;
    } else if (
      path.includes('pages/platform/b101') &&
      'b102' == queryParam.screenId
    ) {
      this.activeIndex = 2;
    } else if ('pages/platform/b103' == path) {
      this.activeIndex = 3;
    }
    this.progressWidth = this.getProgressWidth().toFixed(2) + "%";
  }

  getProgressWidth() {
    return ((this.activeIndex - 1) / 2) * 100;
  }

  isActiveStep(step: number) {
    return this.activeIndex >= step;
  }

  isActiveText(index: number) {
    if (index === this.activeIndex) {
      return true;
    }
    return false;
  }
}
