

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- 戻る -->
    <div class="return">
      <button
        *ngIf="back != '0'"
        pButton
        type="button"
        label="団体一覧へ戻る"
        icon="pi pi-angle-left"
        class="p-button-text "
        (click)="moveA110()"
      ></button>
    </div>
    <!-- タイトル -->
    <div class="p-d-flex title">
      <!-- 団体名 -->
      <i class="pi pi-id-card red-color"></i>
      <label class="member-name">
        {{ memberInformation?.member_organization_name }}
      </label>
    
      <!-- 編集 -->
      <button
        pButton
        type="button"
        label="編集する"
        icon="pi pi-pencil"
        class="p-as-center edit"
        (click)="moveB101()"
        *ngIf="
          decisionPerspective(memberInformation?.member_member_id)
        "
      ></button>
    </div>

    <!-- 団体情報 -->
    <div class="member-information">

      <div class="task-title p-mb-3">
        <h1>団体プロフィール</h1>
      </div>
  
      <!-- 団体情報内容 -->
      <p-table [value]="[{}]" styleClass="information-Form">
        <ng-template pTemplate="body">
          <!-- 団体種別 -->
          <tr
            *ngIf="memberInformation?.member_class_id.value != '9'"
          >
            <td class="item-name">団体組織区分</td>
            <td class="item-information">
              <label>
                {{ memberInformation?.member_class_id.name }}
              </label>
            </td>
          </tr>
          <!-- 団体名称カナ -->
          <tr>
            <td class="item-name">団体組織名</td>
            <td class="item-information">
              {{ memberInformation?.member_organization_name }}
            </td>
          </tr>

          <!-- 法人番号 -->
          <tr>
            <td class="item-input-name">郵便番号</td>
            <td class="item-information">
              {{ memberInformation?.member_postal_no }}
            </td>
          </tr>
          <!-- 郵便番号 -->
          <tr>
            <td class="item-name">都道府県</td>
            <td class="item-information">
              {{ memberInformation?.member_prefectures.name }}
            </td>
          </tr>
          <!-- 都道府県 -->
          <tr>
            <td class="item-name">所在地</td>
            <td class="item-information">
              {{ memberInformation?.member_address_building }}
            </td>
          </tr>
          <!-- 所在地 -->
          <tr>
            <td class="item-name">電話番号</td>
            <td class="item-information">
              
              {{ memberInformation?.user_tel }}
            </td>
          </tr>
         
          <!-- 人口 -->
          <tr
            *ngIf="memberInformation?.member_class_id.value == '2'"
          >
            <td class="item-name">過去の教育支援</td>
            <td class="item-information">
              {{ memberInformation?.member_history_support_result }}
            </td>
          </tr>
         
        </ng-template>
      </p-table>
    </div>




    <div class="information-margin"></div>

    <!-- 課題依頼 -->
    <div
      class="task-request-information"
      *ngIf="decisionPerspective(memberInformation?.member_member_id)"
    >
      <!-- 課題依頼タイトル -->
      <div class="p-d-flex p-jc-center" *ngIf="memberInformation?.member_class_id.value !== '9'">
        <h1>支援内容やニーズを登録してみましょう</h1>
      </div>

      <!-- ボタンエリア -->
      <div class="p-d-flex p-jc-center button-area">
        <button
          pButton
          type="button"
          label="支援内容を登録する"
          class="button-default-size"
          (click)="moveM301orM401(memberInformation?.member_class_id.value)"
          *ngIf="memberInformation?.member_class_id.value == '2' && loginUserMemberId != memberInformation?.member_member_id"
        ></button>
        <button
          pButton
          type="button"
          label="ニーズを登録する"
          class="button-default-size"
          (click)="moveM301orM401(memberInformation?.member_class_id.value)"
          *ngIf="memberInformation?.member_class_id.value == '1' && loginUserMemberId != memberInformation?.member_member_id"
        ></button>
      </div>
    </div>

    <div class="information-margin"></div>


  </div>
</div>

<!-- 共通部品_承認時ダイアログ -->
<app-approval-dialog
  (returnApproval)="getMemberInformation()"
></app-approval-dialog>


<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>
<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<app-generate-input ></app-generate-input>
<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>