import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  INFORMATION,
} from 'manager/http-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';

@Component({
  selector: 'app-g102',
  templateUrl: './g102.component.html',
  styleUrls: ['./g102.component.scss', '../platform.component.scss'],
})

/**
 * お知らせ詳細
 */
export class G102Component extends PlatformComponent {
  // お知らせIDを格納
  informationId: string = this.route.snapshot.params.information_id;

  // お知らせ詳細格納先
  detailInformation: any;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private messageData: MessageData,
    private router: Router,
    private location: Location
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    // お知らせ詳細を取得
    this.getDetailInformation();
  }

  /**
   * お知らせ詳細を取得
   */
  private getDetailInformation(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(INFORMATION);

    // 分科会詳細を取得
    this.dbOperationService
      .getNoTemplateDisplayData(INFORMATION, this.informationId, true)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, INFORMATION);

        if (this.commonService.checkNoneResponse(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );

          // 前画面へ戻る
          this.location.back();
        } else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.location.back();
        } else {
          // お知らせ詳細を格納
          this.detailInformation = response.body;
        }
      });
  }
  moveE001(){
    this.router.navigate(['pages/platform/e001/1']);
  }
}
