<div class="container">
  <div class="progress-container">
    <div
      class="progress"
      id="progress"
      [ngStyle]="{ width: progressWidth }"
    ></div>
    <div class="circle" [ngClass]="{ active: isActiveStep(1) }">
      <span class="first-step"></span>
      <span
        class="first-step-text"
        [ngClass]="{ 'active-text': isActiveText(1) }"
        >団体組織情報入力</span
      >

    </div>
    <div class="circle" [ngClass]="{ active: isActiveStep(2) }">
      <span class="second-step"></span>
      <span
        class="second-step-text"
        [ngClass]="{ 'active-text': isActiveText(2) }"
        >入力内容確認</span
      >
    </div>
    <div class="circle" [ngClass]="{ active: isActiveStep(3) }">
      <span class="third-step"></span>
      <span
        class="third-step-text"
        [ngClass]="{ 'active-text': isActiveText(3) }"
        >確認コード入力</span
      >
    </div>
    <div class="circle" [ngClass]="{ active: isActiveStep(4) }">
      <span class="fourth-step"></span>
      <span
        class="fourth-step-text"
        [ngClass]="{ 'active-text': isActiveText(4) }"
        >完了</span
      >
    </div>
  </div>
</div>
