

<!-- メイン -->
<div class="main" *ngIf="!isLoading">
  <!-- メインコンテンツ -->
  <div class="main-content">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <div
          class="p-fluid p-formgrid p-grid teach-list-item"
          *ngIf="roleAuth == 1 || roleAuth == 9"
        >
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12">
                <h6 class="primary-title bold">【新着】地域からの支援内容</h6>
              </div>
            </div>
          </div>

          <div class="p-col-12 list-function">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-10">
                <div class="p-fluid p-formgrid p-grid">
                  <div
                    class="p-col-3 func-item hover"
                    *ngFor="let item of listAgendaSolutions"
                  >
                    <p-card (click)="moveC331(item?.agenda_solution_agenda_id)" >
                      <div
                        class="p-fluid p-formgrid p-grid func-detail-item center"
                      >
                        <div class="func-item-img">
                          <img
                            [src]="item?.agenda_solution_icon_image"
                            alt="Paris"
                            class="func-img"
                          />
                        </div>
                        <div
                          class="p-col-7 function-item-description separate-line"
                        >
                          <span
                            class="func-name text-sub-size letter-spacing-1"
                            [ngClass]="
                              setClasses(item?.agenda_category_class_name1)
                            "
                            >{{ item?.agenda_category_class_name1 }}</span
                          >
                          <div class="func-desc mt-1 separate-line">
                            <span
                              class="func-description-text text-sub-size letter-spacing-1"
                              [pTooltip]="item?.agenda_solution_title"
                              #tt
                              [tooltipDisabled]="!isOverflow(tt)"
                              showDelay="500"
                              hideDelay="500"
                              >{{ item?.agenda_solution_title }}
                            </span>
                            <span
                              [pTooltip]="item?.member_organization_name"
                              #member_organization_name1
                              [tooltipDisabled]="!isOverflow(member_organization_name1)"
                              showDelay="500"
                              hideDelay="500"
                              class="func-location text-sub-size letter-spacing-1 line-1"
                              >{{ item?.member_organization_name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </p-card>
                  
                  </div>
                </div>
              </div>

              <div class="p-col-2 btn-move">
                <div class="p-fluid p-formgrid p-grid btn-move-wraper">
                  <div
                    class="p-col-10 btn-move-c231 bg-yellow-bold-color center"
                    (click)="moveC231()"
                  >
                    <div class="turn-right">
                      <div class="find-help-text text-white-color center">
                        <h6 class="find-help-text-first letter-spacing-1 bold">
                          もっと
                        </h6>
                        <h6 class="find-help-text-second letter-spacing-1 bold">
                          支援を探す
                        </h6>
                      </div>
                      <div class="turn-right-icon center">
                        <i class="pi pi-arrow-right text-white-color"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="p-fluid p-formgrid p-grid need-list-item"
          *ngIf="roleAuth == 2 || roleAuth == 9"
        >
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12">
                <h6 class="primary-title bold">【新着】学校からの依頼内容</h6>
              </div>
            </div>
          </div>

          <div class="p-col-12 list-function">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-10">
                <div class="p-fluid p-formgrid p-grid">
                  <div
                    class="p-col-3 func-item hover"
                    *ngFor="let item of listAgendaNeeds"
                  >
                    <p-card (click)="moveC341(item?.agenda_need_agenda_id)">
                      <div
                        class="p-fluid p-formgrid p-grid func-detail-item center"
                      >
                        <div class="func-item-img">
                          <img
                            [src]="item?.agenda_need_icon_image"
                            alt="Paris"
                            class="func-img"
                          />
                        </div>

                        <div
                          class="p-col-7 function-item-description separate-line"
                        >
                          <span
                            class="func-name text-sub-size letter-spacing-1"
                            [ngClass]="
                              setClasses(item?.agenda_category_class_name1)
                            "
                            >{{ item?.agenda_category_class_name1 }}</span
                          >
                          <div class="func-desc mt-1 separate-line">
                            <span
                              class="func-description-text text-sub-size letter-spacing-1"
                              [pTooltip]="item?.agenda_need_title"
                              #tt
                              [tooltipDisabled]="!isOverflow(tt)"
                              showDelay="500"
                              hideDelay="500"
                              >{{ item?.agenda_need_title }}
                            </span>
                            <span
                              [pTooltip]="item?.member_organization_name"
                              #member_organization_name2
                              [tooltipDisabled]="!isOverflow(member_organization_name2)"
                              showDelay="500"
                              hideDelay="500"
                              class="func-location text-sub-size letter-spacing-1  line-1"
                              >{{ item?.member_organization_name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </p-card>
                  </div>
                </div>
              </div>

              <div class="p-col-2 btn-move">
                <div class="p-fluid p-formgrid p-grid btn-move-wraper">
                  <div
                    class="p-col-10 btn-move-c231 bg-yellow-bold-color center"
                    (click)="moveC241()"
                  >
                    <div class="turn-right">
                      <div class="find-help-text text-white-color center">
                        <h6 class="find-help-text-first letter-spacing-1 bold">
                          もっと
                        </h6>
                        <h6 class="find-help-text-second letter-spacing-1 bold">
                          依頼を見る
                        </h6>
                      </div>
                      <div class="turn-right-icon center">
                        <i class="pi pi-arrow-right text-white-color"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 list-status">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 p-d-flex">
                <span class="title-new-status bold">進捗状況</span>
                <div class="list-sub-new-status">
                  <p-checkbox
                    name="status"
                    value=""
                    label="すべて"
                    class="status-select"
                    [(ngModel)]="checkAll"
                    (onChange)="checkUncheckAll($event)"
                  ></p-checkbox>
                  <ng-container *ngFor="let status of statusProgress">
                    <p-checkbox
                      name="status"
                      [value]="status.code"
                      [label]="status.name"
                      class="status-select"
                      [(ngModel)]="statusValue"
                      (onChange)="searchChatListCondition()"
                    ></p-checkbox>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 table-detail">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-12">
            <div
              class="p-fluid p-formgrid p-grid bold red-color table-header-row"
            >
              <div class="p-col-3">
                <span class="letter-spacing-2">タイトル</span>
              </div>
              <div class="p-col-2">
                <span class="letter-spacing-2">メッセージ</span>
              </div>
              <div class="p-col-2">
                <span class="letter-spacing-2 center">ステータス</span>
              </div>
              <div class="p-col-2 p-d-flex p-flex-column center">
                <span class="letter-spacing-2 header-ml-15">実施日</span>
              </div>
              <div class="p-col-3">
                <span class="letter-spacing-2 header-ml-15">メモ</span>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <div class="line-separate"></div>
          </div>
          <ng-container *ngFor="let chat of listChatMypage">
            <div class="p-col-12">
              <div class="line-separate-grey"></div>
            </div>
            <div class="p-col-12 content-detail">
              <div class="p-fluid p-formgrid p-grid center">
                <div class="p-col-3">
                  <div class="p-fluid p-formgrid p-grid first-content">
                    <div class="chat-func-img-container">
                      <img
                        [src]="chat?.icon_image"
                        alt="Paris"
                        class="chat-func-img"
                        
                      />
                    </div>

                    <div
                      class="content-detail-first"
                      
                    >
                      <span
                        class="func-name text-sub-size letter-spacing-1"
                        [ngClass]="setClasses(chat?.category)"
                        >{{ chat?.category }}
                      </span>
                      <span
                        (click)="
                          moveScreenByMatchType(chat?.match_id, chat?.match_type)
                        "
                          class="desc-detail tooltip-text title-link"
                          [pTooltip]="item?.agenda_need_title"
                          #tt
                          [tooltipDisabled]="!isOverflow(tt)"
                          showDelay="500"
                          hideDelay="500"
                          >{{ chat?.title }}
                      </span>
                      <div
                        [pTooltip]="chat?.seeds_organization_name"
                        #seeds_organization_name
                        [tooltipDisabled]="!isOverflow(seeds_organization_name)"
                        showDelay="500"
                        hideDelay="500"
                        class="desc-detail line-1"
                        *ngIf="roleAuth == '1' || roleAuth == '9'"
                      >
                        {{ chat?.seeds_organization_name }}
                      </div>
                      <div
                        [pTooltip]="chat?.needs_organization_name"
                        #needs_organization_name
                        [tooltipDisabled]="!isOverflow(needs_organization_name)"
                        showDelay="500"
                        hideDelay="500"
                        class="desc-detail line-1"
                        *ngIf="roleAuth == '2' || roleAuth == '9'"
                      >
                        {{ chat?.needs_organization_name }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-col-2">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-9">
                      <div
                        class="second-desc"
                        [ngClass]="{ redMsg: chat?.count_unread !== '0' }"
                      >
                        <ng-container
                          *ngIf="
                            chat?.chat_member_id_comment == loginUser.member_id;
                            else memberChatOther
                          "
                        >
                          <span class="meChat"> あなた </span>
                        </ng-container>
                        <ng-template #memberChatOther>
                          <span class="second-location">{{
                            chat?.chat_member_organization_name
                          }}</span>
                        </ng-template>

                        <div
                          class="second-detail item-description-large tooltip-text underline"
                          [pTooltip]="chat?.chat_chat_comment"
                          #tt
                          [tooltipDisabled]="!isOverflow(tt)"
                          showDelay="500"
                          hideDelay="500"
                          *ngIf="chat?.chat_chat_comment !== ''"
                          (click)="moveC601(chat?.match_id)"
                        >
                          {{ chat?.chat_chat_comment }}
                        </div>
                      </div>
                    </div>
                    <div class="p-col-3 col-number center">
                      <div class="number" *ngIf="chat.count_unread !== '0'">
                        <ng-container *ngIf="number(chat.count_unread) > 99 ; else norCount">
                          <p-avatar label="99+" shape="circle" styleClass="item-messge-quality"></p-avatar>
                          </ng-container>
                          <ng-template #norCount>
                          <p-avatar [label]="chat.count_unread" shape="circle" styleClass="item-messge-quality"></p-avatar>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-col-2 list-dropdown center">
                  <ng-container
                    *ngIf="
                      chat?.match_status == '1' || chat?.match_status == '4';
                      else elseTemplate
                    "
                  >
                    <div [ngClass]="setClassStatusDropdown(chat.match_status)">
                      <p-dropdown
                        optionLabel="name"
                        [options]="status"
                        optionValue="code"
                        [(ngModel)]="chat.match_status"
                        (onChange)="changeStatus($event, chat?.match_id)"
                      ></p-dropdown>
                    </div>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <div
                      class="statusOther"
                      [ngClass]="setClassStatus(chat?.match_status)"
                    >
                      {{ chat?.match_status_name }}
                    </div>
                  </ng-template>
                </div>
                <div class="p-col-2 list-implement-date p-d-flex p-flex-column p-jc-center">
                  <div class="fit-content">
                    <div class="start-date">
                      {{ chat?.start_implementation_date }}
                    </div>
                    <div class="separate">
                      <div
                        class="separate-icon center"
                        *ngIf="chat?.start_implementation_date"
                      >
                        〜
                      </div>
                    </div>
                    <div class="end-date">
                      {{ chat?.end_implementation_date }}
                    </div>
                  </div>
                </div>
                <div class="p-col-2 notepad">
                  <div class="notepad-text">
                    <span
                      class="first-text-notepad tooltip-text"
                      [pTooltip]="chat?.memo"
                      #memo
                      [tooltipDisabled]="!isOverflow(memo)"
                      showDelay="500"
                      hideDelay="500"
                    >
                      {{ chat?.memo }} </span
                    ><br />
                  </div>
                </div>
                <div class="p-col-1 btn-edit-memo">
                  <button
                    pButton
                    type="button"
                    label="更新"
                    class=""
                    (click)="openMemoDialog(chat?.match_id, chat?.memo)"
                  ></button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirm-reject-match-dialog
  (reject)="cancelConfirm($event)"
  (isSuccess)="changeStatusSuccess($event)"
  (error)="searchChatListCondition()"
  (fail)="searchChatListCondition()"
></app-confirm-reject-match-dialog>
<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>
<app-c331-dialog
  *ngIf="isShowDialogC331"
  (returnStatusDialogC331)="closeDialogC331($event)"
  [matchId]="matchId"
  (reloadData)="searchChatListCondition()"
></app-c331-dialog>
<app-c341-dialog
  *ngIf="isShowDialogC341"
  (returnStatusDialogC341)="closeDialogC341($event)"
  [matchId]="matchId"
  (reloadData)="searchChatListCondition()"
></app-c341-dialog>
<app-memo-dialog (returnMemoDialog)="closeMemoDialog($event)">
</app-memo-dialog>
