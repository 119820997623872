import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  API_URL_DIC_LISTS,
  LOGIN_NOT_AGENDA_SOLUTIONS,
  API_URL_GET_DETAIL_EVALUATION
} from "manager/http-constants";
import { forkJoin, Observable } from "rxjs";
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { CONSTANT, LOADING_KEY } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { Validator } from "src/app/shared/validator/validator";
import { PlatformComponent } from "../../platform.component";
import { DatePipe, Location } from "@angular/common";
import { LoginService } from "src/app/shared/service/login.service";

@Component({
  selector: "app-evaluation-dialog",
  templateUrl: "./evaluation-dialog.component.html",
  styleUrls: ["./evaluation-dialog.component.scss"],
})

/**
 * 共通部品_承認時ダイアログ
 */
export class EvaluationDialogComponent extends PlatformComponent {
  @Output() returnStatusDialog = new EventEmitter<boolean>();
  @Output() reload = new EventEmitter<null>();
  @Input() matchId: string;

  boardFlag: boolean = false;
  roleValue: string;

  data: any[] = [];
  class_id: any = '';

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private formBuilder: FormBuilder,
    private validator: Validator,
    private messageData: MessageData,
    private location: Location,
    private loginService: LoginService
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.getUserLoginInfo();
    this.openRoleDialog();
  }

  public getUserLoginInfo() {
    this.loginService.getLoginUser().subscribe((response) => {
      this.loadingState.loadSleepEnd(0.3);
      if (this.commonService.checkNoneResponse(response)) {
        this.loginService.logout(MESSAGE_CODE.E90000);
        return;
      } else {
        this.class_id = response.body.class_id;
      }
    });
  }

  public openRoleDialog(): void {
    this.boardFlag = true;
  }

  public closeDialog(): void {
    this.boardFlag = false;
    this.returnStatusDialog.emit(this.boardFlag);
  }


  ngAfterViewInit() {
    this.getDetailTask();
  }

  public getDetailTask(): void {
    this.loadingState.loadStart(API_URL_GET_DETAIL_EVALUATION);

    this.dbOperationService
      .getNoTemplateDisplayData(API_URL_GET_DETAIL_EVALUATION, this.matchId)
      .subscribe((response) => {
        this.loadingState.loadSleepEnd(0.3, API_URL_GET_DETAIL_EVALUATION);

        if (this.commonService.checkInvalidValue(response)){
          this.closeDialog();
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
           )
          this.reload.emit();
           return;
        }else if (this.commonService.checkNoneResponse(response)) {
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );

          // this.location.back();
        } else {
          this.data.push(response.body);
        }
      });
  }

  public formatDateJapan(implementation_date: any) {
    let date = new Date(implementation_date);
    let lang = 'en',
    year = date.toLocaleString(lang, {year:'numeric'}),
    month = date.toLocaleString(lang, {month:'numeric'}),
    day = date.toLocaleString(lang, {day:'numeric'});
    return `${year}年 ${month}月 ${day}日`;
  }
}
