import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: LoginService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.credentialsService.getAuth0LoginState()) {
      return true;
    }
    const redirect_uri = window.location.origin + '/' + route.routeConfig.path.replace(':information', route.params.information)
    if (route.queryParams.code) {
      this.router.navigate(['/login'], { queryParams: { redirect: redirect_uri, code: route.queryParams.code }, replaceUrl: true });
    } else {
      if (
        route.routeConfig.path.startsWith('pages/platform/c341') ||
        (route.routeConfig.path.startsWith('pages/platform/c241') && route.queryParams.login !== 'on')
      ) {
        return true
      }
      if (this.checkEndPoint(route)) {
        window.location.href = 'https://sumasapo.metro.tokyo.lg.jp/mypage'
        return false
      }
      window.location.href = 'https://id.sumasapo.metro.tokyo.lg.jp/auth?client_id=y7z6ak7zu5&redirect_uri=' + redirect_uri + '&scope=openid&response_type=code'
    }
    return false;
  }
  checkEndPoint(route: any) {
    const listPathEndPoints = [
      'pages/platform/master/m401',
      'pages/platform/c241',
      'pages/platform/master/m301',
      'pages/platform/c231',
      'pages/platform/c502',
      'pages/platform/c601',
      'pages/platform/master/m501',
      'pages/platform/e001/:information'
    ]
    if (!listPathEndPoints.includes(route.routeConfig.path)) {
      return true
    }
    if (route.routeConfig.path == 'pages/platform/e001/:information' && route.params.information !== '1') {
      return true
    }
  }
}
