import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import {
  CONSTANT,
  LOADING_KEY,
  SCREEN_CONSTANT,
} from 'src/app/shared/constant/constant';
import { DIALOG_DEFAULT_CONSTANT } from '../constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { LoadData, LoadingState } from '../../loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from '../../message-common/message-data';

@Injectable({
  providedIn: 'root',
})
export class DeleteDialogService {
  constructor(
    private confirmationService: ConfirmationService,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) {}

  /**
   * 削除処理
   * @param endPoint REST APIエンドポイント
   * @param codeName コード名
   * @param codeValue コード値
   * @param value 削除文言
   */
  public deleteDialog(
    endPoint: string,
    codeName: string,
    codeValue: string,
    value?: string
  ): Subject<string> {
    // 更新対象ID
    let reloadID = new Subject<string>();

    // 削除ダイアログを起動
    this.confirmationService.confirm({
      message: this.commonService.msg(
        MESSAGE_CODE.D00002,
        codeName,
        codeValue,
        value ? value : DIALOG_DEFAULT_CONSTANT.TXT_DELETE
      ),
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: '',
            background_color: '',
            opacity: 0.3,
          }),
          LOADING_KEY.DELETE
        );

        this.dbOperationService
          .deleteData(endPoint, codeValue)
          .subscribe((response) => {
            // APIレスポンスの判定
            if (this.commonService.checkRunningNormallyResponse(response)) {
              // APIが正常終了の場合

              // 正常メッセージ
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.SUCCESS,
                  summary: this.commonService.msg(
                    MESSAGE_CODE.T00004,
                    codeName,
                    codeValue
                  ),
                  detail: this.commonService.msg(
                    MESSAGE_CODE.D00001,
                    value ? value : DIALOG_DEFAULT_CONSTANT.TXT_DELETE
                  ),
                })
              );
            }else if (this.commonService.checkInvalidValue(response)) {
              // 警告メッセージ
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.WARN,
                  summary: this.commonService.msg(MESSAGE_CODE.E00003),
                  detail: this.commonService.msg(MESSAGE_CODE.E00026),
                })
              );
            } else {
              // APIが異常終了の場合

              // 異常メッセージ
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.ERROR,
                  summary: this.commonService.msg(
                    MESSAGE_CODE.T00004,
                    codeName,
                    codeValue
                  ),
                  detail: this.commonService.msg(MESSAGE_CODE.E00005),
                })
              );
            }

            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.DELETE);

            // 親画面に更新対象IDを設定
            reloadID.next(codeValue);
          });
      },
    });

    // 親画面に更新対象IDを設定
    return reloadID;
  }

  /**
   * 削除処理（画面上出すコード値、コード名と主キーが異なる場合）
   * @param endPoint REST APIエンドポイント
   * @param dispCodeArray 表示するコード名とコード値のリスト
   * @param pKey 主キー
   */
  public deleteByPkeyDialog(
    endPoint: string,
    dispCodeArray: { codeName: string; codeValue: string }[],
    pkey: string
  ) {
    // 更新対象ID
    let reloadID = new Subject<string>();

    const message = dispCodeArray.map(
      (one) => one.codeName + CONSTANT.COLON + one.codeValue
    );

    // 削除ダイアログを起動
    this.confirmationService.confirm({
      message: this.commonService.msg(
        MESSAGE_CODE.D00003,
        message.join(SCREEN_CONSTANT.LINE_CODE)
      ),
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: '',
            background_color: '',
            opacity: 0.3,
          }),
          LOADING_KEY.DELETE
        );

        this.dbOperationService
          .deleteData(endPoint, pkey)
          .subscribe((response) => {
            // APIレスポンスの判定
            if (this.commonService.checkRunningNormallyResponse(response)) {
              // APIが正常終了の場合

              // 正常メッセージ
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.SUCCESS,
                  summary: message.join(CONSTANT.HYPHEN),
                  detail: this.commonService.msg(MESSAGE_CODE.D00001),
                })
              );
            } else {
              // APIが異常終了の場合

              // 異常メッセージ
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.ERROR,
                  summary: message.join(CONSTANT.HYPHEN),
                  detail: this.commonService.msg(MESSAGE_CODE.E00005),
                })
              );
            }

            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.DELETE);

            // 親画面に更新対象IDを設定
            reloadID.next(pkey);
          });
      },
    });

    // 親画面に更新対象IDを設定
    return reloadID;
  }

  /**
   * キャンセル処理
   * @param endPoint REST APIエンドポイント
   * @param codeName コード名
   * @param codeValue コード値
   * @param updateValue 更新情報
   */
  public cancelDialog(
    endPoint: string,
    codeName: string,
    codeValue: string,
    updateValue?: object
  ) {
    // 更新対象ID
    let reloadID = new Subject<string>();

    // キャンセルダイアログを起動
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.U00004, codeName, codeValue),
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: '',
            background_color: '',
            opacity: 0.3,
          }),
          LOADING_KEY.CANCEL
        );

        this.dbOperationService
          .updateData(endPoint, codeValue, updateValue)
          .subscribe((response) => {
            // APIレスポンスの判定
            if (this.commonService.checkRunningNormallyResponse(response)) {
              // APIが正常終了の場合

              // 正常メッセージ
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.SUCCESS,
                  summary: this.commonService.msg(
                    MESSAGE_CODE.T00004,
                    codeName,
                    codeValue
                  ),
                  detail: this.commonService.msg(MESSAGE_CODE.U00003),
                })
              );
            } else {
              // APIが異常終了の場合

              // 異常メッセージ
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.ERROR,
                  summary: this.commonService.msg(
                    MESSAGE_CODE.T00004,
                    codeName,
                    codeValue
                  ),
                  detail: this.commonService.msg(MESSAGE_CODE.E00006),
                })
              );
            }

            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.CANCEL);

            // 親画面に更新対象IDを設定
            reloadID.next(codeValue);
          });
      },
    });

    // 親画面に更新対象IDを設定
    return reloadID;
  }
}
