import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';

@Component({
  selector: 'app-a211',
  templateUrl: './a211.component.html',
  styleUrls: ['./a211.component.scss', '../platform.component.scss'],
})

/**
 * パスワード設定メール送付完了
 */
export class A211Component extends PlatformComponent {
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}
}
