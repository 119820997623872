import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CONSTANT } from 'src/app/shared/constant/constant';
import { A230_STEPS_ITEMS } from './a230-steps-constants';

@Component({
  selector: 'app-a230-steps',
  templateUrl: './a230-steps.component.html',
})

/**
 * A230系共通部品_Steps
 */
export class A230StepsComponent implements OnInit {
  // Steps項目一覧
  stepsItems: object[] = A230_STEPS_ITEMS;

  // アクティブインデックス
  activeIndex: number = 0;

  constructor(private readonly _router: Router) {
    this._router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((event) => {
        // Steps項目活性化を設定
        this.cangeSteps(event);
      });
  }

  ngOnInit(): void {}

  /**
   * Steps項目活性化を設定
   * @param event コンポーネント情報
   */
  private cangeSteps(event) {
    // URLを取得
    const path: string = event.snapshot.routeConfig.path;

    // クエリパラメータを取得
    const queryParam: any = event.snapshot.queryParams;

    // 現在urlを判定
    if (path.includes('pages/platform/a232') && 'a232' == queryParam.screenId) {
      // 入会申請編集画面(入力)(a232)の場合

      // 登録・更新内容入力を活性化
      this.activeIndex = 0;
    } else if (
      path.includes('pages/platform/a232') &&
      'a233' == queryParam.screenId
    ) {
      // 入会申請編集確認画面(確認)(A233)の場合

      // 登録・更新内容確認を活性化
      this.activeIndex = 1;
    } else if ('pages/platform/a234' == path) {
      // 入会申請完了画面(A234)の場合

      // 登録・更新完了を活性化
      this.activeIndex = 2;
    }
  }
}
