import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { API_URL_DIC_LISTS, UNAPPROVE_APPLICATION_LIST } from 'manager/http-constants';
import { CONSTANT, DICID_NUMBER } from 'src/app/shared/constant/constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { PlatformComponent } from '../../platform.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-d211',
  templateUrl: './d211.component.html',
  styleUrls: [
    '../secretariat-list.component.scss',
    '../../platform.component.scss',
  ],
})

/**
 * 入会申請一覧
 */
export class D211Component extends PlatformComponent {
  // 画面.検索フォーム
  secretariatSearchForm: FormGroup = this.formBuilder.group({
    //ステータス・ シングルセレクト
    status: '1',
   
    //class_id 
    class_id: ''
  });

  /** テーブル状態 */
  @ViewChild('table') table: Table;

  //ステータス取得格納先
  statusList: object[] = [
    {
      sp_name: '未承認',
      sp_code: '1',
    },
    {
      sp_name: '承認',
      sp_code: '3',
    }
  ];

  membershipStatusType: any[]= [];

  // 検索結果一覧表示
  resultTaskDataList: object[] = new Array();

  // 件数格納先
  countTaskData: number = 0;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
     // 辞書値リストを取得
     this.getSpecCodeList(DICID_NUMBER.GROUP_TYPE);
    // 提案結果を取得
    this.getResultTaskDataList();
  
  }

  /**
   * 課題・ソリューション結果を取得
   * @param secretariatSearchForm 検索条件
   */
  public getResultTaskDataList(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(UNAPPROVE_APPLICATION_LIST);
    for(let key in this.secretariatSearchForm.value){
      if(this.secretariatSearchForm.value[key] === null){
      console.log(this.secretariatSearchForm[key])
        this.secretariatSearchForm.controls[key].setValue(CONSTANT.EMPTY_STRING); 
      }
    }
    // 入会申請結果を取得
    this.dbOperationService
      .getNoTemplateData(
        UNAPPROVE_APPLICATION_LIST,
        this.secretariatSearchForm.value,
        false
      )
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          // 入会申請検索結果結果を格納
          this.resultTaskDataList = response.body;
        } else {
          this.resultTaskDataList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, UNAPPROVE_APPLICATION_LIST);
      });

    // テーブル状態が存在するか否か
    if (this.table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      this.table.reset();
    }
  }

  /**
   * 入会申請詳細(A231)へ遷移する
   * @param application_id 申請ID
   */
  public moveA231(application_id: string): void {
    // 入会申請詳細(A231)へ遷移
    this.router.navigate(['pages/platform/a231/' + application_id]);
  }

 
   getSpecCodeList(...dicId: any[]) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_DIC_LISTS);

    // 全辞書情報取得処理
    this.dbOperationService.getDicList(dicId).subscribe((response) => {
      // 全辞書情報が存在するか否か
      if (!this.commonService.checkNoneResponse(response)) {
        // 辞書情報に存在する場合

        // 辞書情報を格納
        this.membershipStatusType = this.membershipStatusType.concat(response.body);

      } else {
        // 辞書情報に存在しない場合

        this.membershipStatusType = null;
      }

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3, API_URL_DIC_LISTS);
    });
  }
  /**
   * check overflow of element
   * @param el html element of list 
   * @returns 
   */
      isOverflow(el: HTMLElement): boolean {
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === "visible")
          el.style.overflow = "hidden";
        var isOverflowing = el.clientWidth < el.scrollWidth
          || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
      }
}
