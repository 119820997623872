<div class="header p-d-flex">
  <div class="main">
    <div class="main-content">
      <div class="p-fluid p-formgrid p-grid header-content">
        <div
          class="p-col-2 header-title-large"
          (click)="changeUrl()"
        >
          <img
            class="header-text-title"
            src="../../../../assets/images/logo-sumasapo.svg"
            />
        </div>

        <div class="card p-col-8 header-content-menu" *ngIf="!checkLanding()">
          <p-tabMenu
            [model]="menuList"
            class="tab-menu"
            [activeItem]="activeItem2"
            id="listmenu"
            *ngIf="checkShowMenu()"
          >
            <ng-template
              pTemplate="item"
              let-item
              let-i="index"
              class="tab-template"
            >
              <ng-container *ngIf="item.changePage; else oneColumn">
                <div
                  [url]="item.link"
                  [ngClass]="isActive(item) ? 'active' : ''"
                  class="text-white-color"
                >
                  <span class="text-header">{{ item?.title1 }}</span>
                </div>       
              </ng-container>
              <ng-template #oneColumn>
                <div
                  [routerLink]="item.link"
                  [ngClass]="isActive(item) ? 'active' : ''"
                  class="text-white-color"
                >
                <span class="text-header">{{ item?.title1 }}</span>
                </div>    
              </ng-template>
            </ng-template>
          </p-tabMenu>
        </div>

        <div
          class="p-col-2 header-list"
          *ngIf="checkShowMenu()"
        >
          <div class="p-fluid p-formgrid p-grid header-content-grid">

            <div class="p-col-6 notice">
              <span *ngIf="class_id=='1'" class="header-avatar mr-3" (click)="onDisplayProfile()" #avatarContainer></span>

              <i
                class="fa-solid fa-bell notice-icon" 
                (click)="openNotificationDialog($event)">
              </i>
              <div class="badge" *ngIf="unread_notification > 0">{{unread_notification <= 9?unread_notification:'9+'}}</div>
            </div>
            <div class="p-col-6 list">
              <button
                #btn
                type="button"
                class="btn-info-sub-menu"
                pButton
                icon="pi pi-bars"
                (click)="menu.toggle($event)"
              ></button>
              <p-tieredMenu
                #menu
                [model]="items"
                [popup]="true"
                class="position: relative;"
              ></p-tieredMenu>
            </div>
          </div>
        </div>
        
        <div class="card p-col-6 header-content-menu" *ngIf="checkLanding()"></div>
        <div
          class="p-col-4 header-list jc-end"
          *ngIf="checkLanding()"
        >
          <!-- <button
            pButton
            type="button"
            label="新規会員登録"
            class="p-button-text header-button-text header-button-text-landing"
            (click)="login()"
          ></button> -->
          <button
            pButton
            type="button"
            label="ログイン"
            class="p-button-text header-button-text header-button-text-landing button-login"
            (click)="goToMyPage()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-notification-dialog (reCount)="getCount()"></app-notification-dialog>