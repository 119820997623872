<div class="main">
    <!-- メインコンテンツ -->
    <div class="main-content">
      <!-- タイトル -->
			<div class="page-title">
					<label class="name">「まちスク 会津」とは？
					</label>
			</div>
			<div class="service-detail p-col-12 p-mb-3 card">
					<p>　「まちスク　会津」は、職場体験、プログラミング授業などの地域の学校の持つ支援ニーズと、地域企業・人材が支援可能な内容をそれぞれを登録し、マッチングを行うサービスです。 学校支援に関する学校側と地域・人材の双方の情報を見える化することで、事前リサーチや手続きの負荷の軽減するとともに、地域企業・人材の学校教育への参画の促進、地域全体で子ども達の成長を支援する機会を創出していきます。 また、学校支援を通じて地域と子ども達のコミュニケーション機会を増やすことで、地域への理解、魅力発見などに繋げます。
									</p>
					<p>
							<span *ngIf="mode == 1">　※地域企業・人材が登録する支援内容はページ上部の「地域の支援内容を見る」よりご覧いただけます。</span>
							<span *ngIf="mode == 0">　※本サービスは2023年4月～2024年3月の期間、NPO法人 会津エンジンによるサービス運営のもと、サービスの実証運用を行っております。</span>
					</p>
			
			</div>
			<div class="service-image p-d-flex p-jc-center p-mb-3 p-col-12 card">
					<img src="https://edumatching-file.s3.ap-northeast-1.amazonaws.com/common/landing-page/landing-page.jpg">
			</div>
			<div *ngIf="mode == 0" class="trade-mark p-col-12 card p-mb-3">
					「まちスク」／「まちスク」ロゴは凸版印刷が商標登録出願中です。
			</div>
			<div *ngIf="mode == 1" class="list p-col-12 card p-mb-3 p-d-flex p-jc-center">
							登録企業一覧<br>
							登録学校一覧<br>
							協賛・連携企業バナー<br>
							市のイベント情報等の掲載
			</div>
    </div>
</div>
<div class="footer">
    <div class="p-col-12 head p-d-flex">
        <ng-container *ngIf="mode ==0">
            <div class="p-col-5">

            </div>
            <div class="p-col-5">
                <button
                    pButton
                    type="button"
                    label="プライバシーポリシー"
                    class="p-button-text"
                    (click)="null"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="マニュアル"
                    class="p-button-text"
                    (click)="null"
                ></button>
                |
                <button
                    pButton
                    type="button"
                    label="お問い合わせ"
                    class="p-button-text"
                    (click)="moveContact()"
                ></button>
            </div>
        </ng-container>
        <ng-container *ngIf="mode ==1">
            <div class="p-col-12 p-d-flex p-jc-center pd-0">
                <div class="p-col-4 p-jc-between p-d-flex pd-0">
                    <button
                        pButton
                        type="button"
                        label="お問い合わせ"
                        class="p-button-text"
                        (click)="moveContact()"
                    ></button>
                    |
                    <button
                        pButton
                        type="button"
                        label="利用規約"
                        class="p-button-text"
                        (click)="null"
                    ></button>
                    |
                    <button
                        pButton
                        type="button"
                        label="プライバシーポリシー"
                        class="p-button-text"
                        (click)="null"
                    ></button>
                </div>
            </div>
        </ng-container>
    </div>
        
    <div class="p-col-12 body p-grid p-m-0">
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">運営団体：NPO法人 会津エンジン</div></div>
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">〒965-0042</div></div>
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">福島県会津若松市大町1-1-41（株式会社ミンナノチカラ内）</div></div>
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">0242-85-6274</div></div>
        <div class="p-col-12 p-d-flex cp-0"><div class="p-col-5"></div><div class="p-col-5">受付時間：平日9時～17時</div></div>
    </div>
</div>