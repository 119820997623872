import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss', '../platform.component.scss']
})
export class LandingComponent implements OnInit {
  isShowContact = false;
  mode:number = 0;
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  moveContact(){
    this.router.navigate(["pages/platform/contact"]);
  }
}
