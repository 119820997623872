import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {} from "manager/http-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { CONSTANT, LOADING_KEY } from "src/app/shared/constant/constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import { MessageData, ToastMessageData } from "src/app/shared/html-parts/message-common/message-data";

@Component({
  providers: [ConfirmationService],
  selector: "app-confirm-reject-match-dialog",
  templateUrl: "./confirm-reject-match-dialog.component.html",
  styleUrls: ["./confirm-reject-match-dialog.component.scss"],
})
export class ConfirmRejectMatchDialogComponent implements OnInit {
  constructor(
    private confirmationService: ConfirmationService,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageService: MessageService,
    private loadingState: LoadingState,
    private messageData: MessageData
  ) {}
  ngOnInit() {}
  @Output() isSuccess = new EventEmitter<boolean>();
  @Output() reject = new EventEmitter<string>();
  @Output() numMsgEdit = new EventEmitter<number>();
  @Output() deletedId = new EventEmitter<number>();
  @Output() error = new EventEmitter<null>();
  @Output() fail = new EventEmitter<null>();
  
  /**
   * 削除処理
   * @param endPoint REST APIエンドポイント
   * @param codeName コード名
   * @param codeValue コード値
   */
  public updateMatchStatus(endPoint: string, matchId: any, status: any) {
    let mess = ''
    if (status === '1') {
      mess = this.commonService.msg(MESSAGE_CODE.D00011)
    } else if (status === '2') {
      mess = this.commonService.msg(MESSAGE_CODE.D00012)
    } else if (status === '3') {
      mess = this.commonService.msg(MESSAGE_CODE.D00013)
    } else {
      this.acceptUpdateMatchStatus(endPoint, matchId, status)
      return
    }
    this.confirmationService.confirm({
      message: mess,
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: "pi pi-info-circle",
      accept: () => this.acceptUpdateMatchStatus(endPoint, matchId, status),
      reject: () => {
        this.reject.emit(matchId);
      },
    });
  }

  public acceptUpdateMatchStatus(endPoint: string, matchId: any, status: any) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_UPDATE
    );

    this.dbOperationService
      .updateData(endPoint, matchId, { status: status })
      .subscribe((response) => {
        // APIレスポンスの判定
        if (this.commonService.checkRunningNormallyResponse(response)) {
          // APIが正常終了の場合
          this.isSuccess.emit(matchId);

          // 正常メッセージ
          this.messageService.add({
            severity: TOAST.SUCCESS,
            summary: this.commonService.msg(MESSAGE_CODE.T00002),
          });
        }else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.error.emit();
        } else {
          // APIが異常終了の場合

          // 異常メッセージ
          this.messageService.add({
            severity: TOAST.ERROR,
            summary: this.commonService.msg(MESSAGE_CODE.E00002),
            detail: this.commonService.msg(MESSAGE_CODE.E90006),
          });
          this.fail.emit();  
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_UPDATE);

        // 親画面に更新対象IDを設定
      });
  }

  /**
   * Cancel editing mode for messages
   */
  public cancelEditMsg() {
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.D00009),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: "pi pi-info-circle",
      accept: () => {
        this.numMsgEdit.emit(null);
      },
      reject: () => {},
    });
  }

  /**
   * confirm delete messages
   */
   public deleteMessage(index: number, chatId: any, endPoint) {
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.D00010),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: "pi pi-info-circle",
      accept: () => {
       // 画面カスタムロードフラグをON(ロード中状態)
       this.loadingState.customLoadStart(
        new LoadData({
          loadingText: CONSTANT.EMPTY_STRING,
          background_color: CONSTANT.EMPTY_STRING,
          opacity: 0.3,
        }),
        LOADING_KEY.DATA_UPDATE
      );

      this.dbOperationService
        .deleteData(endPoint, chatId)
        .subscribe((response) => {
          // APIレスポンスの判定
          if (this.commonService.checkInvalidValue(response)) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.error.emit();
          } else if (this.commonService.checkRunningNormallyResponse(response)) {
            // APIが正常終了の場合
            this.deletedId.emit(index);
            // 正常メッセージ
            this.messageService.add({
              severity: TOAST.SUCCESS,
              summary: this.commonService.msg(MESSAGE_CODE.T00002),
            });
          } else {
            // APIが異常終了の場合

            // 異常メッセージ
            this.messageService.add({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00002),
              detail: this.commonService.msg(MESSAGE_CODE.E00006),
            });
          }
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.DATA_UPDATE);

          // 親画面に更新対象IDを設定
        });
      },
      reject: () => {},
    });
  }
}
