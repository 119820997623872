import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LOADING_DEFAULT_CONSTANT } from './constant';

/**
 * ローディング状態クラス
 */
@Injectable()
export class LoadingState {
  // ローディングフラグ
  public loadingFlag: boolean = false;

  // ロード情報(テキストや色など)
  public loadData: LoadData = new LoadData();

  // ローディングキーリスト
  // (キーが設定されている場合、ローディング終了時に同一ローディングキーを設定した場合のみ、解除可能)
  public loadingKeyList: string[] = new Array();

  /**
   * ローディング開始
   * @param loadingKey ローディングキー
   */
  public loadStart(loadingKey?: string): void {
    // 引数.ローディングキーをローディングキーリストに設定
    this.loadingKeyList.push(loadingKey);

    // ローディング中の場合、デフォルトローディング情報は格納しない
    if (!this.loadingFlag) {
      // デフォルトロード情報を格納
      this.loadData = new LoadData();
    }

    // ローディング開始
    this.loadingFlag = true;
  }

  /**
   * カスタムローディング開始
   * @param loadData カスタムローディング用オブジェクト(内包要素でローディングをカスタムする)
   * @param loadingKey ローディングキー
   * ※ ローディング開始 or カスタムローディング開始 が複数同一タイミングで実施された場合、
   * 最初のローディングが優先されます
   */
  public customLoadStart(loadData: LoadData, loadingKey?: string): void {
    // 引数.ローディングキーをローディングキーリストに設定
    this.loadingKeyList.push(loadingKey);

    // カスタムロードオブジェクトを格納
    // ローディング中の場合、カスタムローディング情報は格納しない
    if (!this.loadingFlag) {
      this.loadData = loadData;
    }

    // ローディング開始
    this.loadingFlag = true;
  }

  /**
   * ローディングタイマー開始
   * @param time スリープ時間(秒で設定)
   * @param loadingKey ローディングキー
   * ※注意 ローディングタイマー開始を行う場合、処理実行時間を考慮の上お使いください
   * 処理実行順番でローディング終了→ローディングタイマー開始で行われた場合、
   * ローディングで画面が止まります
   */
  public loadSleepStart(time: number, loadingKey?: string): void {
    // スリープ時間(ミリ秒)が未設定の場合
    if (!time) {
      // ローディング開始
      this.loadStart(loadingKey);
    }

    // sleep時間を設定(設定された秒をミリ秒に変換)
    const sleep = of('').pipe(delay(Math.floor(time * 1000)));
    // sleep時間後処理を実施
    sleep.subscribe(() => {
      // ローディング開始
      this.loadStart(loadingKey);
    });
  }

  /**
   * カスタムローディングタイマー開始
   * @param time スリープ時間(秒で設定)
   * @param loadData ローディング用オブジェクト(内包要素でローディングをカスタムする)
   * @param loadingKey ローディングキー
   */
  public customLoadSleepStart(
    time: number,
    loadData: LoadData,
    loadingKey?: string
  ): void {
    // スリープ時間(ミリ秒)が未設定の場合
    if (!time) {
      // カスタムローディング開始
      this.customLoadStart(loadData, loadingKey);
    }

    // sleep時間を設定(設定された秒をミリ秒に変換)
    const sleep = of('').pipe(delay(Math.floor(time * 1000)));
    // sleep時間後処理を実施
    sleep.subscribe(() => {
      // カスタムローディング開始
      this.customLoadStart(loadData, loadingKey);
    });
  }

  /**
   * ローディング終了
   * @param loadingKey ローディングキー
   */
  public loadEnd(loadingKey?: string): void {
    // ローディングキーリストから引数.ローディングキーを削除
    this.loadingKeyList = this.loadingKeyList.filter(
      (key) => key !== loadingKey
    );

    // ローディングキーリストが設定されているか否か
    if (!this.loadingKeyList.length) {
      // ローディングキーリストが設定されていない場合

      // ローディング終了
      this.loadingFlag = false;
    }
  }

  /**
   * ローディングタイマー終了
   * @param time スリープ時間(秒で設定)
   * @param loadingKey ローディングキー
   */
  public loadSleepEnd(time: number, loadingKey?: string): void {
    // スリープ時間(ミリ秒)が未設定の場合
    if (!time) {
      // ローディング終了
      this.loadEnd(loadingKey);
    }

    // sleep時間を設定(設定された秒をミリ秒に変換)
    const sleep = of('').pipe(delay(Math.floor(time * 1000)));
    // sleep時間後処理を実施
    sleep.subscribe(() => {
      // ローディング終了
      this.loadEnd(loadingKey);
    });
  }

  /**
   * ローディング強制終了
   */
  public loadForcedEnd(): void {
    // ローディングキーリストを全て削除
    this.loadingKeyList.splice(0);

    // ローディング終了
    this.loadingFlag = false;
  }
}

/** ローディングオブジェクト */
export class LoadData {
  // ローディングテキスト(不変)
  // 文字をなくす場合は、空文字を指定
  private _loadingText: string = LOADING_DEFAULT_CONSTANT.loadingText;

  // ローディングテキスト(変動)
  // 文字をなくす場合は、空文字を指定
  // 注:ローディングテキスト(変動)は処理で生成されるため、
  // ローディングテキスト(変動)が変更された場合、0.3秒待ってから再度変更or初期デフォルトを使用してください
  private _changeText: string = LOADING_DEFAULT_CONSTANT.changeText;

  // テキストカラー
  // HTMLカラーコード or 色名で設定
  private _color: string = LOADING_DEFAULT_CONSTANT.color;

  // 背景カラー
  // HTMLカラーコード or 色名で設定
  private _background_color: string = LOADING_DEFAULT_CONSTANT.background_color;

  // 背景透明度
  // 0（完全に透明）~ 1（完全に不透明）で設定
  private _opacity: number = LOADING_DEFAULT_CONSTANT.opacity;

  constructor(init?: Partial<LoadData>) {
    Object.assign(this, init);
  }

  set loadingText(loadingText: string) {
    this._loadingText = loadingText;
  }

  get loadingText(): string {
    return this._loadingText;
  }

  set changeText(changeText: string) {
    this._changeText = changeText;
  }

  get changeText(): string {
    return this._changeText;
  }

  set color(color: string) {
    this._color = color;
  }

  get color(): string {
    return this._color;
  }

  set background_color(background_color: string) {
    this._background_color = background_color;
  }

  get background_color(): string {
    return this._background_color;
  }

  set opacity(opacity: number) {
    this._opacity = opacity;
  }

  get opacity(): number {
    return this._opacity;
  }
}
