<p-dialog
  [(visible)]="boardFlag"
  [modal]="true"
  [responsive]="true"
  [styleClass]="'main-dialog'"
  [minY]="70"
  [baseZIndex]="10000"
  (onHide)="closeDialog()"
>
<ng-template pTemplate="header">
  <p class="p-d-flex header-date">実施日を入力してください</p>
</ng-template>
  <form [formGroup]="dateInputForm" class="form-date">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-3">
            <span class="from-date">開始日</span>
          </div>
          <div class="p-col-9">
            <p-calendar
              [showIcon]="true"
              inputId="icon"
              [yearNavigator]="true"
              [monthNavigator]="true"
              [readonlyInput]="true"
              formControlName="start_implementation_date"
              placeholder=""
              dateFormat="yy/mm/dd"
              dataType="string"
              appendTo="body"
              yearRange="2000:2999"
              translate
            ></p-calendar>
          </div>
        </div>
      </div>
      <div class="p-col-12 date-to">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-3">
            <span class="from-date">終了日 </span>
          </div>
          <div class="p-col-9">
            <p-calendar
              [showIcon]="true"
              inputId="icon"
              [yearNavigator]="true"
              [monthNavigator]="true"
              [readonlyInput]="true"
              formControlName="end_implementation_date"
              placeholder=""
              dateFormat="yy/mm/dd"
              dataType="string"
              appendTo="body"
              yearRange="2000:2999"
              translate
            ></p-calendar>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <button
       pButton
      (click)="updateDateTime()"
      label="登録する"
      [disabled]="isvalidForm()"
    ></button>
  </ng-template>
</p-dialog>
<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>