import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';

@Component({
  selector: 'app-c703',
  templateUrl: './c703.component.html',
  styleUrls: ['./c703.component.scss', '../platform.component.scss'],
})

/**
 * マッチング提案登録完了
 */
export class C703Component extends PlatformComponent {
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * マイページ画面(B301)へ遷移する
   */
  public moveB301(): void {
    // マイページ画面(B301)へ遷移する
    this.router.navigate(['pages/platform/b301']);
  }
}
