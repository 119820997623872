/**
 * 設定テンプレートID
 */

/**
 * マスタ管理
 */

// 会員プロフィールマスタ画面
// export const MEMBER_TEMPLATE = {
//   // 検索項目生成テンプレートID
//   SEARCH_TEMPLATE_ID: 2709,
//   // 一覧項目テンプレートID
//   SEARCH_RESULTS_TEMPLATE_ID: 2704,
//   // 表示項目生成テンプレートID
//   OUTPUT_TEMPLATE_ID: 1403,
//   // CSV出力テンプレートID
//   CSV_TEMPLATE_ID: 2714,
// };

// 課題・ソリューションマスタ画面
export const AGENDA_SOLUTION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2708,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2703,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2713,
};

// 提案マスタ画面
export const SUGGEST_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2710,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2705,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2715,
};

// 会員主催イベントマスタ画面
export const MEMBER_EVENT_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2707,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2702,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2712,
};

// 分科会マスタ画面
export const SUBCOMMITTEE_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2706,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2701,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2711,
};

/** 団体マスタ画面 */
export const ORGANIZATION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1401,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1402,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1404,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 1405,
  // 入力項目生成(編集)テンプレートID
  INPUT_EDIT_TEMPLATE_ID: 1406,
};

/** ユーザーマスタ画面 */
export const USER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1401,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1402,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1404,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 1405,
  // 入力項目生成(編集)テンプレートID
  INPUT_EDIT_TEMPLATE_ID: 1406,
};

/** 教育支援内容マスタ画面 */
export const TEACHING_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1401,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1402,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1404,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 1405,
  // 入力項目生成(編集)テンプレートID
  INPUT_EDIT_TEMPLATE_ID: 1406,
};

/** お知らせマスタ画面 */
export const INFORMATION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2801,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2802,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1503,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1504,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 2805,
};

/** 組織マスタ画面 */
export const MEMBER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1301,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1302,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1303,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1304,
  // 入力項目生成(新規,複製)テンプレートID
  INPUT_TEMPLATE_ID: 1305,
  // 入力項目生成(編集)テンプレートID
  INPUT_EDIT_TEMPLATE_ID: 1306,
};

export const ITEM_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1104,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 1105,
  // 在庫管理用表示項目生成テンプレートID
  STOCK_OUTPUT_TEMPLATE_ID: 1108,
};

// 課題・ソリューションマスタ画面
export const AGENDA_SOLUTION_M301_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 3001,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 3002,
   // 入力項目生成(新規,複製)テンプレートID
   INPUT_TEMPLATE_ID: 3005,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 3003,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 3004,

  SEARCH_C231_RESULTS_TEMPLATE_ID: 3008,
};
// master user
export const USER_M201_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 3201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 3202,
   // 入力項目生成(新規,複製)テンプレートID
   INPUT_TEMPLATE_ID: 3205,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 3203,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 3204,
};

// 課題・ソリューションマスタ画面
export const AGENDA_SOLUTION_M401_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 3101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 3102,
   // 入力項目生成(新規,複製)テンプレートID
   INPUT_TEMPLATE_ID: 3105,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 3103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 3104,
  
  SEARCH_C241_RESULTS_TEMPLATE_ID: 3108,
  EDIT_TEMPLATE_ID: 3106,
};
// Assignment / organization master screen
export const ORGANIZATION_M101_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 3301,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 3302,
   // 入力項目生成(新規,複製)テンプレートID
   INPUT_TEMPLATE_ID: 3305,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 3303,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 3304,
};
// Information master screen
export const INFORMATION_M501_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1501,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1502,
   // 入力項目生成(新規,複製)テンプレートID
   INPUT_TEMPLATE_ID: 1505,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1503,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1504,
};
