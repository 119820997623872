import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL_MULTIVALIDATION_RESULT } from 'manager/http-constants';
import { CommonService } from '../service/common.service';
import { CONSTANT } from '../constant/constant';

@Injectable({
  providedIn: 'root',
})

/**
 * アイテムアダプター専用_サービスクラス
 */
export class GenerateService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 検索項目生成
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @returns JSONデータ
   */
  getGenerateSearch(endPoint: string, templateId: number): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, '?TemplateID=', templateId);

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 表示項目生成
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   * @param duplicate 複製処理(入力項目生成(複製)の場合、true)
   * @returns JSONデータ
   */
  getGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string,
    duplicate?: boolean
  ): Observable<any> {
    // LambdaのEndpoint格納先を宣言
    let url;

    // 入力項目生成(複製)処理か否か
    if (duplicate) {
      // 入力項目生成(複製)処理の場合

      // 表示項目生成(複製)のEndpointを生成
      url = this.commonService.url(
        endPoint,
        'duplicate/',
        pkeyId,
        '?TemplateID=',
        templateId
      );
    } else {
      // 表示項目生成のEndpointを生成
      url = this.commonService.url(
        endPoint,
        pkeyId,
        '?TemplateID=',
        templateId
      );
    }

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 入力項目生成
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param inputQueryParameters 入力項目生成時の絞り込み条件
   * @returns JSONデータ
   */
  getGenerateInput(
    endPoint: string,
    templateId: number,
    inputQueryParameters: any
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      endPoint,
      '?TemplateID=',
      templateId,
      // 入力項目生成時の絞り込み条件が存在するか否か
      inputQueryParameters
        ? // 入力項目生成時の絞り込み条件が存在する場合
          CONSTANT.AMPERSAND +
            new URLSearchParams(inputQueryParameters).toString()
        : // 入力項目生成時の絞り込み条件が存在しない場合
          CONSTANT.EMPTY_STRING
    );

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 入力項目値バリデーションチェック
   * @param tableId テーブル物理名
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  multiValidationResult(tableId: string, inputItem: object): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_MULTIVALIDATION_RESULT, tableId);

    // 取得データの返却
    return this.http
      .post(url, inputItem, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 業務入力項目値バリデーションチェック
   * @param endPoint 業務入力項目値バリデーションチェックエンドポイント
   * @param pkeyId 対象データのID
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  workValidationResult(
    endPoint: string,
    pkeyId: string,
    inputItem: object
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, pkeyId);

    // 取得データの返却
    return this.http
      .post(url, inputItem, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DB登録を実施
   * @param endPoint REST APIエンドポイント
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  insert(endPoint: string, inputItem: any): Observable<any> {
    // 登録結果を返却する
    return this.http
      .post(this.commonService.url(endPoint), inputItem, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DB更新を実施
   * @param endPoint REST APIエンドポイント
   * @param pkeyId 対象データのID
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  update(endPoint: string, pkeyId: string, inputItem: any): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, pkeyId);

    // 更新結果を返却する
    return this.http
      .put(url, inputItem, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * response結合(バリデーションチェック)
   * 非同期同時実行、レスポンスのバリデーションチェックで使用
   * @param responseList レスポンス情報
   * @returns response レスポンス結合情報
   */
  public JoinValidationResponseList(responseList: any[]): any {
    // レスポンス情報が存在するか否か
    if (!responseList.length) {
      // レスポンス情報が存在しない場合

      return null;
    }

    // レスポンス用返却用オブジェクトを生成
    let response: any = {
      // HTTPステータス
      status: 200,
      // 内部情報
      body: [
        {
          result: 0,
          message: new Object(),
          value: new Object(),
        },
      ],
    };
    console.log("List", responseList)
    // response分ループ
    for (const responseData of responseList) {
      // レスポンスのHTTPステータスを判定
      if (200 != responseData.status) {
        // HTTPステータスが200以外の場合

        // レスポンスのHTTPステータスを格納
        response.status = responseData.status;
      }
      // レスポンス返却値のresultが成功か否か
      if (0 != responseData.body.result) {
        // レスポンス返却値のresultが成功以外の場合
        

        // レスポンス返却値のresultを格納
        response.body[0].result = responseData.body.result;
      }
      // レスポンス返却値のエラーを格納
      response.body[0].message = Object.assign(
        response.body[0].message,
        responseData.body.message
      );

      // レスポンス返却値の値を格納
      response.body[0].value = Object.assign(
        response.body[0].value,
        responseData.body.value
      );
    }

    // 結合したレスポンス用返却用オブジェクトを返却
    return response;
  }
}
