import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonService } from "src/app/shared/service/common.service";
import { GenerateService } from "../../../generate.service";
import {
  DISPLAY_INFORMATIONS_API_CONSTANT,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { GENERATE_INPUT_TYPE } from "../../constant";
import {
  CONSTANT,
  FILE_EXTENSION,
  IMAGE_EXTENSION,
  LOADING_KEY,
  NUMBER,
} from "src/app/shared/constant/constant";
import { TranslateService } from "@ngx-translate/core";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { Validator } from "src/app/shared/validator/validator";
import { GenerateInputErrorMessage } from "../generate-input-error-message/generate-input-error-message";
import { GenerateInputFormConfirmation } from "./generate-input-form-confirmation";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import { forkJoin, Observable } from "rxjs";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { FileUpload } from "primeng/fileupload";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";
import {
  API_URL_GET_LIST_POSTALS,
  API_URL_GET_LIST_POSTALS_INITIAL,
  API_URL_GET_UPLOAD_URL,
} from "manager/http-constants";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { GenerateInputComponent } from "../../generate-input.component";

@Component({
  selector: "app-generate-input-form",
  templateUrl: "./generate-input-form.component.html",
  styleUrls: ["./generate-input-form.component.scss"],
})

/**
 * 入力フォーム
 */
export class GenerateInputFormComponent implements OnInit {
  // 画面.入力フォーム
  generateInputForm: FormGroup = this.formBuilder.group(Object());

  // アウトプット.入力フォーム情報
  @Output() generateInputInformation = new EventEmitter<FormGroup>();

  // アウトプット.入力フォームエラー情報
  @Output() generateInputErrorInformation = new EventEmitter<
    GenerateInputErrorMessage[]
  >();

  @Output() reloadID = new EventEmitter<string | boolean>();
  // 画面.入力項目生成
  generateInputList: any[] = new Array();

  // 入力フォーム確認画面出力オブジェクトリスト
  generateInputFormConfirmationList: GenerateInputFormConfirmation[] =
    new Array();

  // 入力エラーメッセージオブジェクト
  generateInputErrorMessageList: GenerateInputErrorMessage[] = new Array();

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 登録ボタン押下フラグ(データ登録中にボタン押下させないフラグ)
  insertFlag: boolean;

  // アップロードファイル
  uploadedFiles: any[] = [];

  // file upload
  fileUploaded: any;

  //reset file upload
  fileUploadFlag: boolean = true;

  hasSelectedFile: boolean = true;
  pkeyId: string;
  endPointWorkInputCheck: string;
  listCategory: any[] = [];
  listSubCategory: any[] = [];
  fileUploadedImage: File;
  fileUploadDocument: File;
  generateDisplayList: any;
  inputType: string;
  selectedCategory: string;

  //List postal of member
  postalNoList: any[] = [];

  constructor(
    private generateService: GenerateService,
    private loadingState: LoadingState,
    private commonService: CommonService,
    private validator: Validator,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private messageData: MessageData,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private http: HttpClient,
    private dbOperationService: DbOperationService
  ) {
    // PrimeNGを日本語化
    this.translateService
      .get("primeng")
      .subscribe((res) => this.config.setTranslation(res));
  }

  ngOnInit(): void {}

  /**
   * 入力項目生成
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param endPointInput 入力項目生成用エンドポイント
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象ID
   * @param inputQueryParameters 入力項目生成時の絞り込み条件
   * @param endPointWorkInputCheck 業務入力チェックエンドポイント
   * ※引数:endPointはinputType=新規:new かつ 入力フォームから実施する場合、任意項目とする
   * ※引数:inputQueryParametersは以下の記述式とする
   * 引数
   * {
   *   content_id:'1111',
   *   circulation_id:'2222'
   * }
   * → 入力フォーム情報取得時のQueryParametersにcontent_id=1111&circulation_id=2222が追加
   */
  public initial(
    inputType: string,
    endPointInput: string,
    endPointGenerateDisplay: string,
    templateId: number,
    pkeyId?: string,
    inputQueryParameters?: Object,
    endPointWorkInputCheck?: string
  ) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.GENERATE_INPUT);
    this.inputType = inputType;
    // 対象IDを一時保存
    this.pkeyId = pkeyId;

    // 業務入力チェックエンドポイントを一時保存
    this.endPointWorkInputCheck = endPointWorkInputCheck;

    // 画面.入力フォームを初期化
    {
      this.generateInputList = new Array();
      this.generateInputForm = this.formBuilder.group(Object());
    }

    // 入力フォーム状態初期化
    this.resetFlag();

    /* 入力項目情報取得処理  */
    this.generateService
      .getGenerateInput(endPointInput, templateId, inputQueryParameters)
      .subscribe((response) => {

        if (this.commonService.checkInvalidValue(response)){
          // 入力項目情報が取得されなかった場合
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          
           this.reloadID.emit(this.pkeyId);
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
           return;
        }
        // 入力項目情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 入力項目情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }

        // 入力フォーム用コントロール作成
        for (const generateInput of response.body) {
          // 入力フォームグループに入力フォームコントロールを追加
          this.generateInputForm.addControl(
            generateInput.column_id,
            this.formBuilder.control(generateInput.column_defualt, {
              asyncValidators: [
                this.validator.CheckValidator(
                  generateInput.table_id,
                  generateInput.column_id,
                  generateInput.input_type
                ),
              ],
            })
          );

          if (generateInput.column_id == "agenda_category_class_cd1") {
            this.listCategory = generateInput.column_code_list_multi.map(
              (value: any) => {
                return {
                  name: value.class_name1,
                  value: value.value,
                  children: value.cd2.map((child1: any) => {
                    return {
                      name: child1.class_name2,
                      value: child1.value,
                    };
                  }),
                };
              }
            );
          }
          if (
            generateInput.column_id == "member_prefectures" ||
            generateInput.column_id == "prefectures"
          ) {
            this.getListPostalsInitial();
          }

          // 入力項目タイプの判定
          if (
            generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.CHECKBOX_TYPE
          ) {
            // 入力項目タイプが"checkbox"の場合

            // 入力フォームコントロールにチェックが外れた場合のバリデーションチェックを実施を追加
            this.generateInputForm.get(generateInput.column_id).updateOn;
          }

          // 登録種別が編集 かつ
          // 編集不可項目か否か
          if (
            GENERATE_INPUT_TYPE.EDIT == inputType &&
            (generateInput.column_input_update == "0" ||
              generateInput.column_input_update == "2")
          ) {
            // 登録種別が編集の場合 かつ
            // 編集不可項目の場合

            // 入力フォームコントロールを非活性化
            this.generateInputForm.get(generateInput.column_id).disable();
          }
        }

        // 入力項目情報を画面.入力項目生成に格納
        this.generateInputList = response.body;

        // 登録種別の判定
        if (GENERATE_INPUT_TYPE.NEW == inputType) {
          // 登録種別が新規の場合
          this.listSubCategory = [];
          this.fileUploadDocument = null;
          this.fileUploadedImage = null;
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
        } else if (GENERATE_INPUT_TYPE.EDIT == inputType) {
          // 登録種別が編集の場合
          // 更新用の詳細情報取得
          this.editGenerateDisplay(endPointGenerateDisplay, templateId, pkeyId);
        } else if (GENERATE_INPUT_TYPE.DUPLICATE == inputType) {
          // 登録種別が複製の場合
          // 複製用の詳細情報取得
          this.duplicateGenerateDisplay(
            endPointGenerateDisplay,
            templateId,
            pkeyId
          );
        }
      });
  }

  /**
   * 更新用の詳細情報取得
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  private editGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 更新用の詳細情報取得
    this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId)
      .subscribe((response) => {
        if (this.commonService.checkInvalidValue(response)){
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
         );
          
           this.reloadID.emit(pkeyId);
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
           return;
       }
        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }

        // 詳細情報初期値セット
        this.setFormControlValue(response.body);

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
      });
  }

  /**
   * 複製用の詳細情報取得
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  private duplicateGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 複製用の詳細情報取得
    this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId, true)
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkInvalidValue(response)){
          // 入力項目情報が取得されなかった場合
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
            this.reloadID.emit(pkeyId);
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
           return;
        }else if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }
        // 詳細情報をプロパティに格納
        this.generateDisplayList = response.body;
        // 詳細情報初期値セット
        this.setFormControlValue(response.body);

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
      });
  }

  /**
   * 詳細情報初期値セット
   * @param generateDisplayInformation
   */
  private setFormControlValue(generateDisplayInformation: any[]) {
    // 入力項目分ループ
    for (const generateInput of this.generateInputList) {
      // 入力項目タイプを判定
      if (
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ||
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.PASSWORD_TYPE
      ) {
        // 入力項目タイプが以下の場合
        // テキスト or
        // 数値 or
        // 日付 or
        // テキストエリア
        // パスワードの場合

        // 詳細情報のカラム値をフォームコントロールに格納
        this.generateInputForm
          .get(generateInput.column_id)
          .setValue(
            this.commonService.getArrayObjectValue(
              generateDisplayInformation,
              DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
              DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
              generateInput.column_id
            )
          );
        if (
          this.generateInputForm.controls["member_class_id"] &&
          this.generateInputForm.controls["member_class_id"].value == "1"
        ) {
          this.generateInputForm.controls[
            "member_history_support_result"
          ].disable();
        }
      } else if (
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.FILE_TYPE
      ) {
        this.fileUploadDocument = null;
        // 詳細情報のカラム値(コード)を取得する
        const data = this.commonService.getArrayObjectValue(
          generateDisplayInformation,
          DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
          generateInput.column_id
        );
        if (data !== CONSTANT.EMPTY_STRING) {
          // 詳細情報のカラム値をフォームコントロールに格納
          const documentBlob = this.dataURItoBlob(data["base64"]);
          const documentFile = new File([documentBlob], data["name"]);
          this.fileUploadDocument = documentFile;

          // 詳細情報のカラム値をフォームコントロールに格納

          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(this.fileUploadDocument.name);
        }
      } else if (
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.IMAGE_TYPE
      ) {
        this.fileUploadedImage = null;
        // 詳細情報のカラム値(コード)を取得する
        const data = this.commonService.getArrayObjectValue(
          generateDisplayInformation,
          DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
          generateInput.column_id
        );
        if (data.name !== CONSTANT.EMPTY_STRING) {
          // 詳細情報のカラム値をフォームコントロールに格納
          const imageBlob = this.dataURItoBlob(data["base64"]);
          const imageFile = new File([imageBlob], data["name"]);
          this.fileUploadedImage = imageFile;

          // 詳細情報のカラム値をフォームコントロールに格納

          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(this.fileUploadedImage.name);
        }
      } else {
        // 入力項目タイプが以下の場合
        // ラジオボタン or
        // チェックボックス or
        // シングルセレクト or
        // マルチセレクトの場合

        // 詳細情報のカラム値(コード)を取得する
        const dataMulti = this.commonService.getArrayObjectValue(
          generateDisplayInformation,
          DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          DISPLAY_INFORMATIONS_API_CONSTANT.DATA_MULTI,
          generateInput.column_id
        );

        // 入力項目タイプを判定
        if (
          generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE ||
          generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
        ) {
          // 入力項目タイプが以下の場合
          // ラジオボタン or
          // シングルセレクトの場合
          if (generateInput.column_id == "agenda_category_class_cd1") {
            this.listCategory.map((category) => {
              if (dataMulti[0] == category.value) {
                this.listSubCategory = category.children;
              }
            });
          }
          if (generateInput.column_id == "agenda_category_class_cd2") {
            // カラム値(コード)を文字列に変換してフォームコントロールに格納
            this.generateInputForm
              .get(generateInput.column_id)
              .setValue(dataMulti.join(CONSTANT.COMMA));
          }
          if (generateInput.column_id == "member_prefectures") {
            this.postalNoList = new Array();
            this.loadingState.loadStart(API_URL_GET_LIST_POSTALS);

            this.dbOperationService
              .getNoTemplateData(
                API_URL_GET_LIST_POSTALS,
                {
                  member_postal_no:
                    this.generateInputForm.get("member_postal_no").value,
                },
                true
              )
              .subscribe((response) => {
                if (!this.commonService.checkNoneResponse(response)) {
                  this.postalNoList = response.body;
                  this.generateInputForm.patchValue({
                    member_prefectures: this.postalNoList[0].value,
                  });
                } else {
                  this.postalNoList = new Array();
                }

                this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS);
              });
          }
          if (generateInput.column_id == "prefectures") {
            this.postalNoList = new Array();
            this.loadingState.loadStart(API_URL_GET_LIST_POSTALS);

            this.dbOperationService
              .getNoTemplateData(
                API_URL_GET_LIST_POSTALS,
                { postal_no: this.generateInputForm.get("postal_no").value },
                true
              )
              .subscribe((response) => {
                if (!this.commonService.checkNoneResponse(response)) {
                  this.postalNoList = response.body;
                  this.generateInputForm.patchValue({
                    prefectures: this.postalNoList[0].value,
                  });
                } else {
                  this.postalNoList = new Array();
                }

                this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS);
              });
          }
          // カラム値(コード)を文字列に変換してフォームコントロールに格納
          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(dataMulti.join(CONSTANT.COMMA));
        } else {
          // 入力項目タイプが以下の場合
          // チェックボックス or
          // マルチセレクトの場合

          // カラム値(コード)をフォームコントロールに格納
          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(dataMulti);
        }
      }
    }
  }

  /**
   * ピッカーリスト.格納前情報
   * @param columnId カラムID
   */
  protected pickListSource(columnId: string): object[] {
    // カラムIDの入力項目コード情報を取得
    const generateInputDataMulti: object[] =
      this.commonService.getArrayObjectValue(
        this.generateInputList,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI,
        columnId
      );

    // 画面.入力フォームにカラムIDの入力情報が存在するか否か
    if (!this.generateInputForm.controls[columnId].value) {
      // 画面.入力フォームにカラムIDの入力情報が存在しない場合

      // 入力項目コード情報を返却
      return generateInputDataMulti;
    }

    // 入力項目コード情報から詳細情報のコードを削除
    const returnDataMulti: any[] =
      this.commonService.deleteArrayObjectMultipleValue(
        generateInputDataMulti.slice(),
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
        this.generateInputForm.controls[columnId].value
      );

    // 詳細情報のコードが削除された入力項目コード情報を返却
    return returnDataMulti;
  }

  /**
   * ピッカーリスト.格納後情報
   * @param columnId カラムID
   */
  protected pickListTarget(columnId: string): object[] {
    // 画面.入力フォームにカラムIDの入力情報が存在するか否か
    if (!this.generateInputForm.controls[columnId].value) {
      // 画面.入力フォームにカラムIDの入力情報が存在しない場合

      // 空配列を返却
      return new Array();
    }

    // カラムIDの入力項目コード情報を取得
    const generateInputDataMulti: any[] =
      this.commonService.getArrayObjectValue(
        this.generateInputList,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI,
        columnId
      );

    // 入力項目コード情報から詳細情報のコードを取得
    const returnDataMulti: any[] =
      this.commonService.createArrayObjectSearchValueString(
        generateInputDataMulti,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
        this.generateInputForm.controls[columnId].value
      );

    // 詳細情報のコードに一致する入力項目コード情報を返却
    return returnDataMulti;
  }

  /**
   * ピッカーリスト.格納前情報から格納後情報に移動
   * @param columnId カラムID
   * @param moveData 移動情報
   */
  protected moveToTarget(columnId: string, moveData: any) {
    // 移動情報分ループ
    for (const item of moveData.items) {
      // 画面.入力フォームからカラムIDの入力情報を取得
      let formControlValue: Object[] =
        this.generateInputForm.controls[columnId].value;

      // 入力情報がnull or 空文字の場合
      if (!formControlValue) {
        // 配列初期化を実施
        formControlValue = new Array();
      }

      // 入力情報に移動情報のコード値を格納
      formControlValue.push(item.value);

      //  画面.入力フォームに入力情報を格納
      this.generateInputForm.get(columnId).setValue(formControlValue);
    }
  }

  /**
   * ピッカーリスト.格納後情報から格納前情報に移動
   * @param columnId カラムID
   * @param moveData 移動情報
   */
  protected moveToSource(columnId: string, moveData: any) {
    // 移動情報分ループ
    for (const item of moveData.items) {
      // 画面.入力フォームからカラムIDの入力情報を取得
      let formControlValue: Object[] =
        this.generateInputForm.controls[columnId].value;

      //  画面.入力フォームの入力情報から移動情報を削除
      this.generateInputForm
        .get(columnId)
        .setValue(formControlValue.filter((val) => val != item.value));
    }
  }

  /**
   * アップロードしたファイル名を取得
   * @param columnId カラムID
   */
  protected getFileName(columnId: string): string {
    // 画面.入力フォームからカラムIDでファイル情報を取得
    const fileData = this.generateInputForm.get(columnId);
    console.log(this.generateInputForm.value);
    // ファイル情報が存在するか否か
    if (!fileData.value) {
      // ファイル情報が存在しない場合
      return null;
    }

    // ファイル情報をカンマで区切る
    const fileDataSplit = fileData.value.split(CONSTANT.COLON);

    // ファイル名が存在する場合
    if (!fileDataSplit[0]) {
      // ファイル名が存在しない場合
      return null;
    }

    return fileDataSplit[0];
  }

  /**
   * ファイルアップロード
   * @param columnId カラムID
   * @param event ファイル情報
   * @param fileUpload ボタン動作状態
   */
  // public uploadFile(columnId: string, event: any, fileUpload: any) {
  //   // ファイル情報からファイル名を取得
  //   const fileName = event.files[0].name;

  //   // ファイルサイズの判定
  //   if (NUMBER.ZERO == event.files[0].size) {
  //     // ファイルサイズが0KBの場合

  //     // 画像サイズエラーメッセージ
  //     this.messageData.toastMessage(
  //       new ToastMessageData({
  //         severity: TOAST.WARN,
  //         summary: this.commonService.msg(MESSAGE_CODE.E00012),
  //         detail: this.commonService.msg(MESSAGE_CODE.E00025, '1KB'),
  //       })
  //     );

  //     // ボタン動作状態をクリア
  //     fileUpload.clear();

  //     // 処理を終了
  //     return;
  //   }

  //   // ファイル情報(base64)取得処理
  //   this.commonService.getBase64(event.files[0]).then((fileInformation) => {
  //     // ファイル情報(base64)が返却後、処理を続行

  //     // ファイル情報(base64)からbase64情報のみ取得
  //     const fileBase64Data = fileInformation.replace(/^data:.*?;base64,/, '');

  //     // 画面.入力フォームにファイル情報を格納
  //     // ファイル名：ファイル情報(Base64)で格納
  //     this.generateInputForm
  //       .get(columnId)
  //       .setValue(fileName + CONSTANT.COLON + fileBase64Data);

  //     // フォームコントロール(ファイル登録)変更検知をtrueにする(エラーメッセージ表示有無フラグ)
  //     this.generateInputForm.get(columnId).markAsDirty();
  //   });
  //   // ボタン動作状態をクリア
  //   fileUpload.clear();
  // }

  /**
   * アップロードファイル削除
   * @param columnId カラムID
   */
  public deleteFile(columnId: string) {
    // 画面.入力フォームからファイル情報を削除
    this.generateInputForm.get(columnId).setValue(CONSTANT.EMPTY_STRING);
  }

  /**
   * 入力項目値確認
   */
  public inputVerification() {
    // 入力エラーメッセージオブジェクトを初期化
    this.generateInputErrorMessageList = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: "",
        background_color: "",
        opacity: 0.3,
      }),
      LOADING_KEY.GENERATE_INPUT
    );

    /* 入力項目値バリデーションチェック */
    // 非同期同時実行リスト
    let task: Observable<any>[] = [
      /* 共通バリデーションチェック */
      this.generateService.multiValidationResult(
        this.generateInputList[0].table_id,
        this.generateInputForm.value
      ),
    ];

    // 業務入力チェックエンドポイントが存在するか否か
    if (this.endPointWorkInputCheck) {
      // 業務入力チェックエンドポイントが存在する場合

      task.push(
        /* 業務用バリデーションチェック */
        this.generateService.workValidationResult(
          this.endPointWorkInputCheck,
          this.pkeyId,
          this.generateInputForm.value
        )
      );
    }

    // 非同期同時実行
    forkJoin(task).subscribe((dataList) => {
      // レスポンスを結合
      const data = this.generateService.JoinValidationResponseList(dataList);
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
      let totalsize: number = 0;
      // 入力項目分ループ
      for (const item of this.generateInputList) {
        // 入力項目に該当するエラーメッセージが存在するか判定
        if (data.body[0].message[item.column_id]) {
          // エラーメッセージが存在する場合

          //  入力フォームエラー情報を生成
          let generateInputErrorMessage: GenerateInputErrorMessage =
            new GenerateInputErrorMessage();
          //  入力フォームエラー情報を格納
          generateInputErrorMessage.columnId = item.column_id;
          generateInputErrorMessage.columnName = item.column_name;
          generateInputErrorMessage.columnErrorMessage =
            data.body[0].message[item.column_id];
          this.generateInputErrorMessageList.push(generateInputErrorMessage);
        }
        switch (item.column_id) {
          case "agenda_need_document":
            if (this.fileUploadDocument) {
              totalsize += this.fileUploadDocument.size;
            }
            break;
          case "agenda_need_icon_image":
            if (this.fileUploadedImage) {
              totalsize += this.fileUploadedImage.size;
            }
            break;
          case "agenda_solution_document":
            if (this.fileUploadDocument) {
              totalsize += this.fileUploadDocument.size;
            }
            break;
          case "agenda_solution_icon_image":
            if (this.fileUploadedImage) {
              totalsize += this.fileUploadedImage.size;
            }
            break;
          default:
            break;
        }
      }

      // 親コンポーネントへ入力フォームエラー情報を渡す
      this.generateInputErrorInformation.emit(
        this.generateInputErrorMessageList
      );
      // バリデーションチェック状態を確認
      if ("0" != data.body[0].result) {
        // チェック状態が異常の場合

        // 処理を終了
        return;
      }

      if (totalsize > CONSTANT.MAX_FILE_SIZE) {
        this.messageService.add({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00009),
          detail: this.commonService.msg(MESSAGE_CODE.E00014, CONSTANT.MAX_FILE_SIZE_text),
        });
        return;
      }

      // ページアンカーへの画面スクロールを実施
      this.pageTopScroll();

      /* 入力値確認データを作成 */
      {
        // 入力値確認用リスト生成
        this.generateInputFormConfirmationList = new Array();

        // 入力項目分ループ
        for (const generateInput of this.generateInputList) {
          // 画面表示不可項目か否か
          if (generateInput.column_input_update == "4") {
            // 画面表示不可項目の場合

            continue;
          }

          // 入力値確認用オブジェクト
          let generateInputFormConfirmation: GenerateInputFormConfirmation =
            new GenerateInputFormConfirmation();

          // 入力項目タイプを判定
          if (
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
          ) {
            // 入力項目タイプが以下の場合
            // テキスト or
            // 数値 or
            // 日付 or
            // テキストエリアの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormControlの入力値を格納
            generateInputFormConfirmation.columnData =
              this.generateInputForm.controls[generateInput.column_id].value;

            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else if (
            generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.PASSWORD_TYPE
          ) {
            // パスワードの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormControlの入力値を'●'に置換して格納
            generateInputFormConfirmation.columnData = "●".repeat(
              this.generateInputForm.controls[generateInput.column_id].value
                .length
            );
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else if (
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.FILE_TYPE ||
            generateInput.input_type == "image"
          ) {
            //file type
            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;
            // FormControlの入力値を取得
            let value = this.generateInputForm.value[generateInput.column_id];
            console.log(value);
            // FormControlの入力値が取得不可(フォームが非活性の場合)
            if (undefined == value) {
              // 表示項目から値を取得
              value = this.commonService.getArrayObjectValue(
                this.generateDisplayList,
                DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
                DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
                generateInput.column_id
              );
            }

            // FormControlの入力値を格納
            generateInputFormConfirmation.columnData = value;
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
          } else if (
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
          ) {
            // 種類が以下の場合
            // ラジオボタン or
            // シングルセレクトの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;
            if (generateInput.column_id == "agenda_category_class_cd1") {
              // FormControlの入力値を名称に変換して格納
              generateInputFormConfirmation.columnData =
                this.commonService.getArrayObjectValue(
                  this.listCategory,
                  INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                  INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                  this.generateInputForm.controls[generateInput.column_id].value
                );
            } else if (generateInput.column_id == "agenda_category_class_cd2") {
              // FormControlの入力値を名称に変換して格納
              generateInputFormConfirmation.columnData =
                this.commonService.getArrayObjectValue(
                  this.listSubCategory,
                  INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                  INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                  this.generateInputForm.controls[generateInput.column_id].value
                );
            } else {
              // FormControlの入力値を名称に変換して格納
              generateInputFormConfirmation.columnData =
                this.commonService.getArrayObjectValue(
                  generateInput.column_code_list_multi,
                  INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                  INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                  this.generateInputForm.controls[generateInput.column_id].value
                );
            }

            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else {
            // 種類が以下の場合
            // チェックボックス or
            // マルチセレクトの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormGroupの入力値(複数)を名称に変換して格納
            generateInputFormConfirmation.columnData = this.commonService
              .getArrayObjectMultipleValue(
                generateInput.column_code_list_multi,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                this.generateInputForm.controls[generateInput.column_id].value
              )
              .join(CONSTANT.FUllSIZE_COMMA);
            console.log(generateInputFormConfirmation);
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          }
        }

        // 確認画面を表示
        this.confirmationFlag = true;
      }
    });
  }

  /**
   * 確認画面戻るボタン
   */
  protected returnConfirmationButton() {
    // 確認画面から入力画面へ遷移する
    this.confirmationFlag = false;

    // ページアンカーへの画面スクロールを実施
    this.pageTopScroll();
  }

  /**
   * ページアンカーへの画面スクロール
   */
  private pageTopScroll() {
    // 入力エラーメッセージのページアンカーが存在するか否か
    if (document.getElementById("generateInputErrorTop")) {
      // 入力エラーメッセージのページアンカーが存在する場合

      // 入力エラーメッセージのページアンカーにスクロール
      let top = document.getElementById("generateInputErrorTop");
      top.scrollIntoView();
      top = null;
    } else {
      // 入力エラーメッセージのページアンカーが存在しない場合

      // 入力フォームのページアンカーにスクロール
      let top = document.getElementById("generateInputFormTop");
      top.scrollIntoView();
      top = null;
    }
  }

  /**
   * 入力フォーム情報返却
   */
  public async returnGenerateInputForm() {
    this.loadingState.loadStart();
    // 登録ボタン押下フラグをONにする(登録、戻るボタン非活性化)
    this.insertFlag = true;

    for (const generateInput of this.generateInputList) {
      if (
        generateInput.input_type == "file" &&
        this.fileUploadDocument !== null &&
        generateInput.column_id == "agenda_need_document"
      ) {
        await this.uploadFiletoS3(
          "agenda_need_document",
          this.fileUploadDocument,
          generateInput.table_id
        );
      }
      if (
        generateInput.input_type == "image" &&
        this.fileUploadedImage !== null &&
        generateInput.column_id == "agenda_need_icon_image"
      ) {
        await this.uploadFiletoS3(
          "agenda_need_icon_image",
          this.fileUploadedImage,
          generateInput.table_id
        );
      }
      if (
        generateInput.input_type == "file" &&
        this.fileUploadDocument !== null &&
        generateInput.column_id == "agenda_solution_document"
      ) {
        await this.uploadFiletoS3(
          "agenda_solution_document",
          this.fileUploadDocument,
          generateInput.table_id
        );
      }

      if (
        generateInput.input_type == "image" &&
        this.fileUploadedImage !== null &&
        generateInput.column_id == "agenda_solution_icon_image"
      ) {
        await this.uploadFiletoS3(
          "agenda_solution_icon_image",
          this.fileUploadedImage,
          generateInput.table_id
        );
      }
    }
    setTimeout(() => {
      console.log('upload finish');
      // 親コンポーネントへ入力フォーム情報を渡す
      this.generateInputInformation.emit(this.generateInputForm);
      this.loadingState.loadEnd();
    }, 3000);

  }

  /**
   * 入力フォーム状態初期化
   */
  public resetFlag() {
    // 入力エラーメッセージオブジェクトを初期化
    this.generateInputErrorMessageList = new Array();

    // 確認画面表示フラグを初期化
    this.confirmationFlag = false;

    // 登録ボタン押下フラグを初期化
    this.insertFlag = false;
  }

  /**
   * エラー項目チェック
   * @param columnId 対象項目
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(columnId: string): boolean {
    // エラーメッセージリストのエラーメッセージ存在チェック
    // 対象項目が存在する場合、trueを返却
    return this.generateInputErrorMessageList.some(
      (errorMessage) => errorMessage.columnId === columnId
    );
  }

  // remove fileUpload from list
  removeFile(file: File, uploader: FileUpload, colId) {
    const index = uploader.files.indexOf(file);
    uploader.remove(null, index);
    if (
      colId == "agenda_solution_icon_image" ||
      colId == "agenda_need_icon_image"
    ) {
      this.fileUploadedImage = null;
    }

    if (
      colId == "agenda_solution_document" ||
      colId == "agenda_need_document"
    ) {
      this.fileUploadDocument = null;
    }
    this.generateInputForm.controls[colId].setValue(CONSTANT.EMPTY_STRING);
    this.generateInputForm.controls[colId].markAsDirty();
    this.hasSelectedFile = false;
  }

  uploadFile(event, controlId, fileUpload) {
    const fileObj: File = event.files[0];

    // Obtain an file extension
    const fileExtension = fileObj.name.substring(
      event.files[0].name.lastIndexOf(CONSTANT.PERIOD)
    );

    let valid = true;

    if (
      !(
        FILE_EXTENSION.PDF == fileExtension ||
        FILE_EXTENSION.PDF2 == fileExtension
      ) &&
      (controlId == "agenda_solution_document" ||
        controlId == "agenda_need_document")
    ) {
      // Invalid Pdf File

      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00009),
        detail: this.commonService.msg(MESSAGE_CODE.E00013, "PDF"),
      });

      valid = false;
    } else if (
      !(
        IMAGE_EXTENSION.JPG == fileExtension ||
        IMAGE_EXTENSION.JPEG == fileExtension ||
        IMAGE_EXTENSION.PNG == fileExtension ||
        IMAGE_EXTENSION.JPG2 == fileExtension ||
        IMAGE_EXTENSION.JPEG2 == fileExtension ||
        IMAGE_EXTENSION.PNG2 == fileExtension
      ) &&
      (controlId == "agenda_solution_icon_image" ||
        controlId == "agenda_need_icon_image")
    ) {
      // Invalid image File

      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00035),
        detail: this.commonService.msg(
          MESSAGE_CODE.E00010,
          "JPEG形式（jpg・jpeg）、PNG形式（png）"
        ),
      });

      valid = false;
    } else if (fileObj.size > CONSTANT.MAX_FILE_SIZE) {
      if (
        controlId == "agenda_solution_icon_image" ||
        controlId == "agenda_need_icon_image"
      ) {
        this.messageService.add({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00009),
          detail: this.commonService.msg(MESSAGE_CODE.E00031, CONSTANT.MAX_FILE_SIZE_text),
        });
        valid = false;
      } else if (
        controlId == "agenda_solution_document" ||
        controlId == "agenda_need_document"
      ) {
        this.messageService.add({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00009),
          detail: this.commonService.msg(MESSAGE_CODE.E00032, CONSTANT.MAX_FILE_SIZE_text),
        });
        valid = false;
      }
    } else if (!valid) {
      // If file is invalid
      // Clear the button operation status
      fileUpload.clear();
      if (
        controlId == "agenda_solution_icon_image" ||
        controlId == "agenda_need_icon_image"
      ) {
        this.fileUploadedImage = null;
      } else if (
        controlId == "agenda_solution_document" ||
        controlId == "agenda_need_document"
      ) {
        this.fileUploadDocument = null;
      }
      this.generateInputForm.controls[controlId].setValue(null);
      // Processing is terminated
      return;
    } else {
      this.generateInputForm.controls[controlId].setValue(
        event.currentFiles[0].name
      );
      if (
        controlId == "agenda_solution_icon_image" ||
        controlId == "agenda_need_icon_image"
      ) {
        this.fileUploadedImage = fileObj;
      } else this.fileUploadDocument = fileObj;
    }

    if (!this.fileUploadFlag) {
      fileUpload.clear();
    }
  }

  public async uploadFiletoS3(id: any, fileUploaded: File, tableId) {
    const uploadInfo: any = await this._getUploadInfo(
      id,
      fileUploaded.name,
      tableId
    );
    for (let index = 0; index < uploadInfo.length; index++) {
      const upload = uploadInfo[index];
      if (
        upload.url === undefined ||
        upload.url === null ||
        upload.url.length === 0 ||
        upload.url.indexOf("s3.amazonaws.com") === -1
      ) {
        this.messageService.add({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.I00005),
          detail: this.commonService.msg(MESSAGE_CODE.E00002),
        });
        return;
      }
      await this._uploadToS3(upload, fileUploaded);
    }
  }

  private _getUploadInfo(code, filename, tableId) {
    let fileType;
    let colName;
    const url = this.commonService.url(API_URL_GET_UPLOAD_URL);
    if (code == "agenda_need_document") {
      fileType = "file";
      colName = "agenda_need_document";
    } else if (code == "agenda_solution_document") {
      fileType = "file";
      colName = "agenda_solution_document";
    } else if (code == "agenda_need_icon_image") {
      fileType = "image";
      colName = "agenda_need_icon_image";
    } else {
      fileType = "image";
      colName = "agenda_solution_icon_image";
    }
    return this.http
      .get(url, {
        params: {
          tablename: tableId,
          filetype: fileType,
          colname: colName,
          filename: encodeURIComponent(filename),
          code: this.inputType == GENERATE_INPUT_TYPE.EDIT ? this.pkeyId : "",
        },
      })
      .toPromise();
  }

  private _uploadToS3(uploadInfo, fileUpload) {
    return new Promise<any>((resolve) => {
      const uploadUrl = uploadInfo.url;
      const fields: any = uploadInfo.fields;

      const formData = new FormData();
      formData.append("key", fields.key);
      formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
      formData.append("policy", fields.policy);
      formData.append("signature", fields.signature);
      formData.append("x-amz-security-token", fields["x-amz-security-token"]);
      formData.append("file", fileUpload, fileUpload.name);

      this.http
        .post(uploadUrl, formData, { observe: "response" })
        .pipe(map((res: HttpResponse<any>) => res))
        .subscribe((res) => resolve(res));
    });
  }

  // // public _getUploadContent(code) {
  // //   const url = this.commonService.url(API_URL_GET_FILECONTENT);
  // //   return this.http
  // //     .get(url, {
  // //       params: {
  // //         tblname: 'form_template',
  // //         code,
  // //       },
  // //     })
  // //     .toPromise();
  // // }
  // }

  onChangCategory(event: any) {
    this.listSubCategory = [];
    this.listCategory.map((valCategory: any) => {
      if (valCategory.value == event.value) {
        this.listSubCategory = valCategory.children;
      }
    });
    this.generateInputForm.controls["agenda_category_class_cd2"].setValue(
      this.listSubCategory[0].value
    );
    this.generateInputForm.get("agenda_category_class_cd2").markAsDirty();
  }
  onchangeClassId(event: any) {
    if (event.value == "1") {
      this.generateInputForm.controls[
        "member_history_support_result"
      ].disable();
      this.generateInputForm.controls["member_history_support_result"].setValue(
        ""
      );
    } else {
      this.generateInputForm.controls["member_history_support_result"].enable();
    }
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);
    return blob;
  }

  public getListPostalsInitial(): void {
    this.postalNoList = new Array();
    this.loadingState.loadStart(API_URL_GET_LIST_POSTALS_INITIAL);

    this.dbOperationService
      .getNoTemplateData(API_URL_GET_LIST_POSTALS_INITIAL, {}, true)
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.postalNoList = response.body;
        } else {
          this.postalNoList = new Array();
        }

        this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS_INITIAL);
      });
  }

  public getPostalsList(e: any, columnId: any): void {
    const prefecturesId =
      columnId == "member_postal_no" ? "member_prefectures" : "prefectures";
    const portalVal = new Object();

    portalVal[columnId] = e.target.value;
    if (!e.target.value.trim()) {
      this.getListPostalsInitial();
      this.generateInputForm.get(prefecturesId).reset();
    } else {
      this.generateInputForm.get(prefecturesId).reset();
      this.generateInputForm.get(prefecturesId).markAsDirty();
      this.postalNoList = new Array();
      this.loadingState.loadStart(API_URL_GET_LIST_POSTALS);

      this.dbOperationService
        .getNoTemplateData(API_URL_GET_LIST_POSTALS, portalVal, true)
        .subscribe((response) => {
          if (!this.commonService.checkNoneResponse(response)) {
            this.postalNoList = response.body;
            const prefecturesVal = new Object();
            prefecturesVal[prefecturesId] = this.postalNoList[0].value;
            this.generateInputForm.patchValue(prefecturesVal);
          } else {
            this.postalNoList = new Array();
          }
          this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS);
        });
    }
  }
}
