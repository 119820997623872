// import { API_URL_AGENDA_SOLUTION } from './http-constants';
import { CONSTANT } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TEST_DOMAIN, STAGING_DOMAIN, PROD_DOMAIN, STG_DOMAIN } from "./environment";

/**
 * API URL取得用キー
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/userauthinfos/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/userauthinfos/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/userauthinfos/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/userauthinfos/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/userauthinfos/"
);
// ヘッダー項目生成
export const HEADER_LIST = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/headerlist/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/headerlist/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/headerlist/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/headerlist/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/headerlist/",
);
// バリデーションチェック
export const API_URL_VALIDATION_RESULT = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/validationresult/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/validationresult/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/validationresult/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/validationresult/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/validationresult/"
);
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/multivalidationresult/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/multivalidationresult/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/multivalidationresult/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/multivalidationresult/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/multivalidationresult/",
);

// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/runcount/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/runcount/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/runcount/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/runcount/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/runcount/",
);

// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT_NO_TOKEN = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/runcount_pub/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/runcount_pub/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/runcount_pub/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/runcount_pub/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/runcount_pub/",
);

// 全辞書値取得
export const API_URL_DIC_LISTS = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/spec_code_masters/list/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/spec_code_masters/list/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/spec_code_masters/list/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/spec_code_masters/list/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/spec_code_masters/list/"
);

// ファイルアップロード（JOB登録）
export const API_URL_APPLICATIONS_FILES = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/applications/files/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/applications/files/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/applications/files/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/applications/files/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/applications/files/",
);

// 郵便番号マスタ一覧
export const POSTALS_LIST = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/postals/list/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/postals/list/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/postals/list/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/postals/list/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/postals/list/"
);

// 会員申請登録
export const APPLICATIONS = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/applications/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/applications/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/applications/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/applications/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/applications/"
);

// 確認コードチェック（会員プロフィール、ユーザへの分離作成）
export const AUTH_CODES = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/authcodes/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/authcodes/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/authcodes/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/authcodes/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/authcodes/"
);

// 認証コード再送信
export const AUTH_CODES_SEND = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/authcodes/send",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/authcodes/send/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/authcodes/send/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/authcodes/send/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/authcodes/send/"
);

// メールアドレス入力チェック
export const EMAIL_CHECKS = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/emailchecks/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/emailchecks/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/emailchecks/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/emailchecks/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/emailchecks/"
);

// パスワード設定
export const PASSWORDS = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/passwords/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/passwords/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/passwords/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/passwords/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/passwords/"
);

// 入会申請詳細
export const APPLICATIONS_DETAIL = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/applications/detail/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/applications/detail/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/applications/detail/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/applications/detail/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/applications/detail/"
);


// 未承認一覧(入会申請承認)
export const APPLICATIONS_APPROVE = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/applications/approve/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/applications/approve/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/applications/approve/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/applications/approve/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/applications/approve/"
);

// カテゴリ一覧取得
export const AGENDA_CATEGORY_LIST = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_category/list/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/agenda_category/list/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_category/list/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_category/list/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_category/list/"
);

// 新着情報一覧
export const WHAT_SNEWS = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/whatsnews/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/whatsnews/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/whatsnews/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/whatsnews/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/whatsnews/",
);

// 課題・ソリューション一覧
export const AGENDA_SOLUTIONS_LIST = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_solutions/list",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_solutions/list",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_solutions/list",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/mypage/agenda_solutions/list",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_solutions/list"
);

// addtition image to url of test
export const AGENDA_NEEDS_LIST = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_needs/list/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_needs/list/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_needs/list/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/mypage/agenda_needs/list/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_needs/list/"
);

// addtition image to url of test
export const AGENDA_NEEDS_LIST_NO_TOKEN = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_needs_pub/list/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_needs_pub/list/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_needs_pub/list/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/mypage/agenda_needs_pub/list/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_needs_pub/list/"
);

// 課題・ソリューション詳細
export const LOGIN_NOT_AGENDA_SOLUTIONS = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/"
);

// 課題・ソリューション登録・更新
export const LOGIN_DONE_AGENDA_SOLUTIONS = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/"
);

// 課題・ソリューション承認
export const AGENDA_SOLUTIONS_APPROVE = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/approve/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/approve/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/approve/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions/approve/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/approve/"
);

// 課題・ソリューション件数取得
export const AGENDA_CATEGORY_COUNT = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_category/count/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/agenda_category/count/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_category/count/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_category/count/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_category/count/"
);


// 掲示板コメント(GET:取得 POST:登録 DELETE:削除)
export const BOARDS = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/boards/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/boards/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/boards/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/boards/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/boards/"
);

// 掲示板コメント削除(DELETE:削除)
export const DELETE_BOARDS = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/boards/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/boards/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/boards/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/boards/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/boards/"
);

// 掲示板コメント件数取得
export const BOARDS_COUNT = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/boards/count/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/boards/count/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/boards/count/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/boards/count/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/boards/count/"
);

// マッチングリスト取得
export const AGENDA_SOLUTIONS_SUGGEST = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/suggest/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/suggest/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/suggest/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions/suggest/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/suggest/"
);

// チャットリスト取得
export const CHAT_LIST = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/chat/list/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/chat/list/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/chat/list/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/chat/list/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/chat/list/"
);

// 会員一覧取得
export const MEMBERS_LIST = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/members/list/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/members/list/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/members/list/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/members/list/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/members/list/"
  );

// 会員の件数取得
export const MEMBER_COUNT = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/members/count",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/members/count",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/members/count",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/members/count",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/members/count"
);

// 会員プロファイル更新
export const MEMBER_INFO_UPDATE = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/member/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/member/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/member/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/member/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/member/"
);

// 会員プロフィール詳細
export const MEMBERS_DETAIL = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/members/detail/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/members/detail/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/members/detail/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/members/detail/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/members/detail/"
);

// 会員プロフィール詳細課題一覧
export const MEMBERS_AGENDA = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/members/agenda/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/members/agenda/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/members/agenda/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/members/agenda/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/members/agenda/"
);

// 会員プロフィール詳細 前回情報
export const MEMBERS_DETAIL_LOG = getUrl(
  "https://q5nmvdwxkj.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://q5nmvdwxkj.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://q5nmvdwxkj.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://q5nmvdwxkj.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/",
  "https://q5nmvdwxkj.execute-api.ap-northeast-1.amazonaws.com/dev/member_logs/detail/"
);

// ユーザ一覧取得
export const USER_LIST = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/users/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/users/"
);

// ユーザ一覧詳細
export const USER_GENERATE = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/users/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/users/"
);

// ユーザ登録
export const USER_ADD = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/users/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/users/"
);

// ユーザ編集
export const USER_EDIT = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/users/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/users/"
);

// ユーザ編集
export const USER_DELETE = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/users/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/users/"
);

// ユーザ編集 master
export const USER_DELETE_MASTER = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/users_master/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/users_master/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/users_master/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/users_master/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/users_master/"
);


// 未承認入会申請一覧
export const UNAPPROVE_APPLICATION_LIST = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/unapproves/application",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/unapproves/application",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/unapproves/application",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/unapproves/application",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/unapproves/application"
);

// 未承認件数取得
export const UNAPPROVES_COUNT = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/unapproves/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/unapproves/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/unapproves/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/unapproves/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/unapproves/"
);

// 未承認一覧（提案）
export const UNAPPROVES_SUGGEST_LIST = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/unapproves/suggest",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/unapproves/suggest",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/unapproves/suggest",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/unapproves/suggest",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/unapproves/suggest"
);

// 未承認一覧(課題・ソリューション)
export const UNAPPROVES_AGENDA_SOLUTION = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/unapproves/agenda_solution",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/unapproves/agenda_solution",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/unapproves/agenda_solution",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/unapproves/agenda_solution",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/unapproves/agenda_solution"
);



// 課題・ソリューションマスタ管理／REST API(GET:一覧/GET:詳細)
export const API_URL_AGENDA_SOLUTION = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/list",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/list",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions/list",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/list"
);


// 検索項目取得／検索画面(GET)
export const API_URL_SEARCH_AGENDA_SOLUTION = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/searchagendasolutions/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/searchagendasolutions/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/searchagendasolutions/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/searchagendasolutions/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/searchagendasolutions/"
);

// ユーザーマスタ管理／REST API(GET:一覧/GET:詳細)
export const API_URL_USER = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/users_master/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/users_master/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/users_master/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/users_master/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/users_master/"
);
// ユーザーマスタ管理／REST API(GET:一覧/GET:詳細)
export const API_URL_USER_DUPLICATE = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/users/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/users/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/users/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/users/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/users/"
);

// ユーザーマスタ管理／検索画面(GET)
export const API_URL_SEARCH_USER = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/searchusers/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/searchusers/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/searchusers/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/searchusers/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/searchusers/"
);

// お知らせ一覧取得
export const INFORMATION_LIST = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/information/list",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/information/list",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/information/list",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/information/list",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/information/list"
);
// E002 
// お知らせ一覧取得
export const INFORMATION = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/information/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/information/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/information/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/information/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/information/"
);


// お知らせ詳細
export const INFORMATION_DETAIL = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/informations/detail/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/informations/detail/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/informations/detail/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/informations/detail/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/informations/detail/"
);

// お知らせ登録
export const INFORMATION_INSERT = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/informations/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/informations/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/informations/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/informations/"
);

// 会員プロフィール申請
export const MEMBERS_PROFILE_APPROVE = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/members/approve/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/members/approve/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/members/approve/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/members/approve/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/members/approve/"
);

// 会員プロフィール未登録項目チェック(GET)
export const NEEDINPUT_STATUS = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/inputchecks/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/inputchecks/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/inputchecks/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/inputchecks/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/inputchecks/"
);

export const API_URL_INFORMATION = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/informations/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/informations/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/informations/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/informations/"
);

export const API_URL_AGENDA = getUrl(
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/",
  "https://lwyjs61ai5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/list/"
);

export const API_URL_INPUT_MASTER = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/inputitems",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/inputitems",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/inputitems",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/inputitems",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/inputitems"
);

export const API_URL_AGENDA_SOLUTION_DETAIL = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions_master/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions_master/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions_master/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions_master/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions_master/"
);

// Nga add
export const API_URL_AGENDA_SOLUTION_ADD = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/"
);
// Nga add
export const API_URL_AGENDA_SOLUTION_DELETE = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/"
);
export const API_URL_AGENDA_SOLUTION_DUPLICATE = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_solutions/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/agenda_solutions/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_solutions/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_solutions/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_solutions/"
);

export const API_URL_AGENDA_NEED_LIST = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs/list/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs/list/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs/list/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_needs/list/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs/list/"
);
export const API_URL_AGENDA_NEED_SEARCH = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/searchagendaneeds/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/searchagendaneeds/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/searchagendaneeds/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/searchagendaneeds/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/searchagendaneeds/"
);

export const API_URL_AGENDA_NEED_DETAIL = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs_master/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs_master/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs_master/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_needs_master/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs_master/"
);

export const API_URL_AGENDA_NEED_DELETE = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_needs/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs/"
);

export const API_URL_AGENDA_NEED_ADD_EDIT = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_needs/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs/"
);

export const API_URL_AGENDA_NEED_DUPLICATE = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_needs/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs/"
);

export const API_URL_GET_AGENDA_SOLUTIONS = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_solutions/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_solutions/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_solutions/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/mypage/agenda_solutions/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_solutions/"
);

export const API_URL_GET_AGENDA_NEEDS = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/agenda_needs/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/mypage/agenda_needs/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/agenda_needs/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/mypage/agenda_needs/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/agenda_needs/"
);

export const API_URL_GET_MATCHING_AGENDA_SOLUTIONS = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/matching/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/matching/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/matching/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/matching/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/matching/"
);

export const API_URL_ADD_MATCHING_AGENDA_SOLUTIONS_TEACHING = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/matching/agenda_solution/add/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/matching/agenda_solution/add/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/matching/agenda_solution/add/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/matching/agenda_solution/add/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/matching/agenda_solution/add/"
);

export const API_URL_ADD_MATCHING_AGENDA_SOLUTIONS_NEEDS_SUGGESTION = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/matching/agenda_need/add/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/matching/agenda_need/add/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/matching/agenda_need/add/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/matching/agenda_need/add/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/matching/agenda_need/add/"
);

// api generate upload url in S3
export const API_URL_GET_UPLOAD_URL = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/generate-upload-url/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/generate-upload-url/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/generate-upload-url/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/generate-upload-url/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/generate-upload-url/"
);

export const API_URL_GET_DETAIL_AGENDA_NEEDS = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_needs/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs/"
);

export const API_URL_GET_DETAIL_AGENDA_NEEDS_NO_TOKEN = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs_pub/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs_pub/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs_pub/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_needs_pub/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs_pub/"
);

export const GENERATE_DISPLAY_NEED_MATCHING = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/agenda_needs/{0}/{1}",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/agenda_needs/{0}/{1}",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/agenda_needs/{0}/{1}",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/agenda_needs/{0}/{1}",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/agenda_needs/{0}/{1}"
);

export const GENERATE_DISPLAY_NEED_SEED_MATCHING = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/matching/ns/{0}",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/matching/ns/{0}",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/matching/ns/{0}",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/matching/ns/{0}",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/matching/ns/{0}"
);

export const API_URL_ADD_MATCHING = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/massmatch/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/massmatch/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/massmatch/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/massmatch/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/massmatch/"
);

export const API_URL_GET_LIST_CATEGORY_NEEDS = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/searchagendaneeds",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/searchagendaneeds",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/searchagendaneeds",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/searchagendaneeds",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/searchagendaneeds"
);

export const API_URL_GET_DETAIL_MATCHING = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/matching/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/matching/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/matching/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/matching/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/matching/"
);

export const API_URL_ADD_EVALUATION = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/evaluations/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/evaluations/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/evaluations/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/evaluations/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/evaluations/"
);

export const API_URL_GET_LIST_POSTALS_INITIAL = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/postals/list/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/postals/list/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/postals/list/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/postals/list/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/postals/list/"
);

export const API_URL_GET_LIST_POSTALS = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/postals/",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/postals/",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/postals/",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/postals/",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/postals/"
);

// organization master
export const API_URL_SEARCH_ORGANIZATION = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/searchmembers/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/searchmembers/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/searchmembers/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/searchmembers/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/searchmembers/"
);
export const API_URL_ORGANIZATION = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/members/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/members/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/members/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/members/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/members/"
);

export const API_URL_DETAIL_ORGANIZATION = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/members/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/members/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/members/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/members/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/members/"
);

export const API_URL_GET_DETAIL_EVALUATION = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/evaluation/{0}",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/evaluation/{0}",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/evaluation/{0}",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/evaluation/{0}",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/evaluation/{0}"
  
);

export const API_URL_GET_LIST_TITLE_APPLICATION = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/match/application/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/match/application/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/match/application/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/match/application/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/match/application/"
);

// chat list api

export const API_URL_GENERATE_CHAT_LIST = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/chats/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/chats/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/chats/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/chats/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/chats/"
);

export const API_URL_CHAT_STATUS = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/chats/status/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/chats/status/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/chats/status/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/chats/status/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/chats/status/"
);

export const API_URL_MATCH_CHAT_DETAIL = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/match/chat/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/match/chat/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/match/chat/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/match/chat/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/match/chat/"
);

// get api searcg my page chat
export const API_URL_SEARCH_MY_PAGE_CHAT = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/mypage/chats",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/mypage/chats",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/mypage/chats",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/mypage/chats",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/mypage/chats"
);
// api update implemetation date
export const API_URL_UPDATE_IMPLEMENTATION_DATE = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/match/implementation_date/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/match/implementation_date/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/match/implementation_date/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/match/implementation_date/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/match/implementation_date/"
);
// api update match status 
export const API_URL_UPDATE_MATCH_STATUS = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/match/status/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/match/status/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/match/status/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/match/status/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/match/status/"
);

// api update match status my page
export const API_URL_UPDATE_MATCH_STATUS_MY_PAGE = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/match/status/mypage/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/match/status/mypage/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/match/status/mypage/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/match/status/mypage/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/match/status/mypage/"
);

// api update memo
export const API_URL_UPDATE_MEMO = getUrl(
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/match/memo/",
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/match/memo/",
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/match/memo/",
  "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/match/memo/",
  "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/match/memo/"
);
// 会員主催イベント検索(POST)
export const MEMBER_EVENTS_LIST = getUrl(
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2",
  "https://vvvv.execute-api.ap-northeast-1.amazonaws.com/dev/memberevent/list2"
);

// Information master

// Information search(POST)
export const INFOMATION_LIST_MASTER = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/information_master",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/information_master",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/information_master",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/information_master",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/information_master"
);
// Generate Search information master(GET)
export const INFOMATION_GENERATE_SEARCH_MASTER = getUrl(
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/searchinformation",
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/searchinformation",
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/searchinformation",
  "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/searchinformation",
  "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/searchinformation"
);
// Add Edit information master(POST, PUT)
export const INFOMATION__MASTER_ADD_EDIT = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/information_master/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/information_master/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/information_master/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/information_master/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/information_master/"
);
//Generate Display Information master
export const INFOMATION_GENERATE_DISPLAY_MASTER = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/information_master/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/information_master/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/information_master/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/information_master/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/information_master/"
);
//Delete Information master(DELETE)
export const DELETE_INFOMATION_MASTER = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/information/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/information/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/information/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/information/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/information/"
);

//Get list 5 recent notification (GET)
export const LIST_NEW_NOTIFICATION = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/information_bell",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/information_bell",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/information_bell",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/information_bell",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/information_bell"
);

//count unread
export const COUNT_UNREAD = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/information/unread",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/information/unread",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/information/unread",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/information/unread",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/information/unread"
);

// get detail match
export const API_URL_GET_DETAIL_MATCH = getUrl(
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/match/",
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/match/",
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/match/",
  "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/match/",
  "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/match/"
);

// export const AUTH_TOKEN = getUrl(
//   "https://id.sumasapo.metro.tokyo.lg.jp/api/v1/token",
//   "https://id.sumasapo.metro.tokyo.lg.jp/api/v1/token",
//   "https://id.sumasapo.metro.tokyo.lg.jp/api/v1/token"
// );

export const AUTH_TOKEN = getUrl(
  "https://iocl1xnmt1.execute-api.ap-northeast-1.amazonaws.com/dev/token/",
  "https://hjb8ye3ema.execute-api.ap-northeast-1.amazonaws.com/test/token/",
  "https://o2k2jxv1l6.execute-api.ap-northeast-1.amazonaws.com/staging/token/",
  "https://mt9onsd1gh.execute-api.ap-northeast-1.amazonaws.com/stg/token/",
  "https://yauybzuxpl.execute-api.ap-northeast-1.amazonaws.com/prod/token/"
);

// url取得(検証、本番取得)
function getUrl(devUrl: string, testUrl: string, StagingUrl: string, stgUrl: string, prodUrl: string): string {
  // エンドポイント判定
  const endPoint =
    STAGING_DOMAIN == window.location.host? StagingUrl
      : TEST_DOMAIN == window.location.host ? testUrl
      : STG_DOMAIN == window.location.host ? stgUrl
      : PROD_DOMAIN == window.location.host ? prodUrl
      : devUrl;

  // エンドポイントが設定されたか否か
  if (!endPoint) {
    // エンドポイントが設定されなかった場合

    console.error(
      this.msg(
        MESSAGE_CODE.E80000,
        PROD_DOMAIN == window.location.host ? "本番用" 
          : STAGING_DOMAIN ? "演出" 
          : TEST_DOMAIN ? "テスト用" 
          : STG_DOMAIN ? "STG用" 
          : "開発用"
      )
    );

    return CONSTANT.EMPTY_STRING;
  }

  return endPoint;
}
