import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AUTH_TOKEN, USER_AUTHINFOS } from "manager/http-constants";
import { SESSION_KEY } from "src/app/shared/constant/session-constants";
import { map } from "rxjs/operators";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { CommonService } from "./common.service";
import {
  MessageData,
  ToastMessageData,
} from "../html-parts/message-common/message-data";
import { TOAST } from "../constant/primeng-constants";
import { URL_MYPAGE } from "../constant/constant";

const credentialsKey = 'access_token';
const idToken = 'id_token';

@Injectable({
  providedIn: "root",
})

/**
 * ログイン処理
 */
export class LoginService {
  private _credentials: any;
  loginFlag: BehaviorSubject<boolean>;
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private messageData: MessageData,
  ) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = savedCredentials;
      this.loginFlag = new BehaviorSubject(true);
    } else {
      this.loginFlag = new BehaviorSubject(false);
    }
  }
  
  setLoginFlag(loginFlag: boolean) {
    this.loginFlag.next(loginFlag);
  }

  /**
   * ログアウト処理
   * @param messageCode メッセージコード
   */
  public logout(messageCode?: string): void {
    setTimeout(() => {
      const token = window.sessionStorage.getItem(idToken);
      sessionStorage.clear();
      localStorage.clear();
      this.setCredentials()
      this.setLoginFlag(false);
      // セッションに保存したログインユーザ情報を削除
      window.sessionStorage.removeItem(SESSION_KEY.loginUserInformation);
  
      // セッションに保存した全辞書情報を削除
      window.sessionStorage.removeItem(SESSION_KEY.dicList);
  
      // メッセージコードが存在するか否か
      if (!messageCode) {
        // メッセージコードが存在しない場合
  
        // メッセージコード(ログアウトしました)を標準セット
        messageCode = MESSAGE_CODE.N90000;
      }
  
      // セッションにログアウト画面用のメッセージコードを格納
      window.sessionStorage.setItem(SESSION_KEY.loginOutMessageCode, messageCode);
      window.location.href = URL_MYPAGE.LOGOUT.replace('{0}',token);
    }, 2000);
  }

  /**
   * ログインユーザ情報取得
   * @returns JSONデータ
   */
  public getLoginUser(): Observable<any> {
    // ログインユーザ情報を返却する
    return this.http
      .get(this.commonService.url(USER_AUTHINFOS), { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * ログインメッセージ
   * @param loginUserName ログインユーザ名
   */
  public loginMessage(loginUserName?: string): void {
    // ログインメッセージを出力
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.INFO,
        summary: this.commonService.msg(MESSAGE_CODE.N80000),
        detail: this.commonService.msg(MESSAGE_CODE.N80001, loginUserName),
        position: TOAST.TOP_RIGHT,
        life: 5000,
      })
    );
  }

  /**
   * ログアウトメッセージ
   */
  public logoutMessage(): void {
    // ログアウトメッセージを出力
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.INFO,
        summary: this.commonService.msg(MESSAGE_CODE.N80002),
        detail: this.commonService.msg(MESSAGE_CODE.N80003),
        position: TOAST.TOP_RIGHT,
        life: 5000,
      })
    );
  }

  /**
   * Auth0ログイン状態取得
   * @returns true:ログイン中,false:ログアウト中
   * ※ セッション切れの場合は、true:ログイン中となる
   */
  public getAuth0LoginState(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): any {
    return this._credentials;
  }

  /**
   * Auth0ログインユーザトークン
   */
  public getAuth0LoginToken(): Observable<any> {
    return this._credentials;
  }

  
  public getToken(): Observable<any> {
    // ログインユーザ情報を返却する
    return this.http
      .get(this.commonService.url(AUTH_TOKEN), { observe: "response" })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(code: any, redirect_uri: any): Observable<any> {
    // Replace by proper authentication call
    
    return this.http.get(this.commonService.url(AUTH_TOKEN) + '?code=' + code + '&redirect_uri=' + redirect_uri).pipe(
      map((data: any) => {
        // login successful if there's a jwt token in the response
        if (data && data.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setCredentials(data.access_token, false ,data.id_token);
        }
        return data.access_token;
      })
    );
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: string, remember?: boolean, id_token?: string) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, credentials);
      storage.setItem(idToken, id_token);
      this.setLoginFlag(true);
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
      sessionStorage.removeItem(idToken);
      localStorage.removeItem(idToken);
    }
  }
}
