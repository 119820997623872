import { Component, OnInit, Input } from "@angular/core";
import { ActivationEnd, Router, ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { A200_STEPS_ITEMS } from "./a200-steps-constants";
import { NavigationEnd } from "@angular/router";

@Component({
  selector: "app-a200-steps",
  templateUrl: "./a200-steps.component.html",
  styleUrls: ["./a200-steps.component.scss", "../../platform.component.scss"],
})
export class A200StepsComponent implements OnInit {
  @Input() class_id: string;
  stepsItems: object[] = A200_STEPS_ITEMS;

  currentActive: number = 1;
  currentUrl: string;
  totalSteps = 4;
  progressWidth: string = "0%";

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.handleProgress();
  }

  public handleProgress(): void {
    if (this.currentUrl.startsWith("/pages/platform/a201?screenId=a201")) {
      this.currentActive = 1;
    } else if (this.currentUrl.startsWith("/pages/platform/a201?screenId=a202")) {
      this.currentActive = 2;
    } else if (this.currentUrl.startsWith("/pages/platform/a203")) {
      this.currentActive = 3;
    } else if (this.currentUrl.startsWith("/pages/platform/a204")) {
      this.currentActive = 4;
    }

    this.progressWidth = this.getProgressWidth().toFixed(2) + "%";
  }

  getProgressWidth() {
    return ((this.currentActive - 1) / 3) * 100;
  }

  isActiveStep(step: number) {
    return this.currentActive >= step;
  }

  isActiveText(index: number) {
    if (index === this.currentActive) {
      return true;
    }
    return false;
  }

  ngAfterViewInit() {
  }
}
