import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  API_URL_GET_MATCHING_AGENDA_SOLUTIONS,
} from "manager/http-constants";
import { CONSTANT } from "src/app/shared/constant/constant";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import { PlatformComponent } from "../platform.component";
import { Table } from "primeng/table";
import { LoginService } from "src/app/shared/service/login.service";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { EvaluationDialogComponent } from "../common/evaluation-dialog/evaluation-dialog.component";
import { SESSION_KEY } from "manager/environment";

// import C502 - C532
@Component({
  selector: "app-c502",
  templateUrl: "./c502.component.html",
  styleUrls: ["./c502.component.scss", "../platform.component.scss"],
})

/**
 * マッチング依頼一覧
 */
export class C502Component extends PlatformComponent {
  // 画面.検索フォーム
  c502SearchForm: FormGroup = this.formBuilder.group({
    match_status_agenda: CONSTANT.EMPTY_STRING,
    agenda_need_title:CONSTANT.EMPTY_STRING,
    agenda_need_expected_content: CONSTANT.EMPTY_STRING,
    match_match_type: CONSTANT.EMPTY_STRING,
    agenda_need_event_date_FROM: CONSTANT.EMPTY_STRING,
    agenda_need_event_date_TO: CONSTANT.EMPTY_STRING,
    match_match_id: CONSTANT.EMPTY_STRING
  });

  /** テーブル状態 */
  @ViewChild("table") table: Table;

  @ViewChild(EvaluationDialogComponent)
  evaluationDialogComponent: EvaluationDialogComponent;

  first = 0;

  rows = 10;

  //ステータス取得格納先
  statusList: object[] = [
    {
      sp_name: "全て",
      sp_code: "9",
    },
    {
      sp_name: "未回答",
      sp_code: "0",
    },
    {
      sp_name: "検討中",
      sp_code: "1",
    },
    {
      sp_name: "見送り（理由１）",
      sp_code: "2",
    },
    {
      sp_name: "見送り（理由２）",
      sp_code: "3",
    },
    {
      sp_name: "見送り（理由３）",
      sp_code: "4",
    },
    {
      sp_name: "意見交換希望",
      sp_code: "5",
    },
  ];

  // 検索結果一覧表示
  resultTaskDataList: object[] = new Array();

  // 件数格納先
  countTaskData: number = 0;

  class_id: any;

  listStatus: any[] = [];

  userInfo: any;
  roleAuth: any;

  // カテゴリのAccordion開閉フラグ
  accordion_category: boolean = false;

  // 業種のAccordion開閉フラグ
  accordion_industry_id: boolean = false;

  // SDGsゴールのAccordion開閉フラグ
  accordion_sdgs_goal: boolean = false;

  // カテゴリの中項目Accordion開閉フラグ
  accordion_category_detail = new Map();
  isLoading: boolean = true;

  isShowDialogEvaluate: boolean = false;
  isShowDialogC331: boolean = false;
  isShowDialogC341: boolean = false;

  matchId: string;

  isM4: any = false;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private changeDetector: ChangeDetectorRef

  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    // this.getAgendaCategoryList();
    // 提案結果を取得
    this.initialScreen();
    this.getSpecCodeList(109,114);
  }

  public async initialScreen() {
    // 辞書値リストを取得
    await this.getAuthRoleUser();
    // await this.getListStatus();
  }

  // public getListStatus() {
  //   return new Promise<any>((resolve) => {
  //     this.dbOperationService.getDicLists(901).subscribe((response) => {
  //       if (!this.commonService.checkNoneResponse(response)) {
  //         this.listStatus = response.body;
  //         this.getAgendaCategoryList();
  //       } else {
  //         this.listStatus = null;
  //       }
  //       resolve(null);
  //     });
  //   });
  // }

  public getAuthRoleUser() {
    return new Promise<any>((resolve) => {
      this.loginService.getLoginUser().subscribe((response) => {
        // this.loadingState.loadSleepEnd(0.3);

        if (this.commonService.checkNoneResponse(response)) {
          this.loginService.logout(MESSAGE_CODE.E90000);
        } else {
          this.userInfo = response.body;
          this.class_id = response.body.class_id;
          this.isLoading = false;
          this.getResultTaskDataList();
        }
        resolve(null);
      });
    });
  }

  public checkParamFollowRole(): void {
    const dataParms = JSON.parse(JSON.stringify(this.c502SearchForm.value));
    return dataParms;
  }

  // /**
  //  * 課題・ソリューション結果を取得
  //  * @param c502SearchForm 検索条件
  //  */
  public getResultTaskDataList(screenSearchFlg?: boolean): void {
    // 画面.検索フォームから検索条件を取得
    // ※値渡し
    const c502SearchForm: any = this.checkParamFollowRole();

    for (const key in c502SearchForm) {
      if (Object.prototype.hasOwnProperty.call(c502SearchForm, key)) {
        if (c502SearchForm[key] == null) {
          c502SearchForm[key] = '';
        }
      }
    }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_GET_MATCHING_AGENDA_SOLUTIONS);
    const table = "matching"
    // 課題・ソリューション一覧を取得
    this.dbOperationService
      .getForkJoinDataNoTemplate(table, API_URL_GET_MATCHING_AGENDA_SOLUTIONS, c502SearchForm)
   
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          // 提案検索結果結果を格納
          this.resultTaskDataList = response.body;
        } else {
          this.resultTaskDataList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(
          0.3,
          API_URL_GET_MATCHING_AGENDA_SOLUTIONS
        );

        //return to first page
        this.first = 0;
      });

    // テーブル状態が存在するか否か
    if (this.table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      this.table.reset();
    }
  }

  // /**
  //  * マッチング提案掲示板(C601)へ遷移する
  //  * @param suggestId 提案ID
  //  */
  // public moveNextPage(match_id: string, match_type: any): void {
  //   // マッチング提案掲示板(C601)へ遷移
  //       this.moveScreenByMatchType(match_id, match_type);
   
  // }

  // public moveScreenByMatchType(match_id: string, match_type: string) {
  //   this.matchId = match_id;
  //   if (match_type == MATCH_TYPE.AGENDA_SOLUTION) {
  //     this.isShowDialogC331 = true;
  //   } else if (match_type == MATCH_TYPE.AGENDA_NEED) {
  //     this.isShowDialogC341 = true;
  //   }
  // }

  // public checkLargeCategory(type: string, list: any, controlName: string): void {
  //   // 画面.検索フォーム.カテゴリを取得
  //   let getList = this.c502SearchForm.get(controlName).value;
   
  //   // チェック対象小項目リストからコード値のみ取得
  //   let spCodeList 
  //   if(controlName !== 'category')
  //   spCodeList = this.commonService.createArrayGetArrayObject(
  //     list,
  //     "sp_code"
  //   );
  //   else spCodeList = this.commonService.createArrayGetArrayObject(
  //     list,
  //     "value"
  //   );
  //   let result = [];
  //   // 該当のカテゴリコードを全て削除してresult変数に格納
  //   if (getList) {
  //     result = getList.filter((value) => !spCodeList.includes(value));
  //   }

  //   // チェック状態の判定

  //   // チェック状態の場合
  //   if (type == "checkAll") {
  //     // result変数に選択したカテゴリコードリストを追加
  //     result = result.concat(spCodeList);
  //   }
  //   else {
  //     result = result.filter((val) => !spCodeList.includes(val))
  //   }
   
  //   // 画面.検索フォーム.地域に設定
  //   this.c502SearchForm.get(controlName).setValue(result);
  // }

  // public checkMediumCategory(event: any, code: string, list: any): void {
  //   // 画面.検索フォーム.カテゴリを取得
  //   let getList = this.c502SearchForm.get("category").value;

  //   // チェック状態の判定
  //   if (event.checked) {
  //     // チェック状態の場合

  //     // チェック対象小項目リストからコード値のみ取得
  //     let spCodeList = this.commonService.createArrayGetArrayObject(
  //       list,
  //       "value"
  //     );

  //     // チェック対象小項目リストが全てチェック状態の場合
  //     if (spCodeList.every((spCode) => getList.indexOf(spCode) != -1)) {
  //       // 画面.検索フォーム.カテゴリに大項目コードを追加
  //       getList.push(code);

  //       // 画面.検索フォーム.カテゴリに設定
  //       this.c502SearchForm.get("category").setValue(getList);
  //     }
  //   } else {
  //     // 非チェック状態の場合

  //     // 画面.検索フォーム.カテゴリから大項目コードを削除
  //     this.c502SearchForm
  //       .get("category")
  //       .setValue(getList.filter((value) => value != code));
  //   }
  // }

  // isDisabledTab(controlName) {
  //   const value = this.c502SearchForm.get(controlName).value
  //   if(value == null){
  //     return false
  //   }
  //   else if(value.length > 0 )
  //   return true;

  //   else return false
  // }

  // isDisabledMediumTab(medumTabName:string) {
  //   const value = this.c502SearchForm.get('category')?.value;
  //   for (let index = 0; index < this.categoryList.length; index++) {
  //     let tab:any = this.categoryList[index];
  //     if (medumTabName == tab.class_cd1) {
  //       for (let index = 0; index < tab.cd2.length; index++) {
  //         const element = tab.cd2[index];
  //         if (value && value.indexOf(element.value) != -1) {
  //           return true;
  //         }
  //       }
  //       break;
  //     }
  //   }
  //   return false;
  // }

  // public moveC701(matchId: any) {
  //   this.router.navigate(["pages/platform/c701/new"], {
  //     queryParams: {
  //       screenId: "c701",
  //       match_id: matchId,
  //     },
  //   });
  // }

  // public setClassStatus(matchStatus: any) {
  //   let classActiveColor = CONSTANT.EMPTY_STRING;
  //   switch (matchStatus) {
  //     case MATCH_STATUSES.REQUEST:
  //     case  MATCH_STATUSES.PROPOSE:
  //       classActiveColor = "text-yellow-color";
  //       break;

  //     case MATCH_STATUSES.ADJUSTMENT:
  //       classActiveColor = "text-green-color";
  //       break;

  //     case MATCH_STATUSES.DECISION:
  //       classActiveColor = "text-blue-color";
  //       break;
  //     case MATCH_STATUSES.IMPLEMENTED:
  //       classActiveColor = "text-red-color";
  //       break;
  //     case  MATCH_STATUSES.END:
  //       classActiveColor = "text-black-color";
  //       break;
  //     case MATCH_STATUSES.EVALUATION_ACHIEVEMENT_UNREGISTERED:
  //     case MATCH_STATUSES.UNRATED:
  //       classActiveColor = "text-red-color";
  //       break;
  //     default:
  //       classActiveColor = "text-default-color";
  //       break;
  //   }
  //   return classActiveColor;
  // }

  // public showDialogEvaluate(match_id: string): void {
  //   this.isShowDialogEvaluate = true;
  //   this.matchId = match_id;
  // }

  // public closeDialogEvaluation(openDialog: boolean): void {
  //   this.isShowDialogEvaluate = openDialog;
  // }

  // public closeDialogC331(openDialog: boolean): void {
  //   this.isShowDialogC331 = openDialog;
  // }
  // public closeDialogC341(openDialog: boolean): void {
  //   this.isShowDialogC341 = openDialog;
  // }
  moveC601(matchId) {
    localStorage.setItem('isM4', this.isM4)
    this.router.navigate(["pages/platform/c601", matchId])
  }
  /**
   * check overflow of element
   * @param el html element of list 
   * @returns 
   */
   isOverflow(el: HTMLElement): boolean {
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === "visible")
      el.style.overflow = "hidden";
    const isOverflowing = el.clientWidth < el.scrollWidth
      || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  // reloadData(){
  //   this.getResultTaskDataList();
  // }
}
