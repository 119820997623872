import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AUTH_CODES, AUTH_CODES_SEND } from "manager/http-constants";
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { CONSTANT, LOADING_KEY } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { Validator } from "src/app/shared/validator/validator";
import { PlatformComponent } from "../platform.component";

@Component({
  selector: "app-a203",
  templateUrl: "./a203.component.html",
  styleUrls: ["./a203.component.scss", "../platform.component.scss"],
})

/**
 * 入会申請メールアドレス確認
 */
export class A203Component extends PlatformComponent {
  // 画面.入力フォーム
  // 確認コード
  code = new FormControl(CONSTANT.EMPTY_STRING, {
    asyncValidators: [
      this.validator.CheckValidator(
        "authcode",
        "code",
        INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
      ),
    ],
  });

  // eメールアドレス
  email: string = this.route.snapshot.queryParams.email;
  class_id: string = this.route.snapshot.queryParams.class_id;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private validator: Validator,
    private messageData: MessageData
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * 確認コード再送信
   */
  public resendCode(): void {
    // 認証コード再送信
    this.dbOperationService
      .insertData(AUTH_CODES_SEND, {
        email: this.email,
      })
      .subscribe((response) => {
        // レスポンス結果を判定
        if (API_RESPONSE.SUCCESS == response.body.Message) {
          // 正常終了の場合

          // 正常終了終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.SUCCESS,
              summary: this.commonService.msg(MESSAGE_CODE.T00014),
              detail: this.commonService.msg(MESSAGE_CODE.I00019),
            })
          );
        } else {
          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.T00014),
              detail: this.commonService.msg(MESSAGE_CODE.E00029),
            })
          );
        }
      });
  }

  /**
   * 入会申請完了(A204)へ遷移する
   */
  public moveA204(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.DATA_INSERT);

    // 確認コードチェックを実施
    this.dbOperationService
      .insertData(AUTH_CODES, {
        email: this.email,
        code: this.code.value,
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

        // レスポンス結果を判定
        if (API_RESPONSE.SUCCESS == response.body.Message) {
          // 正常終了の場合

          this.router.navigate(["pages/platform/a204"], {
            queryParams: {
              class_id: this.class_id
            },
          });
        } else {
          // 異常終了の場合

          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00002),
              detail: this.commonService.msg(MESSAGE_CODE.E00027),
            })
          );
        }
      });
  }
}
