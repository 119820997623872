<div class="main" *ngIf="!isLoading">
  <div class="main-content">
    <div class="p-fluid p-formgrid p-grid search-screen">
      <div class="p-col-12">
        <div class="p-fluid p-formgrid p-grid search-screen-col grid">
          <div class="p-col-3 p-lg-3 p-md-3 p-xl-3 p-flex-sm-nowrap side-left">
            <div class="p-fluid p-formgrid p-grid">
              <div
                class="p-col-12"
                [formGroup]="c502SearchForm"
                (ngSubmit)="getResultTaskDataList(true)"
              >
                <p-accordion [multiple]="true">
                  <p-accordionTab header="ステータス">
                    <p-dropdown
                      [options]="getDicList(114)"
                      optionLabel="sp_name"
                      optionValue="sp_code"
                      placeholder="ステータス"
                      [showClear]="true"
                      styleClass="information-Form-dropdown p-datatable-striped master-datatable center"
                      formControlName="match_status_agenda"
                    ></p-dropdown>
                  </p-accordionTab>

                  <p-accordionTab
                    header="活動の場名称"
                  >
                    <input
                      id="agenda_need_title"
                      type="text"
                      pInputText
                      formControlName="agenda_need_title"
                      autocomplete="off"
                    />
                  </p-accordionTab>

                  <p-accordionTab
                    header="活動内容"
                  >
                    <input
                      id="expected_content"
                      type="text"
                      pInputText
                      formControlName="agenda_need_expected_content"
                      autocomplete="off"
                    />
                  </p-accordionTab>

                  <p-accordionTab header="応募・依頼区分">
                    <p-dropdown
                      [options]="getDicList(109)"
                      optionLabel="sp_name"
                      optionValue="sp_code"
                      placeholder="区分"
                      [showClear]="true"
                      styleClass="information-Form-dropdown p-datatable-striped master-datatable center"
                      formControlName="match_match_type"
                    ></p-dropdown>
                  </p-accordionTab>

                  <p-accordionTab header="開催日">
                    <p class="text-start-time">開催日</p>
                    <p-calendar
                      [showIcon]="true"
                      [showButtonBar]="true"
                      [yearNavigator]="true"
                      [monthNavigator]="true"
                      [readonlyInput]="true"
                      placeholder=""
                      dateFormat="yy/mm/dd"
                      dataType="string"
                      appendTo="body"
                      yearRange="2000:2999"
                      formControlName="agenda_need_event_date_FROM"
                      translate
                    ></p-calendar>
                    <p class="text-connect-time center">から</p>
                    <p-calendar
                      [showIcon]="true"
                      [showButtonBar]="true"
                      [yearNavigator]="true"
                      [monthNavigator]="true"
                      [readonlyInput]="true"
                      placeholder=""
                      dateFormat="yy/mm/dd"
                      dataType="string"
                      appendTo="body"
                      yearRange="2000:2999"
                      formControlName="agenda_need_event_date_TO"
                      translate
                    ></p-calendar>
                  </p-accordionTab>

                  <p-accordionTab
                    header="マッチングID"
                  >
                    <input
                      id="match_match_id"
                      type="text"
                      pInputText
                      formControlName="match_match_id"
                      autocomplete="off"
                    />
                  </p-accordionTab>
                </p-accordion>
              </div>
              <div class="p-col-12 btn-block-search">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-12">
                    <button
                      pButton
                      type="button"
                      label="絞り込む"
                      class="p-button-danger btn-search-item"
                      (click)="getResultTaskDataList()"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-9 list-matches">
            <div class="p-fluid p-formgrid p-grid detail-item">
              <div class="p-col-12">
                <div class="card">
                  <p-table
                    [value]="resultTaskDataList"
                    [paginator]="true"
                    [rows]="30"
                    [scrollable]="true"
                    scrollHeight="70vh"
                    [(first)]="first"
                  >
                    <ng-template pTemplate="header">
                      <tr class="list-match-col">
                        <th class="list-match-title">マッチングID</th>
                        <th class="list-match-contact">サポーター</th>
                        <th class="list-match-status">主催者</th>
                        <th class="list-match-start-date">活動の場名称</th>
                        <th class="list-match-notice">開催日</th>
                        <th class="list-match-notice">区分</th>
                        <th class="list-match-notice">ステータス</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                      <tr class="body-row">
                        <td
                          class="content-item"
                          [pTooltip]="item?.match_id"
                          #match_id
                          [tooltipDisabled]="!isOverflow(match_id)"
                          showDelay="500"
                          hideDelay="500"
                          (click)="moveC601(item?.match_id)"
                        >
                          <u class="pointer">{{ item?.match_id }}</u>
                        </td>
                        <td
                          class="content-item"
                          [pTooltip]="item?.seeds_member_organization_name"
                          #seeds_member_organization_name
                          [tooltipDisabled]="!isOverflow(seeds_member_organization_name)"
                          showDelay="500"
                          hideDelay="500"
                          (click)="moveC601(item?.match_id)"
                        >

                          <span class="default">{{ item?.seeds_member_organization_name }}</span>
                        </td>
                        <td
                          class="content-item"
                          [pTooltip]="item?.needs_member_organization_name"
                          #needs_member_organization_name
                          [tooltipDisabled]="!isOverflow(needs_member_organization_name)"
                          showDelay="500"
                          hideDelay="500"
                          (click)="moveC601(item?.match_id)"
                        >

                          <span class="default">{{ item?.needs_member_organization_name }}</span>
                        </td>
                        <td
                          class="content-item"
                          [pTooltip]="item?.title"
                          #title
                          [tooltipDisabled]="!isOverflow(title)"
                          showDelay="500"
                          hideDelay="500"
                          (click)="moveC601(item?.match_id)"
                        >
                          <span class="default">{{ item?.title }}</span>
                        </td>
                        <td
                          class="content-item"
                          [pTooltip]="item?.event_date"
                          #date
                          [tooltipDisabled]="!isOverflow(date)"
                          showDelay="500"
                          hideDelay="500"
                          (click)="moveC601(item?.match_id)"
                        >
                          <span class="default">{{ item?.event_date }}</span>
                        </td>

                        <td
                          class="content-item"
                          [pTooltip]="item?.match_type"
                          #match_type
                          [tooltipDisabled]="!isOverflow(match_type)"
                          showDelay="500"
                          hideDelay="500"
                          (click)="moveC601(item?.match_id)"
                        >
                          <span class="default">{{ item?.match_type }}</span>
                        </td>
                        <td
                          class="content-item"
                          [pTooltip]="item?.match_status_agenda"
                          #match_status_agenda
                          [tooltipDisabled]="!isOverflow(match_status_agenda)"
                          showDelay="500"
                          hideDelay="500"
                          (click)="moveC601(item?.match_id)"
                        >
                        <span class="default">{{ item?.match_status_agenda }}</span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template 
                      pTemplate="emptymessage" 
                      let-columns
                    >
                      <div class="emptymessage">
                        応募・依頼がありません。
                      </div>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-evaluation-dialog
  *ngIf="isShowDialogEvaluate"
  (returnStatusDialog)="closeDialogEvaluation($event)"
  [matchId]="matchId"
  (reload)="reloadData()"
></app-evaluation-dialog>
<app-c331-dialog
  *ngIf="isShowDialogC331"
  (returnStatusDialogC331)="closeDialogC331($event)"
  (reloadData)="reloadData()"
  [matchId]="matchId"
></app-c331-dialog>
<app-c341-dialog
  *ngIf="isShowDialogC341"
  (returnStatusDialogC341)="closeDialogC341($event)"
  (reloadData)="reloadData()"
  [matchId]="matchId"
></app-c341-dialog>
