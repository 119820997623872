<div class="main">
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <app-b220-steps></app-b220-steps>

    <div class="information-margin"></div>

    <div class="message">
      <div class="message-text">
        <div class="p-d-flex p-jc-center">
          <i class="pi pi-check-circle black-color"></i><br />
        </div>
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center">
          <h2 class="black-color">ユーザー登録を登録・更新いたしました。</h2>
        </div>
      </div>
      <div class="message-sub" >
        <div class="p-d-flex p-jc-center">
          <h4 >
            新規登録の場合、登録したメールアドレスへ登録通知メールが送信されます。
          </h4>
        </div>
      </div>

      <div class="p-d-flex p-jc-center button-area">
        <button
          pButton
          type="button"
          label="ユーザー管理へ戻る"
          class="button-default-size btn-back button1"
          (click)="moveB220()"
        ></button>
      </div>
    </div>
  </div>
</div>
