import { Component, ViewChild } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MEMBERS_PROFILE_APPROVE,
  MEMBERS_AGENDA,
  MEMBERS_DETAIL,
  MEMBERS_DETAIL_LOG,
  API_URL_INPUT_MASTER,
  API_URL_AGENDA_SOLUTION_ADD,
  API_URL_AGENDA_NEED_ADD_EDIT,
} from 'manager/http-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { DATE_FORMAT, ROLES } from 'src/app/shared/constant/constant';
import { ApprovalDialogComponent } from '../common/approval-dialog/approval-dialog.component';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { AGENDA_SOLUTION_M301_TEMPLATE, AGENDA_SOLUTION_M401_TEMPLATE } from 'manager/template-constant';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-b211',
  templateUrl: './b211.component.html',
  styleUrls: ['./b211.component.scss', '../platform.component.scss',"../master/master.component.scss",],
})

/**
 * 会員プロフィール(自治体)
 */
export class B211Component extends PlatformComponent {
  /** 共通部品_承認時ダイアログ */
  @ViewChild(ApprovalDialogComponent)
  approvalDialogComponent: ApprovalDialogComponent;

   /** 新規登録、編集、複製画面 */
   @ViewChild(GenerateInputComponent)
   generateInputComponent: GenerateInputComponent;

  // 会員ID
  memberId: string = this.route.snapshot.params.member_id;

  // 課題ID
  agendaId: string = this.route.snapshot.params.agenda_id;
  back: string = this.route.snapshot.queryParams.back;

  // 会員情報格納先
  memberInformation: any;

  class_id: string;

  loginUserMemberId:any ='';

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private messageData: MessageData,
    private location: Location,
    private datePipe: DatePipe,
    private router: Router,
    private loginService: LoginService,
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.getLoginUserMemberId();
    // 会員情報を取得
    this.getMemberInformation();
  }

  getLoginUserMemberId(){
    this.loginService.getLoginUser().subscribe((response) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3);

      // ログインユーザ情報がユーザマスタに存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        // ユーザマスタに存在しない場合

        // 不正なユーザの為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.E90000);

        // 処理を終了
        return;
      } else {
        // ユーザマスタに存在する場合
        this.loginUserMemberId = response.body.class_id;
      }
    });
  }

  /**
   * 会員情報を取得
   */
  public getMemberInformation(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(MEMBERS_DETAIL);

    // 会員詳細を取得
    this.dbOperationService
      .getNoTemplateDisplayData(MEMBERS_DETAIL, this.memberId, true)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, MEMBERS_DETAIL);

        if (this.commonService.checkNoneResponse(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );

          // 前画面へ戻る
          this.location.back();
        }else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.location.back();
        }  else {
          // 会員詳細を格納
          this.memberInformation = response.body;
        }
      });
  }


  /**
   * 自治体会員登録・更新画面(入力)(B101)へ遷移する
   */
  public moveB101(): void {
    // 自治体会員登録・更新画面(入力)(B101)へ遷移
    this.router.navigate(['pages/platform/b101/edit'], {
      queryParams: {
        screenId: 'b101',
        member_id: this.memberId,
        class_id: this.memberInformation.member_class_id.value,
      },
    });
  }

  /**
   *  課題・ソリューション登録(C101)へ遷移する
   * @param agendaKbn 区分 1:課題　2:ソリューション
   */
  public moveC101(agendaKbn: number): void {
    // 課題・ソリューション登録(C101)へ遷移
    this.router.navigate(['pages/platform/c101/new'], {
      queryParams: {
        screenId: 'c101',
        agenda_kbn: agendaKbn,
      },
    });
  }

  /**
   * 内閣府承認ダイアログ表示
   */
  public openSmallApprovalDialog(): void {
    // 内閣府承認ダイアログ表示
    this.approvalDialogComponent.openSmallApprovalDialog(
      MEMBERS_PROFILE_APPROVE,
      this.memberId,
      2002,
      0,
      1
    );
  }

 

  /**
   * 課題・ソリューション詳細(C301)へ遷移する
   * @param agendaId 課題ID
   */
   moveM301orM401(classId) {
    if(classId == ROLES.PROVIDER){
        // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_MASTER,
      API_URL_AGENDA_SOLUTION_ADD,
      API_URL_AGENDA_SOLUTION_ADD,
      AGENDA_SOLUTION_M301_TEMPLATE.INPUT_TEMPLATE_ID,
      null
    );
    }
    else if(classId == ROLES.TEACHER) {
      // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_MASTER,
      API_URL_AGENDA_NEED_ADD_EDIT,
      API_URL_AGENDA_NEED_ADD_EDIT,
      AGENDA_SOLUTION_M401_TEMPLATE.INPUT_TEMPLATE_ID,
      null
    );
    }
   }


  /**
   * 会員一覧(A110)へ遷移する
   *
   */
  public moveA110(): void {
    //会員一覧(A110)へ遷移する
    this.router.navigate(['pages/platform/a110']);
  }

}
