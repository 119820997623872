<div class="container">
  <div class="progress-container">
    <div
      class="progress"
      id="progress"
      [ngStyle]="{ width: progressWidth }"
    ></div>
    <div class="circle" [ngClass]="{ active: isActiveStep(1) }">
      <span class="first-step"></span>
      <span
        class="first-step-text"
        [ngClass]="{ 'active-text': isActiveText(1) }"
        >ユーザー登録・更新</span
      >
    </div>
    <div class="circle" [ngClass]="{ active: isActiveStep(2) }">
      <span class="second-step"></span>
      <span
        class="second-step-text"
        [ngClass]="{ 'active-text': isActiveText(2) }"
        >登録内容確認</span
      >
    </div>
    <div class="circle" [ngClass]="{ active: isActiveStep(3) }">
      <span class="third-step"></span>
      <span
        class="third-step-text"
        [ngClass]="{ 'active-text': isActiveText(3) }"
        >登録完了</span
      >
    </div>
  </div>
</div>
