import { Component, OnInit } from '@angular/core';
import { ConfirmDialogData } from './confirm-dialog-data';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})

/**
 * 確認ダイアログ
 */
export class ConfirmDialogComponent implements OnInit {
  // 確認ダイアログ状態
  confirmDialog = this.confirmDialogData;

  constructor(private confirmDialogData: ConfirmDialogData) {}

  ngOnInit(): void {}
}
