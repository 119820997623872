<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- A210系共通部品_Steps -->
    <app-a210-steps></app-a210-steps>

    <!-- メッセージ -->
    <div class="message">
      <!-- メッセージ本文 -->
      <div class="message-text">
        <div class="p-d-flex p-jc-center black-color">
          <i class="pi pi-envelope"></i><br />
        </div>
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center black-color">
          パスワード設定メールを送信しました
        </div>
      </div>
    </div>

    <!-- 本文 -->
    <div class="p-d-flex p-jc-center">
      <div class="p-flex-wrap sparse-text">
        入力したメールアドレス宛にパスワード設定用ＵＲＬを送信しました。そのＵＲＬからパスワードを設定してください。<br />
        パスワード設定メールが届かない場合、<a routerLink="/pages/platform/a210" target="_blank">こちら</a>から、再度ＩＤパスワード設定メールを送信してください。<br />
      </div>
    </div>
  </div>
</div>

<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>
