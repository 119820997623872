<div class="main">
  <div class="main-content" *ngIf="!confirmationFlag">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-jc-center content-detail">
        <div class="steps p-field p-col-12">
          <app-a200-steps
            [class_id]="a201ApplicationInputForm.get('class_id').value"
          ></app-a200-steps>
        </div>

        <div class="p-grid form">
          <div
            class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
          >
            <div class="form-content p-col-8 p-md-12 p-sm-12">
              <form [formGroup]="a201ApplicationInputForm" autocomplete="off">
                <div class="form-container">
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="zip-code" class="zip-code-label"
                        >団体組織区分</label
                      >
                    </div>
                    <div class="p-col-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div
                      class="p-col-10 p-md-9 role-row"
                    >
                      <p-radioButton
                        value="1"
                        label="学校・教員"
                        formControlName="class_id"
                        class="school-teacher-role"
                        (ngModelChange)="resetForm()"
                      ></p-radioButton>

                      <p-radioButton
                        value="2"
                        label="支援人材・会社"
                        formControlName="class_id"
                        class="provider-role"
                        (ngModelChange)="resetForm()"
                      ></p-radioButton>
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="a201ApplicationInputForm.get('class_id')"
                      >
                      </app-validator-message>
                    </div>
                  </div>

                  <div class="p-grid">
                    <div class="p-col-1 p-md-2 p-xxl-1">
                      <label for="school" class="school-label"
                        >団体組織名</label
                      >

                    </div>
                    <div class="p-col-1 p-md-1 p-xxl-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9 p-xxl-10">
                      <input
                        id="school"
                        class="input-school"
                        type="text"
                        pInputText
                        formControlName="organization_name"
                        autocomplete="off"
                        [ngClass]='checkInputMaxBoundary(a201ApplicationInputForm,"organization_name")?"invalid-length":""'
                      />
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="
                          a201ApplicationInputForm.get('organization_name')
                        "
                      >
                      </app-validator-message>
                    </div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2 p-xxl-1">
                      <label for="school" class="school-label"
                        >氏名</label
                      >

                    </div>
                    <div class="p-col-1 p-md-1 p-xxl-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9 p-xxl-10">
                      <input
                        id="school"
                        class="input-school"
                        type="text"
                        pInputText
                        formControlName="full_name"
                        autocomplete="off"
                        [ngClass]='checkInputMaxBoundary(a201ApplicationInputForm,"full_name")?"invalid-length":""'
                      />
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="
                          a201ApplicationInputForm.get('full_name')
                        "
                      >
                      </app-validator-message>
                    </div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="zip-code" class="zip-code-label"
                        >郵便番号</label
                      >
                    </div>
                    <div class="p-col-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-3">
                      <input
                        id="zip-code"
                        class="input-zip-code-after"
                        type="text"
                        pInputText
                        formControlName="postal_no"
                        (change)="getPostalsList($event)"
                        [ngClass]='checkInputMaxBoundary(a201ApplicationInputForm,"postal_no")?"invalid-length":""'
                        autocomplete="off"
                      />
                    </div>

                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="a201ApplicationInputForm.get('postal_no')"
                      >
                      </app-validator-message>
                    </div>
                  </div>

                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="address" class="address-label"
                        >都道府県</label
                      >
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>

                    <div class="p-col-3">
                      <p-dropdown
                        inputId="city"
                        optionLabel="name"
                        optionValue="value"
                        class="select-city"
                        formControlName="prefectures"
                        [options]="postalNoList"
                        placeholder="都道府県"
                        [showClear]="false"
                      ></p-dropdown>
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="a201ApplicationInputForm.get('prefectures')"
                      >
                      </app-validator-message>
                    </div>
                  </div>

                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="city" class="city-label"> 所在地</label>
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9">
                      <input
                        id="address"
                        class="input-address"
                        type="text"
                        pInputText
                        formControlName="address_building"
                        autocomplete="off"
                        [ngClass]='checkInputMaxBoundary(a201ApplicationInputForm,"address_building")?"invalid-length":""'
                      />
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="
                          a201ApplicationInputForm.get('address_building')
                        "
                      >
                      </app-validator-message>
                    </div>
                  </div>

                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="phone-number" class="phone-number-label"
                        >電話番号</label
                      >
                    </div>
                    <div class="p-col-1 required-end">
                      <span class="required">*必須</span>
                    </div>

                    <div class="p-col-4">
                      <input
                        id="phone-number"
                        class="input-phone-number-right"
                        type="text"
                        pInputText
                        formControlName="tel"
                        autocomplete="off"
                        [ngClass]='checkInputMaxBoundary(a201ApplicationInputForm,"tel")?"invalid-length":""'
                      />
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="a201ApplicationInputForm.get('tel')"
                      >
                      </app-validator-message>
                    </div>
                  </div>

                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="mail-address" class="mail-address-label"
                        >メールアドレス</label
                      >
                    </div>
                    <div class="p-col-1 p-md-1 required-end">
                      <span class="required">*必須</span>
                    </div>
                    <div class="p-col-10 p-md-9">
                      <input
                        id="mail-address"
                        class="input-mail-address"
                        type="text"
                        pInputText
                        formControlName="email"
                        autocomplete="off"
                        [ngClass]='checkInputMaxBoundary(a201ApplicationInputForm,"email")?"invalid-length":""'
                      />
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="a201ApplicationInputForm.get('email')"
                      >
                      </app-validator-message>
                    </div>
                  </div>

                  <div class="p-grid">
                    <div class="p-col-1 p-md-2">
                      <label for="edu-support" class="edu-support-label"
                        >過去の教育支援</label
                      >
                    </div>
                    <div class="p-col-1 p-md-1"></div>
                    <div class="p-col-10 p-md-9">
                      <textarea
                        id="edu-support"
                        class="input-edu-support"
                        pInputTextarea
                        formControlName="history_support_result"
                        rows="3"
                        autocomplete="off"
                        [ngClass]='checkInputMaxBoundary(a201ApplicationInputForm,"history_support_result")?"invalid-length":""'
                      >
                      </textarea>
                    </div>
                    <div class="p-col-10 p-md-9 error-message">
                      <app-validator-message
                        [control]="
                          a201ApplicationInputForm.get('history_support_result')
                        "
                      >
                      </app-validator-message>
                    </div>
                  </div>
                </div>
              </form>
            </div>


            <div class="form-content register p-col-8">
              <div class="p-grid p-grid-register">
                <div class="p-col-4 btn-block p-md-3">
                  <button
                    pButton
                    type="button"
                    label="確認画面へ"
                    class="btn-register"
                    (click)="moveA202()"
                    [disabled]="
                       !a201ApplicationInputForm.valid
                    "
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content" *ngIf="confirmationFlag">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-jc-center content-detail">
        <div class="steps p-field p-col-12">
          <app-a200-steps
            [class_id]="a201ApplicationInputForm.get('class_id').value"
          ></app-a200-steps>
        </div>

        <div class="p-grid form">
          <div
            class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center"
          >
            <div class="form-content p-col-8 p-md-12 p-sm-12">
              <div class="form-cofirm-container">
                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="school" class="school-label"
                      >団体組織区分</label
                    >
                  </div>
                  <div class="p-col-1 p-md-1 required-end">
                    <span class="required">*必須</span>
                  </div>
                  <div
                    class="p-col-10 p-md-9 text-center p-d-flex"
                  >
                    <div class="star-rate">
                      <span
                        class="circle-role"
                        *ngIf="
                          a201ApplicationInputForm.get('class_id').value == '1'
                        "
                      ></span>
                      <span class="star-rate-number"
                        >学校・教員</span
                      >
                    </div>
                    <div class="star-rate role-after">
                      <span
                        class="circle-role"
                        *ngIf="
                          a201ApplicationInputForm.get('class_id').value == '2'
                        "
                      ></span>
                      <span class="star-rate-number"
                        >支援人材・会社</span
                      >
                    </div>
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="school" class="school-label">団体組織名</label>
                  </div>
                  <div class="p-col-1 p-md-1 required-end">
                    <span class="required">*必須</span>
                  </div>
                  <div class="p-col-10 p-md-9 text-center">
                    {{ a201ApplicationInputForm.value?.organization_name }}
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="school" class="school-label">氏名</label>
                  </div>
                  <div class="p-col-1 p-md-1 required-end">
                    <span class="required">*必須</span>
                  </div>
                  <div class="p-col-10 p-md-9 text-center">
                    {{ a201ApplicationInputForm.value?.full_name }}
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="zip-code" class="zip-code-label"
                      >郵便番号</label
                    >
                  </div>
                  <div class="p-col-1 required-end">
                    <span class="required">*必須</span>
                  </div>
                  <div class="p-col-3 text-center">
                    {{ a201ApplicationInputForm.value?.postal_no }}
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="address" class="address-label">都道府県</label>
                  </div>
                  <div class="p-col-1 p-md-1 required-end">
                    <span class="required">*必須</span>
                  </div>

                  <div class="p-col-3 text-center">
                    {{ this.postalNoList[0].name }}
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="city" class="city-label"> 所在地</label>
                  </div>
                  <div class="p-col-1 p-md-1 required-end">
                    <span class="required">*必須</span>
                  </div>
                  <div class="p-col-10 p-md-9 text-center">
                    {{ a201ApplicationInputForm.value?.address_building }}
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="phone-number" class="phone-number-label"
                      >電話番号</label
                    >
                  </div>
                  <div class="p-col-1 required-end">
                    <span class="required">*必須</span>
                  </div>

                  <div class="p-col-4 text-center">
                    {{ a201ApplicationInputForm.value?.tel }}
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="mail-address" class="mail-address-label"
                      >メールアドレス</label
                    >
                  </div>
                  <div class="p-col-1 p-md-1 required-end">
                    <span class="required">*必須</span>
                  </div>
                  <div class="p-col-10 p-md-9 text-center">
                    {{ a201ApplicationInputForm.value?.email }}
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-1 p-md-2">
                    <label for="edu-support" class="edu-support-label"
                      >過去の教育支援</label
                    >
                  </div>
                  <div class="p-col-1 p-md-1"></div>
                  <div class="p-col-10 p-md-9 text-center text-newline">
                    {{ a201ApplicationInputForm.value?.history_support_result }}
                  </div>
                </div>

                <div
          class="form-content register p-col-12"
        >
          <div class="p-grid p-grid-register center">
            <div class="p-col-4 btn-block p-md-3">
              <button
                pButton
                type="button"
                label="登録する"
                class="btn-register"
                (click)="moveA203()"
              ></button>
            </div>
            <div class="p-col-4 btn-block p-md-3">
              <button
              pButton
              type="button"
              label="入力画面へ戻る"
              class="button2 btn-register"
              (click)="moveA201()"
            ></button>
            </div>
          </div>
        </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
