import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MEMBER_COUNT,
  AGENDA_CATEGORY_LIST,
  MEMBERS_LIST,
} from 'manager/http-constants';
import { Table } from 'primeng/table';
import { DICVALUES_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { CONSTANT, DICID_NUMBER } from 'src/app/shared/constant/constant';
import { LOCAL_CATEGORY } from 'src/app/shared/constant/local';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';

@Component({
  selector: 'app-a110',
  templateUrl: './a110.component.html',
  styleUrls: ['./a110.component.scss', '../platform.component.scss'],
})

/**
 * 会員検索（会員一覧）
 */
export class A110Component extends PlatformComponent {
  // 画面.検索フォーム
  a110SearchForm: FormGroup = this.formBuilder.group({
    // フリーワード検索
    freeword: CONSTANT.EMPTY_STRING,
    // 会員種別
    class_id: CONSTANT.EMPTY_STRING,
    // 地域(都道府県)
    prefectures: CONSTANT.EMPTY_STRING,
    // カテゴリ
    category: CONSTANT.EMPTY_STRING,
    // 業種
    industry_id: CONSTANT.EMPTY_STRING,
    // // めざすゴール
    // sdgs_goal: CONSTANT.EMPTY_STRING,
  });

  /** テーブル状態 */
  @ViewChild('table') table: Table;

  // 会員種別のAccordion開閉フラグ
  accordion_class_id: boolean = false;

  // 都道府県のAccordion開閉フラグ
  accordion_prefectures: boolean = false;

  // 都道府県の中項目Accordion開閉フラグ
  accordion_prefectures_detail = new Map();

  // カテゴリのAccordion開閉フラグ
  accordion_category: boolean = false;

  // カテゴリの中項目Accordion開閉フラグ
  accordion_category_detail = new Map();

  // 更新日のAccordion開閉フラグ
  accordion_update_date: boolean = false;

  // 業種のAccordion開閉フラグ
  accordion_industry_id: boolean = false;

  // SDGsゴールのAccordion開閉フラグ
  accordion_sdgs_goal: boolean = false;

  // 会員結果
  memberResultDataList: object[] = new Array();

  // 会員件数格納先
  countMemberData: number = 0;

  // セッション保存名
  sessionTagName: string = 'a110SearchConditions';

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder,
    public datePipe: DatePipe,
    private changeDetector: ChangeDetectorRef

  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    const dicIdOfGroupType = 1;
    // 辞書値リストを取得
    this.getSpecCodeList(DICID_NUMBER.GROUP_TYPE);

    // 初期処理:クエリパラメータの設定
    this.setQueryParams();

    // 会員結果を取得
    this.getMemberResultDataList();
  }

  /**
   * 初期処理:クエリパラメータの設定
   */
  private setQueryParams(): void {
    // クエリパラメータ.会員種別
    {
      // クエリパラメータ.会員種別を取得
      const classId = this.route.snapshot.queryParams.class_id;

      // 会員種別が存在するか否か
      if (classId) {
        // 会員種別が存在する場合

        // 会員種別を画面.検索フォームに格納
        this.a110SearchForm
          .get('class_id')
          .setValue(classId.split(CONSTANT.COMMA));

        // 会員種別のAccordionを開く
        this.accordion_class_id = true;
      }
    }

    // クエリパラメータ.都道府県
    {
      // クエリパラメータ.都道府県を取得
      let prefectures = this.route.snapshot.queryParams.prefectures;

      // 都道府県が存在するか否か
      if (prefectures) {
        // 都道府県が存在する場合

        // 地域情報を配列化
        let prefecturesList: string[] = decodeURI(prefectures).split(
          CONSTANT.COMMA
        );

        // 地域カテゴリをループ
        for (const prefectures of this.prefecturesList) {
          // 地域カテゴリ.大項目のリストからsp_nameの情報で配列を生成
          let spNameList = this.commonService.createArrayGetArrayObject(
            prefectures.list,
            'sp_name'
          );

          // 地域カテゴリ.大項目のリストに存在しない数を抽出
          const noneCountPrefectures = spNameList.filter(
            (spName) => !prefecturesList.includes(spName)
          ).length;

          // 地域カテゴリ.大項目のリストに存在しない都道府県があるか否か
          if (noneCountPrefectures) {
            // ある場合

            continue;
          }

          // 大項目のコードを追加
          prefecturesList.push(prefectures.code);
        }

        // 都道府県を画面.検索フォームに格納
        this.a110SearchForm.get('prefectures').setValue(prefecturesList);

        // 都道府県のAccordionを開く
        this.accordion_prefectures = true;
      }
    }

    // クエリパラメータ.カテゴリ
    {
      // クエリパラメータ.カテゴリを取得
      const category = this.route.snapshot.queryParams.category;

      // カテゴリが存在するか否か
      if (category) {
        // カテゴリが存在する場合

        // カテゴリを画面.検索フォームに格納
        this.a110SearchForm
          .get('category')
          .setValue(category.split(CONSTANT.COMMA));

        // カテゴリのAccordionを開く
        this.accordion_category = true;
      }
    }
  }

  /**
   * 会員結果を取得
   * @param a110SearchForm 検索条件
   */
  public getMemberResultDataList(screenSearchFlg?: boolean): void {
    // 画面.検索フォームから検索条件を取得
    // ※値渡し
    let a110SearchForm: any = JSON.parse(
      JSON.stringify(this.a110SearchForm.value)
    );

    
    // 検索条件の地域が配列か否か
    if (a110SearchForm.prefectures) {
      // 検索条件の地域が配列の場合

      // 検索条件の地域から大項目(local_category)を削除する
      a110SearchForm.prefectures = a110SearchForm.prefectures.filter(
        (prefectures) => !prefectures.includes('local_category_')
      );
    }

    // 配列をカンマ区切り変換
    {
      a110SearchForm.freeword = a110SearchForm.freeword
        ? a110SearchForm.freeword.toString()
        : CONSTANT.EMPTY_STRING;

      // 会員種別
      a110SearchForm.class_id = a110SearchForm.class_id
        ? a110SearchForm.class_id.toString()
        : CONSTANT.EMPTY_STRING;

      // 地域
      a110SearchForm.prefectures = a110SearchForm.prefectures
        ? a110SearchForm.prefectures.toString()
        : CONSTANT.EMPTY_STRING;

      // カテゴリ
      a110SearchForm.category = a110SearchForm.category
        ? a110SearchForm.category.toString()
        : CONSTANT.EMPTY_STRING;

      // 業種
      a110SearchForm.industry_id = a110SearchForm.industry_id
        ? a110SearchForm.industry_id.toString()
        : CONSTANT.EMPTY_STRING;

      // めざすゴール
      a110SearchForm.sdgs_goal = a110SearchForm.sdgs_goal
        ? a110SearchForm.sdgs_goal.toString()
        : CONSTANT.EMPTY_STRING;
    }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(MEMBERS_LIST);

    // 会員詳細を取得
    this.dbOperationService
      .getNoTemplateData(MEMBERS_LIST, a110SearchForm, true)
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          // 会員結果を格納
          this.memberResultDataList = response.body;
        } else {
          this.memberResultDataList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, MEMBERS_LIST);
      });

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(MEMBER_COUNT);

    // 会員件数を取得
    this.dbOperationService
      .getNoTemplateData(MEMBER_COUNT, null, true)
      .subscribe((response) => {
        // 会員件数を格納
        this.countMemberData = response.body.count;

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, MEMBER_COUNT);
      });

    // テーブル状態が存在するか否か
    if (this.table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      this.table.reset();
    }
  }

  /**
   * カテゴリ大項目
   * @param event チェック状態
   * @param list チェック対象小項目リスト
   */
  public checkLargeCategory(e: any, list: any): void {

    // 親イベントを止める(ヘッダ開閉)
    event.stopPropagation();

    // 画面.検索フォーム.カテゴリを取得
    let getList = this.a110SearchForm.get('category').value;

    // チェック対象小項目リストからコード値のみ取得
    let spCodeList = this.commonService.createArrayGetArrayObject(
      list,
      'value'
    );

    // 該当のカテゴリコードを全て削除してresult変数に格納
    let result = getList.filter((value) => !spCodeList.includes(value));

    // チェック状態の判定
    if (e.checked) {
      // チェック状態の場合

      // result変数に選択したカテゴリコードリストを追加
      result = result.concat(spCodeList);
    }

    // 画面.検索フォーム.地域に設定
    this.a110SearchForm.get('category').setValue(result);
  }

  /**
   * カテゴリ中項目
   * @param event チェック状態
   * @param code 大項目コード
   * @param list チェック対象小項目リスト
   */
  public checkMediumCategory(event: any, code: string, list: any): void {
    // 画面.検索フォーム.カテゴリを取得
    let getList = this.a110SearchForm.get('category').value;

    // チェック状態の判定
    if (event.checked) {
      // チェック状態の場合

      // チェック対象小項目リストからコード値のみ取得
      let spCodeList = this.commonService.createArrayGetArrayObject(
        list,
        'value'
      );

      // チェック対象小項目リストが全てチェック状態の場合
      if (spCodeList.every((spCode) => getList.indexOf(spCode) != -1)) {
        // 画面.検索フォーム.カテゴリに大項目コードを追加
        getList.push(code);

        // 画面.検索フォーム.カテゴリに設定
        this.a110SearchForm.get('category').setValue(getList);
      }
    } else {
      // 非チェック状態の場合

      // 画面.検索フォーム.カテゴリから大項目コードを削除
      this.a110SearchForm
        .get('category')
        .setValue(getList.filter((value) => value != code));
    }
  }

  /**
   * 地域大項目
   * @param event チェック状態
   * @param list チェック対象小項目リスト
   */
  public checkLargePrefectures(e: any, list: any): void {

    // 親イベントを止める(ヘッダ開閉)
    event.stopPropagation();

    // 画面.検索フォーム.地域を取得
    let getList = this.a110SearchForm.get('prefectures').value;
    console.log(getList)
    // チェック対象小項目リストからコード名称のみ取得
    let spCodeList = this.commonService.createArrayGetArrayObject(
      list,
      DICVALUES_API_CONSTANT.SP_CODE
    );
    // 該当の地域コードを全て削除してresult変数に格納
    let result = getList.filter((value) => !spCodeList.includes(value));

    // チェック状態の判定
    if (e.checked) {
      // チェック状態の場合

      // result変数に選択した地域コードリストを追加
      result = result.concat(spCodeList);

    }

    // 画面.検索フォーム.地域に設定
    this.a110SearchForm.get('prefectures').setValue(result);
  }

  public newcheckLargePrefectures(checkAll: boolean, list: any): void {

    let getList = this.a110SearchForm.get('prefectures').value?this.a110SearchForm.get('prefectures').value:null;
    console.log(getList)

    let spCodeList = this.commonService.createArrayGetArrayObject(
      list,
      DICVALUES_API_CONSTANT.SP_CODE
    );
    let result = getList?getList:[];
    if (checkAll) {

      result = result.concat(spCodeList);

    }else{
      if (getList) {
        result = getList.filter((value) => !spCodeList.includes(value));
      }
    }

    this.a110SearchForm.get('prefectures').setValue(result);
  }

  public newcheckLargeCategory(checkAll: boolean,controlName: string, list: any): void {
    console.log(list)
    let getList = this.a110SearchForm.get(controlName).value;
    console.log(getList)

    let spCodeList = this.commonService.createArrayGetArrayObject(
      list,
      DICVALUES_API_CONSTANT.SP_CODE
    );
    let result = [];
    if (checkAll) {

      result = result.concat(spCodeList);

    }else{
      if (getList) {
        result = getList.filter((value) => !spCodeList.includes(value));
      }
    }

    this.a110SearchForm.get(controlName).setValue(result);
  }

  isDisabledTab(controlName) {
    const value = this.a110SearchForm.get(controlName).value;
    if (value == null) {
      return false;
    } else if (value.length > 0) return true;
    else return false;
  }

  isDisabledMediumPrefectureTab(medumTabName: string) {
    const value = this.a110SearchForm.get('prefectures').value;
    for (let index = 0; index < this.prefecturesList.length; index++) {
      let tab:any = this.prefecturesList[index];
      if (medumTabName == tab.code) {
        for (let index = 0; index < tab.list.length; index++) {
          const element = tab.list[index];
          if (value && value.indexOf(element.sp_code) != -1) {
            return true;
          }
        }
        break;
      }
    }
    return false;
  }

  /**
   * 地域中項目
   * @param event チェック状態
   * @param code 大項目コード
   * @param list チェック対象小項目リスト
   */
  public checkMediumPrefectures(event: any, code: string, list: any): void {
    // 画面.検索フォーム.地域を取得
    let getList = this.a110SearchForm.get('prefectures').value;

    // チェック状態の判定
    if (event.checked) {
      // チェック状態の場合

      // チェック対象小項目リストからコード名称のみ取得
      let spCodeList = this.commonService.createArrayGetArrayObject(
        list,
        DICVALUES_API_CONSTANT.SP_CODE
      );
      console.log(spCodeList)
      // チェック対象小項目リストが全てチェック状態の場合
      if (spCodeList.every((spCode) => getList.indexOf(spCode) != -1)) {
        // 画面.検索フォーム.地域に大項目コードを追加
        getList.push(code);

        // 画面.検索フォーム.地域に設定
        this.a110SearchForm.get('prefectures').setValue(getList);
      }
    } else {
      // 非チェック状態の場合

      // 画面.検索フォーム.地域から大項目コードを削除
      this.a110SearchForm
        .get('prefectures')
        .setValue(getList.filter((value) => value != code));
    }
  }

  /**
   * 検索条件が設定されているかチェックする
   * @param conditions
   * @returns boolean true:設定あり / false:未設定
   */
  public checkSetConditions(conditions: any): boolean {
    // 検索条件が存在するかチェック
    if(conditions === undefined || conditions === null){
      return false;
    } else {
      // 検索条件が設定されているかチェック
      if(conditions["freeword"] || conditions["class_id"] || conditions["prefectures"] ||
         conditions["category"] || conditions["industry_id"] || conditions["sdgs_goal"]){
        return true;
      }
    }
    return false;
  }

  /**
   * アコーディオン表示フラグ設定
   */
  public setAccordionFlg(formVal: any): void {
    // 会員種別
    this.accordion_class_id = (formVal.class_id != null && formVal.class_id?.length != 0) ? true : false;
    // 地域
    this.accordion_prefectures = (formVal.prefectures != null && formVal.prefectures?.length != 0) ? true : false;
    // 地域 中項目
    if(this.accordion_prefectures){
      for(let i = 0; i < this.prefecturesList.length; i++){
        for(let j = 0; j < this.prefecturesList[i]["list"].length; j++){
          if(formVal.prefectures.indexOf(this.prefecturesList[i]["list"][j]["sp_name"]) != -1){
            this.accordion_prefectures_detail[this.prefecturesList[i]["code"]] = true;
            break;
          }
        }
      }
    }
    // カテゴリ
    this.accordion_category = (formVal.category != null && formVal.category?.length != 0) ? true : false;
    // カテゴリ 中項目
    if(this.accordion_category){
      // 課題カテゴリを取得
      this.dbOperationService
        .getNoTemplateData(AGENDA_CATEGORY_LIST, null, true)
        .subscribe((response) => {
          if (!this.commonService.checkNoneResponse(response)) {
            let categorysList = response.body;
            for(let i = 0; i < categorysList.length; i++){
              for(let j = 0; j < categorysList[i]["rec"].length; j++){
                if(formVal.category.indexOf(categorysList[i]["rec"][j]["value"]) != -1){
                  this.accordion_category_detail[categorysList[i]["class_cd1"]] = true;
                  break;
                }
              }
            }
          }
      });
    }
    // 業種
    this.accordion_industry_id = (formVal.industry_id != null && formVal.industry_id?.length != 0) ? true : false;
    // SDGsゴール
    this.accordion_sdgs_goal = (formVal.sdgs_goal != null && formVal.sdgs_goal?.length != 0) ? true : false;
  }
     /**
   * check overflow of element
   * @param el html element of list 
   * @returns 
   */
      isOverflow(el: HTMLElement): boolean {
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === "visible")
          el.style.overflow = "hidden";
        var isOverflowing = el.clientWidth < el.scrollWidth
          || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
      }
    
      ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
      }
}