import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SESSION_KEY } from 'manager/environment';
import {
  API_URL_AGENDA_NEED_ADD_EDIT,
  API_URL_AGENDA_NEED_DELETE,
  API_URL_AGENDA_NEED_DETAIL,
  API_URL_AGENDA_NEED_DUPLICATE,
  API_URL_AGENDA_NEED_LIST,
  API_URL_AGENDA_NEED_SEARCH,
  API_URL_AGENDA_SOLUTION,
  API_URL_INPUT_MASTER,
} from 'manager/http-constants';
import { AGENDA_SOLUTION_M301_TEMPLATE, AGENDA_SOLUTION_M401_TEMPLATE } from 'manager/template-constant';

import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import {
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { AGENDA_SOLUTION_CONSTANT } from './constant';


@Component({
  selector: 'app-m401',
  templateUrl: '../master.component.html',
  styleUrls: ['../master.component.scss', '../../platform.component.scss']
})
export class M401Component implements OnInit {

 
   /** 詳細画面 */
   @ViewChild(GenerateDisplayComponent)
   generateDisplayItemsComponent: GenerateDisplayComponent;
 
  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

    /** 削除ダイアログ */
    @ViewChild(DeleteDialogComponent)
    deleteDialogComponent: DeleteDialogComponent;
    
 
    classId: any = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
    ).class_id;
   // 検索項目生成エンドポイント
   public searchEndPoint: string = API_URL_AGENDA_NEED_SEARCH;
 
   // 検索項目生成テンプレートID
   public searchTemplateId: number = AGENDA_SOLUTION_M401_TEMPLATE.SEARCH_TEMPLATE_ID;
 
   /* 画面用プロパティ */
   // 画面ヘッダー情報格納先
   columnOrder: any[] = new Array();
 
   // 画面検索結果一覧格納先
   searchResultsList: any[] = new Array();
 
   // 検索条件保持
   generateSearchItems: Object;

   // set first page = 1 
   pages: number = 0;
   textAdd: any = '活動の場の新規登録'

   isM4: any = true
   constructor(
     public route: ActivatedRoute,
     public commonService: CommonService,
     public dbOperationService: DbOperationService,
     public loadingState: LoadingState,
     public datePipe: DatePipe,
     private router: Router,
     private changeDetector: ChangeDetectorRef,
     private exportFileService: ExportFileService
   ) {
    //  super(route, commonService, dbOperationService, loadingState);
   }
 
   ngOnInit(): void {
     // 画面ロードフラグをON(ロード中状態)
     this.loadingState.loadStart();
 
     /* ヘッダー情報取得処理(画面用) */
     this.dbOperationService
       .getHeaderList(AGENDA_SOLUTION_M401_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
       .subscribe((response) => {
         this.columnOrder = response.body;
       });
 
     // 検索処理を実施
     this.searchResult();
   }
 
   /**
    * 検索処理
    * @param generateSearchItems 検索条件
    */
   public searchResult(generateSearchItems?: Object) {
     // 画面カスタムロードフラグをON(ロード中状態)
     this.loadingState.loadStart(API_URL_AGENDA_NEED_LIST);

     // CSV出力用に検索条件を保持
     this.generateSearchItems = generateSearchItems;
 
     /* 課題・ソリューション一覧取得処理(画面用) */
     this.dbOperationService
       .getForkJoinData(
         AGENDA_SOLUTION_CONSTANT.TABLE,
         API_URL_AGENDA_NEED_LIST,
         AGENDA_SOLUTION_M401_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
         generateSearchItems,
         true
       )
       .subscribe((response) => {
         // コード値の一覧情報が存在するか否か
         if (!this.commonService.checkNoneResponse(response)) {
           // 課題・ソリューション一覧情報のJSONをオブジェクトに格納する
           this.searchResultsList = response.body;
         } else {
           this.searchResultsList = new Array();
         }
 
         // 画面ロードフラグをOFF(ロード終了)
         this.loadingState.loadSleepEnd(0.3, API_URL_AGENDA_NEED_LIST);

         // return to first page
         this.pages=0;
       });
   }

   /**
   * check overflow of element
   * @param el html element of list 
   * @returns 
   */
    isOverflow(el: HTMLElement): boolean {
      const curOverflow = el.style.overflow;
      if (!curOverflow || curOverflow === "visible")
        el.style.overflow = "hidden";
      const isOverflowing = el.clientWidth < el.scrollWidth
        || el.clientHeight < el.scrollHeight;
      el.style.overflow = curOverflow;
      return isOverflowing;
    }
  
    ngAfterContentChecked(): void {
      this.changeDetector.detectChanges();
    }
    /**
   * 新規登録画面表示
   */
  public new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_MASTER,
      API_URL_AGENDA_NEED_ADD_EDIT,
      API_URL_AGENDA_NEED_ADD_EDIT,
      AGENDA_SOLUTION_M401_TEMPLATE.INPUT_TEMPLATE_ID,
      null
    );
  }

    /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
     public edit(pkeyId: string) {
      // 編集画面表示
      this.generateInputComponent.initial(
        GENERATE_INPUT_TYPE.EDIT,
        API_URL_INPUT_MASTER,
        API_URL_AGENDA_NEED_DETAIL,
        API_URL_AGENDA_NEED_ADD_EDIT,
        AGENDA_SOLUTION_M401_TEMPLATE.EDIT_TEMPLATE_ID,
        pkeyId
      );
    }
  /**
   * 複製画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
   public duplicate(pkeyId: string) {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_MASTER,
      API_URL_AGENDA_NEED_DUPLICATE,
      API_URL_AGENDA_NEED_ADD_EDIT,
      AGENDA_SOLUTION_M401_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }
   /**
    * 詳細情報画面表示
    * @param pkeyId IDコード(クリックされたID)
    */
   protected detail(pkeyId: string) {
   
     // 詳細画面表示
     const generateDisplay: GenerateDisplay = new GenerateDisplay();
     generateDisplay.endPoint = API_URL_AGENDA_NEED_DETAIL;
     generateDisplay.templateId = AGENDA_SOLUTION_M401_TEMPLATE.OUTPUT_TEMPLATE_ID;
     this.generateDisplayItemsComponent.initial(generateDisplay, pkeyId);
   }
 
     /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  public delete(pkeyId: string) {
    const title = "活動の場"
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialog(
      API_URL_AGENDA_NEED_DELETE,
      this.columnOrder[0].header,
      pkeyId,
      title
    );
  }

   /**
    * CSV出力
    */
   protected exportCSV() {
     // csvファイル名の設定
     const fileName =
       AGENDA_SOLUTION_CONSTANT.CSV_FILENAME +
       CONSTANT.UNDERBAR +
       this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);
 
     // CSVファイル出力
     this.exportFileService.exportTemplateCsv(
       fileName,
       AGENDA_SOLUTION_CONSTANT.TABLE,
       API_URL_AGENDA_SOLUTION,
       AGENDA_SOLUTION_M301_TEMPLATE.CSV_TEMPLATE_ID,
       this.generateSearchItems,
       true
     );
   }
 
   /**
    * プライマリーカラムを取得
    */
   protected getPkeyColumn(): string {
     return this.commonService.getPkeyColumn(this.columnOrder);
   }
 
    /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_AGENDA_NEED_LIST);


    // 返却されたコード値が存在するか否か
    if (null == pkeyId || undefined == pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施
      this.searchResult();

      return;
    }

    // 営業日マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getSingleData(
        API_URL_AGENDA_NEED_LIST,
        AGENDA_SOLUTION_M401_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        this.columnOrder[0].field,
        pkeyId
      )
      .subscribe((response) => {

        // コード値の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 一覧情報が存在しない場合

          // 検索結果一覧から該当データを削除
          this.searchResultsList = this.searchResultsList.filter(
            (val) => val[this.columnOrder[0].field] !== pkeyId
          );
          
          // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3,API_URL_AGENDA_NEED_LIST);
        } else {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先から対象のデータの行数を抽出
          const indexTargetData = this.searchResultsList.indexOf(
            this.searchResultsList.find((results) => {
              return results[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList.splice(indexTargetData, 1, response.body[0]);

          // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3,API_URL_AGENDA_NEED_LIST);
        }
      });
  }
  moveC601(agenda_id:string){
    localStorage.setItem('agenda_id', agenda_id)
    localStorage.setItem('isM4', this.isM4)
    this.router.navigate([`pages/platform/c601/`]);    
  }
}
