

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- タイトル -->
    <div class="page-title">
      <i class="pi pi-user"> </i>
      <label class="name">団体一覧</label>
    </div>

    <!-- ガード文言 -->
    <p class="sparse-text">
      ※各団体のプロフィール等の記載情報について、当プラットフォーム及び運営事務局が管理・保証するものではありません。
    </p>

    <div class="search-flex">
      <!-- 検索条件入力 -->
      <div class="search-form-area sparse-text">
        <form
          [formGroup]="a110SearchForm"
          (ngSubmit)="getMemberResultDataList(true)"
        >
          条件を指定して「<i class="pi pi-search"></i>」をクリック
          <div class="p-d-flex p-jc-between">
            <!-- 検索テキスト -->
            <div class="search-text">
              <input
                type="text"
                pInputText
                placeholder="フリーワード検索"
                (focus)="text_focus.toggle($event)"
                (blur)="text_focus.toggle($event)"
                formControlName="freeword"
                autocomplete="off"
              />
              <p-overlayPanel #text_focus>
                <ng-template pTemplate>
                  <div class="overlay-search-text">さらに絞り込む</div>
                </ng-template>
              </p-overlayPanel>
            </div>
            <!-- 検索ボタン -->
            <button
              pButton
              type="button"
              icon="pi pi-search"
              class="button1 search-button"
              (click)="getMemberResultDataList(true)"
            ></button>
          </div>

          <!-- 検索フォーム -->
          <div class="search-form">
            <p-accordion [multiple]="true">
              <!-- 表示 -->
              <p-accordionTab
                header="団体種別を選ぶ"
                [(selected)]="accordion_class_id"
                [disabled]="isDisabledTab('class_id')"
              >
                <div class="btn-check">
                  <span
                    ><a
                      href="javascript:void(0);"
                      (click)="
                        newcheckLargeCategory(true, 'class_id', getDicList(1))
                      "
                      >すべて選択</a
                    ></span
                  >　|　
                  <span class="p-ml-2"
                    ><a
                      href="javascript:void(0);"
                      (click)="
                        newcheckLargeCategory(false, 'class_id', getDicList(1))
                      "
                      >解除</a
                    ></span
                  >
                </div>
                <div *ngFor="let dicData of getDicList(1)">
                  <p-checkbox
                    [label]="dicData.sp_name"
                    [value]="dicData.sp_code"
                    [formControl]="a110SearchForm.controls.class_id"
                  ></p-checkbox>
                </div>
              </p-accordionTab>
              
            </p-accordion>
          </div>
          <!-- 検索ボタン -->
          <div class="p-d-flex p-jc-center">
            <button
              pButton
              type="button"
              label="検索する"
              class="button1 new-button"
              (click)="getMemberResultDataList(true)"
            ></button>
          </div>
          <!-- クリアボタン -->
          <p-button
            label="検索条件をクリアする"
            styleClass="p-button-link"
            (click)="a110SearchForm.reset()"
          ></p-button>
        </form>
      </div>

      <!-- 結果表示 -->
      <div class="result-area">
        <!-- ページアンカー -->
        <a id="jump-top"></a>
        <!-- 検索結果数 -->
        <div class="result-count-area">
          検索結果
          <label class="count"> {{ memberResultDataList.length }} </label>
          団体（総団体数：{{ countMemberData }}団体）
        </div>
        <!-- リスト表示 -->
        <p-table
          #table
          [value]="memberResultDataList"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{first}~{last}団体を表示中(全{totalRecords}団体)"
          [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
          emptyMessage="データが存在しません"
          styleClass="list-area"
          (onPage)="jumpTop()"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="organization_name">団体名</th>
              <th class="category">団体種別</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-memberResultData>
            <tr
              class="list"
            >
              <td
                class="organization_name"
                [pTooltip]="memberResultData.organization_name"
                #organization_name
                [tooltipDisabled]="!isOverflow(organization_name)"
                showDelay="500"
                hideDelay="500"
              >
                {{ memberResultData.organization_name }}
              </td>
            
              <td
                class="className"
                [pTooltip]="memberResultData.class_id.name"
                #class_id
                [tooltipDisabled]="!isOverflow(class_id)"
                showDelay="500"
                hideDelay="500"
              >
                {{ memberResultData.class_id.name }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>
