import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_LIST, USER_DELETE } from 'manager/http-constants';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { PlatformComponent } from '../platform.component';
import { DeleteDialogService } from 'src/app/shared/html-parts/confirm-dialog/delete-dialog/delete-dialog.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';

@Component({
  selector: 'app-b220',
  templateUrl: './b220.component.html',
  styleUrls: ['./b220.component.scss', '../platform.component.scss'],
})

export class B220Component extends PlatformComponent {
  statusList: object[] = new Array();
  resultTaskDataList: object[] = new Array();
  countTaskData: number = 0;
  member_id:string = "";
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public DeleteDialogService: DeleteDialogService,
    public loadingState: LoadingState,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    public loginService: LoginService,

  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.getUserMemberId();
  }

  public getUserMemberId(): string | void {
    this.loginService.getLoginUser().subscribe((response) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3);

      // ログインユーザ情報がユーザマスタに存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        // ユーザマスタに存在しない場合

        // 不正なユーザの為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.E90000);

        // 処理を終了
        return;
      } else {
        // ユーザマスタに存在する場合
        this.member_id = response.body.member_id;
            // 編集初期表示
        this.getResultTaskDataList();
      }
    });
  }
  public getResultTaskDataList(): void {
    this.loadingState.loadStart(USER_LIST);
    this.dbOperationService
      .getNoTemplateData(
        USER_LIST,
        false
      )
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.resultTaskDataList = response.body;
        } else {
          this.resultTaskDataList = new Array();
        }

        this.loadingState.loadSleepEnd(0.3, USER_LIST);
      });
  }

  public moveEditB221(userId: string): void {
    this.router.navigate(['pages/platform/b221/edit'], {
      queryParams: {
        screenId: 'b221',
        user_id: userId,
      },
    });
  }

  public moveInsertB221(): void {
    this.router.navigate(['pages/platform/b221/new'], {
      queryParams: {
        screenId: 'b221',
      },
    });
  }

  public deleteUser(user_id: any): void {
    this.deleteDialogService
      .deleteDialog(USER_DELETE, 'アカウントNO', user_id, 'ユーザー')
      .subscribe(() => {
        this.getResultTaskDataList();
      });
  }
}
