<p-dialog
  [(visible)]="MemoDialogFlag"
  [draggable]="false"
  [modal]="true" 
  [resizable]="false"
  [closable]="true"
  styleClass="small-dialog"
>
  <!-- ヘッダー -->
  <ng-template pTemplate="header">
    <div class="header">
        メモを入力してください
    </div>
  </ng-template>

  <!-- コンテンツ -->
  <div class="content">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-2">
            メモ
        </div>
        <div class="p-col-10">
            <textarea
              pInputTextarea
              [(ngModel)]="memoInput"
              maxlength="200"
              rows="4"
              autocomplete="off"
            ></textarea>
        </div>
        <div class="p-col-12 button-group">
            <button
              pButton
              type="button"
              label="登録する"
              [disabled]="isDisabledMemo()"
              class="button-default-size button1"
              (click)="editMemo()"
            ></button>
        </div>
    </div>

  </div>
</p-dialog>