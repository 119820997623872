import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PASSWORDS } from 'manager/http-constants';
import { API_RESPONSE } from 'src/app/shared/constant/api-constant';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PlatformComponent } from '../platform.component';

@Component({
  selector: 'app-a212',
  templateUrl: './a212.component.html',
  styleUrls: ['./a212.component.scss', '../platform.component.scss'],
})

/**
 * 新規入会時のパスワード設定
 */
export class A212Component extends PlatformComponent {
  // 画面.入力フォーム
  a212InputForm: FormGroup = this.formBuilder.group({
    // パスワード
    password: this.formBuilder.control(CONSTANT.EMPTY_STRING),
    // パスワード確認
    confirmationPassword: this.formBuilder.control(CONSTANT.EMPTY_STRING),
  });

  // eメールアドレス
  email: string = this.route.snapshot.queryParams.email;
  token: string = this.route.snapshot.queryParams.token;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private messageData: MessageData,
    private formBuilder: FormBuilder
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // パスワードチェックを実施
    this.checkPassword();

    // 確認パスワードチェックを実施
    this.checkConfirmationPassword();
  }

  /**
   * パスワードチェック
   * @param input true:画面入力 false:初回
   */
  public checkPassword(input?: boolean): void {
    // 画面入力されたか否か
    if (input) {
      // 画面入力された場合

      // 変更検知をtrueにする(エラーメッセージ表示有無フラグ)
      this.a212InputForm.get('password').markAsDirty();
    }

    // 画面.入力フォーム.パスワードを取得
    const password = this.a212InputForm.get('password').value;

    // 画面.入力フォーム.パスワードが未入力か否か
    if (!password) {
      // 未入力の場合

      // 必須項目エラー
      this.a212InputForm.controls['password'].setErrors({
        message: '必須項目です。必ず入力してください。',
      });

      return;
    }

    // 文字列が以下に一致するか判定
    // 少なくとも1つの小文字
    // 少なくとも1つの大文字
    // 少なくとも1つの数値
    // 8文字以上
    if (
      !password.match(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})[a-zA-Z0-9]+$'
      )
    ) {
      // 一致しない場合

      // パスワード設定エラー
      this.a212InputForm.controls['password'].setErrors({
        message: 'パスワードが設定できません',
      });

      return;
    }

    // エラーメッセージを空にする
    this.a212InputForm.controls['password'].setErrors(null);

    // 確認パスワードチェックを実施
    this.checkConfirmationPassword();

    return;
  }

  /**
   * 確認パスワードチェック
   * @param input true:画面入力 false:初回
   */
  public checkConfirmationPassword(input?: boolean): void {
    // 画面入力されたか否か
    if (input) {
      // 画面入力された場合

      // 変更検知をtrueにする(エラーメッセージ表示有無フラグ)
      this.a212InputForm.get('confirmationPassword').markAsDirty();
    }

    // 画面.入力フォーム.パスワード確認を取得
    const confirmationPassword = this.a212InputForm.get(
      'confirmationPassword'
    ).value;

    // 画面.入力フォーム.パスワード確認が未入力か否か
    if (!confirmationPassword) {
      // 未入力の場合

      // 必須項目エラー
      this.a212InputForm.controls['confirmationPassword'].setErrors({
        message: '必須項目です。必ず入力してください。',
      });

      return;
    }

    // 画面.入力フォーム.パスワード確認がパスワードと同様か確認
    if (this.a212InputForm.get('password').value != confirmationPassword) {
      // 同様でない場合
      this.a212InputForm.controls['confirmationPassword'].setErrors({
        message: 'パスワードの値と一致していません',
      });
      return;
    }

    // エラーメッセージを空にする
    this.a212InputForm.controls['confirmationPassword'].setErrors(null);

    // パスワードチェックを実施
    this.checkPassword();

    return;
  }

  /**
   * 新規入会時のパスワード設定完了(A213)へ遷移する
   */
  public moveA213(): void {
    // パスワード設定を実施
    this.dbOperationService
      .insertData(PASSWORDS, {
        email: this.email,
        password: this.a212InputForm.get('password').value,
        token: this.token
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

        // レスポンス結果を判定
        if (API_RESPONSE.SUCCESS == response.body.Message) {
          // 正常終了の場合

          // 新規入会時のパスワード設定完了(A213)へ遷移
          this.router.navigate(['pages/platform/a213']);
        } else {
          // 異常終了の場合

          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00002),
              detail: this.commonService.msg(MESSAGE_CODE.E80002),
            })
          );
        }
      });
  }
}
