<!-- ページアンカー -->
<a id="jump-page-top"></a>
<div class="content">
  <!-- ヘッダー -->
  <header>
    <app-header [headerFlag]="headerFlag"></app-header>
  </header>

  <!-- メイン画面 -->
  <main>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<!-- 確認ダイアログ -->
<app-confirm-dialog></app-confirm-dialog>

<!-- メッセージ -->
<app-message-common></app-message-common>

<!-- ローディング画面 -->
<app-loading></app-loading>
