import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMAIL_CHECKS } from 'manager/http-constants';
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { Validator } from 'src/app/shared/validator/validator';
import { PlatformComponent } from '../platform.component';

@Component({
  selector: 'app-a210',
  templateUrl: './a210.component.html',
  styleUrls: ['./a210.component.scss', '../platform.component.scss'],
})

/**
 * 入会審査完了後のメールアドレス入力（ＩＤ活性化）
 */
export class A210Component extends PlatformComponent {
  // 画面.入力フォーム
  // eメールアドレス
  email = new FormControl(CONSTANT.EMPTY_STRING, {
    asyncValidators: [
      this.validator.CheckValidator(
        'application_user',
        'email',
        INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
      ),
    ],
  });

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private validator: Validator,
    private messageData: MessageData
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * パスワード設定メール送付完了(A211)へ遷移する
   */
  public moveA211(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.DATA_INSERT);

    // メールアドレス入力チェックを実施
    this.dbOperationService
      .insertData(EMAIL_CHECKS, {
        email: this.email.value,
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

        // レスポンス結果を判定
        if (API_RESPONSE.SUCCESS == response.body.Message) {
          // 正常終了の場合

          // パスワード設定メール送付完了(A211)へ遷移
          this.router.navigate(['pages/platform/a211']);
        } else {
          // 異常終了の場合

          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00002),
              detail: this.commonService.msg(MESSAGE_CODE.E00028),
            })
          );
        }
      });
  }
}
