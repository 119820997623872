import { Injectable } from '@angular/core';
import * as Encoding from 'encoding-japanese';
import * as JSZip from 'jszip';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  CONSTANT,
  FILE_EXTENSION,
  FILE_CONSTANT,
} from 'src/app/shared/constant/constant';
import {
  API_RESPONSE,
  HEADER_LIST_API_CONSTANT,
} from '../../constant/api-constant';
import { MESSAGE_CODE } from '../../constant/message-constant';
import { TOAST } from '../../constant/primeng-constants';
import { CommonService } from '../../service/common.service';
import { DbOperationService } from '../../service/db-operation.service';
import { MessageData, ToastMessageData } from '../message-common/message-data';
import { FILE_IDENTIFIER, REQUEST_IDENTIFIER } from './constant';

@Injectable({
  providedIn: 'root',
})

/**
 * ファイル出力
 */
export class ExportFileService {
  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData
  ) {}

  /**
   * CSVファイル出力(テンプレートに紐づくデータでcsv出力)
   * @param fileName ファイル名
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param createCode コード値作成フラグ
   * true → ヘッダーはカラム値、データ部はコード値で作成
   * false → ヘッダーは論理値、データ部はコード変換値で作成
   * ヘッダーはカラム値,データ部はコード変換値にしたいのでexportCSV部分をfalseに固定
   * @param returnFileInformation ファイル情報を返却フラグ
   * @returns ファイル情報オブジェクト
   * ※returnsはsubscribeで取得
   */

  // TODO @param tableはバックエンドが分割処理未実施の場合、nullをセット
  public exportTemplateCsv(
    fileName: string,
    table: string,
    endPoint: string,
    templateId: number,
    searchConditions?: any,
    createCode?: boolean,
    returnFileInformation?: boolean
  ): Subject<fileInformation> {
    // CSVファイル出力(テンプレートに紐づくデータでcsv出力)の返却
    let returnExportTemplateCsv = new Subject<fileInformation>();

    /* CSV情報を取得 */
    /* API分割リクエストを生成 */
    this.dbOperationService
      .createForkJoinTask(
        table,
        endPoint,
        templateId,
        searchConditions,
        createCode
      )
      .subscribe((task) => {
        // API分割リクエストの配列数を保持
        const getDataTaskListLength = task.length;

        // API同時実行タスクに"CSVヘッダー情報取得"を追加
        task.push(
          /* CSVヘッダー情報取得 */
          this.dbOperationService.getHeaderList(templateId)
        );

        // 非同期同時実行
        forkJoin(task).subscribe((responseList) => {
          // API分割リクエストの結果を結合
          responseList = this.commonService.JoinSearchResponseList(
            responseList,
            getDataTaskListLength
          );

          // CSVファイル出力
          const fileInformation: fileInformation = this.exportCsv(
            fileName,
            responseList[1].body,
            // データ情報が存在するか否か
            this.commonService.checkNoneResponse(responseList[0])
              ? // データ情報が存在しない場合
                new Array()
              : // データ情報が存在する場合
                responseList[0].body,
            //カラム名を日本語に固定したいのでfalseにする
            false,
            returnFileInformation
          );

          // ファイル情報を返却フラグがtrueの場合
          if (returnFileInformation) {
            // ファイル情報オブジェクトを返却
            returnExportTemplateCsv.next(fileInformation);
          }
        });
      });

    // ファイル情報オブジェクトを返却
    return returnExportTemplateCsv;
  }

  /**
   * CSVファイル出力
   * @param fileName ファイル名
   * @param header ヘッダー情報
   * @param data データ情報
   * @param createCode コード値作成フラグ (true:ヘッダーをカラム値、false:ヘッダーを論理値)
   * @param returnFileInformation ファイル情報を返却フラグ
   */
  public exportCsv(
    fileName: string,
    header: object[],
    data: object[],
    createCode?: boolean,
    returnFileInformation?: boolean
  ): fileInformation {
    // ファイル名とヘッダー情報の必須判定
    if (!fileName || !header) {
      // ファイル名かヘッダー情報が空かnullの場合

      return;
    }

    // csv内容格納先
    let record = CONSTANT.EMPTY_STRING;

    /* ヘッダー情報を生成 */
    header.forEach((headerObject) => {
      // ヘッダー情報のデータ情報が存在するか否か
      if (createCode) {
        //  コード値作成フラグがtrueの場合、カラム名で作成
        record +=
          CONSTANT.DOUBLE_QUOTATION +
          headerObject[HEADER_LIST_API_CONSTANT.FIELD] +
          CONSTANT.DOUBLE_QUOTATION +
          CONSTANT.COMMA;
      } else {
        // コード値作成フラグがfalseの場合、論理名で作成
        record +=
          CONSTANT.DOUBLE_QUOTATION +
          headerObject[HEADER_LIST_API_CONSTANT.HEADER] +
          CONSTANT.DOUBLE_QUOTATION +
          CONSTANT.COMMA;
      }
    });

    // ヘッダー情報の行末尾のカンマを削除
    record = record.slice(0, -1);

    // ヘッダー情報の行末尾に改行を追加
    record += FILE_CONSTANT.LINE_CODE;

    /* データ情報を生成 */
    data.forEach((dataObject) => {
      // データ情報の行の格納先
      let dataLine = CONSTANT.EMPTY_STRING;

      // ヘッダー情報分ループ
      header.forEach((headerData) => {
        // ヘッダー情報のデータ情報が存在するか否か
        if (
          this.commonService.ifZeroPermission(
            dataObject[headerData[HEADER_LIST_API_CONSTANT.FIELD]]
          )
        ) {
          // データ情報が存在する場合

          // データ情報格納先にヘッダー情報のデータ情報を格納
          dataLine +=
            CONSTANT.DOUBLE_QUOTATION +
            dataObject[headerData[HEADER_LIST_API_CONSTANT.FIELD]] +
            CONSTANT.DOUBLE_QUOTATION +
            CONSTANT.COMMA;
        } else {
          // データ情報が存在しない場合

          // データ情報格納先に空文字
          dataLine +=
            CONSTANT.DOUBLE_QUOTATION +
            CONSTANT.DOUBLE_QUOTATION +
            CONSTANT.COMMA;
        }
      });

      // データ情報の行末尾のカンマを削除
      dataLine = dataLine.slice(0, -1);

      // csv内容格納先にデータ情報の行と改行を追加
      record += dataLine + FILE_CONSTANT.LINE_CODE;
    });

    // ファイル情報を生成
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, record], { type: 'text/csv' });

    // ファイル情報を返却フラグがtrueの場合
    if (returnFileInformation) {
      // ファイル情報オブジェクトを返却
      return new fileInformation({
        fileName: fileName + FILE_EXTENSION.CSV,
        blob: blob,
      });
    }

    // ファイルダウンロード
    this.fileDownload(fileName + FILE_EXTENSION.CSV, blob);
  }

  /**
   * TSVファイル出力
   * @param fileName ファイル名
   * @param header ヘッダー情報
   * @param data データ情報
   * @param createCode コード値作成フラグ (true:ヘッダーをカラム値、false:ヘッダーを論理値)
   * @param returnFileInformation ファイル情報を返却フラグ
   */
  public exportTsv(
    fileName: string,
    header: object[],
    data: object[],
    createCode?: boolean,
    returnFileInformation?: boolean
  ) {
    // ファイル名とヘッダー情報の必須判定
    if (!fileName || !header) {
      // ファイル名かヘッダー情報が空かnullの場合

      return;
    }

    // csv内容格納先
    let record;

    /* ヘッダー情報を生成 */
    record =
      this.commonService
        .createArrayGetArrayObject(
          header,
          // コード値作成フラグがtrueか否か
          createCode
            ? //  コード値作成フラグがtrueの場合、カラム名で作成
              HEADER_LIST_API_CONSTANT.FIELD
            : // コード値作成フラグがfalseの場合、論理名で作成
              HEADER_LIST_API_CONSTANT.HEADER
        )
        .join(FILE_CONSTANT.TAB) + FILE_CONSTANT.LINE_CODE;

    /* データ情報を生成 */
    data.forEach((dataObject) => {
      // データ情報の行の格納先
      let dataLine = CONSTANT.EMPTY_STRING;

      // ヘッダー情報分ループ
      header.forEach((headerData) => {
        // ヘッダー情報のデータ情報が存在するか否か
        if (
          this.commonService.ifZeroPermission(
            dataObject[headerData[HEADER_LIST_API_CONSTANT.FIELD]]
          )
        ) {
          // データ情報が存在する場合

          // データ情報格納先にヘッダー情報のデータ情報を格納
          dataLine +=
            dataObject[headerData[HEADER_LIST_API_CONSTANT.FIELD]] +
            FILE_CONSTANT.TAB;
        } else {
          // データ情報が存在しない場合

          // データ情報格納先に空文字
          dataLine += FILE_CONSTANT.TAB;
        }
      });

      // データ情報の行末尾のtabを削除
      dataLine = dataLine.trim();

      // csv内容格納先にデータ情報の行と改行を追加
      record += dataLine + FILE_CONSTANT.LINE_CODE;
    });

    // Unicodeコードポイントの配列を作成しSJIS化
    let tmp_arry = new Array();
    for (let idx = 0; idx < record.length; idx++) {
      tmp_arry.push(record.charCodeAt(idx));
    }
    const sjis_arry = Encoding.convert(tmp_arry, {
      to: 'SJIS',
      from: 'UNICODE',
    });
    const uint_arry = new Uint8Array(sjis_arry);

    // ファイル情報を生成
    const blob = new Blob([uint_arry], { type: 'text/tab-separated-values' });

    // ファイル情報を返却フラグがtrueの場合
    if (returnFileInformation) {
      // ファイル情報オブジェクトを返却
      return new fileInformation({
        fileName: fileName + FILE_EXTENSION.CSV,
        blob: blob,
      });
    }

    // ファイルダウンロード
    this.fileDownload(fileName + FILE_EXTENSION.TSV, blob);
  }

  /**
   * ファイル出力(バックエンドからbase64形式で出力)
   * @param endPoint REST APIエンドポイント
   * @param extension ファイル出力形式
   * @param request リクエスト形式
   * @param searchConditions 検索条件入力値
   */
  public exportFile(
    endPoint: string,
    extension: string,
    request: string,
    searchConditions?: any
  ) {
    // ファイル出力形式が定数に存在するか否か
    if (!FILE_IDENTIFIER[extension]) {
      // ファイル出力形式が定数に存在しない場合

      return;
    }

    // リクエスト形式が定数に存在するか否か
    if (!REQUEST_IDENTIFIER[request]) {
      // リクエスト形式が定数に存在しない場合

      return;
    }

    /* ファイル出力 */
    // ファイル情報(base64形式)で取得
    this.dbOperationService
      .getFile(endPoint, request, searchConditions)
      .subscribe(
        (response) => {
          try {
            // 返却値(JSON形式)をオブジェクト化
            const result = JSON.parse(response.body);

            // responseが存在するか否か
            if (!result.length || API_RESPONSE.NO_RECORD == result[0].Message) {
              // responseが存在しない場合 または
              // responseレコードが存在しない場合

              // エラーメッセージ表示
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.ERROR,
                  summary: this.commonService.msg(MESSAGE_CODE.E00002),
                  detail: this.commonService.msg(
                    MESSAGE_CODE.S00001,
                    '出力内容'
                  ),
                })
              );
            }
          } catch {
            // 返却値(JSON形式)以外の場合

            /* ファイル名を取得 */
            // ファイル名を抜き取る正規表現
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            // レスポンスヘッダーから'content-disposition'のファイル名情報を取得
            const fileNameInformation = fileNameRegex.exec(
              response.headers.get('content-disposition')
            );
            // ファイル名情報からファイル名を取得
            const fileName = decodeURI(fileNameInformation[1]);

            /* ファイル情報(base64形式)をファイル情報(BLOB形式)に変更 */
            const bin = atob(response.body.replace(/^.*,/, ''));
            const buffer = new Uint8Array(bin.length);
            for (let i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i);
            }
            var blob = new Blob([buffer.buffer], {
              type:
                // ファイル出力形式の判定
                FILE_IDENTIFIER.EXCEL == extension
                  ? // EXCELファイルの場合
                    'application/ms-Excel'
                  : FILE_IDENTIFIER.CSV == extension
                  ? // CSVファイルの場合
                    'text/csv'
                  : FILE_IDENTIFIER.PDF == extension
                  ? // PDFファイルの場合
                    'application/pdf'
                  : // どれにも一致しない場合
                    'text/csv',
            });

            // ファイルダウンロード
            this.fileDownload(fileName, blob);
          }
        },
        (errorResponse) => {
          // 返却値(JSON形式)をオブジェクト化
          const result = JSON.parse(errorResponse.error);

          // エラーメッセージ表示
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00002),
              detail: result[0].Message,
            })
          );
        }
      );
  }

  /**
   * ZIPファイルダウンロード
   * @param fileName ZIPファイル名
   * @param fileInformation ZIP化を行うファイル情報(BLOB形式)
   */
  public zipFileDownload(
    fileName: string,
    ...fileInformationList: fileInformation[]
  ) {
    // JSZipオブジェクトを作成
    var zip = new JSZip();

    // ZIP化を行うファイル情報(BLOB形式)リスト分ループ
    for (const fileInformation of fileInformationList) {
      // JSZipオブジェクトにファイル名、ファイル情報(BLOB形式)を格納
      zip.file(fileInformation.fileName, fileInformation.blob);
    }

    // JSZipオブジェクトを用いてファイルをZIP化
    zip.generateAsync({ type: 'blob' }).then((zipInformation) => {
      // ファイルダウンロード
      this.fileDownload(fileName + FILE_EXTENSION.ZIP, zipInformation);
    });
  }

  /**
   * ファイルダウンロード
   * @param fileName ファイル名
   * @param blob ファイル情報(BLOB形式)
   */
  private fileDownload(fileName: string, blob: Blob) {
    // ファイル情報(BLOB形式)をURL化
    const url = window.URL.createObjectURL(blob);

    // ファイルダウンロード
    let link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute('style', 'display: none');

    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }
}

/** ファイル情報オブジェクト */
export class fileInformation {
  // ファイル名(拡張子含める)
  private _fileName: string;

  // ファイル情報(BLOB形式)
  private _blob: Blob;

  constructor(init?: Partial<fileInformation>) {
    Object.assign(this, init);
  }

  set fileName(fileName: string) {
    this._fileName = fileName;
  }

  get fileName(): string {
    return this._fileName;
  }

  set blob(blob: Blob) {
    this._blob = blob;
  }

  get blob(): Blob {
    return this._blob;
  }
}
