import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../service/login.service';
import { CommonService } from '../service/common.service';
import { MESSAGE_CODE } from '../constant/message-constant';
import { SESSION_KEY } from '../constant/session-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private commonService: CommonService,
  ) {
    if (route.snapshot.queryParams.code && !loginService.getAuth0LoginState()) {
      this.loginService.login(route.snapshot.queryParams.code, route.snapshot.queryParams.redirect).subscribe(
        () => {
          this.getLoginUser(route.snapshot.queryParams.redirect)
        },
        () => {
          this.loginService.logout(MESSAGE_CODE.E90000);
        }
      );
    } else {
      this.isLoading = true
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  login() {}

  getLoginUser(redirect_uri: any) {
    this.loginService.getLoginUser().subscribe((response) => {
      if (this.commonService.checkNoneResponse(response)) {
        this.loginService.logout(MESSAGE_CODE.E90000);
        return;
      } else {
        window.sessionStorage.setItem(SESSION_KEY.loginUserInformation, JSON.stringify(response.body));
        this.isLoading = true
        window.location.href = redirect_uri
      }
    });
  }
}
