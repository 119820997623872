/**
 * API値定数
 */
/** APIレスポンス */
export const API_RESPONSE = {
  // APIが正常終了
  SUCCESS: 'SUCCESS',
  // APIが異常終了
  FAIL: 'FAIL',
  // DB結果0件
  NO_RECORD: 'No Record',
  // Invalid Object
  INVALID_OBJECT: 'Invalid Object'
};

/** ヘッダー項目取得 */
export const HEADER_LIST_API_CONSTANT = {
  HEADER: 'header',
  FIELD: 'field',
  COLUMN_PKEY: 'column_pkey',
  DIC_ID: 'dicID',
};

/** 辞書値取得 */
export const DICVALUES_API_CONSTANT = {
  DIC_VALUE: 'dicValue',
  NAME: 'name',
  CODE: 'code',
  SP_NAME: 'sp_name',
  SP_CODE: 'sp_code',
};

/** 検索項目生成 */
export const SEARCH_INFORMATIONS_API_CONSTANT = {
  // 入力種類.テキスト
  TEXT_TYPE: 'text',
  // 入力種類.数値
  NUMBER_TYPE: 'number',
  // 入力種類.日付
  DATE_TYPE: 'date',
  // 入力種類.テキストエリア
  TEXTAREA_TYPE: 'list',
  // 入力種類.チェックボックス
  CHECKBOX_TYPE: 'checkbox',
  // 入力種類.マルチセレクト
  MULTIPLE_SELECT_TYPE: 'pulldown',
};

/** 表示項目生成 */
export const DISPLAY_INFORMATIONS_API_CONSTANT = {
  // カラム名
  COLUMN_NAME: 'column_name',
  // カラムID
  COLUMN_ID: 'column_id',
  // カラム値
  DATA: 'data',
  // カラム値(コード)
  DATA_MULTI: 'data_multi',
  // カラム主キー
  COLUMN_PKEY: 'column_pkey',
};

/** 入力項目生成 */
export const INPUT_INFORMATIONS_API_CONSTANT = {
  // カラムID
  COLUMN_ID: 'column_id',
  // カラム名称
  COLUMN_NAME: 'column_name',
  // カラム値(選択項目)
  COLUMN_CODE_LIST_MULTI: 'column_code_list_multi',
  // カラム値(選択項目名)
  COLUMN_CODE_LIST_MULTI_NAME: 'name',
  // カラム値(選択項目値)
  COLUMN_CODE_LIST_MULTI_VALUE: 'value',
  // 入力種類.テキスト
  TEXT_TYPE: 'text',
  // 入力種類.数値
  NUMBER_TYPE: 'number',
  // 入力種類.日付
  DATE_TYPE: 'date',
  // 入力種類.テキストエリア
  TEXTAREA_TYPE: 'textarea',
  // 入力種類.ラジオ
  RADIO_TYPE: 'radio',
  // 入力種類.チェックボックス
  CHECKBOX_TYPE: 'checkbox',
  // 入力種類.シングルセレクト
  SINGLE_SELECT_TYPE: 'single_select',
  // 入力種類.マルチセレクト
  MULTIPLE_SELECT_TYPE: 'multiple_select',
  // 入力種類.パスワード
  PASSWORD_TYPE: 'password',
  // 入力種類.ファイル
  FILE_TYPE: 'file',
  // image type
  IMAGE_TYPE: "image"
};
