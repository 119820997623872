import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { MEMBERS_DETAIL } from 'manager/http-constants';
import { PlatformComponent } from '../platform.component';

@Component({
  selector: 'app-b223',
  templateUrl: './b223.component.html',
  styleUrls: ['./b223.component.scss', '../platform.component.scss'],
})

/**
 * ユーザー登録完了
 * ユーザ一覧(B220)へ遷移する
 */
export class B223Component extends PlatformComponent {

   //　会員ID
   memberId: string = this.route.snapshot.queryParams.member_id;

   // ユーザー情報格納先
   memberInfo: any;

   constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private messageData: MessageData,
    private location: Location
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * マイページ(B301)へ遷移する
   */
   public moveB301(): void {
    // マイページ(B301)へ遷移
    this.router.navigate(['pages/platform/b301']);
  }
  
  /**
   * ユーザ一覧画面(B220)へ遷移する
  */
   public moveB220(): void {
    // ユーザ一覧画面(B220)へ遷移
    this.router.navigate(['pages/platform/b220/']);
  }
}
