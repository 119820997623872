/**
 * ルート権限設定
 */
/**
 * 注意
 * 画面を追加する場合は"Component"名で定数追加
 * 内部は配列でユーザ権限設定(user-authority-constant.ts)の対象ユーザを設定
 * 空配列の場合、権限チェックは実施しない
 */
/**
 * TOP
 */

import {
  USER_1,
  USER_2,
  USER_3,
  USER_4,
  USER_5,
  USER_6,
  USER_7,
  USER_8,
  USER_9,
  USER_10,
  USER_99,
} from './user-authority-constant';

// TOP画面
export const TopComponent = [USER_99];

/** --------------------------------------------------------- */

/**
 * マスタ管理
 */
// seeks master screen
export const M301Component = [USER_1,USER_4];

// need master screen
export const M401Component = [USER_2,USER_4];

// user master screen
export const M201Component = [USER_4];

export const M101Component = [USER_4];

export const M501Component = [USER_4];

// seek list screen
export const C241Component = [];

// seek list detail screen
export const C341Component = [];

// reviewtrip
export const C701Component = [USER_2];


// 届先マスタ画面
export const DeliveryComponent = [USER_99];

// 届先グループマスタ画面
export const DeliveryGroupComponent = [USER_99];

// 組織マスタ画面
export const DepartmentComponent = [USER_99];

// お知らせマスタ画面
export const InformationComponent = [USER_99];

// 販促資材マスタ画面
export const ItemComponent = [USER_99];

// 販促資材斡旋マスタ画面
export const ItemMediationComponent = [USER_99];

// ユーザーマスタ画面
export const UserComponent = [USER_99];

/** --------------------------------------------------------- */

// 会員一覧（A110）
export const A110Component = [];

// 入会申請(A201)
// 入会申請確認(A202)
export const A201Component = [];

// 入会申請メールアドレス確認
export const A203Component = [];

// 入会申請完了
export const A204Component = [];

// 入会審査完了後のメールアドレス入力（ＩＤ活性化）
export const A210Component = [];

// パスワード設定メール送付完了
export const A211Component = [];

// 新規入会時のパスワード設定
export const A212Component = [];

// 新規入会時のパスワード設定完了
export const A213Component = [];

// 入会申請詳細
export const A231Component = [USER_4];

// 自治体会員登録・更新画面(入力)(B101)
// 自治体会員登録・更新画面(確認画面)(B102)
export const B101Component = [];

// 自治体会員登録・更新画面（登録・更新完了）(B103)
export const B103Component = [];

// 会員プロフィール(自治体)
export const B211Component = [];


// ユーザー一覧(B220)
export const B220Component = [USER_2, USER_1];

// ユーザー一覧(B221)
export const B221Component = [USER_2, USER_1];

// ユーザー登録完了(B223)
export const B223Component = [USER_2, USER_1];

// マイページ
export const B301Component = [];

// 課題・ソリューション登録(C101)
// 課題・ソリューション登録確認(C102)
export const C101Component = [];

// 課題・ソリューション登録完了
export const C103Component = [];

// 課題・ソリューション一覧
export const C201Component = [];

// 課題・ソリューション詳細
export const C301Component = [];

// 提案登録(C401)
// 提案登録確認(C402)
export const C431Component = [USER_2, USER_1];

// マッチング提案登録完了
export const C433Component = [USER_2, USER_1];

// マッチング依頼一覧
export const C502Component = [];

// 提案詳細
export const C601Component = [];

// 会員入会審査一覧
export const D211Component = [USER_4];

// 会員更新審査
export const D212Component = [USER_4];

// 課題・ソリューション審査
export const D221Component = [USER_4];

// 提案審査
export const D231Component = [USER_4];

// 会員主催イベント審査
export const D241Component = [USER_4];

// 分科会審査
export const D251Component = [USER_4];

// 問い合わせ一覧
export const D261Component = [USER_4];

// 問い合わせ詳細
export const D262Component = [USER_4];

// 掲示板コメント（D271）審査
export const D271Component = [USER_4];

// お知らせ一覧(E001)
// PF主催イベント一覧(E002)
// 会員主催イベント一覧(E101)
export const E001Component = [];

// 会員主催イベント詳細
export const E102Component = [];

// 会員主催イベント申請一覧
export const E103Component = [];

// 会員主催イベント申請登録(E104)
// 会員主催イベント申請登録確認E105)
export const E104Component = [];

// 会員主催イベント申請登録完了(E106)
export const E106Component = [];

// 分科会一覧
export const E201Component = [];

// 分科会詳細
export const E202Component = [];

// 分科会申請一覧
export const E203Component = [];

// 分科会申請登録(E204)
// 分科会申請登録確認(E205)
export const E204Component = [];

// 分科会申請登録完了
export const E206Component = [];

// 問い合わせ登録(E301)
// 問い合わせ登録確認(E302)
export const E301Component = [];

// 問い合わせ登録完了
export const E303Component = [];

// 会員一覧・CSVダウンロード
export const F111Component = [USER_4];

// 課題・ソリューション一覧・CSVダウンロード
export const F121Component = [USER_4];

// 提案一覧・CSVダウンロード
export const F131Component = [USER_4];

// 会員主催イベント一覧・CSVダウンロード
export const F141Component = [USER_4];

// 分科会一覧・CSVダウンロード
export const F151Component = [USER_4];

// ユーザー一覧・CSVダウンロード
export const F161Component = [USER_4];


// お知らせ詳細
export const G102Component = [];

// お知らせ登録(E204)
// お知らせ登録確認(E205)
export const G103Component = [USER_4];

// お知らせ登録完了
export const G105Component = [USER_4];

// // 連携事例登録(H101)
// export const H101Component = [];
// // 連携事例詳細(H301)
// export const H301Component = [];
