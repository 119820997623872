// 地域カテゴリ
export const LOCAL_CATEGORY = [
  {
    // 北海道・東北
    name: '北海道・東北',
    code: 'local_category_1',
    list: [
      {
        sp_name: '北海道',
        sp_code: '1',
      },
      {
        sp_name: '青森県',
        sp_code: '2',
      },
      {
        sp_name: '岩手県',
        sp_code: '3',
      },
      {
        sp_name: '宮城県',
        sp_code: '4',
      },
      {
        sp_name: '秋田県',
        sp_code: '5',
      },
      {
        sp_name: '山形県',
        sp_code: '6',
      },
      {
        sp_name: '福島県',
        sp_code: '7',
      },
    ],
  },

  {
    // 関東
    name: '関東',
    code: 'local_category_2',
    list: [
      {
        sp_name: '茨城県',
        sp_code: '8',
      },
      {
        sp_name: '栃木県',
        sp_code: '9',
      },
      {
        sp_name: '群馬県',
        sp_code: '10',
      },
      {
        sp_name: '埼玉県',
        sp_code: '11',
      },
      {
        sp_name: '千葉県',
        sp_code: '12',
      },
      {
        sp_name: '東京都',
        sp_code: '13',
      },
      {
        sp_name: '神奈川県',
        sp_code: '14',
      },
    ],
  },

  {
    // 北陸・中部
    name: '北陸・中部',
    code: 'local_category_3',
    list: [
      {
        sp_name: '新潟県',
        sp_code: '15',
      },
      {
        sp_name: '富山県',
        sp_code: '16',
      },
      {
        sp_name: '石川県',
        sp_code: '17',
      },
      {
        sp_name: '福井県',
        sp_code: '18',
      },
      {
        sp_name: '山梨県',
        sp_code: '19',
      },
      {
        sp_name: '長野県',
        sp_code: '20',
      },
      {
        sp_name: '岐阜県',
        sp_code: '21',
      },
      {
        sp_name: '静岡県',
        sp_code: '22',
      },
      {
        sp_name: '愛知県',
        sp_code: '23',
      },
    ],
  },

  {
    // 近畿
    name: '近畿',
    code: 'local_category_4',
    list: [
      {
        sp_name: '三重県',
        sp_code: '24',
      },
      {
        sp_name: '滋賀県',
        sp_code: '25',
      },
      {
        sp_name: '京都府',
        sp_code: '26',
      },
      {
        sp_name: '大阪府',
        sp_code: '27',
      },
      {
        sp_name: '兵庫県',
        sp_code: '28',
      },
      {
        sp_name: '奈良県',
        sp_code: '29',
      },
      {
        sp_name: '和歌山県',
        sp_code: '30',
      },
    ],
  },

  {
    // 中国・四国
    name: '中国・四国',
    code: 'local_category_5',
    list: [
      {
        sp_name: '鳥取県',
        sp_code: '31',
      },
      {
        sp_name: '島根県',
        sp_code: '32',
      },
      {
        sp_name: '岡山県',
        sp_code: '33',
      },
      {
        sp_name: '広島県',
        sp_code: '34',
      },
      {
        sp_name: '山口県',
        sp_code: '35',
      },
      {
        sp_name: '徳島県',
        sp_code: '36',
      },
      {
        sp_name: '香川県',
        sp_code: '37',
      },
      {
        sp_name: '愛媛県',
        sp_code: '38',
      },
      {
        sp_name: '高知県',
        sp_code: '39',
      },
    ],
  },

  {
    // 九州・沖縄
    name: '九州・沖縄',
    code: 'local_category_6',
    list: [
      {
        sp_name: '福岡県',
        sp_code: '40',
      },
      {
        sp_name: '佐賀県',
        sp_code: '41',
      },
      {
        sp_name: '長崎県',
        sp_code: '42',
      },
      {
        sp_name: '熊本県',
        sp_code: '43',
      },
      {
        sp_name: '大分県',
        sp_code: '44',
      },
      {
        sp_name: '宮崎県',
        sp_code: '45',
      },
      {
        sp_name: '鹿児島県',
        sp_code: '46',
      },
      {
        sp_name: '沖縄県',
        sp_code: '47',
      },
    ],
  },
];
