/**
 * 画面用共通定数
 */
/** 定数 */
export const CONSTANT = {
  // ハイフン
  HYPHEN: '-',
  // アンダーバー
  UNDERBAR: '_',
  // カンマ
  COMMA: ',',
  // コロン
  COLON: ':',
  // ピリオド
  PERIOD: '.',
  // クエスチョン
  QUESTION: '?',
  // アンパサンド
  AMPERSAND: '&',
  // ダブルクォーテーション
  DOUBLE_QUOTATION: '"',
  // イコール
  EQUAL: '=',
  // 空文字
  EMPTY_STRING: '',
  // 空文字(1文字)
  EMPTY_ONE_STRING: ' ',
  // 左_波括弧
  LEFT_BRACE: '{',
  // 右_波括弧
  RIGHT_BRACE: '}',
  // Max uploaded file size: 4MB
  MAX_FILE_SIZE: 4194304,
  // Max uploaded file size: 4MB
  MAX_FILE_SIZE_text: '4MB',
  // カンマ
  FUllSIZE_COMMA: '、',
  // teacher class id 
  TEACHER_ID: '1'
};

/** 数字 */
export const NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

/** 日付形式 */
export const DATE_FORMAT = {
  // 年月日
  DATE: 'yyyyMMdd',
  // 年月日(ハイフン)
  DATE_HYPHEN: 'yyyy-MM-dd',
  // 年月日時分秒(ハイフン)
  DATETIME_HYPHEN: 'yyyy-MM-dd-HHmmss',
  // 年月日(アンダーバー)
  DATE_UNDERBAR: 'yyyy_MM_dd',
  // 年月日時分秒
  DATETIME: 'yyyyMMddHHmmss',
};

/** ファイル拡張子 */
export const FILE_EXTENSION = {
  // CSVファイル
  CSV: '.csv',
  // CSVファイル
  CSV2: '.CSV',
  // TSVファイル
  TSV: '.tsv',
  // TSVファイル
  TSV2: '.TSV',
  // Excelファイル
  EXCEL: '.xlsx',
  // Excelファイル
  EXCEL2: '.XLSX',
  // PDF(小文字)ファイル
  PDF: '.pdf',
  //PDF(大文字)ファイル
  PDF2: '.PDF',
  // ZIPファイル
  ZIP: '.zip',
  // ZIPファイル
  ZIP2: '.ZIP',
  // PPTファイル
  PPT: '.ppt',
  // PPTファイル
  PPT2: '.PPT',
  // PPTXファイル
  PPTX: '.pptx',
  // PPTXファイル
  PPTX2: '.PPTX',
};

/** 画像拡張子 */
export const IMAGE_EXTENSION = {
  // JPG画像
  JPG: '.jpg',
  // JPG画像
  JPG2: '.JPG',
  // JPEG画像
  JPEG: '.jpeg',
  // JPEG画像
  JPEG2: '.JPEG',
  // PNG画像
  PNG: '.png',
  // PNG画像
  PNG2: '.PNG',
  // GIF画像
  GIF: '.gif',
  // GIF画像
  GIF2: '.GIF',
};

/** 画面定数 */
export const SCREEN_CONSTANT = {
  LINE_CODE: '<br>',
};

/** ファイル定数 */
export const FILE_CONSTANT = {
  LINE_CODE: '\r\n',
  TAB: '\t',
};

/** 文字コード */
export const CHARACTER_CODE = {
  SJIS: 'Shift_JIS',
};

/** 画像無しファイル名 */
export const NO_IMAGE_FILE_NAME = 'NoImage.jpg';

/** ローディングキー */
export const LOADING_KEY = {
  // ヘッダーメニュー
  HEADER_MENU: 'HEADER_MENU',
  // TOP画面.お知らせ
  TOP_NOTICE: 'TOP_NOTICE',
  // アイテムアダプター詳細
  GENERATE_DISPLAY: 'GENERATE_DISPLAY',
  // アイテムアダプター入力
  GENERATE_INPUT: 'GENERATE_INPUT',
  // アイテムアダプター検索
  GENERATE_SEARCH: 'GENERATE_SEARCH',
  // データ登録
  DATA_INSERT: 'DATA_INSERT',
  // データ更新
  DATA_UPDATE: 'DATA_UPDATE',
  // 削除
  DELETE: 'DELETE',
  // キャンセル
  CANCEL: 'CANCEL',
  // ファイルアップロード
  FILE_UPLOAD: 'FILE_UPLOAD',
  // ファイルダウンロード
  FILE_DOWNLOAD: 'FILE_DOWNLOAD',
};

/** コンポーネント値渡しキー */
export const COMPONENT_VALUE_KEY = {
  // ヘッダータイトル
  HEADER_TITLE: 'HEADER_TITLE',
};

// dicId Number
export const DICID_NUMBER = {
  // dicId number of the group type
  GROUP_TYPE: 1,
  // dicId number of the target grade
  TARGET_GRADE: 102,
  // dicId number of the pratice format
  PRACTICE_FORMAT: 106,
  // dicId number of the oublic/private
  PUBLIC_PRIVATE: 109,
  // dicId number of the agenda_solution_activity_area
  AGENDA_SOLUTION_ACTIVITY_AREA: 102,
};
export const ROLES = {
  ADMIN: '9',
  TEACHER: '2',
  PROVIDER: '1',
  ADMIN_NUM: 9,
  TEACHER_NUM: 2,
  PROVIDER_NUM: 1,
}

export const CATEGORIES ={
  WORK_EXPERIENCE: '職場体験',
  CLASS_SUPPORT: '授業支援',
  CLUB_ACTIVITY_SUPPORT: '部活動支援',
  OTHERS: 'その他（授業外）',
}

export const RECRUITMENT_TYPE = {
  CONFERENCE: '相談会アドバイザー',
  OTHERS: '体験会サポーター',
}

export const CHATSTATUSES = {
  REQUEST_PROPOSAL: { name: "返信待ち", code: "0" },
  ADJUSTMENT : { name: "検討中", code: "1" },
  DECISION : { name: "マッチング成立", code: "2" },
  IMPLEMENTED : { name: "マッチング不成立", code: "3" },
  END : { name: "活動済", code: "4" },
};
  
export const MATCH_TYPE = {
  AGENDA_SOLUTION: "1",
  AGENDA_NEED: "0",
  AGENDA_SOLUTION_TXT: "agenda_solution",
  AGENDA_NEED_TXT: "agenda_need",
};

export const MATCH_STATUSES = {
  REQUEST: "依頼中",
  PROPOSE: "提案中",
  ADJUSTMENT: "調整中",
  DECISION: "実施決定",
  IMPLEMENTED: "実施済",
  END: "終了",
  EVALUATION_ACHIEVEMENT_UNREGISTERED: "評価・実績未登録",
  UNRATED: "評価未登録",
}
  
export const NO_LOGIN_SCREENS = [
  'pages/platform/landing',
  'pages/platform/contact',
  'pages/platform/a201',
  'pages/platform/a202',
  'pages/platform/a203',
  'pages/platform/a204',
  'pages/platform/a210',
  'pages/platform/a211',
  'pages/platform/a212',
  'pages/platform/a213',
  'pages/platform/a214',
]

  
export const NO_NEED_LOGIN_SCREENS = [
  'pages/platform/c241',
  'pages/platform/c341',
]
  
export const URL_MYPAGE = {
  ADMIN: {
    LOGO: 'https://sumasapo.metro.tokyo.lg.jp/',
    TOP: 'https://mypage.sumasapo.metro.tokyo.lg.jp/manage/',
    LEARNING: 'https://tokyo-sumasapo.learning-ware.jp/oidc/login',
    LOGOUT: 'https://id.sumasapo.metro.tokyo.lg.jp/logout'
  },
  PROVIDER: {
    LOGO: 'https://sumasapo.metro.tokyo.lg.jp/',
    TOP: 'https://mypage.sumasapo.metro.tokyo.lg.jp/mypage',
    LEARNING: 'https://tokyo-sumasapo.learning-ware.jp/oidc/login',
    PROFILE: 'https://mypage.sumasapo.metro.tokyo.lg.jp/profile',
    LOGOUT: 'https://id.sumasapo.metro.tokyo.lg.jp/logout'
  },
  TEACHER: {
    LOGO: 'https://sumasapo.metro.tokyo.lg.jp/',
    TOP: 'https://mypage.sumasapo.metro.tokyo.lg.jp/group_mypage',
    PROFILE: 'https://mypage.sumasapo.metro.tokyo.lg.jp/group_profile',
    LOGOUT: 'https://id.sumasapo.metro.tokyo.lg.jp/logout'
  },
  OTHERS: {
    LOGO: 'https://sumasapo.metro.tokyo.lg.jp/',
    LOGIN: 'https://mypage.sumasapo.metro.tokyo.lg.jp/mypage'
  },
  LOGOUT: 'https://mypage.sumasapo.metro.tokyo.lg.jp/logout', 
  LANDING_PAGE : 'https://sumasapo.metro.tokyo.lg.jp/'
}
