import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  API_URL_APPLICATIONS_FILES,
  APPLICATIONS,
  API_URL_GET_LIST_POSTALS,
  API_URL_GET_LIST_POSTALS_INITIAL,
} from "manager/http-constants";
import { forkJoin, Observable } from "rxjs";
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import {
  CONSTANT,
  FILE_EXTENSION,
  LOADING_KEY,
  NUMBER,
  ROLES,
} from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { Validator } from "src/app/shared/validator/validator";
import { PostalNoDialogComponent } from "../common/postal-no-dialog/postal-no-dialog.component";
import { RoleDialogComponent } from "../common/role-dialog/role-dialog.component";
import { PlatformComponent } from "../platform.component";

@Component({
  selector: "app-a201",
  templateUrl: "./a201.component.html",
  styleUrls: ["./a201.component.scss", "../platform.component.scss"],
})

/**
 * 入会申請(A201)
 * 入会申請確認(A202)
 */
export class A201Component extends PlatformComponent {
  /** 共通部品_郵便番号ダイアログ */
  @ViewChild(PostalNoDialogComponent)
  postalNoDialogComponent: PostalNoDialogComponent;

  @ViewChild(RoleDialogComponent)
  roleDialogComponent: RoleDialogComponent;

  a201ApplicationInputForm: FormGroup = this.formBuilder.group({
   
    class_id: this.formBuilder.control(CONSTANT.TEACHER_ID, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "class_id",
          INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE
        ),
      ],
    }),
    organization_name: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "organization_name",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),
    postal_no: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "postal_no",
          INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE
        ),
      ],
    }),
    prefectures: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "prefectures",
          INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
        ),
      ],
    }),
    address_building: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "address_building",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),
    tel: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "tel",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),
    email: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "email",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),
    history_support_result: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "history_support_result",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),
    full_name: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "full_name",
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),
  });

  // 規約確認状態
  ruleCheck: boolean;

  // 規約チェック状態
  ruleCheckFlag: boolean[] = new Array();

  // 確認画面表示フラグ(true=入会申請確認(A202),false=入会申請(A201))
  confirmationFlag: boolean = false;

  // プラットフォーム誓約書.ファイル名格納先
  fileName: string;

  selectedValue: string;

  postalNoList: any = new Array();
  // class_id: string = '1';
  showFormFlag: boolean;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder,
    private validator: Validator,
    private messageData: MessageData
  ) {
    super(route, commonService, dbOperationService, loadingState);

    // urlを入会申請(A201)に変更する
    this.router.navigate(
      ["pages/platform/a201"],
      {
        queryParams: { screenId: "a201" },
      }
    );
  }

  ngOnInit(): void {
 
    this.a201ApplicationInputForm.controls["history_support_result"].disable();
    this.getListPostalsInitial();
   
  }

  public getListPostalsInitial(): void {
    this.postalNoList = new Array();
    this.loadingState.loadStart(API_URL_GET_LIST_POSTALS_INITIAL);

    this.dbOperationService
      .getNoTemplateData(API_URL_GET_LIST_POSTALS_INITIAL, {}, true)
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.postalNoList = response.body;
        } else {
          this.postalNoList = new Array();
        }

        this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS_INITIAL);
      });
  }

  public getPostalsList(e: any): void {
    this.a201ApplicationInputForm.get("prefectures").reset();
    if (!e.target.value.trim()) {
      this.getListPostalsInitial();
    } else {
      this.postalNoList = new Array();

      this.loadingState.loadStart(API_URL_GET_LIST_POSTALS);

      this.dbOperationService
        .getNoTemplateData(
          API_URL_GET_LIST_POSTALS,
          { postal_no: e.target.value },
          true
        )
        .subscribe((response) => {
          if (!this.commonService.checkNoneResponse(response)) {
            this.postalNoList = response.body;
            this.a201ApplicationInputForm.patchValue({
              prefectures: this.postalNoList[0].value,
            });
            this.a201ApplicationInputForm.get("prefectures").markAsDirty();
          } else {
            this.postalNoList = new Array();
            this.a201ApplicationInputForm.get("prefectures").markAsDirty();
          }

          this.loadingState.loadSleepEnd(0.3, API_URL_GET_LIST_POSTALS);
        });
    }
  }

 
  /**
   * 入会申請(A201)を表示する
   */
  public moveA201(): void {
    // 入会申請(A201)へ遷移
    this.router.navigate(
      ["pages/platform/a201"],
      {
        queryParams: { screenId: "a201" },
      }
    );

    // 入会申請(A201)を表示する
    this.confirmationFlag = false;
  }

  /**
   * 入会申請確認(A202)を表示する
   */
  public moveA202(): void {
    // 画面.入力フォームから入力内容を取得
    // ※値渡し
    let a201ApplicationInputForm: any = JSON.parse(
      JSON.stringify(this.a201ApplicationInputForm.value)
    );

    if (this.a201ApplicationInputForm.get("class_id").value === ROLES.TEACHER) {
      delete a201ApplicationInputForm.history_support_result;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: "",
        background_color: "",
        opacity: 0.3,
      })
    );

    /* 入力項目値バリデーションチェック */
    // 非同期同時実行リスト
    let task: Observable<any>[] = [
      /* 共通バリデーションチェック */
      this.dbOperationService.multiValidationResult(
        "application",
        a201ApplicationInputForm
      ),
    ];

    // 非同期同時実行
    forkJoin(task).subscribe((dataList) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd();

      // 入会申請確認(A202)へ遷移
      this.router.navigate(
        ["pages/platform/a201"],
        {
          queryParams: { screenId: "a202" },
        }
      );

      // 入会申請確認(A202)を表示する
      this.confirmationFlag = true;
    });
  }

  /**
   * 入会申請メールアドレス確認(A203)へ遷移する
   */
  public moveA203(): void {
    // 画面.入力フォームから入力内容を取得
    // ※値渡し
    let a201ApplicationInputForm: any = JSON.parse(
      JSON.stringify(this.a201ApplicationInputForm.value)
    );

    // if (this.a201ApplicationInputForm.get("class_id").value === "1") {
    //   delete a201ApplicationInputForm.history_support_result;
    // }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.DATA_INSERT);

    // 会員申請登録を実施
    this.dbOperationService
      .insertData(APPLICATIONS, a201ApplicationInputForm)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

        // レスポンス結果を判定
        if (API_RESPONSE.SUCCESS == response.body.Message) {
          // 正常終了の場合

          // 入会申請メールアドレス確認(A203)へ遷移
          this.router.navigate(["pages/platform/a203"], {
            queryParams: {
              email: a201ApplicationInputForm.email,
              class_id: this.a201ApplicationInputForm.get("class_id").value,
            },
          });
        } else {
          // 異常終了の場合

          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00001),
              detail: this.commonService.msg(MESSAGE_CODE.E80002),
            })
          );

          // 入会申請(A201)を表示する
          this.confirmationFlag = false;
        }
      });
  }

  /**
   * 郵便番号ダイアログ表示
   */
  // public openPostalNoDialog() {
  //   // 郵便番号ダイアログを表示
  //   this.postalNoDialogComponent.openPostalNoDialog();
  // }

  /**
   * 郵便番号セット
   * @param postalNoData 郵便番号情報
   */
  public setPostalNo(postalNoData: any): void {
    // 郵便番号情報をセット
    // 郵便番号
    {
      this.a201ApplicationInputForm.get("postal_no").markAsDirty();
      this.a201ApplicationInputForm
        .get("postal_no")
        .setValue(postalNoData.postal_no);
    }

    // 都道府県
    {
      this.a201ApplicationInputForm.get("prefectures").markAsDirty();
      this.a201ApplicationInputForm
        .get("prefectures")
        .setValue(postalNoData.prefectures);
    }

    // 所在地（市区町村）
    {
      this.a201ApplicationInputForm.get("city").markAsDirty();
      this.a201ApplicationInputForm.get("city").setValue(postalNoData.city);
    }

    // 所在地（町域）
    {
      this.a201ApplicationInputForm.get("town_area").markAsDirty();
      this.a201ApplicationInputForm
        .get("town_area")
        .setValue(postalNoData.town_area);
    }
    // 所在地（番地・建物）
    {
      this.a201ApplicationInputForm.get("address_building").markAsDirty();
      this.a201ApplicationInputForm
        .get("address_building")
        .setValue(postalNoData.address_building);
    }
  }

  public resetForm(): void {
    const valueClassId = this.a201ApplicationInputForm.get("class_id").value;
    if (valueClassId == ROLES.TEACHER) {
      this.a201ApplicationInputForm.controls[
        "history_support_result"
      ].setValue('')
      this.a201ApplicationInputForm.controls[
        "history_support_result"
      ].disable();
    } else {
      this.a201ApplicationInputForm.controls["history_support_result"].enable();
    }
  }
}
