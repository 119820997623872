<p-overlayPanel #op (onShow)="onShow()" (onHide)="onHide()">
    
  <div class="p-col-12 dialog-content">
    <ng-container *ngIf="e001.length < 1; else list">
      <div *ngIf="!loading" class="p-col-12 label-title p-d-flex p-jc-center p-mb-2 pt-0"> お知らせ無し... </div>
      <div *ngIf="loading" class="p-col-12 loader center p-jc-center p-d-flex">
        <p-progressSpinner
          strokeWidth="6"
          animationDuration=".5s"
        ></p-progressSpinner>
      </div>
    </ng-container>
    <ng-template #list> 
      <ng-container *ngFor="let info of e001">
        <div class="p-grid item pointer" (click)="moveE002(info?.information_id)">
          <div class="p-col-12 label-title"
            [pTooltip]="info?.title"
            #tt
            [tooltipDisabled]="!isOverflow(tt)"
            showDelay="500"
            hideDelay="500"
            tooltipPosition="bottom"
          >
            {{info?.title}}
          </div>
          <div class="p-col-12 label-detail" 
            [pTooltip]="info?.information_detail"
            #detail
            [tooltipDisabled]="!isOverflow(detail)"
            showDelay="500"
            hideDelay="500"
            tooltipPosition="bottom"
          >
            {{info?.information_detail}}
          </div>
          <div class="p-col-6 label-sub">
            <span class="black">お知らせ区分:&nbsp;</span>
            <span class="grey inline" 
            [pTooltip]="info?.information_kbn?.name"
            #name
            [tooltipDisabled]="!isOverflow(name)"
            showDelay="500"
            hideDelay="500"
            tooltipPosition="bottom"
            >{{info?.information_kbn?.name}}</span>
          </div>
          <div class="p-col-6 label-sub">
            <span class="black">作成日付:&nbsp;</span>
            <span class="grey inline"
            [pTooltip]="info?.publishe_start"
            #date
            [tooltipDisabled]="!isOverflow(date)"
            showDelay="500"
            hideDelay="500"
            tooltipPosition="bottom"
            >{{info?.publishe_start}}</span>
          </div>
        </div>

        <div class="seperator"></div>
      </ng-container>
    </ng-template>
    
  </div>
  <div class="p-col-12 dialog-footer p-d-flex p-jc-center">
    <button
      pButton
      type="button"
      label="もっと見る"
      class="p-button"
      (click)="moveE001()"
    ></button>
  </div>

</p-overlayPanel>