<p-dialog
  header="ユーザー登録"
  [(visible)]="boardFlag"
  [modal]="true"
  [responsive]="true"
  [styleClass]="'main-dialog'"
  [minY]="70"
  [baseZIndex]="10000"
>
  <form [formGroup]="roleInputForm">
    <div class="choose-role">
      <p-radioButton
        value="1"
        label="学校・教員"
        formControlName="role"
        class="school-teacher-role"
      ></p-radioButton>
      <p-radioButton
        value="2"
        label="支援人材・会社"
        formControlName="role"
        class="provider-role"
      ></p-radioButton>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button
      (click)="closeDialog()"
      label="戻る"
      styleClass="p-button-text"
    ></p-button>
    <p-button
      (click)="selectRoleApplication()"
      label="発行"
      class="btn-confirm"
      [disabled]="!roleInputForm.value?.role"
    ></p-button>
  </ng-template>
</p-dialog>
