import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { PlatformComponent } from "../platform.component";

@Component({
  selector: "app-b103",
  templateUrl: "./b103.component.html",
  styleUrls: ["./b103.component.scss", "../platform.component.scss"],
})

/**
 * 会員プロフィール登録完了
 * 会員プロフィール(B211)へ遷移する
 */
export class B103Component extends PlatformComponent {
  //　会員ID
  memberId: string = this.route.snapshot.queryParams.member_id;
  // 会員種別
  classId: string = this.route.snapshot.queryParams.class_id;

  // 会員情報格納先
  memberInfo: any;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * 会員プロフィール(B211)へ遷移する
   */
  public moveB211(): void {
      this.router.navigate(["pages/platform/b211/" + this.memberId]);
  }
}
