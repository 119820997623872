import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { GenerateService } from "src/app/shared/generate/generate.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SEARCH_INFORMATIONS_API_CONSTANT } from "src/app/shared/constant/api-constant";
import { CommonService } from "../../service/common.service";
import { CONSTANT, LOADING_KEY } from "src/app/shared/constant/constant";
import { LoadingState } from "../../html-parts/loading/loading-state";
import { TreeNode } from "primeng/api";

@Component({
  selector: "app-generate-search",
  templateUrl: "./generate-search.component.html",
  styleUrls: ["./generate-search.component.scss"],
})

/**
 * 検索項目情報
 */
export class GenerateSearchComponent implements OnInit {
  // インプット.検索項目生成エンドポイント
  @Input() searchEndPoint: string;

  // インプット.検索項目生成テンプレートID
  @Input() searchTemplateId: number;

  // 検索ボタン名
  // 未設定時のボタン名は検索
  @Input() searchButtonName: string = "検索";

  // 日付 and 数値の表示形式変換
  // 未設定時の開始and終了の縦に表示
  @Input() changeFromToDisplay: boolean;

  // アウトプット.検索フォーム
  @Output() generateSearchInformation = new EventEmitter<FormGroup>();

  // 画面.検索フォーム
  generateSearchForm: FormGroup = this.formBuilder.group(Object());

  // 画面.検索項目生成
  generateSearchList: any[];
  listCategory: any[] = [];
  selectedCategory: any[] = [];
  subCategory1: any[] = [];
  listSubCategory1: any[] = [];
  subCategory2: any[] = [];
  listSubCategory2: any[] = [];
  listSubCategory: any[] = [];
 

  // 初期化/宣言
  constructor(
    private generateService: GenerateService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}

  // 画面初期表示 or @Input変更時
  ngOnChanges(): void {
    // 検索項目生成を初期化
    this.generateSearchList = new Array();

    // 検索フォームを初期化
    this.generateSearchForm = this.formBuilder.group(Object());

    // 検索項目生成を実施
    this.getGenerateSearch();
  }

  /**
   * 検索項目生成
   */
  private getGenerateSearch(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.GENERATE_SEARCH);

    // 検索項目情報取得処理
    this.generateService
      .getGenerateSearch(this.searchEndPoint, this.searchTemplateId)
      .subscribe((response) => {
        // 検索項目情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 検索項目情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_SEARCH);

          return;
        }

        // 検索項目情報を画面.検索項目生成に格納
        this.generateSearchList = response.body;

        // 検索フォーム用コントロール作成
        for (const generateSearchItems of response.body) {
          if (generateSearchItems.column_id == "agenda_category_class_cd1") {
            this.listCategory = generateSearchItems.code_list_multi.map(
              (value: any) => {
                return {
                  name: value.class_name1,
                  value: value.value,
                  children: value.cd2.map((child1: any) => {
                    return {
                      name: child1.class_name2,
                      value: child1.value,
                    };
                  }),
                };
              }
            );
          }
          // 検索項目タイプの判定
          if (
            SEARCH_INFORMATIONS_API_CONSTANT.DATE_TYPE ==
              generateSearchItems.search_type ||
            SEARCH_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ==
              generateSearchItems.search_type
          ) {
            // 検索項目タイプが"date" or "number"の場合
            this.generateSearchForm.addControl(
              generateSearchItems.column_id + "_FROM",
              this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
            );

            this.generateSearchForm.addControl(
              generateSearchItems.column_id + "_TO",
              this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
            );
          } else {
            // 検索項目タイプが"date" or "number"以外の場合
            this.generateSearchForm.addControl(
              generateSearchItems.column_id,
              this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
            );
          }
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.GENERATE_SEARCH);
      });
  }

  /* 検索ボタン押下 */
  public onSubmit() {
    const defaultInputValue: object = new Object();
    // 画面.検索フォームを親コンポーネントへ値を渡す
    for (const generateSearchItems of this.generateSearchList) {
      // if (generateSearchItems.column_id == "category") {
      //   this.generateSearchForm.controls[
      //     generateSearchItems.column_id
      //   ].setValue(
      //     this.selectedCategory
      //       .map((val) => {
      //         return val.value;
      //       })
      //       .join(CONSTANT.COMMA)
      //   );
      // }
      // 検索項目タイプの判定
      if (
        SEARCH_INFORMATIONS_API_CONSTANT.DATE_TYPE ==
          generateSearchItems.search_type &&
        this.generateSearchForm.value[
          generateSearchItems.column_id + "_FROM"
        ] == null
      ) {
        // set value from null to empty string with date
        this.generateSearchForm.controls[
          generateSearchItems.column_id + "_FROM"
        ].setValue(CONSTANT.EMPTY_STRING);
      }
      if (
        SEARCH_INFORMATIONS_API_CONSTANT.DATE_TYPE ==
          generateSearchItems.search_type &&
        this.generateSearchForm.value[generateSearchItems.column_id + "_TO"] ==
          null
      ) {
        // set value from null to empty string with date
        this.generateSearchForm.controls[
          generateSearchItems.column_id + "_TO"
        ].setValue(CONSTANT.EMPTY_STRING);
      }
      if (
        SEARCH_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ==
          generateSearchItems.search_type &&
        this.generateSearchForm.value[
          generateSearchItems.column_id + "_FROM"
        ] == null
      ) {
        // set value from null to empty string with date
        this.generateSearchForm.controls[
          generateSearchItems.column_id + "_FROM"
        ].setValue(CONSTANT.EMPTY_STRING);
      }
      if (
        SEARCH_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ==
          generateSearchItems.search_type &&
        this.generateSearchForm.value[generateSearchItems.column_id + "_TO"] ==
          null
      ) {
        // set value from null to empty string with date
        this.generateSearchForm.controls[
          generateSearchItems.column_id + "_TO"
        ].setValue(CONSTANT.EMPTY_STRING);
      }
    }
    this.generateSearchInformation.emit(this.generateSearchForm.value);
  }

  /**
   * クリアボタン押下
   */
  public reset() {
    // デフォルト入力値オブジェクトを生成
    const defaultInputValue: object = new Object();

    // 検索項目情報をループ
    for (const generateSearchItems of this.generateSearchList) {
      // 検索項目タイプの判定
      if(generateSearchItems.column_id == 'agenda_category_class_cd2') {
        
        this.listSubCategory = [];
      }
      if (
        SEARCH_INFORMATIONS_API_CONSTANT.DATE_TYPE ==
          generateSearchItems.search_type ||
        SEARCH_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ==
          generateSearchItems.search_type
      ) {
        // 検索項目タイプが"date" or "number"の場合
        defaultInputValue[generateSearchItems.column_id + "_FROM"] =
          CONSTANT.EMPTY_STRING;

        defaultInputValue[generateSearchItems.column_id + "_TO"] =
          CONSTANT.EMPTY_STRING;
      } else {
        // 検索項目タイプが"date" or "number"以外の場合
        defaultInputValue[generateSearchItems.column_id] =
          CONSTANT.EMPTY_STRING;
      }
    }
    this.listSubCategory1 = [];
    this.listSubCategory2 = [];
    this.selectedCategory = [];
    // フォーム入力値をクリア
    this.generateSearchForm.reset(defaultInputValue);
  }

  onChangCategory(event: any) {
    this.generateSearchForm.controls['agenda_category_class_cd2'].setValue(CONSTANT.EMPTY_STRING);
    const listselectdCategory = this.listCategory.filter((valCategory: any) => {
      return event.value.indexOf(valCategory.value) !== -1;
    });
    const listSubcategory = listselectdCategory.map((val) => {
      return val.children;
    });
    if (listSubcategory.length > 0) {
      this.listSubCategory = listSubcategory.reduce((accVal, currentVal) => {
        return accVal.concat(currentVal);
      });
    } else {
      this.listSubCategory = [];
    }
  }
}
