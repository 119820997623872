

<!-- メイン -->
<div class="main" *ngIf="isDataLoaded">
  <!-- メインコンテンツ -->
  <div class="main-content">
    <div class="return">
      <button
        pButton
        type="button"
        label="前のページへ戻る"
        icon="pi pi-angle-left"
        class="p-button-text"
        (click)="moveBack()"
      ></button>
    </div>
    <div class="p-fluid p-formgrid p-grid screen-support">
      <div class="p-col-9">
        <div class="p-fluid p-formgrid p-grid support-header">
          <div class="p-col-12">
            <p-card
              class="support-header-card"
            >
              <div class="support-header-card-show">
                <div
                  class="p-fluid p-formgrid p-grid support-header-card-detail"
                >
                  <div class="p-col-12 support-desc">
                    <div class="support-description-detail">
                      <div class="support-sub-title">
                        <span
                          class="func-name text-sub-size letter-spacing-1 color-request"
                          >{{ detailTask?.match_type == '0' ? '応募' : '依頼' }}
                        </span>
                      </div>
                      <span class="support-large-title primary-title">{{
                        detailTask?.title
                      }}</span>
                      <div class="support-sub-title">
                        <span>{{ detailTask?.needs_organization_name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>

          <div class="p-col-12">
            <div class="support-separate"></div>
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-first">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">活動の場名称</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.title }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-third">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">募集種別</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.match_type == '0' ? '応募' : '依頼' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-second">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview no-wrap"
                      >依頼内容
                    </span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.expected_content }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fourth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview no-wrap">活動内容（URL）</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <a (click)="openURI(detailTask?.expected_url)" class="linktext-blue underline">
                      {{ detailTask?.expected_url }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-sixth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">活動内容（ファイル）</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <a [href]="detailTask?.document?.url" class="linktext-blue underline">
                      {{ detailTask?.document?.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">回答締め切り</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.deadline }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">開催日</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.event_date }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">開催日（テキスト）</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.event_date_memo }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">地域</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.area }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">主催者</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.organizer }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">開催場所</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.venue }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">謝礼支払条件</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.payment_terms }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">運営者（主催者と異なる場合）</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.manager }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>
          
          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">問い合せ先</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.contact_information }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12">
            <div class="support-separate"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>
