import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { API_URL_DIC_LISTS } from "manager/http-constants";
import { forkJoin, Observable } from "rxjs";
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from "src/app/shared/constant/api-constant";
import { CONSTANT, LOADING_KEY } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { Validator } from "src/app/shared/validator/validator";
import { PlatformComponent } from "../../platform.component";

@Component({
  selector: "app-approval-dialog",
  templateUrl: "./approval-dialog.component.html",
  styleUrls: ["./approval-dialog.component.scss"],
})

/**
 * 共通部品_承認時ダイアログ
 */
export class ApprovalDialogComponent extends PlatformComponent {
  // 画面.承認入力フォーム
  approvalInputForm: FormGroup = this.formBuilder.group({
    // ステータス
    status: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "status",
          INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE
        ),
      ],
    }),
    // 承認時コメント
    status_comment: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          "application",
          "status_comment",
          INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
        ),
      ],
    }),
  });

  // 承認結果返却 (true:承認成功,false:承認失敗)
  @Output() returnApproval = new EventEmitter<boolean>(false);
  @Output() returnReject = new EventEmitter<boolean>(false);
  @Output() invalidObj = new EventEmitter<boolean>(false);

  // REST APIエンドポイント格納先
  endPoint: string;

  // 対象データのID格納先
  pkeyId: string;

  // 内閣府承認ダイアログ表示フラグ(true:ダイアログ状態、false:ダイアログ非状態)
  approvalDialogFlag: boolean = false;

  // 内閣府承認ダイアログ(小)表示フラグ(true:ダイアログ状態、false:ダイアログ非状態)
  smallApprovalDialogFlag: boolean = false;

  // 確認画面表示フラグ(true=承認確認,false=承認入力)
  confirmationFlag: boolean = false;

  // 辞書情報格納先
  approvalSpecCodeList: any;

  // 申し送り事項表示フラグ(true=非表示,false=表示)
  boardFlag: boolean = false;
  smallApprovalDialogMasterFlag: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private formBuilder: FormBuilder,
    private validator: Validator,
    private messageData: MessageData
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {}

  /**
   * 内閣府承認ダイアログ表示
   * @param endPoint REST APIエンドポイント
   * @param pkeyId 対象データのID
   * @param dicId ラジオボタン辞書値
   * @param hideCode 非表示ラジオボタン
   */
  public openApprovalDialog(
    endPoint: string,
    pkeyId: string,
    dicId: number,
    ...hideCode: number[]
  ): void {
    // 内閣府承認ダイアログが既に表示済みの場合
    if (this.approvalDialogFlag) {
      // 処理を終了
      return;
    }

    // 辞書値リストを取得を実施
    this.getApprovalSpecCodeList(dicId, hideCode);

    // REST APIエンドポイントを格納
    this.endPoint = endPoint;

    // 対象データのIDを格納
    this.pkeyId = pkeyId;

    // 入力フォームコントロールを非活性化
    this.approvalInputForm.get("status").enable();

    // 画面.承認入力フォームを初期化
    this.approvalInputForm.reset();

    // 確認画面表示フラグ
    this.confirmationFlag = false;

    // 内閣府承認ダイアログ表示
    this.approvalDialogFlag = true;
  }

  /**
   * 内閣府承認ダイアログ(小)表示
   * @param endPoint REST APIエンドポイント
   * @param pkeyId 対象データのID
   * @param dicId ラジオボタン辞書値
   * @param hideCode 非表示ラジオボタン
   */
  public openSmallApprovalDialog(
    endPoint: string,
    pkeyId: string,
    dicId: number,
    ...hideCode: number[]
  ): void {
    // 内閣府承認ダイアログ(小)が既に表示済みの場合
    if (this.smallApprovalDialogFlag) {
      // 処理を終了
      return;
    }
    // 申し送り事項表示フラグのリセット
    this.boardFlag = false;
    // ラジオボタン辞書値が掲示板コメントの場合申し送り事項を非表示とする
    if (dicId == 2008) {
      this.boardFlag = true;
    }

    // 辞書値リストを取得を実施
    this.getApprovalSpecCodeList(dicId, hideCode);

    // REST APIエンドポイントを格納
    this.endPoint = endPoint;

    // 対象データのIDを格納
    this.pkeyId = pkeyId;

    // 入力フォームコントロールを非活性化
    this.approvalInputForm.get("status").enable();

    // 画面.承認入力フォームを初期化
    this.approvalInputForm.reset();

    // 確認画面表示フラグ
    this.confirmationFlag = false;

    // 内閣府承認ダイアログ(小)表示
    this.smallApprovalDialogFlag = true;
  }

  public openSmallApprovalMasterDialog(
    endPoint: string,
    pkeyId: string,
    dicId: number,
    ...hideCode: number[]
  ): void {
    // 内閣府承認ダイアログ(小)が既に表示済みの場合
    if (this.smallApprovalDialogMasterFlag) {
      // 処理を終了
      return;
    }
    // 申し送り事項表示フラグのリセット
    this.boardFlag = false;
    // ラジオボタン辞書値が掲示板コメントの場合申し送り事項を非表示とする
    if (dicId == 2008) {
      this.boardFlag = true;
    }

    // 辞書値リストを取得を実施
    this.getApprovalSpecCodeList(dicId, hideCode);

    // REST APIエンドポイントを格納
    this.endPoint = endPoint;

    // 対象データのIDを格納
    this.pkeyId = pkeyId;

    // 入力フォームコントロールを非活性化
    this.approvalInputForm.get("status").enable();

    // 画面.承認入力フォームを初期化
    this.approvalInputForm.reset();

    // 確認画面表示フラグ
    this.confirmationFlag = false;

    // 内閣府承認ダイアログ(小)表示
    this.smallApprovalDialogMasterFlag = true;
  }
  /**
   * 内閣府承認確認
   */
  protected confirmationApproval(): void {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      })
    );

    /* 入力項目値バリデーションチェック */
    // 非同期同時実行リスト
    let task: Observable<any>[] = [
      /* 共通バリデーションチェック */
      this.dbOperationService.multiValidationResult(
        "application",
        this.approvalInputForm.value
      ),
    ];

    // 非同期同時実行
    forkJoin(task).subscribe((dataList) => {
      // レスポンスを結合
      // const data = this.generateService.JoinValidationResponseList(dataList);

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd();

      // 入力フォームコントロールを非活性化
      this.approvalInputForm.get("status").disable();

      // 確認画面表示フラグ
      this.confirmationFlag = true;
    });
  }

  /**
   * 内閣府承認入力
   */
  protected returnInputApprova(): void {
    // 入力フォームコントロールを活性化
    this.approvalInputForm.get("status").enable();

    // 確認画面非表示フラグ
    this.confirmationFlag = false;
  }

  /**
   * 内閣府承認登録
   */
  protected insertApproval(): void {
    const statusReject = "2";
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // 入力フォームコントロールを活性化
    this.approvalInputForm.get("status").enable();

    // 内閣府承認
    this.dbOperationService
      .updateData(this.endPoint, this.pkeyId, this.approvalInputForm.value)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

        // レスポンス結果を判定
        if (API_RESPONSE.SUCCESS == response.body.Message) {
          // 正常終了の場合

          // 内閣府承認ダイアログ非表示
          this.approvalDialogFlag = false;

          // 内閣府承認ダイアログ(小)非表示
          this.smallApprovalDialogFlag = false;
          this.smallApprovalDialogMasterFlag = false;

         
          if (this.approvalInputForm.get("status").value === statusReject) {
            //reject completion
            this.returnReject.emit(true);
          } else {
            // 承認更新完了
            this.returnApproval.emit(true);
          }
          
        }else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.invalidObj.emit();
        } else {
          // 異常終了の場合

          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00001),
              detail: this.commonService.msg(MESSAGE_CODE.E80002),
            })
          );

          // 確認画面表示フラグ
          this.confirmationFlag = false;
        }
      });
  }
  /**
   * 内閣府承認登録
   */
  protected insertApprovalReject(): void {
    const statusReject = "2";
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: CONSTANT.EMPTY_STRING,
        background_color: CONSTANT.EMPTY_STRING,
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // 入力フォームコントロールを活性化
    this.approvalInputForm.get("status").enable();

    // 内閣府承認
    this.dbOperationService
      .updateData(this.endPoint, this.pkeyId, this.approvalInputForm.value)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

        // レスポンス結果を判定
        if (API_RESPONSE.SUCCESS == response.body.Message) {
          // 正常終了の場合

          // 内閣府承認ダイアログ非表示
          this.approvalDialogFlag = false;

          // 内閣府承認ダイアログ(小)非表示
          this.smallApprovalDialogFlag = false;
          this.smallApprovalDialogMasterFlag = false;

          if (this.approvalInputForm.get("status").value === statusReject) {
            //reject completion
            this.returnReject.emit(true);
          } else {
            // 承認更新完了
            this.returnApproval.emit(true);
          }
        }else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.invalidObj.emit();
        } else {
          // 異常終了の場合

          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00001),
              detail: this.commonService.msg(MESSAGE_CODE.E80002),
            })
          );

          // 確認画面表示フラグ
          this.confirmationFlag = false;
        }
      });
  }

  /**
   * 辞書値リストを取得
   * @param hideCode 非表示ラジオボタン
   */
  protected getApprovalSpecCodeList(dicId: number, hideCode: number[]) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_DIC_LISTS);

    // 辞書情報取得処理
    this.dbOperationService.getDicLists(dicId).subscribe((response) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3, API_URL_DIC_LISTS);

      // 辞書情報が存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        // 辞書情報に存在しない場合
        this.approvalSpecCodeList = new Array();

        return;
      }

      // 辞書情報に存在する場合

      // ラジオボタン隠しが存在するか
      if (!hideCode.length) {
        // 存在しない場合
        this.approvalSpecCodeList = response.body;

        return;
      }

      // 非表示ラジオボタンにないコードのみ抽出
      const dicDataList = response.body.filter(
        (dicData) => !hideCode.includes(Number(dicData.sp_code))
      );

      // 辞書情報を格納
      this.approvalSpecCodeList = dicDataList;
    });
  }
}
