<!-- 詳細画面ダイアログ-->
<p-sidebar
  [(visible)]="detailsNav"
  position="right"
  styleClass="p-sidebar-md"
  blockScroll="false"
  [showCloseIcon]="false"
>
  <p-scrollPanel styleClass="custombar">
    <!-- 詳細情報-->
    <app-generate-display-information (reloadID)="reload($event)"></app-generate-display-information>
  </p-scrollPanel>
</p-sidebar>
