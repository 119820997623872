

<div class="main" id="C601-main">
  <div class="main-content">
    <div class="p-fluid p-formgrid p-grid chat-screen">
      <!-- LIST GROUP CHAT -->
      <div class="p-col-3 list-message-arrive" #listPanelChat>
        <div
          class="p-fluid p-formgrid p-grid message-item"
          *ngFor="let chatCommon of listChatCommon "
          [ngClass]="{
            'focus-message': matchId == chatCommon.match_id
          }"
        >
          <div
            class="item-chat"
            (click)="
              openDetailChat(chatCommon.match_id, chatCommon?.count_unread); resetChat()
            "
          >
            <div class="p-col-2 arrive-item-img center p-p-0">
              <div
                class="message-img"
                [ngStyle]="{
                  'background-image':
                    'url(' + chatCommon.icon_image + ')'
                }"
              ></div>
            </div>
            <div class="p-col-8 arrive-item-desc">
              <span
                class="func-name text-sub-size letter-spacing-1"
                [ngClass]="setClassesKind(chatCommon.recruitment_kind)"
                >{{ chatCommon.recruitment_kind }}
              </span>
              <div
                class="item-desc-location"
                *ngIf="classId == '1' || classId == '9'"
              >
                {{ chatCommon.needs_organization_name }}
              </div>
              <div
                class="item-desc-location"
                *ngIf="classId == '2' || classId == '9'"
              >
                {{ chatCommon.seeds_organization_name }}
              </div>
              <div class="item-desc-detail">
                {{ chatCommon.title }}
              </div>
            </div>
            <div
              class="p-col-2 arrive-item-quality center"
              *ngIf="chatCommon.count_unread !== '0'"
            >
              <ng-container
                *ngIf="number(chatCommon.count_unread) > 99; else norCount"
              >
                <p-avatar
                  label="99+"
                  shape="circle"
                  styleClass="item-messge-quality"
                ></p-avatar>
              </ng-container>
              <ng-template #norCount>
                <p-avatar
                  [label]="chatCommon.count_unread"
                  shape="circle"
                  styleClass="item-messge-quality"
                ></p-avatar>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <!-- /LIST GROUP CHAT -->

      <!-- RIGHT CONTENT -->
      <div class="p-col-9 message-detail" *ngIf="matchId">
        <div class="p-fluid p-formgrid p-grid">
          <!-- SEARCH -->
          <form [formGroup]="c601ChatInputForm" class="chat-form">
            <div class="p-col-12 info-detail-top">
              <div class="info-top-message-detail">
                <div class="p-fluid p-d-flex p-ai-center">
                  <div class="p-col-1 p-d-flex p-ai-center">
                    <div class="letter-spacing-2">
                      <i class="fa-solid fa-map-pin text-red"></i>
                    </div>
                    <span class="letter-spacing-2 p-text-bold p-pr-2 text-red">
                      {{ listMatchChatDetail?.match_type == '0' ? '応募' : listMatchChatDetail?.match_type == '1' ? '依頼' : ''}}
                    </span>
                  </div>
                  <div class="p-d-flex p-ai-center">
                    <span class="letter-spacing-2 p-text-bold p-pr-2"
                      >ステータス</span
                    >
                    <ng-container
                      *ngIf="
                        showPulldown() else elseTemplate
                      "
                    >
                      <div
                        [ngClass]="
                          setClassStatusDropdown(
                            c601ChatInputForm.get('status').value
                          )
                        "
                      >
                        <p-dropdown
                          optionLabel="name"
                          [options]="listStatus[listMatchChatDetail?.status]"
                          optionValue="code"
                          class="drop-down-style-first"
                          formControlName="status"
                          (onChange)="changeStatus($event)"
                        ></p-dropdown>
                      </div>
                    </ng-container>
                    <ng-template #elseTemplate>
                      <div
                        class="statusOther"
                        [ngClass]="setClassStatus(listMatchChatDetail?.status)"
                      >
                        {{ listMatchChatDetail?.status_name }}
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <!-- /.SEARCH -->
          <div class="box">
            <!-- DETAIL INFO -->
            <div class="ptb-10">
              <div class="header info-detail-center bg-yellow-color" id="headerChat">
                <div class="scroll-header">
                  <div class="p-fluid p-formgrid p-grid mlr-0" id="headerChatChild">
                    <div class="info-container">
                      <ng-container *ngIf="classId == '2' || classId == '9'; else need">
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 bold prefer-first-date letter-spacing-1"
                            >活動の場名称
                          </span>
                          <span class="p-col-11 letter-spacing-1"
                            >{{ listMatchChatDetail?.title }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 bold prefer-first-date letter-spacing-1"
                            >開催日
                          </span>
                          <span class="p-col-5 letter-spacing-1"
                            >{{ listMatchChatDetail?.event_date }}
                          </span>
                          <span class="p-col-1 bold prefer-second-date letter-spacing-1"
                            >締め切り</span
                          >
                          <span class="p-col-5 letter-spacing-1">{{
                            listMatchChatDetail?.deadline
                          }}</span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 bold prefer-first-date letter-spacing-1"> サポーター </span>
                          <span
                            class="p-col-11 letter-spacing-1 router-link"
                            (click)="moveC331(listMatchChatDetail?.seeds_agenda_id)"
                            >{{ listMatchChatDetail?.seeds_organization_name }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 bold prefer-first-date letter-spacing-1">主催者</span>
                          <span class="p-col-11 letter-spacing-1"
                            >{{ listMatchChatDetail?.needs_organization_name }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 letter-spacing-1 third-content-title bold"
                            >活動内容</span
                          >
                          <span
                            *ngIf="listMatchChatDetail?.match_type == '0'"
                            class="p-col-11 letter-spacing-1 third-content-value router-link"
                            (click)="moveC341(listMatchChatDetail?.ns_id)"
                            >{{ listMatchChatDetail?.expected_content }}
                          </span>
                          <span
                            *ngIf="listMatchChatDetail?.match_type != '0'"
                            class="p-col-11 letter-spacing-1 third-content-value router-link"
                            (click)="moveC404(listMatchChatDetail?.match_id)"
                            >{{ listMatchChatDetail?.expected_content }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date" *ngIf="listMatchChatDetail?.match_type == '0'">
                          <span class="p-col-1 letter-spacing-1 third-content-title bold"
                            >メッセージ</span
                          >
                          <span class="p-col-11 letter-spacing-1 third-content-value"
                            >{{ listMatchChatDetail?.memo }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date" *ngIf="classId == '2'">
                          <span class="p-col-1 letter-spacing-1 third-content-title bold"
                            >アンケート</span
                          >
                          <div class="p-col-5 letter-spacing-1 third-content-value">
                            <a [href]="getUrl()" class="linktext-blue underline"> サポーター評価アンケート </a>
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #need>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 bold prefer-first-date letter-spacing-1"
                            >活動の場名称
                          </span>
                          <span class="p-col-11 letter-spacing-1"
                            >{{ listMatchChatDetail?.title }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 bold prefer-first-date letter-spacing-1"
                            >開催日
                          </span>
                          <span class="p-col-5 letter-spacing-1"
                            >{{ listMatchChatDetail?.event_date }}
                          </span>
                          <span class="p-col-1 bold prefer-second-date letter-spacing-1"
                            >締め切り</span
                          >
                          <span class="p-col-5 letter-spacing-1">{{
                            listMatchChatDetail?.deadline
                          }}</span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 bold prefer-first-date letter-spacing-1"> サポーター </span>
                          <span
                            class="p-col-11 letter-spacing-1 router-link"
                            (click)="moveC331(listMatchChatDetail?.seeds_agenda_id)"
                            >{{ listMatchChatDetail?.seeds_organization_name }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 bold prefer-first-date letter-spacing-1">主催者</span>
                          <span class="p-col-11 letter-spacing-1"
                            >{{ listMatchChatDetail?.needs_organization_name }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 letter-spacing-1 third-content-title bold"
                            >活動内容</span
                          >
                          <span
                            *ngIf="listMatchChatDetail?.match_type == '0'"
                            class="p-col-11 letter-spacing-1 third-content-value router-link"
                            (click)="moveC341(listMatchChatDetail?.ns_id)"
                            >{{ listMatchChatDetail?.expected_content }}
                          </span>
                          <span
                            *ngIf="listMatchChatDetail?.match_type == '1'"
                            class="p-col-11 letter-spacing-1 third-content-value router-link"
                            (click)="moveC404(listMatchChatDetail?.match_id)"
                            >{{ listMatchChatDetail?.expected_content }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date" *ngIf="listMatchChatDetail?.match_type == '0'">
                          <span class="p-col-1 letter-spacing-1 third-content-title bold"
                            >メッセージ</span
                          >
                          <span class="p-col-11 letter-spacing-1 third-content-value"
                            >{{ listMatchChatDetail?.memo }}
                          </span>
                        </div>
                        <div class="p-fluid p-formgrid p-grid prefer-date">
                          <span class="p-col-1 letter-spacing-1 third-content-title bold"
                            >アンケート</span
                          >
                          <div class="p-col-5 letter-spacing-1 third-content-value">
                            <a [href]="getUrl()" class="linktext-blue underline"> サポーター用活動アンケート </a>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.DETAIL INFO -->
            <!-- CHAT LIST -->

            <div class="content chat-list" #chatList>
              <ng-container *ngFor="let message of listChatDetail; index as i">
                <div class="row" [ngClass]="setClassBgColor(message)">
                  <ng-container *ngIf="numMsgEdit !== i; else EditMode">
                    <div class="talk-bubble">
                      <div
                        class="chat-hover"
                        *ngIf="isLoginMember(message.chat_member_id_comment)"
                      >
                        <i
                          class="pi pi-ellipsis-h p-pl-2 menu"
                          (click)="menu.toggle($event);onClickMenu(i, message)"
                        ></i>
                        <p-menu #menu [popup]="true"  [model]="items" appendTo="body"></p-menu>
                      </div>
                      <div class="talktext">
                        <h5>
                          <span
                            class="time"
                            [innerText]="
                              formatTime(message.chat_create_datetime)
                            "
                          ></span>
                        </h5>
                        <p>
                          {{ message.chat_chat_comment }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #EditMode>
                    <div class="modeEdit">
                      <textarea
                        [(ngModel)]="chatContentEdit"
                        maxlength="200"
                        pInputTextarea
                        autoResize="autoResize"
                        placeholder=""
                        autocomplete="off"
                      ></textarea>
                      <div class="btn-confirm p-p-1">
                        <i class="pi pi-times" (click)="cancelEdit()"></i>
                        <i
                          class="pi pi-check p-pl-2"
                          (click)="editMessage(message, i, 'DB')"
                        ></i>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
            <!-- /.CHAT LIST -->
            <!-- CHAT TEXTAREA -->
            <!-- <div
              class="footer chat-input"
              *ngIf="listMatchChatDetail?.status !== '4'"
            > -->
            <div
              class="footer chat-input"
            >
              <div class="p-grid">
                <div class="p-col-10 input">
                  <textarea
                    [(ngModel)]="chatContent"
                    [disabled]="sendingStt"
                    maxlength="200"
                    [rows]="2"
                    pInputTextarea
                    placeholder="メッセージを入力してください。"
                    autocomplete="off"
                  ></textarea>
                </div>
                <div class="p-col-2 button">
                  <button
                    [disabled]="sendingStt"
                    pButton
                    label="送信する"
                    (click)="sendMessage()"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.CHAT TEXTAREA -->
        </div>
      </div>   
      <div class="p-col-9 message-detail" *ngIf="listChatCommonCount == 0">
        <br>
        　現在、応募がありません。<br><br>
        　活動の場の詳細を確認したい場合は、<br>
        　メニューの「活動の場登録」から活動の場IDをクリックすることで確認ができます。
      </div>
      <!-- /.RIGHT CONTENT -->
    </div>
  </div>
</div>

<app-datetime-dialog
  *ngIf="isShowTimeDialog"
  (updateCloseDialog)="updateCloseDialog()"
  (returnDateSelect)="updateDateSelect($event)"
  [matchId]="matchId"
  [start_implementation_date]="
    this.c601ChatInputForm.get('start_implementation_date').value
  "
  [end_implementation_date]="
    this.c601ChatInputForm.get('end_implementation_date').value
  "
></app-datetime-dialog>
<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>

<app-confirm-reject-match-dialog
  (reject)="cancelConfirm($event)"
  (isSuccess)="redirectListChat($event)"
  (numMsgEdit)="CancelEditMode($event)"
  (deletedId)="handleDeleteFromList($event)"
  (error)="reset()"
  (fail)="changeStatusFail()"
></app-confirm-reject-match-dialog>
