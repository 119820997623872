import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  API_URL_GET_DETAIL_MATCH,
} from "manager/http-constants";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { PlatformComponent } from "../platform.component";
import { ApprovalDialogComponent } from "../common/approval-dialog/approval-dialog.component";
import { GenerateInputComponent } from "src/app/shared/generate/generate-input/generate-input.component";
import { DeleteDialogComponent } from "src/app/shared/html-parts/delete-dialog/delete-dialog.component";
import { RECRUITMENT_TYPE } from "src/app/shared/constant/constant";

@Component({
  selector: "app-c404",
  templateUrl: "./c404.component.html",
  styleUrls: ["../master/master.component.scss","./c404.component.scss", "../platform.component.scss"],
})

/**
 * 課題・ソリューション詳細
 */
export class C404Component extends PlatformComponent {
  /** 共通部品_承認時ダイアログ */
  @ViewChild(ApprovalDialogComponent)
  approvalDialogComponent: ApprovalDialogComponent;
  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;
  // 課題ID
  agendaId: string = this.route.snapshot.params.agenda_id;

  // 課題・ソリューション詳細格納先
  detailTask: any;

  // マッチング依頼一覧格納先
  matchingList: any;

  // 質問掲示板の件数格納先
  countQuestionBoard: number = 0;
  class_id: any;
  // variable to show content
  isDataLoaded: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private messageData: MessageData,
    private location: Location,
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  public async ngOnInit() {
    // 辞書値リストを取得

    // ユーザマスタに存在する場合
    this.class_id = this.loginUser.class_id;

    await this.getDetailTask();
  }


  /**
   * 課題・ソリューション詳細を取得
   */
  public getDetailTask() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_GET_DETAIL_MATCH);

    return new Promise<any>((resolve) => {
      // 課題・ソリューション詳細を取得
      this.dbOperationService
        .getNoTemplateDisplayData(API_URL_GET_DETAIL_MATCH, this.agendaId)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, API_URL_GET_DETAIL_MATCH);

          if (this.commonService.checkInvalidValue(response)) {
            this.isDataLoaded = false;
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );

            // 前画面へ戻る
            this.location.back();
          } else {
            this.isDataLoaded = true;
            // 課題・ソリューション詳細を格納
            this.detailTask = response.body;

            // 公開掲示板をデフォルトで表示
            // if (1 == this.detailTask?.board_open.id && this.decisionLoginState()) {
            //   this.moveChat()
            // }
          }
          resolve(null);
        });
    });
  }

  moveBack(){
    history.back()
  }

  openURI(url: any) {
    window.open(url, '_blank').focus();
  }
}
