

<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- 戻る -->
    <div class="return">
      <button
        pButton
        type="button"
        label="一覧へ戻る"
        icon="pi pi-angle-left"
        class="p-button-text"
        (click)="moveD211()"
      ></button>
    </div>
    <!-- タイトル -->
    <div class="title">
      <h1>入会申請詳細</h1>
    </div>

    <!-- 内閣府承認情報 -->
    <div class="approval-information">
      <!-- 内閣府承認情報内容 -->
      <p-table [value]="[{}]" styleClass="information-Form">
        <ng-template pTemplate="body">
          <!-- 内閣府承認 -->
          <tr>
            <td class="item-name">運営事務局承認</td>
            <td class="item-information">
              <div [class.denial]="2 == applicationInformation?.status.id">
                <div class="p-d-flex status">
                  <label class="p-as-center">
                    <!-- ステータス -->
                    <label class="status-name" [class.status-red]='applicationInformation?.status==="非承認"'>
                      {{ applicationInformation?.status }}
                    </label>
                    <!-- 承認日付 -->
                    <label>
                      {{ applicationInformation?.status_date }}
                    </label>
                  </label>

                  <!-- 承認可否 -->
                  <button pButton type="button" label="ステータス登録" class="button3 approval-button" [disabled]="applicationInformation?.status!='未承認'"
                    (click)="openSmallApprovalDialog()"></button>
                </div>
                <!-- 承認時コメント -->
                <div class="status-comment">
                  {{ applicationInformation?.status_comment }}
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <!-- 申請情報 -->
    <div class="application-detail">
      <!-- タイトル -->
      <div class="application-detail-title">
        <h1>申請情報</h1>
      </div>
      <!-- 申請詳細 -->
      <div class="application-detail-information">
        <!-- 申請内容 -->
        <p-table [value]="[{}]" styleClass="information-Form">
          <ng-template pTemplate="body">
            <!-- 団体名 -->
            <tr>
              <td class="item-input-name">団体組織区分</td>
              <td class="item-label-name">
                <p-tag value="公開" styleClass="public-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.class_id.name}}
              </td>
            </tr>
            <!-- 団体名 -->
            <tr>
              <td class="item-input-name">団体組織名</td>
              <td class="item-label-name">
                <p-tag value="公開" styleClass="public-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.organization_name }}
              </td>
            </tr>

            <tr>
              <td class="item-input-name">氏名</td>
              <td class="item-label-name">
                <p-tag value="公開" styleClass="public-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.full_name }}
              </td>
            </tr>
            <!-- 郵便番号 -->
            <tr>
              <td class="item-input-name">郵便番号</td>
              <td class="item-label-name">
                <p-tag value="公開" styleClass="public-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.postal_no }}
              </td>
            </tr>
            <!-- 都道府県 -->
            <tr>
              <td class="item-input-name">都道府県</td>
              <td class="item-label-name">
                <p-tag value="公開" styleClass="public-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.prefectures }}
              </td>
            </tr>
            <!-- 所在地 -->
            <tr>
              <td class="item-input-name">所在地</td>
              <td class="item-label-name">
                <p-tag value="公開" styleClass="public-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.address_building }}
              </td>
            </tr>
            <!-- 電話番号 -->
            <tr>
              <td class="item-input-name">電話番号</td>
              <td class="item-label-name">
                <p-tag value="非公開" styleClass="private-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.tel }}
              </td>
            </tr>
            <!-- メールアドレス -->
            <tr>
              <td class="item-input-name">メールアドレス</td>
              <td class="item-label-name">
                <p-tag value="非公開" styleClass="private-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.email }}
              </td>
            </tr>
            <!-- 過去の教育支援実績 -->
            <tr>
              <td class="item-input-name">過去の教育支援</td>
              <td class="item-label-name">
                <p-tag value="非公開" styleClass="private-tag"></p-tag>
              </td>
              <td class="item-information">
                {{ applicationInformation?.history_support_result }}
              </td>
            </tr>

          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- 共通部品_承認時ダイアログ -->
<app-approval-dialog  (returnReject)="moveD211()" (returnApproval)="getDetailApplication()" (invalidObj)="moveD211()"></app-approval-dialog>

<!-- フッダー -->
<footer>
  <app-footer></app-footer>
</footer>