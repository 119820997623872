<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- タイトル -->
    <div class="p-d-flex p-jc-center title">
      <div>
        <!-- <label>地方創生SDGs官民連携プラットフォーム</label><br /> -->
        <label class="p-d-flex p-jc-center title-name">
          <label *ngIf="information">お知らせ</label>
          <!-- <label *ngIf="!information">会員からのお知らせ</label> -->
        </label>
      </div>
    </div>
    <!-- 本文 -->
    <!-- <div>
      <label *ngIf="information"
        >運営事務局より、ウェブサイトの更新やプラットフォーム主催のイベント等についてお知らせします。</label
      >
      <label *ngIf="!information"
        >会員の皆様の主催によるイベントや、分科会からのお知らせについて情報発信します。<br />
        ※各会員イベント等について、当プラットフォーム及び運営事務局(内閣府)が管理・保証するものではありません。</label
      >
    </div> -->

    <!-- お知らせ -->
    <div class="notice">
      <p-tabView>
        <p-tabPanel header="お知らせ" *ngIf="information">
          <ng-container
            *ngTemplateOutlet="noticeList; context: { information_kbn: 1 }"
          ></ng-container>
        </p-tabPanel>
        <p-tabPanel header="システムに関するお知らせ" *ngIf="information">
          <ng-container
            *ngTemplateOutlet="noticeList; context: { information_kbn: 2 }"
          ></ng-container>
        </p-tabPanel>
        <!-- <p-tabPanel header="会員主催イベント" *ngIf="!information">
          <ng-container
            *ngTemplateOutlet="membreEvent; context: { event_kbn: 1 }"
          ></ng-container>
        </p-tabPanel>
        <p-tabPanel header="分科会からのお知らせ" *ngIf="!information">
          <ng-container
            *ngTemplateOutlet="membreEvent; context: { event_kbn: 2 }"
          ></ng-container>
        </p-tabPanel> -->
      </p-tabView>
    </div>
  </div>
</div>

<!-- お知らせ,システムに関するお知らせ リスト -->
<ng-template #noticeList let-information_kbn="information_kbn">
  <!-- お知らせ区分を判定-->
  <p-dataView
    [value]="1 == information_kbn ? noticeDataList : platformEventDataList"
    emptyMessage="データが存在しません"
  >
    <!-- 1:お知らせの場合、noticeDataListを使用 -->
    <!-- 2:PF主催イベントの場合、platformEventDataListを使用 -->
    <ng-template let-noticeData pTemplate="listItem">
      <div class="p-col-12">
        <!-- 一覧アイテム-->
        <div
          class="list-data"
          [value]="noticeData.url"
          (click)="detailInformation(noticeData.information_id)"
        >
          <!-- 掲載開始日 -->
          <div class="list-date">
            {{ noticeData.publishe_start }}
          </div>
          <!-- 見出し -->
          <div class="list-heading p-mr-5">
            {{ noticeData.head }}
          </div>
          <!-- タイトル -->
          <div class="list-title">
            {{ noticeData.title }}
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</ng-template>

<!-- 会員主催イベント リスト -->
<ng-template #membreEvent let-event_kbn="event_kbn">
  <p-dataView
    [value]="1 == event_kbn ? memberEventDataList : subcommitteeEventDataList"
    emptyMessage="データが存在しません"
  >
    <ng-template let-memberEventData pTemplate="listItem">
      <div class="p-col-12">
        <!-- 一覧アイテム-->
        <div
          class="list-data"
          (click)="moveE102(memberEventData.member_event_id)"
        >
          <!-- 掲載日 -->
          <div class="list-date-pf">
            {{ memberEventData.status.date }}
          </div>
          <!-- 申請団体名 -->
          <div class="list-org-pf">
            {{ memberEventData.organization_name }}
          </div>
          <!-- タイトル -->
          <div class="list-title-pf">
            {{ memberEventData.title }}
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</ng-template>

