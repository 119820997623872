import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_ADD, USER_EDIT, USER_GENERATE } from 'manager/http-constants';
import { forkJoin, Observable } from 'rxjs';
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { Validator } from 'src/app/shared/validator/validator';
import { PlatformComponent } from '../platform.component';
import { LoginService } from 'src/app/shared/service/login.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-b221',
  templateUrl: './b221.component.html',
  styleUrls: ['./b221.component.scss', '../platform.component.scss'],
})

/**
 * ユーザ登録(B221)
 * ユーザ登録確認(B222)
 */
export class B221Component extends PlatformComponent {
  // 画面.入力フォーム
  member_id: string =  CONSTANT.EMPTY_STRING;
  b221InputForm: FormGroup = this.formBuilder.group({
    member_id: CONSTANT.EMPTY_STRING,
    // 部局名／事業部名
    // department_name: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
    //   asyncValidators: [
    //     this.validator.CheckValidator(
    //       'user',
    //       'department_name',
    //       INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
    //     ),
    //   ],
    // }),
    // 課室名／部署名
    // division_name: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
    //   asyncValidators: [
    //     this.validator.CheckValidator(
    //       'user',
    //       'division_name',
    //       INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
    //     ),
    //   ],
    // }),
    // 役職
    // jobposition_name: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
    //   asyncValidators: [
    //     this.validator.CheckValidator(
    //       'user',
    //       'jobposition_name',
    //       INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
    //     ),
    //   ],
    // }),
    // 氏名
    full_name: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
        this.validator.CheckValidator(
          'user',
          'full_name',
          INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
        ),
      ],
    }),
    // 電話番号
    // tel: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
    //   asyncValidators: [
    //     this.validator.CheckValidator(
    //       'user',
    //       'tel',
    //       INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
    //     ),
    //   ],
    // }),
    // emailアドレス
    // email: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
    //   asyncValidators: [
    //     this.validator.CheckValidator(
    //       'user',
    //       'email',
    //       INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
    //     ),
    //   ],
    // }),
    // emailアドレス確認
    // email_confirmation: this.formBuilder.control(CONSTANT.EMPTY_STRING),
    // メルマガ受信設定
    email: this.formBuilder.control(CONSTANT.EMPTY_STRING, {
      asyncValidators: [
      this.validator.CheckValidator(
         'user',
         'email',
         INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE
      ),
      ],
    }),
  });

  user_id: string = this.route.snapshot.queryParams.user_id;
  confirmationFlag: boolean = false;
  editDisplayEmail: string;
  flg_true = 1
  flg_false = 0

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private formBuilder: FormBuilder,
    private validator: Validator,
    private messageData: MessageData,
    public loginService: LoginService,
    private location: Location,
  ) {
    super(route, commonService, dbOperationService, loadingState);

    this.router.navigate(['pages/platform/b221/' + this.inputType], {
      queryParams: {
        screenId: 'b221',
        user_id: this.user_id,
      },
    });
  }

  ngOnInit(): void {
    this.getUserMemberId();
  }

  ngAfterViewInit(): void {
    // 登録タイプ判定
    // if (GENERATE_INPUT_TYPE.NEW == this.inputType) {
    //   // 新規の場合
    //   // emailアドレス確認チェックを実施
    //   this.checkConfirmationEmail();
    // }
  }

  private setEditInitialData() {
    if (!(GENERATE_INPUT_TYPE.EDIT == this.inputType)) {
      return;
    }

    this.loadingState.loadStart(USER_GENERATE);

    this.dbOperationService
      .getNoTemplateDisplayData(USER_GENERATE, this.user_id, false)
      .subscribe((response) => {
        if (this.commonService.checkNoneResponse(response)) {
          return;
        }else if (this.commonService.checkInvalidValue(response)) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.location.back();
        }
        if (response.body.member_id != this.member_id) {
          this.loginService.logout();
        }
        this.commonService.setFormGroupInitialData(
          this.b221InputForm,
          response.body
        );

        this.editDisplayEmail = response.body.email;

        this.b221InputForm.patchValue({
          'email': response.body.email
        })

        this.b221InputForm.get('email').disable();

      
        this.loadingState.loadSleepEnd(0.3, USER_GENERATE);
      });
  }

  public getUserMemberId(): string | void {
    this.loginService.getLoginUser().subscribe((response) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3);

      // ログインユーザ情報がユーザマスタに存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        // ユーザマスタに存在しない場合

        // 不正なユーザの為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.E90000);

        // 処理を終了
        return;
      } else {
        // ユーザマスタに存在する場合
        this.member_id = response.body.member_id;
            // 編集初期表示
        this.setEditInitialData();
      }
    });
  }

  public moveB222(): void {
    let b221InputForm: any = JSON.parse(
      JSON.stringify(this.b221InputForm.value)
    );

    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: '',
        background_color: '',
        opacity: 0.3,
      })
    );

    let task: Observable<any>[] = [
      this.dbOperationService.multiValidationResult('user', b221InputForm),
    ];

    forkJoin(task).subscribe((dataList) => {
      this.loadingState.loadEnd();

      this.router.navigate(
        ['pages/platform/b221/' + this.route.snapshot.params.inputType],
        {
          queryParams: {
            screenId: 'b222',
            user_id: this.user_id,
          },
        }
      );
      this.confirmationFlag = true;
    });
  }

  public moveB223(): void {
    let b221InputForm: any = JSON.parse(
      JSON.stringify(this.b221InputForm.value)
    );

    this.loadingState.loadStart(LOADING_KEY.DATA_INSERT);

    if (GENERATE_INPUT_TYPE.NEW == this.inputType) {
      b221InputForm.member_id = this.loginUser.member_id;

      this.dbOperationService
        .insertData(USER_ADD, b221InputForm)
        .subscribe((response) => {
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

          if (API_RESPONSE.SUCCESS == response.body.Message) {
            this.router.navigate(['pages/platform/b223']);
          }else if (this.commonService.checkInvalidValue(response)) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.moveB220();
          } else {
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(MESSAGE_CODE.E00001),
                detail: this.commonService.msg(MESSAGE_CODE.E80002),
              })
            );

            this.confirmationFlag = false;
          }
        });
    } else if (GENERATE_INPUT_TYPE.EDIT == this.inputType) {
      this.dbOperationService
        .updateData(USER_EDIT, this.user_id, b221InputForm)
        .subscribe((response) => {
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);

          if (API_RESPONSE.SUCCESS == response.body.Message) {
            this.router.navigate(['pages/platform/b223']);
          }else if (this.commonService.checkInvalidValue(response)) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.moveB220();
          } else {
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(MESSAGE_CODE.E00001),
                detail: this.commonService.msg(MESSAGE_CODE.E80002),
              })
            );

            this.confirmationFlag = false;
          }
        });
    }
  }
  moveB220(){
    this.router.navigate(['pages/platform/b220']);
  }
}
