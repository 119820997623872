<!-- 入力画面ダイアログ -->
<p-dialog [(visible)]="inputModal" [modal]="true" [resizable]="false">
  <!-- 入力情報 -->
  <ng-template pTemplate="header">
    <div>
      <span class="p-dialog-title">{{ title }}</span>
      <a *ngIf="showLinkPDF" class="linktext-blue underline link-pdf" (click)="openURI()">？入力内容について</a>
    </div>
  </ng-template>
  <app-generate-input-information
    (reloadID)="return($event)"
  ></app-generate-input-information>
</p-dialog>
