import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GenerateDisplayInformationComponent } from './generate-display-information/generate-display-information.component';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';

@Component({
  selector: 'app-generate-display',
  templateUrl: './generate-display.component.html',
  styleUrls: ['./generate-display.component.scss'],
})

/**
 * 詳細画面ダイアログ
 */
export class GenerateDisplayComponent implements OnInit {
  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 詳細画面ダイアログ表示フラグ
  detailsNav: boolean;

  constructor() {}

  ngOnInit(): void {}

  /**
   * 詳細画面ダイアログ表示
   * @param generateDisplay 詳細情報入力オブジェクト(オブジェクトorリスト)
   * @param pkeyId 選択対象ID
   */
  public initial(
    generateDisplay: GenerateDisplay | GenerateDisplay[],
    pkeyId?: string
  ) {
    // 詳細画面ダイアログ表示
    this.detailsNav = true;

    // 詳細情報表示
    this.generateDisplayInformationComponent.initial(generateDisplay, pkeyId);
  }

  close(){
    this.detailsNav = false;
  }
  reload(pkeyId: string) {
    if(!this.generateDisplayInformationComponent.noReload){
      this.close();
    }
    this.reloadID.emit(pkeyId);
  }
}
