<!-- 詳細情報出力オブジェクトリスト(画面表示情報)分ループ-->
<ng-container *ngFor="let outputGenerateDisplay of outputGenerateDisplayList">
  <!-- 詳細情報出力領域 -->
  <p-table
    [value]="outputGenerateDisplay.generateDisplay"
    styleClass="p-datatable-sm"
  >
    <!-- 詳細情報タイトル -->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <th colspan="2">{{ outputGenerateDisplay.title }}</th>
      </tr>
    </ng-template>
    <!-- 詳細情報出力箇所 -->
    <ng-template pTemplate="body" let-generateDisplayInformation>
      <tr>
        <!-- カラム名称 -->
        <td class="table-body-label">
          {{ generateDisplayInformation.column_name }}
        </td>

        <!-- カラムデータ -->
        <!-- データ判定 -->
        <!-- プライマリーキーの場合 -->
        <td
          class="table-body-data"
          *ngIf="'P' == generateDisplayInformation.column_pkey; else notPkey"
        >
          {{ generateDisplayInformation.data }}
        </td>

        <!-- プライマリーキー以外の場合 -->
        <ng-template #notPkey>
          <!-- カラム判定 -->
          <td
            class="table-body-data"
            [ngSwitch]="generateDisplayInformation?.input_type"
          >
            <span *ngSwitchCase="'NUMBER'">
              <!-- 数値の場合 -->

              <!-- TODO 特定カラムID判定は暫定対応-->
              <span
                *ngIf="
                  generateDisplayInformation.column_id == 'item_code' ||
                    generateDisplayInformation.column_id == 'delivery_id';
                  else PkExcepting
                "
              >
                {{ generateDisplayInformation.data }}
              </span>
              <!-- 特定カラムID以外の場合 -->
              <ng-template #PkExcepting>
                {{ generateDisplayInformation.data | number }}
              </ng-template>
            </span>
            <span *ngSwitchCase="'IMAGE'">
              <!-- 画像の場合 -->
              <img
                [src]="generateDisplayInformation.data.url"
                alt="image"
                (error)="handleMissingImage($event)"
              />
            </span>
            <span *ngSwitchCase="'FILE'">
              <!-- ファイルの場合 -->
              <a [href]="generateDisplayInformation.data.url" target="_blank" class="linktext-blue underline">
                {{ generateDisplayInformation.data.name }}
              </a>
            </span>
            <span *ngSwitchCase="'URL'">
              <!-- URLの場合 -->
              <a [href]="generateDisplayInformation.data.url" target="_blank" class="linktext-blue underline">
                {{ generateDisplayInformation.data.name }}
              </a>
            </span>
            <span *ngSwitchDefault>
              <!-- 上記以外の場合の場合 -->
              <ng-container *ngIf="(generateDisplayInformation.column_id=='url' && generateDisplayInformation.table_id == 'view_information') ||( generateDisplayInformation.column_id=='agenda_need_expected_url' && generateDisplayInformation.table_id =='view_agenda_need' ); else default">
                <a [href]="generateDisplayInformation.data" target="_blank" class="linktext-blue underline">
                  {{ generateDisplayInformation.data }}
                </a>
              </ng-container>
              <ng-template #default>
                {{ generateDisplayInformation.data }}
              </ng-template>
            </span>
          </td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
  <!-- 改行 -->
  <br />
  <p-footer *ngIf="outputGenerateDisplay.generateDisplay[0]?.table_id == 'view_member' && classId === '9'" >
    <p-table [value]="[{}]" styleClass="p-datatable-sm"> -->
      <!-- 在庫管理タイトル -->
      <ng-template pTemplate="header">
        <tr class="p-col-2">
          <th colspan="2">申請ステータス</th>
        </tr>
      </ng-template>
      <!-- pdf managers -->
      <ng-template pTemplate="body">
        <tr >
            <!-- 承認可否 -->
            <button
              pButton
              type="button"
              label="ステータス登録"
              class="button1 approval-button p-mt-2"
              [disabled]="outputGenerateDisplay.generateDisplay[9].data == '承認済'"
              (click)="openSmallApprovalDialog()"
            ></button>
        </tr>
      </ng-template>
    </p-table>
  </p-footer>
</ng-container>

 <!-- 共通部品_承認時ダイアログ -->
<app-approval-dialog (returnApproval)="approval()" (returnReject)="reject()" (invalidObj)="reject()"></app-approval-dialog>