<!-- 確認ダイアログ -->
<p-confirmDialog
  #cd
  header=" "
  icon="pi pi-info-circle"
  [autoZIndex]="true"
  [focusTrap]="true"
>
  <p-footer>
    <div class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button btn-confirm"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button btn-cancel"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>
