<p-dialog
  header="評価結果詳細"
  [(visible)]="boardFlag"
  [modal]="true"
  [responsive]="true"
  [styleClass]="'main-dialog'"
  (onHide)="closeDialog()"
>
  <div class="main-height">
    <div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12 p-jc-center">
       

          <p-table [value]="data">
            <ng-template pTemplate="header"> </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr>
                <td class="legend-cell">
                  <label
                    for="school"
                    class="school-label bold"
                    *ngIf="data.match_type === 1"
                    >教育支援内容タイトル</label
                  >
                  <label
                    for="school"
                    class="school-label bold"
                    *ngIf="data.match_type === 0"
                    >提案タイトル</label
                  >
                </td>
                <td class="non-edit-cell">
                  {{ data?.title }}
                </td>
              </tr>

              <tr>
                <td class="legend-cell bold">
                  <label for="school" class="school-label bold">学年</label>
                </td>
                <td class="non-edit-cell">
                  {{ data?.target_grade }}
                </td>
              </tr>
              <tr>
                <td class="legend-cell bold">
                  <label for="school" class="school-label bold">記録者</label>
                </td>
                <td class="non-edit-cell">
                  {{ data?.organization_name }}
                </td>
              </tr>
              <tr>
                <td class="legend-cell bold">
                  <label for="school" class="school-label bold">実施日</label>
                </td>
                <td class="non-edit-cell">
                  {{ data?.implementation_date ? formatDateJapan(data?.implementation_date) : ''}}
                </td>
              </tr>
              <tr>
                <td class="legend-cell bold">
                  <label for="school" class="school-label bold"
                    >教育支援の内容</label
                  >
                </td>
                <td class="non-edit-cell">
                  {{ data?.detail }}
                </td>
              </tr>
              <tr>
                <td class="legend-cell bold">
                  <label for="school" class="school-label bold"
                    >児童生徒の反応</label
                  >
                </td>
                <td class="non-edit-cell">
                  {{ data?.comment }}
                </td>
              </tr>
              <tr>
                <td class="legend-cell bold">
                  <label for="school" class="school-label bold">評価</label>
                </td>
                <td class="non-edit-cell-rate">
                  <div>
                    <div class="p-grid">
                      <div class="p-col-12 p-d-flex p-ai-center list-rate-container">
                        <div
                          class="p-fluid p-formgrid p-grid p-d-flex p-ai-center p-jc-center list-rate-wraper"
                        >
                          <span class="p-col-2">良い</span>
                          <div
                            class="list-rate p-col-8"
                          >
                            <div class="star-rate">
                              <span class="star-rate-number">5</span>
                              <span
                                class="circle"
                                *ngIf="data?.evaluation == '5'"
                              ></span>
                            </div>
                            <div class="star-rate">
                              <span class="star-rate-number">4</span>
                              <span
                                class="circle"
                                *ngIf="data?.evaluation == '4'"
                              ></span>
                            </div>
                            <div class="star-rate">
                              <span class="star-rate-number">3</span>
                              <span
                                class="circle"
                                *ngIf="data?.evaluation == '3'"
                              ></span>
                            </div>
                            <div class="star-rate">
                              <span class="star-rate-number">2</span>
                              <span
                                class="circle"
                                *ngIf="data?.evaluation == '2'"
                              ></span>
                            </div>
                            <div class="star-rate">
                              <span class="star-rate-number">1</span>
                              <span
                                class="circle"
                                *ngIf="data?.evaluation == '1'"
                              ></span>
                            </div>
                          </div>
                          <span class="p-col-2 list-rate-end"
                            >悪い</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="legend-cell bold">
                  <label for="school" class="school-label bold"
                    >評価の理由</label
                  >
                </td>
                <td class="non-edit-cell">
                  {{ data?.reason }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
