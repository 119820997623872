import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

/** モジュール */
// ルーティング
import { sharedRoutingModule } from "./shared-routing.module";

// 共通
import { LibraryModule } from "src/app/library/library.module";

// Ngx Socket IO Module
import { SocketIoModule } from "ngx-socket-io";

/** コンポーネント */
// GENERATE
import { GenerateDisplayComponent } from "./generate/generate-display/generate-display.component";
import { GenerateDisplayInformationComponent } from "./generate/generate-display/generate-display-information/generate-display-information.component";
import { GenerateInputComponent } from "./generate/generate-input/generate-input.component";
import { GenerateInputInformationComponent } from "./generate/generate-input/generate-input-information/generate-input-information.component";
import { GenerateInputFormComponent } from "./generate/generate-input/generate-input-information/generate-input-form/generate-input-form.component";
import { GenerateInputErrorMessageComponent } from "./generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component";
import { GenerateSearchComponent } from "./generate/generate-search/generate-search.component";

// HTML_PARTS
import { ConfirmDialogComponent } from "./html-parts/confirm-dialog/confirm-dialog.component";
import { FooterComponent } from "./html-parts/footer/footer.component";
import { HeaderComponent } from "./html-parts/header/header.component";
import { MessageCommonComponent } from "./html-parts/message-common/message-common.component";
import { LoadingComponent } from "./html-parts/loading/loading.component";

// VALIDATOR
import { ValidatorMessageComponent } from "./validator/validator-message.component";

// 確認ダイアログボタンクラス
import { ConfirmDialogData } from "./html-parts/confirm-dialog/confirm-dialog-data";

// ローディング状態クラス
import { LoadingState } from "./html-parts/loading/loading-state";

// メッセージクラス
import { MessageData } from "./html-parts/message-common/message-data";
import { DeleteDialogComponent } from "./html-parts/delete-dialog/delete-dialog.component";
import { MessageContainerComponent } from "./html-parts/message-container/message-container.component";
import { socketIoConfig } from "manager/environment";
import { ApprovalDialogComponent } from "../pages/platform/common/approval-dialog/approval-dialog.component";
import { NotificationDialogComponent } from "./html-parts/notification-dialog/notification-dialog.component";
import { LoginModule } from "./login/login.module";

@NgModule({
  declarations: [
    // GENERATE
    GenerateDisplayComponent,
    GenerateDisplayInformationComponent,
    GenerateInputComponent,
    GenerateInputInformationComponent,
    GenerateInputFormComponent,
    GenerateInputErrorMessageComponent,
    GenerateSearchComponent,

    // HTML_PARTS
    ConfirmDialogComponent,
    FooterComponent,
    HeaderComponent,
    MessageCommonComponent,
    LoadingComponent,
    MessageContainerComponent,
    ApprovalDialogComponent,
    NotificationDialogComponent,

    // VALIDATOR
    ValidatorMessageComponent,
    DeleteDialogComponent,
  ],
  imports: [
    CommonModule,
    sharedRoutingModule,
    LibraryModule,
    LoginModule,
    // SocketIoModule.forRoot(socketIoConfig),
  ],
  exports: [
    // GENERATE
    GenerateDisplayComponent,
    GenerateDisplayInformationComponent,
    GenerateInputComponent,
    GenerateInputInformationComponent,
    GenerateInputFormComponent,
    GenerateInputErrorMessageComponent,
    GenerateSearchComponent,

    // HTML_PARTS
    ConfirmDialogComponent,
    FooterComponent,
    HeaderComponent,
    MessageCommonComponent,
    LoadingComponent,
    ApprovalDialogComponent,
    NotificationDialogComponent,


    // VALIDATOR
    ValidatorMessageComponent,
    DeleteDialogComponent,
    MessageContainerComponent,
  ],
  providers: [ConfirmDialogData, LoadingState, MessageData],
})
export class SharedModule {}
