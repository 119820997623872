<!-- メイン -->
<div class="main">
  <!-- メインコンテンツ -->
  <div class="main-content" [class.platform-large-text]="getTextSize()">
    <!-- A210系共通部品_Steps -->
    <app-a210-steps></app-a210-steps>

    <!-- メッセージ -->
    <div class="message">
      <!-- メッセージ本文 -->
      <div class="message-text">
        <div class="message-margin"></div>
        <div class="p-d-flex p-jc-center red-color">ＩＤ・パスワード登録</div>
      </div>
    </div>

    <!-- ＩＤ登録タイトル -->
    <div class="idInput-title">
      <h1>ＩＤ（メールアドレス）入力</h1>
    </div>

    <!-- 入力項目 -->
    <div class="sub-text">
      <!-- 入力内容 -->
      <div class="sub-div">
        ＩＤ（申請済みメールアドレス）を入力の上、パスワードを設定してください。<br />
        ※申請済みのメールアドレスがログインＩＤとなります。<br />
        <div class="input-margin"></div>
      </div>
      <!-- 入力フォーム -->
      <p-table [value]="[{}]" styleClass="information-Form">
        <ng-template pTemplate="body">
          <tr>
            <td class="item-name">
              eメールアドレス
              <span class="required">【必須】</span>
            </td>
            <td class="item-information">
              <input
                type="text"
                pInputText
                placeholder="abcdeg0123@hijklmn.jp"
                class="information-Form-inputtext"
                [formControl]="email"
                [ngClass]='checkInputMaxBoundary(email)?"invalid-length":""'
                autocomplete="off"
              />
              <!-- エラーメッセージ出力箇所 -->
              <app-validator-message [control]="email"> </app-validator-message>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <!-- ボタンエリア -->
    <div class="p-d-flex p-jc-center button-area">
      <button
        pButton
        type="button"
        label="パスワード設定メールを送信する"
        class="button-default-size button1 btn-confirm-code"
        (click)="moveA211()"
        [disabled]="!email.valid"
      ></button>
    </div>
  </div>
</div>

